import { FC } from 'react';
import { useAuth } from '@hooks/useAuth';
import { AppRoutes } from '@common/routes';
import * as Sentry from '@sentry/react';
import { ConfirmProvider } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import {
  CollapseDrawerProvider,
  LoadingScreen,
  NotistackProvider,
  ScrollToTop,
} from './components';
import { ThemeConfig } from './theme';
import { GlobalStyles } from './theme/globalStyles';
import { MaintenancePage } from './pages/MaintenancePage/MaintenancePage';

// Hatch to force re-render ConfirmProvider after language change
const ConfirmProviderText = (props: any) => {
  const { t } = useTranslation();
  return t(props.i18nKey) as any;
};

export const App: FC = () => {
  const { isInitializing } = useAuth();

  return (
    <ThemeConfig>
      <CollapseDrawerProvider>
        <NotistackProvider>
          <ConfirmProvider
            defaultOptions={{
              cancellationText: <ConfirmProviderText i18nKey="cancel" />,
              confirmationText: <ConfirmProviderText i18nKey="yes" />,
              title: <ConfirmProviderText i18nKey="areYouSure" />,
            }}
          >
            <GlobalStyles />
            <ScrollToTop />
            {/*todo: uncomment after adding measurement id (or not)*/}
            {/*<GoogleAnalytics />*/}
            <Sentry.ErrorBoundary
              fallback={props => <MaintenancePage {...props} />}
            >
              {!isInitializing ? <AppRoutes /> : <LoadingScreen />}
            </Sentry.ErrorBoundary>
          </ConfirmProvider>
        </NotistackProvider>
      </CollapseDrawerProvider>
    </ThemeConfig>
  );
};
