import React, { FC } from 'react';
import { Page } from '@components/Page';
import { useTranslation } from 'react-i18next';
import { Container, Paper } from '@mui/material';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { AdvertiserRefLinksTable } from '@pages/AdvertiserRefLinksPage/AdvertiserRefLinksTable';
import { CreateRefLinkButton } from './CreateRefLinkButton';

export const AdvertiserRefLinksPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('pageTitle.advertiserRefLinks')}>
      <Container>
        <HeaderBreadcrumbs
          action={<CreateRefLinkButton />}
          heading={t('pageTitle.advertiserRefLinks')}
          links={[]}
        />
        <Paper elevation={3}>
          <AdvertiserRefLinksTable />
        </Paper>
      </Container>
    </Page>
  );
};
