import { Link, useLocation } from 'react-router-dom';
import { AdvertiserStatus, UserRole } from '@shared/constants';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { ROUTES } from '@common/routes';
import { FC, useEffect } from 'react';
import { useCurrentUser } from '@hooks/useCurrentUser';

const AddendumSignNeededNotification = ({ role }) => {
  const { t } = useTranslation();

  if (role === UserRole.AdvertiserMember) {
    return (
      <Stack>
        <Typography>
          {t('AddendumSignNotificationMember.description')}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography variant={'h6'}>
        {t('AddendumSignNotification.title')}
      </Typography>{' '}
      <Typography>{t('AddendumSignNotification.description')}</Typography>
    </Stack>
  );
};
const AddendumSignNeededNotificationAction = () => {
  const { t } = useTranslation();
  return (
    <Link to={ROUTES.ADVERTISER.documents()}>
      <Button>{t('sign')}</Button>
    </Link>
  );
};

interface Props {
  status: AdvertiserStatus;
}

const SNACKBAR_KEY = 'encourageSignAddendum';

export const SignAddendumNotification: FC<Props> = ({ status }) => {
  const location = useLocation();
  const { role } = useCurrentUser();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  if (
    status === AdvertiserStatus.ADDENDUM_SIGNING &&
    location.pathname !== ROUTES.ADVERTISER.documents.path
  ) {
    enqueueSnackbar(<AddendumSignNeededNotification role={role} />, {
      action: role === UserRole.Advertiser && (
        <AddendumSignNeededNotificationAction />
      ),
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      key: SNACKBAR_KEY,
      persist: true,
      preventDuplicate: true,
      variant: 'info',
    });
  } else {
    closeSnackbar(SNACKBAR_KEY);
  }

  useEffect(() => () => closeSnackbar(SNACKBAR_KEY), []);

  return null;
};
