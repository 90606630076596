import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { Stack, Typography } from '@mui/material';
import { Checkboxes } from '@common/forms/fields';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { InsurancePurpose } from '@shared/constants';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_insurancePurposes: Yup.array()
      .ensure()
      .min(1, t('validation.required')),
  });

export const OccupationStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.ocсupation')}</Typography>
      </StepTitle>

      <Checkboxes
        name="main_0_insurancePurposes"
        data={[
          {
            label: t('study') as string,
            value: InsurancePurpose.Study,
          },
          {
            label: t('pension') as string,
            value: InsurancePurpose.Pension,
          },
          {
            label: t('unemployment') as string,
            value: InsurancePurpose.Unemployment,
          },
          {
            label: t('employment') as string,
            value: InsurancePurpose.Employment,
          },
          {
            label: t('partner') as string,
            value: InsurancePurpose.Partner,
          },
          {
            label: t('trainee') as string,
            value: InsurancePurpose.Trainee,
          },
          {
            label: t('occupation.other') as string,
            value: InsurancePurpose.Other,
          },
        ]}
      />
    </Stack>
  );
};

OccupationStep.getValidationSchema = getValidationSchema;
