import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import { useClipboard } from '@common/hooks';

interface Props {
  link: string;
}

export const AdvertiserRefLinkLinkCell: React.FC<Props> = React.memo(
  ({ link }) => {
    const { t } = useTranslation();
    const copyToClipboard = useClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const onClick = () => {
      copyToClipboard(link);
      enqueueSnackbar(t('copiedToClipboard'), { variant: 'success' });
    };

    return (
      <Typography variant="body2" onClick={onClick} sx={{ cursor: 'pointer' }}>
        {link}
      </Typography>
    );
  },
);
