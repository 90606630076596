import { FC, memo } from 'react';
import { Box, Stack } from '@mui/material';
import { Dot } from './Dot';

interface Props {
  count: number;
  selectedIndex: number;
  onDotClick: (index: number) => any;
}

// todo max count
export const DotStepper: FC<Props> = memo(
  ({ count, selectedIndex, onDotClick }) => (
    <Stack direction="row">
      {[...Array(count)].map((_, index) => (
        <Box
          onClick={() => onDotClick(index)}
          key={index}
          sx={{ cursor: 'pointer', padding: 0.5 }}
        >
          <Dot isSelected={index === selectedIndex} />
        </Box>
      ))}
    </Stack>
  ),
);
