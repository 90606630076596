import { Typography } from '@mui/material';
import { getProfileTypeLabel } from '@src/helpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  profileType: string;
  index: string;
  fullName: string;
}
export const InsuranceProfileGroupTitle: FC<Props> = ({
  profileType,
  index,
  fullName,
}) => {
  const { t } = useTranslation();

  return (
    <Typography paddingBottom={1} variant="subtitle2" color="GrayText">
      {getProfileTypeLabel({ index, profileType, t })}{' '}
      {fullName ? `(${fullName})` : ''}
    </Typography>
  );
};
