import React from 'react';
import MaintenancePage from '../pages/MaintenancePage';

export interface Props {
  children: React.ReactNode;
  fallbackComponent: React.ComponentType<any>;
}

export class ErrorBoundary extends React.Component<
  Props,
  { eventId: string | null; hasError: boolean }
> {
  static defaultProps = {
    fallbackComponent: MaintenancePage,
  };
  constructor(props: Props) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  static getDerivedStateFromError(): { hasError: true } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // todo: sentry setup
    // Sentry.withScope(scope => {
    //   scope.setExtras(errorInfo as any);
    //   Sentry.captureException(error);
    // });
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { fallbackComponent: FallbackComponent, children } = this.props;

    if (hasError) {
      return <FallbackComponent />;
    }

    return children;
  }
}

export function withErrorBoundary<TProps extends Record<string, any>>(
  fallbackComponent?: React.ComponentType<any>,
) {
  return (Component: React.ComponentType<TProps>): React.FC<TProps> =>
    props =>
      (
        <ErrorBoundary fallbackComponent={fallbackComponent}>
          <Component {...props} />
        </ErrorBoundary>
      );
}
