import { FormStepper } from '@components/forms/FormStepper';
import { FormStepsSidebar } from '@components/forms/FormStepsSidebar';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { GQL } from '@queries';
import { useMutation } from '@apollo/client';
import {
  UpdateAdvertiserProfile,
  UpdateAdvertiserProfileVariables,
} from '@queries/types/UpdateAdvertiserProfile';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ROUTES } from '@common/routes';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { Typography } from '@mui/material';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import createCalculateDecorator from 'final-form-calculate';
import { IBANCodes } from '@components/forms/AdvertiserVerificationForm/IBANCodes';
import { BankAccountType } from '../../../../types/globalTypes';
import { getSteps } from './getSteps';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  minHeight: '100%',
  overflow: 'auto',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: parseInt(theme.spacing(4)),
    paddingRight: theme.spacing(4),
    paddingTop: APP_BAR_MOBILE + 24,
  },
}));

const StepStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 800,
  },
  [theme.breakpoints.down('lg')]: {
    width: 600,
  },
  [theme.breakpoints.down('sm')]: {
    width: 400,
  },
}));

const calculateFields = createCalculateDecorator({
  field: 'address.country',
  updates: {
    ['bankAccount.type']: (fieldValue, allValues: any) =>
      fieldValue in IBANCodes ? BankAccountType.IBAN : BankAccountType.SWIFT,
  },
});

interface Props {
  initialValues?: Record<string, any>;
  initialStepName?: string | null;
  formContainerId: string;
}

export const AdvertiserVerificationForm: FC<Props> = ({
  initialValues,
  initialStepName,
  formContainerId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { advertiserId } = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [updateAdvertiser] = useMutation<
    UpdateAdvertiserProfile,
    UpdateAdvertiserProfileVariables
  >(GQL.ADVERTISER.UPDATE_PROFILE);

  const onSubmit = async values => {
    // todo: is there any way to omit that unused variable?
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isPrivacyPolicyAccepted, ...advertiser } = values;
    try {
      await updateAdvertiser({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          advertiserId: advertiserId!,
          input: {
            ...advertiser,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bankAccount: {
              iban: advertiser.bankAccount?.iban?.code
                ? advertiser.bankAccount.iban
                : null,
              swift: advertiser.bankAccount?.swift?.name
                ? advertiser.bankAccount.swift
                : null,
            },
          },
        },
      });
      navigate(ROUTES.ADVERTISER.root.path);
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  return (
    <MainStyle>
      <FormStepper
        getSteps={getSteps}
        initialValues={initialValues}
        initialStepName={initialStepName}
        persistenceData={{
          formContainerId,
          formType: 'advertiserVerification',
        }}
        onSubmit={onSubmit}
        decorators={[calculateFields]}
      >
        {({
          currentStep: { component: CurrentStep, stepNameSidebar, meta },
          stepsWithMeta,
        }) => (
          <>
            <FormStepsSidebar stepsWithMeta={stepsWithMeta} />
            <Helmet>
              <title>{stepNameSidebar}</title>
            </Helmet>
            <StepStyled sx={{ width: 400 }}>
              <Typography variant="h4" sx={{ pb: 2 }}>
                {t('letsGetKnowYou')}
              </Typography>
              <CurrentStep meta={meta} />
            </StepStyled>
          </>
        )}
      </FormStepper>
    </MainStyle>
  );
};
