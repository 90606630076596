import { Alert, AlertTitle, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { Select, TextField } from '@common/forms/fields';
import { StepComponent } from '@components/forms/types';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { ConditionalFields } from '@components/forms';
import { toUpperCase } from '@common/forms/normalization';
import { IBANCodeSchema } from '@common/validationSchemas/IBANCodeSchema';
import { LATIN_REG_EXP } from '@src/../../../shared/regex';
import { BankAccountType } from '../../../../../types/globalTypes';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    bankAccount: Yup.object().shape({
      iban: Yup.object().when('type', {
        is: BankAccountType.IBAN,
        then: Yup.object().shape({
          code: IBANCodeSchema(t),
          vatId: Yup.string()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .nullable(),
        }),
      }),
      swift: Yup.object().when('type', {
        is: BankAccountType.SWIFT,
        then: Yup.object().shape({
          abaRoutingTransitNumber: Yup.string()
            .nullable()
            .when('country', {
              is: 'US',
              then: Yup.string()
                .nullable()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            }),
          account: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
          address: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
          beneficiary: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
          bsbCode: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
          clabeNumber: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
          code: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
          country: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
          ifscCode: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
          name: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
        }),
      }),
      type: Yup.string().required(t('validation.required')),
    }),
  });

const getActiveFields = values => {
  const isSWIFT = values?.bankAccount?.type === BankAccountType.SWIFT;
  const isIBAN = values?.bankAccount?.type === BankAccountType.IBAN;
  const country = values?.bankAccount?.swift?.country;

  return {
    ['bankAccount.iban.code']: isIBAN,
    ['bankAccount.iban.vatId']: isIBAN,
    ['bankAccount.swift.abaRoutingTransitNumber']: country === 'US',
    ['bankAccount.swift.account']: isSWIFT,
    ['bankAccount.swift.address']: isSWIFT,
    ['bankAccount.swift.beneficiary']: isSWIFT,
    ['bankAccount.swift.bsbCode']: country === 'AU',
    ['bankAccount.swift.clabeNumber']: country === 'MX',
    ['bankAccount.swift.code']: isSWIFT,
    ['bankAccount.swift.country']: isSWIFT,
    ['bankAccount.swift.ifscCode']: country === 'IN',
    ['bankAccount.swift.name']: isSWIFT,
  };
};

export const AdvertiserVerificationBillingStep: StepComponent = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Alert severity={'info'}>
          <AlertTitle>{t('bankAccountDetails')}</AlertTitle>
          <div>{t('pleaseEntryYourBankAccountDetails')}</div>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Select name="bankAccount.type" label={t('bankAccountType')}>
          <MenuItem value={BankAccountType.SWIFT}>{t('swift')}</MenuItem>
          <MenuItem value={BankAccountType.IBAN}>{t('iban')}</MenuItem>
        </Select>
      </Grid>
      <ConditionalFields
        fieldNames={['bankAccount.iban.code', 'bankAccount.iban.vatId']}
      >
        <Grid item xs={12}>
          <TextField
            type="text"
            label={t('ibanCode')}
            parse={toUpperCase}
            name="bankAccount.iban.code"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            label={t('ibanVatNumber')}
            name="bankAccount.iban.vatId"
          />
        </Grid>
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          'bankAccount.swift.beneficiary',
          'bankAccount.swift.code',
          'bankAccount.swift.account',
          'bankAccount.swift.name',
          'bankAccount.swift.address',
          'bankAccount.swift.country',
        ]}
      >
        <Grid item xs={6}>
          <TextField
            type="text"
            label={t('swiftBeneficiary')}
            name="bankAccount.swift.beneficiary"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label={t('swiftCode')}
            name="bankAccount.swift.code"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label={t('swiftBankAccount')}
            name="bankAccount.swift.account"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label={t('swiftBankName')}
            name="bankAccount.swift.name"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label={t('swiftBankAddress')}
            name="bankAccount.swift.address"
          />
        </Grid>
        <Grid item xs={6}>
          <CountrySelect name="bankAccount.swift.country" />
        </Grid>
        <ConditionalFields
          fieldNames={['bankAccount.swift.abaRoutingTransitNumber']}
        >
          <Grid item xs={6}>
            <TextField
              label={t('abaRoutingTransitNumber')}
              name="bankAccount.swift.abaRoutingTransitNumber"
            />
          </Grid>
        </ConditionalFields>
        <ConditionalFields fieldNames={['bankAccount.swift.clabeNumber']}>
          <Grid item xs={6}>
            <TextField
              label={t('clabeNumber')}
              name="bankAccount.swift.clabeNumber"
            />
          </Grid>
        </ConditionalFields>
        <ConditionalFields fieldNames={['bankAccount.swift.ifscCode']}>
          <Grid item xs={6}>
            <TextField
              label={t('ifscCode')}
              name="bankAccount.swift.ifscCode"
            />
          </Grid>
        </ConditionalFields>
        <ConditionalFields fieldNames={['bankAccount.swift.bsbCode']}>
          <Grid item xs={6}>
            <TextField label={t('bsbCode')} name="bankAccount.swift.bsbCode" />
          </Grid>
        </ConditionalFields>
      </ConditionalFields>
    </Grid>
  );
};

AdvertiserVerificationBillingStep.getActiveFields = getActiveFields;
AdvertiserVerificationBillingStep.getValidationSchema = getValidationSchema;
