import { createContext, FC } from 'react';
import { useAuth } from '@hooks/index';
import { UserInterface } from './AuthProvider';

export const CurrentUserContext = createContext<UserInterface | undefined>(
  undefined,
);

export const CurrentUserProvider: FC = ({ children }) => {
  const { currentUser } = useAuth();
  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
};
