import { Trans, useTranslation } from 'react-i18next';
import { StepComponent } from '@components/forms/types';
import { StepTitle } from '@components/forms';
import {
  Stepper,
  Typography,
  Step,
  StepLabel,
  stepConnectorClasses,
  StepConnector,
  Button,
  useMediaQuery,
} from '@mui/material';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { styled, useTheme } from '@mui/material/styles';
import isPropValid from '@emotion/is-prop-valid';

const ConnectorStyled = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',

    border: 0,

    borderRadius: 1,
    // backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.lighter}55 0%,${theme.palette.primary.light}55 50%,${theme.palette.primary.main}55 100%)`,
    height: 2,
    margin: `0 ${theme.spacing(2)}`,
  },
}));

interface StepIconProps {
  ownerState: {
    active: boolean;
  };
}

const StepIconRoot = styled('div', {
  shouldForwardProp: isPropValid,
})<StepIconProps>(({ theme, ownerState }) => ({
  alignItems: 'center',
  borderRadius: '30%',
  color: '#fff',
  display: 'flex',
  height: 50,
  justifyContent: 'center',
  width: 50,
  zIndex: 1,
  ...(ownerState.active && {
    backgroundImage: `linear-gradient( 90deg, ${theme.palette.primary.lighter}aa, ${theme.palette.primary.light}55)`,
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ['& svg']: {
    fill: theme.palette.primary.main,
  },
}));

function StepIcon(props) {
  const { active, className } = props;

  const icons = {
    1: <ContentPasteOutlinedIcon />,
    2: <CloudUploadOutlinedIcon />,
    3: <CheckCircleOutlineOutlinedIcon />,
    4: <CreditCardOutlinedIcon />,
  };

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {icons[String(props.icon)]}
    </StepIconRoot>
  );
}

const getSteps = (t: TFunction) => [
  {
    description: t('stepper.description.iWillGuideYouThrowIt'),
    label: t('stepper.label.getHealthCard'),
  },
  {
    description: t(
      'stepper.description.weWillAskYouToUploadOnlyThoseDocuments',
    ),
    label: t('stepper.label.uploadDocuments'),
  },
  {
    description: t('stepper.description.youWillGetEmailWithConfirmation'),
    label: t('stepper.label.getBarmerApproval'),
  },
  {
    description: t('stepper.description.youWillGetAPostMailAfterArriving'),
    label: t('stepper.label.application'),
  },
];

export const WelcomeStep: StepComponent = ({ goNext }) => {
  const { t } = useTranslation();
  const steps = useMemo(() => getSteps(t), [t]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.helloIamEmma')}</Typography>
      </StepTitle>

      <Trans i18nKey="text.iAmHereToHelpFillOutYourInformation">
        <Typography variant="body2" color="text.disabled">
          I'm here to help fill out the Application!
        </Typography>
        <Typography variant="body2" color="text.disabled">
          I will ask you some information and don't worry, your data is saved
          automatically. You can always logout and continue later.
        </Typography>
        <Typography variant="body2" color="text.disabled">
          Let's take a look at the steps.
        </Typography>
      </Trans>
      <Stepper
        sx={{ my: 7 }}
        alternativeLabel={!isMobile}
        connector={
          !isMobile ? <ConnectorStyled /> : <StepConnector sx={{ ml: 3 }} />
        }
        orientation={isMobile ? 'vertical' : 'horizontal'}
      >
        {steps.map(({ label, description }) => (
          <Step key={label} active={true}>
            <StepLabel StepIconComponent={StepIcon}>
              <Typography variant="subtitle2">{label}</Typography>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Button fullWidth={isMobile} variant="contained" onClick={goNext}>
        {t('getStarted')}
      </Button>
    </>
  );
};
