import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    defaultNS: 'translation',
    detection: {
      order: ['localStorage'],
    },
    fallbackLng: 'en-GB',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    load: 'currentOnly',
    ns: ['translation', 'shared'],
    returnEmptyString: false,
  });

export const getShortLanguageName = (longName: string): string =>
  longName.split('-')[0];

export { i18n };
export default i18n;
