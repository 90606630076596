// COPIED FROM VISARIGT/MONOREPO

export const clickOnDownloadUrl = (url: string, newWindow = false): void => {
  const link = document.createElement('a');
  link.href = url;
  if (newWindow) {
    link.target = '_blank';
  }
  link.download = 'file';
  document.body.append(link);
  link.click();
  document.body.removeChild(link);

  // todo the following shit doesn't work for mobile safari
  // ad probably many more browsers
  // const query = qs.parse(url, { ignoreQueryPrefix: true });
  // const contentType = query['response-content-type'];
  // const contentDisposition = query['response-content-disposition'];

  // let fileName;
  // if (contentDisposition && contentDisposition.includes('filename=')) {
  //   fileName = contentDisposition
  //     .split('filename="')
  //     .pop()
  //     .split('"')[0];
  // } else {
  //   const urlParts = url.split('?')[0].split('/');
  //   fileName = urlParts[urlParts.length - 1];
  // }

  // const response = await fetch(url, { method: 'GET' });
  // const blob = await response.blob();
  // const newBlob = new Blob([blob], {
  //   type: contentType,
  // });

  // const data = window.URL.createObjectURL(newBlob);
  // const link = document.createElement('a');
  // document.body.appendChild(link);
  // link.href = data;
  // link.download = fileName;
  // link.click();
  // document.body.removeChild(link);

  // setTimeout(() => {
  //   // For Firefox it is necessary to delay revoking the ObjectURL
  //   window.URL.revokeObjectURL(data);
  // }, 60);
};
