import { Alert, AlertTitle, Grid, Typography } from '@mui/material';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { PhoneNumberField, TextField } from '@common/forms/fields';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import * as yup from 'yup';
import { AnySchema } from 'yup';
import { StepComponent } from '@components/forms/types';
import formatString from 'format-string-by-pattern';
import {
  compose,
  removeSpaces,
  toLowerCase,
} from '@common/forms/normalization';
import { EMAIL_REGEX, LATIN_REG_EXP, PHONE_REGEX } from '@shared/regex';

const formatInviteCode = (value: string) =>
  formatString('XXXXX-XXXXX', value.toUpperCase());

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    address: yup.object().shape({
      country: Yup.string().required(t('validation.required')),
      houseNumber: Yup.string().required(t('validation.required')),
      place: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .required(t('validation.required')),
      streetName: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .required(t('validation.required')),
      zipCode: Yup.string().required(t('validation.required')),
    }),
    contactPerson: yup.object().shape({
      firstName: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .required(t('validation.required')),
      lastName: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .required(t('validation.required')),
    }),
    email: Yup.string()
      .matches(EMAIL_REGEX, t('validation.validEmail'))
      .required(t('validation.required')),
    inviteCode: Yup.string().required(t('validation.required')),
    name: yup
      .string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .required(t('validation.required')),
    phone: Yup.string()
      .matches(PHONE_REGEX, t('validation.invalidPhoneNumber'))
      .required(t('validation.required')),
  });

export const AdvertiserVerificationGeneralStep: StepComponent = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Alert severity={'info'}>
          <AlertTitle>{t('companyInformation')}</AlertTitle>
          <div>{t('pleaseEnterYourCompanyDetails')}</div>
          <b>{t('pleaseEnterYourCompanyDetailsBoldPart')}</b>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{t('companyInformation')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField type="text" label={t('nameLabel')} name="name" />
      </Grid>
      <Grid item xs={6}>
        <CountrySelect name="address.country" />
      </Grid>
      <Grid item xs={6}>
        <TextField type="text" label={t('cityLabel')} name="address.place" />
      </Grid>
      <Grid item xs={6}>
        <TextField type="text" label={t('zipLabel')} name="address.zipCode" />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="text"
          label={t('streetLabel')}
          name="address.streetName"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="text"
          label={t('houseNumberLabel')}
          name="address.houseNumber"
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneNumberField name="phone" variant="outlined" label={t('mobile')} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t('emailLabel')}
          name="email"
          parse={compose(removeSpaces, toLowerCase)}
          type="email"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{t('contactPerson')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="text"
          label={t('firstNameLabel')}
          name="contactPerson.firstName"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="text"
          label={t('lastNameLabel')}
          name="contactPerson.lastName"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          {t('advertiserCode')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="text"
          name="inviteCode"
          label={t('advertiserCode')}
          parse={formatInviteCode}
        />
      </Grid>
    </Grid>
  );
};

AdvertiserVerificationGeneralStep.getValidationSchema = getValidationSchema;
