import { useMutation, useQuery } from '@apollo/client';
import { TextField } from '@common/forms/fields/TextField';
import { GQL } from '@common/queries';
import {
  GetOfficeMembersPaged,
  GetOfficeMembersPagedVariables,
} from '@common/queries/types/GetOfficeMembersPaged';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Popover,
  Stack,
  Typography,
  MenuItem,
} from '@mui/material';
import { OfficeMemberType, OrderDirection } from '@shared/constants';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';

interface Props {
  assignedTo?: {
    id: string;
    name: string;
  };
  insuranceCaseId: string;
}

export const ServicePointAssign: FC<Props> = ({
  assignedTo,
  insuranceCaseId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { data, loading } = useQuery<
    GetOfficeMembersPaged,
    GetOfficeMembersPagedVariables
  >(GQL.OFFICE_MEMBER.OFFICE_MEMBERS_PAGED, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !anchorEl,
    variables: {
      first: 100,
      officeMemberTypes: [OfficeMemberType.ServicePoint],
      orderBy: 'name',
      orderDirection: OrderDirection.Asc,
      withBranchOfficeName: false,
    },
  });

  const officeMembers = data?.officeMembersPaged?.edges.map(({ node }) => node);

  const openPopover = event => setAnchorEl(event.currentTarget);
  const closePopover = event => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const [assignCase] = useMutation(
    GQL.INSURANCE_CASE.SET_ASSIGNED_SERVICE_POINT,
  );

  const onSubmit = async ({ officeMemberId }) => {
    const isUnassign = officeMemberId && officeMemberId === assignedTo?.id;
    try {
      await assignCase({
        variables: {
          insuranceCaseId,
          officeMemberId: isUnassign ? null : officeMemberId,
        },
      });
      closePopover(null);
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        height: theme => theme.spacing(9),
      }}
      onClick={openPopover}
    >
      {assignedTo && (
        <Box>
          <Typography variant="subtitle2" color="GrayText" align="right">
            {t('servicePointAssign.assignedTo')}
          </Typography>
          <Typography variant="body2">{assignedTo?.name}</Typography>
        </Box>
      )}
      {!assignedTo && (
        <Button color="warning" variant="contained">
          {t('servicePointAssign.assignToServicePoint')}
        </Button>
      )}
      <Popover
        PaperProps={{
          sx: { minWidth: 400 },
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Form
          initialValues={{ officeMemberId: assignedTo?.id }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, submitting, values }) => {
            const { officeMemberId } = values;
            const isUnassign =
              officeMemberId && officeMemberId === assignedTo?.id;
            return (
              <form onSubmit={handleSubmit}>
                <Stack direction="column" spacing={2} sx={{ p: 2 }}>
                  <Typography variant="h6">
                    {t('servicePointAssign.assignToServicePoint')}
                  </Typography>
                  <TextField
                    disabled={loading || !officeMembers?.length}
                    name="officeMemberId"
                    select
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                    label={t('servicePoint')}
                  >
                    {officeMembers?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button onClick={closePopover}>{t('cancel')}</Button>
                    <LoadingButton
                      loading={submitting}
                      type="submit"
                      color={isUnassign ? 'warning' : 'primary'}
                      variant="contained"
                    >
                      {isUnassign ? t('unassign') : t('assign')}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            );
          }}
        </Form>
      </Popover>
    </Box>
  );
};
