import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceCaseFragment } from '@common/queries/types/InsuranceCaseFragment';
import { Dot } from '@components/Dot';
import { InsuranceCaseStatus } from '@shared/constants';
import { getInsuranceCaseStatusTranslated } from '@utils/getInsuranceCaseStatusTranslated';
import LoadingButton from '@mui/lab/LoadingButton';
import { useToggle } from '@common/hooks';
import { GQL } from '@common/queries';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';

const StatusSelectDot = styled(Dot)(({ theme }) => ({
  height: 4,
  marginRight: theme.spacing(1.5),
  width: 4,
}));

interface Props {
  insuranceCase: InsuranceCaseFragment;
}

export const FinalStatusChangeAction: FC<Props> = ({ insuranceCase }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpened, { toggleOn: openDialog, toggleOff: closeDialog }] =
    useToggle();
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const { status: currentStatus } = insuranceCase;

  const onMenuItemClick = (finalStatus: InsuranceCaseStatus) => () => {
    setSelectedStatus(finalStatus);
    closeMenu();
    openDialog();
  };

  const [submitStatus, { loading }] = useMutation(
    GQL.INSURANCE_CASE.CHANGE_STATUS,
  );

  const availableStatuses = useMemo(
    () =>
      [
        {
          dot: theme.palette.error.dark,
          status: InsuranceCaseStatus.NotInsurable,
        },
        {
          dot: theme.palette.success.main,
          status: InsuranceCaseStatus.Approved,
        },
        {
          dot: theme.palette.warning.dark,
          status: InsuranceCaseStatus.Pending,
        },
        {
          dot: theme.palette.success.dark,
          status: InsuranceCaseStatus.Covered,
        },
        {
          dot: theme.palette.warning.main,
          status: InsuranceCaseStatus.Reviewing,
        },
      ].filter(({ status }) => status !== currentStatus),
    [currentStatus],
  );

  const onSubmit = async () => {
    try {
      await submitStatus({
        variables: {
          insuranceCaseId: insuranceCase.id,
          status: selectedStatus,
        },
      });
      enqueueSnackbar(
        t('insuranceCaseSetFinalStatus.statusSubmittedSnack', {
          status: selectedStatus,
        }),
        {
          variant: 'success',
        },
      );
      closeDialog();
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        onClick={openMenu}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t('insuranceCaseSetFinalStatus.buttonLabel')}
      </Button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMenu}>
        <Typography variant="subtitle1" sx={{ pb: 1, px: 2 }}>
          {t('insuranceCaseSetFinalStatus.selectStatus')}
        </Typography>
        {availableStatuses.map(({ status, dot }) => (
          <MenuItem key={status} onClick={onMenuItemClick(status)}>
            <StatusSelectDot isSelected sx={{ backgroundColor: dot }} />
            {getInsuranceCaseStatusTranslated(status, t)}
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={isDialogOpened}
        onClose={() => !loading && closeDialog()}
      >
        <DialogTitle sx={{ pb: 2 }}>
          {t('insuranceCaseSetFinalStatus.confirmDialogTitle', {
            status: getInsuranceCaseStatusTranslated(selectedStatus, t),
          })}
        </DialogTitle>
        <DialogContent>
          {selectedStatus === InsuranceCaseStatus.Pending && (
            <>
              <Typography sx={{ pb: 1 }}>
                {t('insuranceCaseSetFinalStatus.confirmDialogDescr.pending')}
              </Typography>
            </>
          )}
          {selectedStatus === InsuranceCaseStatus.Covered && (
            <Typography>
              {t('insuranceCaseSetFinalStatus.confirmDialogDescr.covered')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={loading} onClick={closeDialog}>
            {t('cancel')}
          </Button>
          <LoadingButton
            disabled={loading}
            loading={loading}
            variant="contained"
            onClick={onSubmit}
          >
            {t('submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
