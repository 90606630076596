import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { Form } from 'react-final-form';
import { InsuranceCaseFragment } from '@queries/types/InsuranceCaseFragment';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { EMAIL_REGEX } from '@shared/regex';
import { useTranslation } from 'react-i18next';
import { TextField } from '@common/forms/fields';
import { useClipboard } from '@common/hooks';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { GQL } from '@queries';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { LoadingButton } from '@mui/lab';
import EmailIcon from '@mui/icons-material/Email';
import { DocumentsGeneratingStatusContext } from '@pages/InsuranceCaseDetailsPage/InsuranceCaseDetailsPage';

interface Props {
  insuranceCase: InsuranceCaseFragment;
}

export const ReferrerLinkBlock: FC<Props> = ({ insuranceCase }) => {
  const { t } = useTranslation();
  const context = useContext(DocumentsGeneratingStatusContext);
  const { enqueueSnackbar } = useSnackbar();
  const copyToClipboard = useClipboard();
  const {
    profile: { email },
    id,
    refLink,
  } = insuranceCase as any;

  const [updateEmail, { loading: isEmailChanging }] = useMutation(
    GQL.INSURANCE_CASE.CHANGE_EMAIL,
  );

  const [sendInviteEmail, { loading: isEmailSending }] = useMutation(
    GQL.INSURANCE_CASE.SEND_INVITE_EMAIL,
  );

  const onSubmit = async (values, formApi) => {
    const { pristine } = formApi.getState();
    try {
      if (pristine) {
        await sendInviteEmail({
          variables: {
            insuranceCaseId: id,
          },
        });
        enqueueSnackbar(t('inviteEmailSent'), {
          variant: 'success',
        });
        return;
      }
      context?.startPolling(700);
      await updateEmail({
        variables: {
          email: values.email,
          insuranceCaseId: id,
        },
      });
      enqueueSnackbar(t('applicantEmailChangedInviteSent'), {
        variant: 'success',
      });
    } catch (error) {
      context?.stopPolling();
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, {
        variant: 'error',
      });
    }
  };

  const onCopyButtonClick = useCallback(() => {
    copyToClipboard(refLink);
    enqueueSnackbar(t('copied'), {
      variant: 'success',
    });
  }, [refLink]);

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          email: Yup.string()
            .matches(EMAIL_REGEX, t('validation.validEmail'))
            .required(t('validation.required')),
        }) as any,
      ),
    [t],
  );
  return (
    <Box>
      <Typography color="GrayText" variant="subtitle1" sx={{ pb: 2 }}>
        {t('emailInformation')}
      </Typography>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={{
          email,
        }}
      >
        {({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField name="email" label={t('email')} />
              <Stack
                sx={{ justifyContent: 'flex-end' }}
                spacing={2}
                direction="row"
              >
                <Button
                  variant="outlined"
                  onClick={onCopyButtonClick}
                  startIcon={<ContentCopyIcon />}
                >
                  {t('copyInvitationLink')}
                </Button>
                <LoadingButton
                  loading={
                    isEmailChanging ||
                    isEmailSending ||
                    context?.isRegenerationInProcess
                  }
                  variant="contained"
                  startIcon={<EmailIcon />}
                  type="submit"
                >
                  {pristine ? t('sendInvite') : t('changeAndSendInvite')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        )}
      </Form>
    </Box>
  );
};
