import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceIllustration } from '../../common/assets/illustration_maintenance';

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  minHeight: '100%',
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(15),
}));

interface Props {
  error?: Error;
  componentStack?: string | null;
  eventId?: string | null;
  resetError?: VoidFunction;
}

export const MaintenancePage: FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <RootStyle title="MaintenancePage">
      <Container sx={{ textAlign: 'center' }}>
        <Typography variant="h3" paragraph>
          {t('maintenancePage.somethingWentWrong')}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          {t('maintenancePage.weAreCurrentlyWorkingAtThis')}
        </Typography>

        <MaintenanceIllustration sx={{ height: 240, my: 10 }} />
      </Container>
    </RootStyle>
  );
};
