import { FC, useMemo, useRef, useState } from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material';
import { useAuth } from '@hooks/useAuth';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { ROUTES } from '@common/routes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { UserRole } from '@shared/constants';
import { MenuPopover, MIconButton } from '../../components';

const getMenuOptions = (t: TFunction) => [
  {
    icon: ManageAccountsIcon,
    label: t('menu.profile'),
    linkTo: ROUTES.COMMON.settings.path,
    roles: [
      UserRole.Advertiser,
      UserRole.ServicePoint,
      UserRole.HeadOffice,
      UserRole.BranchOffice,
    ],
  },
];

export const AccountPopover: FC = () => {
  const { logout } = useAuth();
  const { t, i18n } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { email, role } = useCurrentUser();
  const menuOptions = useMemo(() => getMenuOptions(t), [i18n.language]);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          height: 44,
          padding: 0,
          width: 44,
          ...(open && {
            '&:before': {
              bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
              borderRadius: '50%',
              content: "''",
              height: '100%',
              position: 'absolute',
              width: '100%',
              zIndex: 1,
            },
          }),
        }}
      >
        <Avatar alt="My Avatar" />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ lineBreak: 'anywhere', my: 1.5, px: 2.5 }}>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary', fontSize: 12 }}
          >
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {menuOptions
          .filter(({ roles }) => roles.includes(role))
          .map(option => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ px: 2.5, py: 1, typography: 'body2' }}
            >
              <Box
                component={option.icon}
                sx={{
                  height: 24,
                  mr: 2,
                  width: 24,
                }}
              />
              {option.label}
            </MenuItem>
          ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={async () => {
              await logout();
              navigate(ROUTES.AUTH.login());
            }}
            fullWidth
            color="inherit"
            variant="outlined"
          >
            {t('menu.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};
