import { AnySchema, reach } from 'yup';
import { Maybe } from '@shared/types';
import { TFunction } from 'i18next';
import { stitchFieldNamesFromSchema } from '@components/forms/FormStepper';
import get from 'lodash/get';

// CRUTCH: function which couples form stepper schemas with rejected fields
export const withRejectedFieldsValidation =
  (yupSchema: Maybe<AnySchema>) =>
  ({
    rejectedFieldGroups,
    rejectedValues = {},
    t,
  }: {
    rejectedFieldGroups: string[][];
    rejectedValues: Record<string, any>;
    t: TFunction;
  }): Maybe<AnySchema> => {
    if (!yupSchema || !Object.keys(rejectedValues).length) {
      return yupSchema;
    }
    const schemaFieldNames = stitchFieldNamesFromSchema(yupSchema);
    const currentStepRejectionGroups = rejectedFieldGroups.filter(fieldGroups =>
      fieldGroups.some(groupFieldName =>
        schemaFieldNames.includes(groupFieldName),
      ),
    );
    for (const schemaFieldName of schemaFieldNames) {
      reach(yupSchema, schemaFieldName).withMutation(schema =>
        schema.test({
          message: t('requestedToChange'),
          name: 'rejectedFieldNames',
          test: (value, testContext) => {
            const schemaValues = testContext.from.reduce(
              (acc, parent) => ({ ...acc, ...parent.value }),
              {},
            );
            const currentFieldGroup =
              currentStepRejectionGroups.find(group =>
                group.includes(testContext.path),
              ) || [];
            // Form invalid if
            // 1. any field is listed in rejected group AND
            // 2. all fields in rejected group has not changed it value or it was rejected as empty
            return !(
              currentFieldGroup.length &&
              currentFieldGroup.every(field => {
                const currentValues = get(schemaValues, field);
                const currentRejectedValues = rejectedValues[field];
                const isRejectedAsEmptyArray =
                  currentRejectedValues &&
                  Array.isArray(currentRejectedValues) &&
                  !currentRejectedValues.length;
                // If value in form was rejected as empty array (needed to be filled out)
                // then return check that it is filled
                if (isRejectedAsEmptyArray) {
                  return Array.isArray(currentValues) && !currentValues.length;
                }

                // If current field has rejections
                if (currentRejectedValues !== undefined) {
                  // Then check that current values and current rejected values is array and not empty and unchanged
                  if (
                    Array.isArray(currentValues) &&
                    Array.isArray(currentRejectedValues) &&
                    currentValues.length &&
                    currentRejectedValues.length
                  ) {
                    // eg. currentRejectedValues: [Study, Pension, Employment]
                    // 1. currentValues: [Study, Pension] // valid
                    // 2. currentValues: [Study, Pension, Unemployment, Employment] // invalid
                    // 2. currentValues: [Study, Unemployment, Pension] // valid
                    return (
                      currentValues.length === currentRejectedValues.length &&
                      currentRejectedValues.every(val =>
                        currentValues.includes(val),
                      )
                    );
                  }
                  // otherwise check field equality
                  return currentValues === currentRejectedValues;
                }
                // If none of above that means that field was rejected as non filled
                return !currentValues;
              })
            );
          },
        }),
      );
    }
    return yupSchema;
  };
