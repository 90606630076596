import { GQL } from '@queries';
import { Column, EntityTable } from '@components/EntityTable';
import React, { FC } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox } from '@mui/material';
import { GetInviteCodesPaged_inviteCodesPaged_edges_node as InviteCode } from '@common/queries/types/GetInviteCodesPaged';
import { InviteCodeStatus, OrderDirection } from '@shared/constants';
import { InviteCodeStatusBadge } from '@pages/InviteCodePage/InviteCodeStatusBadge';
import { DateTime } from 'luxon';
import EuroIcon from '@mui/icons-material/Euro';

const getColumns = ({ t }: { t: TFunction }): Column<InviteCode>[] => [
  {
    id: 'code',
    isSortable: true,
    label: t('code'),
    renderCell: ({ data: { code } }) => code.toUpperCase(),
  },
  {
    id: 'createdAt',
    isSortable: true,
    label: t('createdAt'),
    renderCell: ({ data: { createdAt } }) =>
      DateTime.fromISO(createdAt).toFormat('dd.MM.yyyy'),
  },
  {
    id: 'provision',
    isSortable: true,
    label: t('commissionRate'),
    renderCell: ({ data: { provision } }) => (
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <EuroIcon color="action" sx={{ fontSize: 12 }} />
        {provision}
      </Box>
    ),
  },
  {
    id: 'usageCount',
    isSortable: true,
    label: t('usageCount'),
    renderCell: ({ data: { usageCount } }) => usageCount,
  },
  {
    id: 'isMulti',
    isSortable: true,
    label: t('reusable'),
    renderCell: ({ data: { isMulti } }) => (
      <Checkbox disabled checked={isMulti} />
    ),
  },
  {
    filterValues: Object.values(InviteCodeStatus),
    filterView: value => t(value) as string,
    id: 'status',
    isSortable: true,
    label: t('status'),
    renderCell: ({ data: { id, status } }) => (
      <InviteCodeStatusBadge id={id} status={status as InviteCodeStatus} />
    ),
  },
];

export const InviteCodeTable: FC = () => {
  const { t } = useTranslation();
  return (
    <EntityTable<InviteCode>
      query={GQL.INVITE_CODE.PAGED}
      columns={getColumns({ t })}
      defaultSortOptions={{
        orderBy: 'createdAt',
        orderDirection: OrderDirection.Desc,
      }}
    />
  );
};
