import { FC } from 'react';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArticleIcon from '@mui/icons-material/Article';

interface Props {
  onSignClick: () => void;
}

export const AdvertiserAgreementNotSigned: FC<Props> = ({
  onSignClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={'row'}>
          <ArticleIcon />
          <Typography variant={'h6'}>
            {t('advertisingAgreement.title')}
          </Typography>
        </Stack>
        <Chip label={t('advertisingAgreement.notSigned.status')} color="info" />
      </Stack>
      <Typography variant="body2">
        {t('advertisingAgreement.notSigned.info')}
      </Typography>

      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Button variant="contained" onClick={onSignClick}>
          {t('sign')}
        </Button>
      </Stack>
    </>
  );
};
