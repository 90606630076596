import { gql } from '@apollo/client';

export const AdvertiserPaymentsPlanFrag = gql`
  fragment AdvertiserPaymentsPlanFragment on AdvertiserPaymentsPlan {
    id
    createdAt
    updatedAt
    fixationDate
    status
  }
`;

export const ADVERTISER_PAYMENTS_PLAN = {
  ADVERTISER_PAYMENTS_PLAN: gql`
    query advertiserPaymentsPlan($advertiserPaymentsPlanId: ID!) {
      advertiserPaymentsPlan(
        advertiserPaymentsPlanId: $advertiserPaymentsPlanId
      ) {
        ...AdvertiserPaymentsPlanFragment
        paymentsAmount
        transactionsAmount
        paymentsPaidAmount
      }
    }
    ${AdvertiserPaymentsPlanFrag}
  `,
  ADVERTISER_PAYMENTS_PLANS_PAGED: gql`
    query advertiserPaymentsPlansPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      advertiserPaymentsPlansPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          count
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...AdvertiserPaymentsPlanFragment
          }
        }
      }
    }
    ${AdvertiserPaymentsPlanFrag}
  `,
  APPROVE: gql`
    mutation aproveAdvertiserPaymentsPlan($advertiserPaymentsPlanId: String!) {
      advertiserPaymentsPlanMutations {
        approve(advertiserPaymentsPlanId: $advertiserPaymentsPlanId) {
          id
          status
        }
      }
    }
  `,
  CANCEL: gql`
    mutation cancelAdvertiserPaymentsPlan($advertiserPaymentsPlanId: String!) {
      advertiserPaymentsPlanMutations {
        cancel(advertiserPaymentsPlanId: $advertiserPaymentsPlanId)
      }
    }
  `,
  CREATE: gql`
    mutation createAdvertiserPaymentsPlan(
      $fixationDate: Date!
      $advertiserIds: [String!]
    ) {
      advertiserPaymentsPlanMutations {
        create(fixationDate: $fixationDate, advertiserIds: $advertiserIds) {
          ...AdvertiserPaymentsPlanFragment
        }
      }
    }
    ${AdvertiserPaymentsPlanFrag}
  `,
};
