import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { GQL } from '@queries';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { LoadingScreen } from '@components/LoadingScreen';
import { ApplicationForm } from '@components/forms/ApplicationForm/ApplicationForm';
import { GetCurrentUserInsuranceCase_currentUserInsuranceCase as InsuranceCase } from '@queries/types/GetCurrentUserInsuranceCase';
import { Container } from '@mui/material';
import {
  GetFormStateAndReviews,
  GetFormStateAndReviewsVariables,
} from '@queries/types/GetFormStateAndReviews';

interface Props {
  insuranceCase: InsuranceCase;
  onSubmitted: VoidFunction;
  isRefilling?: boolean;
}

export const ApplicationFormPage: FC<Props> = ({
  insuranceCase,
  onSubmitted,
  isRefilling,
}) => {
  const formStateQueryState = useQuery<
    GetFormStateAndReviews,
    GetFormStateAndReviewsVariables
  >(GQL.FORM_STATE.FORM_STATE_WITH_REVIEWS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      formContainerId: insuranceCase.formContainerId,
      formType: 'application',
      insuranceCaseId: insuranceCase.id,
    },
  });

  return (
    <QueryLoaderComponent
      renderLoading={() => (
        <Container>
          <LoadingScreen />
        </Container>
      )}
      renderData={({
        formState,
        insuranceCaseFieldReviews,
        insuranceCaseDocumentReviews,
      }) =>
        !formState ? (
          <ApplicationForm
            onSubmitted={onSubmitted}
            insuranceCase={insuranceCase}
          />
        ) : (
          <ApplicationForm
            insuranceCase={insuranceCase}
            onSubmitted={onSubmitted}
            isRefilling={isRefilling}
            initialValues={formState.state.values}
            initialStepName={formState.currentStepName}
            fieldReviews={insuranceCaseFieldReviews}
            documentReviews={insuranceCaseDocumentReviews}
          />
        )
      }
      queryState={formStateQueryState}
    />
  );
};
