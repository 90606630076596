import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { GQL } from '@queries';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import {
  GetFormState,
  GetFormStateVariables,
} from '@queries/types/GetFormState';
import { AdvertiserVerificationForm } from '@components/forms/AdvertiserVerificationForm/AdvertiserVerificationForm';
import { Container } from '@mui/material';
import { Page } from '@src/components';
import {
  GetAdvertiser,
  GetAdvertiserVariables,
} from '@queries/types/GetAdvertiser';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { Suspender } from '@components/Suspender';

export const AdvertiserVerificationPage: FC = () => {
  const { advertiserId, email } = useCurrentUser();
  const advertiserQueryState = useQuery<GetAdvertiser, GetAdvertiserVariables>(
    GQL.ADVERTISER.ADVERTISER,
    {
      skip: !advertiserId,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        advertiserId: advertiserId!,
      },
    },
  );
  const formStateQueryState = useQuery<GetFormState, GetFormStateVariables>(
    GQL.FORM_STATE.FORM_STATE,
    {
      skip: !advertiserQueryState?.data?.advertiser?.formContainerId,
      variables: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formContainerId:
          advertiserQueryState?.data?.advertiser?.formContainerId,

        formType: 'advertiserVerification',
      },
    },
  );
  if (advertiserQueryState.error) {
    throw advertiserQueryState.error;
  }
  if (advertiserQueryState.loading) return <Suspender />;
  return (
    <Page>
      <Container>
        <QueryLoaderComponent
          renderData={({ formState }) => (
            <AdvertiserVerificationForm
              formContainerId={
                // eslint-disable-next-line
                advertiserQueryState.data!.advertiser.formContainerId
              }
              initialValues={formState?.state.values || { email }}
              initialStepName={formState?.currentStepName}
            />
          )}
          queryState={formStateQueryState}
        />
      </Container>
    </Page>
  );
};
