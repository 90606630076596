import { gql } from '@apollo/client';

export const RevolutTransactionFragment = gql`
  fragment RevolutTransactionFragment on RevolutTransaction {
    id
    createdAt
    isMatched
    description
    amount
    reference
    currency
    status
  }
`;

export const REVOLUT_TRANSACTIONS = {
  GET_PAGED: gql`
    query GetRevolutTransactionsPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      revolutTransactionsPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          count
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...RevolutTransactionFragment
          }
        }
      }
    }
    ${RevolutTransactionFragment}
  `,
};
