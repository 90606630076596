import { gql } from '@apollo/client';

const DocumentFrag = gql`
  fragment DocumentFragment on Document {
    id
    documentName
    fileName
    signedSlots
    uploadUrl
  }
`;

export const DOCUMENT = {
  CREATE: gql`
    mutation CreateDocument(
      $fileName: String!
      $documentName: String!
      $documentContainerId: String!
    ) {
      documentMutations {
        create(
          fileName: $fileName
          documentName: $documentName
          documentContainerId: $documentContainerId
        ) {
          ...DocumentFragment
        }
      }
    }
    ${DocumentFrag}
  `,
  DOCUMENT_CONTAINER_DOCUMENTS: gql`
    query GetDocumentContainerDocuments($documentContainerId: String!) {
      documentContainerDocuments(documentContainerId: $documentContainerId) {
        documentName
        createdAt
        fileName
      }
    }
  `,
  GET_SIGNED_URL: gql`
    mutation GetSignedUrl($documentKey: DocumentKeyInput!, $filename: String) {
      documentMutations {
        getSignedUrl(documentKey: $documentKey, filename: $filename)
      }
    }
  `,
  GET_SIGN_SHARED_LINK: gql`
    mutation GetSignSharedLink(
      $documentKey: DocumentKeyInput!
      $slotsInfo: [SlotsInfoInput!]!
    ) {
      documentMutations {
        getSignSharedLink(documentKey: $documentKey, slotsInfo: $slotsInfo)
      }
    }
  `,
  SEND_SIGN_SHARED_EMAIL: gql`
    mutation SendSignSharedEmail(
      $documentKey: DocumentKeyInput!
      $slotsInfo: [SlotsInfoInput!]!
      $to: String!
    ) {
      documentMutations {
        sendSignSharedEmail(
          documentKey: $documentKey
          slotsInfo: $slotsInfo
          to: $to
        )
      }
    }
  `,
  SHARED_DOCUMENT_WITH_SIGNED_URL: gql`
    query GetSharedDocumentWithSignedUrl($token: String!) {
      sharedDocument(token: $token) {
        ...DocumentFragment
      }
      sharedSignedUrl(token: $token)
    }
    ${DocumentFrag}
  `,
  SHARED_SIGNED_URL: gql`
    query GetSharedSignedUrl($token: String!) {
      sharedSignedUrl(token: $token)
    }
  `,
  SIGN: gql`
    mutation SignDocument(
      $documentKey: DocumentKeyInput!
      $signatureBase64: String!
      $slotNames: [String!]!
    ) {
      documentMutations {
        sign(
          documentKey: $documentKey
          signatureBase64: $signatureBase64
          slotNames: $slotNames
        ) {
          id
          signedSlots
        }
      }
    }
  `,
  SIGNED_URL: gql`
    query SignedUrl(
      $documentKey: DocumentKeyInput!
      $forceDownload: Boolean
      $filename: String
    ) {
      signedUrl(
        documentKey: $documentKey
        forceDownload: $forceDownload
        filename: $filename
      )
    }
  `,
  SIGN_SHARED: gql`
    mutation SignSharedDocument(
      $signatureBase64: String!
      $token: String!
      $slotNames: [String!]!
    ) {
      documentMutations {
        signShared(
          signatureBase64: $signatureBase64
          token: $token
          slotNames: $slotNames
        ) {
          id
          signedSlots
        }
      }
    }
  `,
};
