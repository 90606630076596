import { StepComponent } from '@components/forms/types';
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

export const ConfirmationStep: StepComponent = ({
  goBack,
  goNext,
  meta = {},
}) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { openReviewSidebar } = meta;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const onSubmit = async () => {
    setIsUploading(true);
    try {
      await goNext?.();
    } catch {
      setIsUploading(() => false);
      enqueueSnackbar(t('error.couldNotSubmitForm'), { variant: 'error' });
    }
  };

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('submitApplication')}</Typography>
      </StepTitle>
      <Typography variant="subtitle1">
        {t('checkYourApplicationForm')}
      </Typography>
      <Typography>{t('pleaseBeCarefulAboutNextStep')}</Typography>
      <Box>
        <Button
          sx={{ marginRight: 2 }}
          variant="outlined"
          onClick={isMobile && openReviewSidebar ? openReviewSidebar : goBack}
        >
          {isMobile && openReviewSidebar ? t('review') : t('back')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onSubmit}
          disabled={isUploading}
          loading={isUploading}
        >
          {t('submit')}
        </LoadingButton>
      </Box>
    </Stack>
  );
};
