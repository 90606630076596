import { StepComponent } from '@components/forms/types';
import { Button, List, ListItem, Stack, Typography } from '@mui/material';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { TFunction } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { getMissingInfo, MissingInfo } from '../getSteps';

const getMissingInfoLabel = (value: MissingInfo, t: TFunction): string => {
  const MAP_MISSING_INFO: Record<MissingInfo, string> = {
    dependants: t('theirDependantsText'),
    occupation: t('theirOccupationText'),
    previousInsurance: t('theirPreviousInsuranceText'),
  };

  return MAP_MISSING_INFO[value];
};

export const ConfirmationStep: StepComponent = ({ goBack, goNext }) => {
  const { t } = useTranslation();
  const { values } = useFormState();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const missingInfo = getMissingInfo(values);
  const hasMissingInfo = missingInfo.length > 0;

  const onSubmit = async () => {
    setIsUploading(true);
    try {
      await goNext?.();
    } catch {
      setIsUploading(false);
      enqueueSnackbar(t('error.couldNotSubmitForm'), { variant: 'error' });
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <StepTitle>
          <Typography variant="h4">{t('readyToSendToApplicant')}</Typography>
        </StepTitle>
        <Typography color="textSecondary">
          {hasMissingInfo
            ? t('weAskApplicantToCheckThisFormIncludingFollowing')
            : t('weAskApplicantToCheckThisForm')}
        </Typography>
        <List dense={true} disablePadding={true} component="ul">
          {missingInfo.map(key => (
            <ListItem component="li" key={key}>
              <Typography color="textSecondary">
                • {getMissingInfoLabel(key, t)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <Button variant="outlined" onClick={goBack}>
          {t('back')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onSubmit}
          disabled={isUploading}
          loading={isUploading}
        >
          {t('sendToApplicantForReview')}
        </LoadingButton>
      </Stack>
    </>
  );
};
