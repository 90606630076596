import { useQuery } from '@apollo/client';
import { GQL } from '@common/queries';
import { InsuranceCaseFragment } from '@common/queries/types/InsuranceCaseFragment';
import { InsuranceProfileGroupTitle } from '@components/InsuranceProfileGroupTitle';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Maybe } from '@shared/types';
import { groupByInsProfile } from '@src/helpers';
import { FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferrerLinkBlock } from '@pages/InsuranceCaseDetailsPage/ReferrerLinkBlock';
import { InsuranceCaseStatus } from '../../../types/globalTypes';
import { AdditionalDocumentsPicker } from './AdditionalDocumentsPicker';
import { DocumentItem } from './DocumentItem';
import { DocumentsPreview } from './DocumentsPreview';

interface Props {
  insuranceCase: InsuranceCaseFragment;
  isReviewsVisible: boolean;
  isReviewing: boolean;
}

export const InsuranceCaseDocuments: FC<Props> = ({
  insuranceCase,
  isReviewsVisible,
  isReviewing,
}) => {
  const { t } = useTranslation();
  const [previewDocumentName, setPreviewDocumentName] =
    useState<Maybe<string>>(null);
  const {
    childProfiles,
    documentContainerId,
    documents,
    profile,
    spouseProfile,
  } = insuranceCase;
  const queryState = useQuery(GQL.INSURANCE_CASE_REVIEW.DOCUMENT_REVIEWS, {
    skip: !isReviewing && !isReviewsVisible,
    variables: {
      insuranceCaseId: insuranceCase.id,
    },
  });

  const documentReviews = queryState.data?.insuranceCaseDocumentReviews ?? [];
  const documentsByDocumentName = useMemo(() => {
    const rejectedDocumentsIdsSet = new Set(
      documentReviews
        .map(({ rejectedDocuments }) => rejectedDocuments)
        .flat()
        .map(({ id }) => id),
    );

    return documents.reduce(
      (acc, document) => ({
        ...acc,
        [document.documentName]: [
          ...(acc[document.documentName] ?? []),
          {
            ...document,
            isUploadedAfterReview: rejectedDocumentsIdsSet.has(document.id),
          },
        ],
      }),
      {},
    );
  }, [documents, documentReviews]);

  const documentReviewsByDocumentName = useMemo(
    () =>
      documentReviews.reduce(
        (acc, documentReview) => ({
          ...acc,
          ...(documentsByDocumentName[documentReview.documentName] ||
          documentReview.documentName.includes('powerOfAttorney')
            ? {
                uploaded: {
                  ...acc.uploaded,
                  [documentReview.documentName]: documentReview,
                },
              }
            : {
                custom: {
                  ...acc.custom,
                  [documentReview.documentName]: documentReview,
                },
              }),
        }),
        { custom: {}, uploaded: {} },
      ) ?? { custom: {}, uploaded: {} },
    [documentReviews],
  );

  return (
    <>
      {!previewDocumentName && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
            p: 4,
            width: '100%',
          }}
        >
          {![
            InsuranceCaseStatus.ApplicationFilling,
            InsuranceCaseStatus.Created,
          ].includes(insuranceCase.status) && (
            <Typography color="GrayText" variant="subtitle1" sx={{ pb: 2 }}>
              {t('submittedDocuments')}
            </Typography>
          )}
          {queryState.loading ? (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Stack spacing={1.5} sx={{ overflowY: 'auto' }}>
                {groupByInsProfile({
                  childProfiles,
                  getFieldName: ([documentName]) => documentName,
                  items: Object.entries(documentsByDocumentName).filter(
                    ([documentName]) => documentName !== 'barmerApplication',
                  ),
                  profile,
                  spouseProfile,
                }).map(
                  (
                    {
                      profileType,
                      groupName,
                      items: groupDocumEntries,
                      fullName,
                      index,
                    },
                    _,
                    arr,
                  ) => (
                    <Fragment key={groupName}>
                      {arr.length > 1 && (fullName || profileType) && (
                        <InsuranceProfileGroupTitle
                          fullName={fullName}
                          index={index}
                          profileType={profileType}
                        />
                      )}
                      {groupDocumEntries.map(([documentName, docs]) => (
                        <DocumentItem
                          insuranceCaseId={insuranceCase.id}
                          isReviewing={isReviewing}
                          documents={docs as any}
                          documentReview={
                            documentReviewsByDocumentName.uploaded[documentName]
                          }
                          documentContainerId={documentContainerId}
                          onPreviewClick={setPreviewDocumentName}
                          key={documentName}
                        />
                      ))}
                    </Fragment>
                  ),
                )}
              </Stack>
              {isReviewsVisible && (
                <Stack pt={4} spacing={2}>
                  <Typography
                    color="GrayText"
                    variant="subtitle1"
                    sx={{ pb: 2 }}
                  >
                    {isReviewing
                      ? t('requestAdditionalDocuments')
                      : t('requestedAdditionalDocuments')}
                  </Typography>
                  <AdditionalDocumentsPicker
                    isDisabled={!isReviewing}
                    excludedDocumentNames={[
                      'barmerApplication',
                      'powerOfAttorney',
                      'powerOfAttorneyUploaded',
                      ...Object.keys(documentsByDocumentName),
                      ...Object.keys(documentReviewsByDocumentName.custom),
                    ]}
                    documentReviews={Object.values(
                      documentReviewsByDocumentName.custom,
                    )}
                    insuranceCase={insuranceCase}
                    insuranceCaseId={insuranceCase.id}
                  />
                </Stack>
              )}
            </>
          )}
          {[InsuranceCaseStatus.Created].includes(insuranceCase.status) && (
            <ReferrerLinkBlock insuranceCase={insuranceCase} />
          )}
        </Box>
      )}
      {previewDocumentName && (
        <DocumentsPreview
          onCloseClick={() => setPreviewDocumentName(null)}
          documents={documentsByDocumentName[previewDocumentName]}
          documentContainerId={documentContainerId}
        />
      )}
    </>
  );
};
