import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { ElementType, FC } from 'react';
import { LoadingScreen } from '../../components';

export const Loadable =
  (Component: ElementType): FC =>
  (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard'); // todo rework and move
    // suspense to layouts

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isDashboard && {
                left: 0,
                position: 'fixed',
                top: 0,
                width: 1,
                zIndex: 9999,
              }),
            }}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
