import { FC, useState, MouseEvent } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  children: (renderArgs: {
    anchorEl: null | HTMLElement;
    closeFilterView: () => void;
  }) => React.ReactNode;
}

export const CustomFilterHeaderButton: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openFilterView = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterView = () => setAnchorEl(null);

  return (
    <>
      <Tooltip title={t('tooltip.filter') as string}>
        <IconButton
          size="small"
          id="table-filter-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          onClick={openFilterView}
        >
          <FilterListIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {children({ anchorEl, closeFilterView })}
    </>
  );
};
