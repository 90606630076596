import { FC, useEffect, useRef, useState } from 'react';
import { Dialog, Stack } from '@mui/material';
import { useQuery } from '@apollo/client';
import {
  GetAdvertiser,
  GetAdvertiserVariables,
} from '@queries/types/GetAdvertiser';
import { GQL } from '@queries';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { Suspender } from '@components/Suspender';
import { AdvertiserAgreementPreviewAndSign } from '@pages/AdvertiserDocumentsPage/AdvertiserAgreementPreviewAndSign';
import { AdvertiserAgreementSigned } from '@pages/AdvertiserDocumentsPage/AdvertiserAgreementSigned';
import {
  ADVERTISER_AGREEMENT_ADDENDUM_DOCUMENT_NAME,
  ADVERTISER_AGREEMENT_DOCUMENT_NAME,
} from '@common/constants';
import { AdvertiserStatus } from '@src/../types/globalTypes';
import { AdvertiserAgreementAddendum } from '@pages/AdvertiserDocumentsPage/AdvertiserAgreementAddendum';
import { AdvertiserAgreementNotSigned } from '@pages/AdvertiserDocumentsPage/AdvertiserAgreementNotSigned';

// states: no preview, preview, signed
// if not -> preview and sign
// if signed -> download -> url?
export const AdvertiserSignAgreement: FC = () => {
  const { advertiserId } = useCurrentUser();
  const [previewOpened, setPreviewOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef: { current: number | null } = useRef(null);

  const advertiserQueryState = useQuery<GetAdvertiser, GetAdvertiserVariables>(
    GQL.ADVERTISER.ADVERTISER,
    {
      skip: !advertiserId,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        advertiserId: advertiserId!,
      },
    },
  );

  const advertiser = advertiserQueryState.data?.advertiser;

  if (advertiserQueryState.error) {
    throw advertiserQueryState.error;
  }

  useEffect(() => {
    if (advertiser?.status === AdvertiserStatus.APPROVED) {
      advertiserQueryState.stopPolling();
      setIsLoading(false);
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [advertiser?.status]);

  const onSigned = () => {
    setPreviewOpened(false);
    if (advertiser?.status !== AdvertiserStatus.APPROVED) {
      advertiserQueryState.startPolling(500);
      setIsLoading(true);
      timeoutRef.current = window.setTimeout(() => {
        if (advertiser?.status !== AdvertiserStatus.APPROVED) {
          setIsLoading(false);
          console.warn(
            `expected status changed to APPROVED, got ${advertiser?.status}, polling failed`,
          );
        }
      }, 15000);
    }
  };

  if (isLoading) {
    return <Suspender />;
  }

  if (!advertiser) return null;

  // a bit of heuristics here
  // we need to guess which document is current agreement and what we should sign
  // current Agreement -> the latest document with barmerAdvertiserAgreement documentName and signedSlots
  // if need to sign -> advertiser.status ADDENDUM_SIGNING and the latest document with barmerAdvertiserAgreement documentName and without signedSlots
  const sortedDocs = advertiser.documents
    .slice()
    .sort((docA, docB) =>
      new Date(docA.createdAt) < new Date(docB.createdAt) ? 1 : -1,
    );
  const latestSigned = sortedDocs.find(
    doc =>
      (doc.documentName === ADVERTISER_AGREEMENT_DOCUMENT_NAME ||
        doc.documentName === ADVERTISER_AGREEMENT_ADDENDUM_DOCUMENT_NAME) &&
      doc.signedSlots.length,
  );

  const agreementToSign =
    ((advertiser.status === AdvertiserStatus.CONTRACT_SIGNING ||
      advertiser.status === AdvertiserStatus.ADDENDUM_SIGNING) &&
      sortedDocs.find(
        doc =>
          doc.documentName === ADVERTISER_AGREEMENT_DOCUMENT_NAME &&
          !doc.signedSlots.length,
      )) ||
    undefined;

  const onSignClick = async () => {
    setPreviewOpened(true);
  };

  if (
    (advertiser.status === AdvertiserStatus.ADDENDUM_SIGNING ||
      advertiser.status === AdvertiserStatus.CONTRACT_SIGNING) &&
    !agreementToSign
  ) {
    // still generating
    advertiserQueryState.startPolling(300);
  } else {
    advertiserQueryState.stopPolling();
  }

  return (
    <>
      <Dialog
        fullScreen
        open={previewOpened}
        onClose={() => setPreviewOpened(false)}
      >
        {agreementToSign ? (
          <AdvertiserAgreementPreviewAndSign
            advertiser={advertiser}
            document={agreementToSign}
            onSign={onSigned}
            onClose={() => {
              setPreviewOpened(false);
            }}
          />
        ) : (
          <Suspender />
        )}
      </Dialog>
      <Stack spacing={2}>
        {advertiser.status === AdvertiserStatus.ADDENDUM_SIGNING && (
          <AdvertiserAgreementAddendum
            advertiser={advertiser}
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            document={latestSigned!}
            onSignClick={onSignClick}
          />
        )}
        {advertiser.status === AdvertiserStatus.APPROVED && (
          <AdvertiserAgreementSigned
            advertiser={advertiser}
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            document={latestSigned!}
          />
        )}
        {advertiser.status === AdvertiserStatus.CONTRACT_SIGNING && (
          <AdvertiserAgreementNotSigned onSignClick={onSignClick} />
        )}
      </Stack>
    </>
  );
};
