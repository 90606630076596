import { Typography, IconButton, Popover } from '@mui/material';
import { FC, memo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

interface Props {
  publicId: string;
}

export const AdvertiserPublicIdBadge: FC<Props> = memo(({ publicId }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = event => setAnchorEl(event.target);
  const closePopover = () => setAnchorEl(null);

  return (
    <Typography
      component="div"
      sx={{
        alignItems: 'center',
        backgroundColor: theme => theme.palette.grey[300],
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'space-between',
        pl: 2,
        pr: 1,
        py: 0.5,
      }}
      color="GrayText"
      variant="subtitle2"
    >
      {t('advertiserPublicId')}: {publicId}
      <IconButton size="small" onClick={openPopover}>
        <InfoOutlinedIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closePopover}
        PaperProps={{ sx: { p: 2 } }}
      >
        <Typography sx={{ pb: 1 }} variant="h6">
          {t('advertiserPublicId')}
        </Typography>
        <Typography>{t('advertiserPublicId.badgeInfoDescr')}</Typography>
      </Popover>
    </Typography>
  );
});
