import {
  GetAdvertiser_advertiser,
  GetAdvertiser_advertiser_documents,
} from '@common/queries/types/GetAdvertiser';
import { DocumentPreview } from '@components/DocumentPreview';
import { useQuery } from '@apollo/client';
import { SignedUrl } from '@queries/types/SignedUrl';
import { GQL } from '@queries';
import { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { useTranslation } from 'react-i18next';
import { SignatureForm } from '@components/SignatureForm';

interface Props {
  advertiser: GetAdvertiser_advertiser;
  document: GetAdvertiser_advertiser_documents;
  onSign: () => void;
  onClose: () => void;
}

export const AdvertiserAgreementPreviewAndSign: FC<Props> = ({
  advertiser,
  document,
  onSign,
  onClose,
}: Props) => {
  const [t] = useTranslation();

  const documentKey = {
    documentContainerId: advertiser?.documentContainerId,
    id: document?.id,
  };

  const queryState = useQuery<SignedUrl>(GQL.DOCUMENT.SIGNED_URL, {
    fetchPolicy: 'network-only',
    variables: {
      documentKey: documentKey,
    },
  });

  const { name } = advertiser;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ height: 1, width: 1 }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ p: 2 }}
        >
          <SignatureForm
            showMoreOptions
            onSubmitted={onSign}
            documentKey={documentKey}
            fullName={name || ''}
            isSigned={!!document.signedSlots.length}
            slotNames={['advertiser']}
          />
          <Button onClick={onClose} size="large" variant="text">
            {t('back')}
          </Button>
        </Stack>
        <QueryLoaderComponent
          queryState={queryState}
          renderLoading={() => <DocumentPreview />}
          renderData={data => <DocumentPreview url={data.signedUrl} />}
        />
      </Stack>
    </>
  );
};
