import { FC } from 'react';
import { Icon, IconifyIcon } from '@iconify/react';
import { SnackbarProvider } from 'notistack';
import infoFill from '@iconify/icons-eva/info-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles } from '@mui/material';
import { ColorSchema } from '@common/types/theme';

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.customShadows.z8,
            color: theme.palette.grey[isLight ? 0 : 800],
            margin: theme.spacing(0.25, 0),
            padding: theme.spacing(1.5),
            width: '100%',
          },
          '& .SnackbarItem-action': {
            '& svg': { height: 20, width: 20 },
            color: theme.palette.action.active,
            marginRight: 0,
          },
          '& .SnackbarItem-message': {
            fontWeight: theme.typography.fontWeightMedium,
            padding: '0 !important',
          },
        },
      }}
    />
  );
}

type SnackbarIconProps = {
  icon: IconifyIcon;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        alignItems: 'center',
        bgcolor: theme => alpha(theme.palette[color].main, 0.16),
        borderRadius: 1.5,
        color: `${color}.main`,
        display: 'flex',
        height: 40,
        justifyContent: 'center',
        mr: 1.5,
        width: 40,
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}

export const NotistackProvider: FC = ({ children }) => {
  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        iconVariant={{
          error: <SnackbarIcon icon={infoFill} color="error" />,
          info: <SnackbarIcon icon={alertCircleFill} color="info" />,
          success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
          warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
};
