import * as Yup from 'yup';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '@hooks/useAuth';
import { FC, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useSnackbar } from 'notistack';
import { makeValidate, TextField } from 'mui-rff';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import {
  MAP_LANGUAGE_CODE_TO_PRIVACY_POLICY_LINK,
  PRIVACY_POLICY_LINK_DEFAULT,
} from '@shared/constants';
import { Checkbox } from '@common/forms/fields/Checkbox';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';

type InitialValues = {
  password: string;
  confirmPassword: string;
  isPrivacyPolicyAccepted: boolean;
};

export const ChangePasswordForm: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [inputType, setInputType] = useState<
    Record<
      keyof Omit<InitialValues, 'isPrivacyPolicyAccepted'>,
      'text' | 'password'
    >
  >({
    confirmPassword: 'password',
    password: 'password',
  });
  const { enqueueSnackbar } = useSnackbar();
  const { completePassword } = useAuth();

  const toggleIsPasswordShown = (name: keyof InitialValues) => () => {
    setInputType(prevState => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : prevState[name],
    }));
  };

  const onSubmit = async ({ password }: InitialValues) => {
    try {
      await completePassword({
        password,
      });
      enqueueSnackbar(t('notification.passwordChangedSuccessfully'), {
        variant: 'success',
      });
    } catch (error: any) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      return {
        [FORM_ERROR]: label,
      };
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], t('validation.passwordsShouldMatch'))
            .required(t('validation.passwordIsRequired')),
          isPrivacyPolicyAccepted: Yup.boolean()
            .oneOf([true], t('validation.required'))
            .required(t('validation.required')),
          password: Yup.string()
            .min(8, `${t('validation.passwordShouldBeMinimum8')}. `)
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/,><’:;|_~`])[A-Za-z\d^$*.[\]{}()?\-"!@#%&/,><’:;|_~`]{8,}$/,
              t('validation.passwordShouldContain'),
            )
            .required(t('validation.passwordIsRequired')),
        }) as any,
      ),
    [t],
  );

  const initialValues = {
    confirmPassword: '',
    isPrivacyPolicyAccepted: false,
    password: '',
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
      {({ handleSubmit, submitting, submitError }) => (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {submitError && <Alert severity="error">{t(submitError)}</Alert>}
            <TextField
              fullWidth
              type={inputType['password']}
              label={t('password')}
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleIsPasswordShown('password')}
                      edge="end"
                    >
                      <Icon
                        icon={
                          inputType['password'] === 'text'
                            ? eyeFill
                            : eyeOffFill
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              type={inputType['confirmPassword']}
              label={t('confirmPassword')}
              name="confirmPassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleIsPasswordShown('confirmPassword')}
                      edge="end"
                    >
                      <Icon
                        icon={
                          inputType['confirmPassword'] === 'text'
                            ? eyeFill
                            : eyeOffFill
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Checkbox
              name="isPrivacyPolicyAccepted"
              label={
                <Trans i18nKey="iAgreeToPrivacyPolicy">
                  I agree to{' '}
                  <Link
                    underline="none"
                    variant="subtitle2"
                    href={
                      MAP_LANGUAGE_CODE_TO_PRIVACY_POLICY_LINK[language] ||
                      PRIVACY_POLICY_LINK_DEFAULT
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </Trans>
              }
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={submitting}
            >
              {t('changePassword')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Form>
  );
};
