import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { TFunction } from 'i18next';
import { MenuItem, Stack, Typography } from '@mui/material';
import {
  Checkboxes,
  DatePicker,
  Select,
  TextField,
} from '@common/forms/fields';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { SalaryRange } from '@shared/constants';
import { useTranslation } from 'react-i18next';
import { StepComponent } from '@components/forms/types';
import { StepTitle } from '@components/forms';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_employerAddress: Yup.object({
      houseNumber: Yup.string().nullable(),
      place: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .nullable(),
      streetName: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .nullable(),
      zipCode: Yup.string().nullable(),
    })
      .nullable()
      .default({}),
    main_0_employerName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    main_0_hasFamilyRelationshipToEmployer: Yup.boolean().nullable(),
    main_0_jobStartDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
    main_0_salaryRange: Yup.string().nullable(),
  });

export const EmploymentInformationStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.employmentInformation')}
        </Typography>
      </StepTitle>

      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <Select
          name="main_0_salaryRange"
          label={getLabel('main_0_salaryRange', t, { isDraft })}
        >
          <MenuItem value={SalaryRange.Low}>{t('salaryLessRange')}</MenuItem>
          <MenuItem value={SalaryRange.High}>{t('salaryMoreRange')}</MenuItem>
        </Select>
        <DatePicker
          label={getLabel('main_0_jobStartDate', t)}
          name="main_0_jobStartDate"
        />
      </Stack>
      <TextField
        name="main_0_employerName"
        label={getLabel('main_0_employerName', t)}
      />

      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <TextField
          label={getLabel('main_0_employerAddress.zipCode', t)}
          name="main_0_employerAddress.zipCode"
        />
        <TextField
          label={getLabel('main_0_employerAddress.place', t)}
          name="main_0_employerAddress.place"
        />
      </Stack>

      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <TextField
          label={getLabel('main_0_employerAddress.streetName', t)}
          name="main_0_employerAddress.streetName"
        />
        <TextField
          label={getLabel('main_0_employerAddress.houseNumber', t)}
          name="main_0_employerAddress.houseNumber"
        />
      </Stack>
      <Checkboxes
        name="main_0_hasFamilyRelationshipToEmployer"
        data={{
          label: getLabel('main_0_hasFamilyRelationshipToEmployer', t, {
            isDraft,
          }),
          value: true,
        }}
      />
    </Stack>
  );
};

EmploymentInformationStep.getValidationSchema = getValidationSchema;
