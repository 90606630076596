import { FC, useContext, useMemo } from 'react';

import { ActiveFieldsContext } from '@components/forms/ActiveFieldsProvider';

interface Props {
  fieldNames: string[];
}
export const ConditionalFields: FC<Props> = ({ fieldNames, children }) => {
  const { activeFields } = useContext(ActiveFieldsContext);
  const isAllFieldsActive = useMemo(
    () => fieldNames.every(el => [...activeFields].includes(el)),
    [activeFields],
  );
  return <>{isAllFieldsActive && children}</>;
};
