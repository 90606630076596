import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Button, Paper, Typography } from '@mui/material';
import { checkIsMobilePlatform } from '@utils/checkIsMobilePlatform';
import { Maybe } from '@shared/types';

const DocumentContainer = styled(Paper)(({ theme }) => ({
  aspectRatio: '1/1.1',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const MobileDocumentContainer = styled(DocumentContainer)(({ theme }) => ({
  '& p': {
    marginTop: theme.spacing(2),
  },
  alignItems: 'center',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('xs')]: {
    height: 'auto',
  },
  justifyContent: 'center',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

interface Props {
  url?: Maybe<string>;
}

export const DocumentPreview: FC<Props> = ({ url }: Props) => {
  const { t } = useTranslation();
  if (!url) {
    return <DocumentContainer />;
  }

  return checkIsMobilePlatform(navigator.userAgent) ? (
    <MobileDocumentContainer>
      <Button
        color="primary"
        fullWidth
        variant="contained"
        href={url}
        target="_blank"
      >
        {t('shared:previewDocument')}
      </Button>
      <Typography color="textSecondary" variant="body2">
        {t('shared:yourBrowserDoesntSupportDocumentPreview')}
      </Typography>
    </MobileDocumentContainer>
  ) : (
    <DocumentContainer>
      <embed
        width="100%"
        height="100%"
        src={url}
        type="application/pdf"
        style={{ border: 0 }}
      />
    </DocumentContainer>
  );
};
