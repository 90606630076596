import { onError } from '@apollo/client/link/error';

// todo: configure, add sentry authorized fallbacks etc. IMPORTANT
export const errorLink = onError(errorResponse => {
  const { graphQLErrors, networkError } = errorResponse;
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${extensions?.code}`,
      );
      return null;
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});
