import { memo } from 'react';
import { QueryResult } from '@apollo/client';
import { captureException } from '@sentry/react';
import { GenericError, GenericLoading } from './QueryLoaderGenericHandlers';

interface Props<TData, TVariables> {
  queryState: QueryResult<TData, TVariables>;
  renderData: (data: TData) => JSX.Element;
  renderEmpty?: () => JSX.Element;
  renderError?: (error: Error) => JSX.Element | void;
  renderLoading?: () => JSX.Element;
}

const QueryLoaderComponentBase = <TData = any, TVariables = any>({
  queryState: { data, error, loading },
  renderEmpty,
  renderError = GenericError,
  renderLoading = GenericLoading,
  renderData,
}: Props<TData, TVariables>): JSX.Element | null => {
  if (loading && !data) {
    return renderLoading();
  }

  if (error) {
    captureException(error);
  }

  if (error && !data) {
    return renderError(error) || null;
  }

  if (!data) {
    return renderEmpty ? renderEmpty() : null;
  }

  if (data) {
    return renderData(data);
  }

  return null;
};

export const QueryLoaderComponent = memo(
  QueryLoaderComponentBase,
) as typeof QueryLoaderComponentBase;
