import { useTranslation } from 'react-i18next';
import { deDE, enUS, Localization } from '@mui/material/locale';
import { TFunction } from 'i18next';
import { useAuth } from '@hooks/useAuth';
import { useEffect } from 'react';

interface Lang {
  labelKey: string;
  value: string;
  systemValue: Localization;
  icon: string;
}

const LANGS = [
  {
    icon: '/static/icons/ic_flag_en.svg',
    labelKey: 'English',
    systemValue: enUS,
    value: 'en-GB',
  },
  {
    icon: '/static/icons/ic_flag_de.svg',
    labelKey: 'German',
    systemValue: deDE,
    value: 'de-DE',
  },
];

interface LocaleInfo {
  changeLanguage: (lang: string) => void;
  t: TFunction;
  currentLang: string;
  currentLangObj: Lang;
  locales: Lang[];
}

/**
 * Return stuff about locales and function to change it
 * @return {LocaleInfo}
 */
export const useLocales = (): LocaleInfo => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const { currentUser, updateUserAttributes } = useAuth();
  const currentUserLocale = currentUser?.locale;
  const currentLangObject = LANGS.find(_lang => _lang.value === language);

  useEffect(() => {
    if (!!currentUserLocale && currentUserLocale !== language) {
      localStorage.setItem('i18nextLng', currentUserLocale);
      i18n.changeLanguage(currentUserLocale);
    }
  }, [currentUserLocale]);

  const changeLanguage = async (lang: string) => {
    localStorage.setItem('i18nextLng', lang);
    await i18n.changeLanguage(lang);
    if (currentUser) {
      await updateUserAttributes({
        locale: lang,
      });
      (window as any).Intercom('shutdown');
      (window as any).Intercom('boot', {
        // todo move to env
        api_base: 'https://api-iam.eu.intercom.io',
        // todo move to env
        app_id: 'zorw26zx',
        ...(currentUser
          ? {
              ins_internalLocale: currentUser.locale,
              user_hash: currentUser.intercomHash,
              user_id: currentUser.id,
            }
          : {}),
      });
    }
  };

  return {
    changeLanguage,
    currentLang: language,
    currentLangObj: currentLangObject || LANGS[0],
    locales: LANGS,
    t,
  };
};
