import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AppBar } from './AppBar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  overflow: 'auto',
  paddingBottom: theme.spacing(10),
  paddingTop: APP_BAR_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: APP_BAR_DESKTOP + 24,
  },
}));

export const AppBarLayout: FC = () => (
  <RootStyle>
    <AppBar />
    <MainStyle>
      <Outlet />
    </MainStyle>
  </RootStyle>
);
