import * as Sentry from '@sentry/react';

export function connectSentry(): void {
  const buildType = process.env.NODE_ENV;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const release = process.env.REACT_APP_VERSION;
  const isSentryEnabled = process.env.REACT_APP_DISABLE_SENTRY !== 'true';

  if (isSentryEnabled && buildType === 'production') {
    if (dsn) {
      Sentry.init({
        dsn,
        environment,
        release,
      });
      console.log('Sentry initialized with dsn: ' + dsn);
    } else {
      console.log('Sentry not configured');
    }
  }
}
