import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { InsuranceCaseDocumentReviewStatus } from '@shared/constants';
import { Maybe } from '@shared/types';

interface Props {
  status: Maybe<InsuranceCaseDocumentReviewStatus>;
  sx?: SxProps<Theme>;
}

export const DocumentReviewStatusBadge: FC<Props> = memo(
  ({ sx = {}, status }) => {
    const { t } = useTranslation();

    if (
      ![
        InsuranceCaseDocumentReviewStatus.Rejected,
        InsuranceCaseDocumentReviewStatus.Changed,
      ].includes(status as any)
    ) {
      return null;
    }
    const isRejected = status === InsuranceCaseDocumentReviewStatus.Rejected;

    return (
      <Typography
        variant="subtitle2"
        component="div"
        noWrap
        sx={{
          alignSelf: 'flex-start',
          background: theme =>
            isRejected
              ? theme.palette.error.lighter
              : theme.palette.warning.lighter,
          borderRadius: theme => theme.spacing(0.5),
          color: theme =>
            isRejected ? theme.palette.error.main : theme.palette.warning.dark,
          display: 'inline-block',
          fontSize: 12,
          mr: 2,
          padding: theme => theme.spacing('1px', 1),
          paddingX: 1,
          textOverflow: 'ellipsis',
          textTransform: 'none',
          ...{},
        }}
      >
        {isRejected
          ? t('requestedToResubmit')
          : t('documentReview.newDocumentsUploaded')}
      </Typography>
    );
  },
);
