import React, { FC } from 'react';
import { Page } from '@components/Page';
import { useTranslation } from 'react-i18next';
import { Container, Paper } from '@mui/material';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { InviteCodeTable } from '@pages/InviteCodePage/InviteCodeTable';
import { CreateInviteCodeButton } from '@src/components';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { UserRole } from '@shared/constants';

export const InviteCodePage: FC = () => {
  const { t } = useTranslation();
  const { role } = useCurrentUser();

  return (
    <Page title={t('pageTitle.inviteCodes')}>
      <Container>
        <HeaderBreadcrumbs
          action={role !== UserRole.Administrator && <CreateInviteCodeButton />}
          heading={t('inviteCodes')}
          links={[]}
        />
        <Paper elevation={3}>
          <InviteCodeTable />
        </Paper>
      </Container>
    </Page>
  );
};
