import * as Yup from 'yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import { StepComponent, StepMeta } from '@components/forms/types';
import { StepTitle } from '@components/forms/StepTitle';
import { FileState, UploadFiles } from '@components/upload/UploadFiles';
import { GQL } from '@queries';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { ConditionalFields } from '@components/forms';
import { APPLICATION_FORM_DOCUMENT_NAMES } from '@shared/constants';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useAlert } from '@common/hooks';
import { InsuranceCaseFragment } from '@queries/types/InsuranceCaseFragment';
import { InsuranceCaseFrag } from '@common/queries/insuranceCase.queries';

const getValidationSchema = (
  t: TFunction,
  { rejectedDocuments = {}, idx }: StepMeta = {},
): AnySchema =>
  Yup.object().shape({
    [`child_${idx}_photography`]: Yup.boolean().nullable(),
    [`child_${idx}_enrollmentCertificate`]: Yup.boolean().nullable(),
    [`child_${idx}_birthCertificate`]: Yup.boolean().nullable(),
    ...Object.keys(rejectedDocuments)
      // controls prefixes on document name (filters non custom documents)
      .filter(rejectedDocName => rejectedDocName.includes(`child_${idx}`))
      .reduce(
        (acc, rejectedDocName) => ({
          ...acc,
          [rejectedDocName]: Yup.boolean().nullable(),
        }),
        {},
      ),
  });

const getActiveFields = (
  values: Record<string, any>,
  { idx, rejectedDocuments }: StepMeta = {},
) => ({
  [`child_${idx}_enrollmentCertificate`]:
    !!values[`child_${idx}_isStudying`] ||
    !!rejectedDocuments?.[`child_${idx}_enrollmentCertificate`],
  [`child_${idx}_currentInsuranceCard`]:
    !!rejectedDocuments?.[`child_${idx}_currentInsuranceCard`],
  [`child_${idx}_sepaMandate`]:
    !!rejectedDocuments?.[`child_${idx}_sepaMandate`],
  [`child_${idx}_birthCertificate`]: true,
  [`child_${idx}_marriageCertificate`]:
    !!rejectedDocuments?.[`child_${idx}_passport`],
  [`child_${idx}_passport`]: !!rejectedDocuments?.[`child_${idx}_passport`],
  [`child_${idx}_photography`]: true,
});

export const ChildDocumentsStep: StepComponent = ({
  meta: { idx, rejectedDocuments = {}, insuranceCase } = {},
  goBack,
  goNext,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);
  const [documents, setDocuments] = useState<Record<string, FileState[]>>(
    insuranceCase.documents?.reduce(
      (acc, { documentName, fileName }) => ({
        ...acc,
        [documentName]: [
          ...(acc?.[documentName] || []),
          { isUploaded: true, name: fileName },
        ],
      }),
      {},
    ),
  );
  const customRequestedDocumentNames = Object.entries(rejectedDocuments).filter(
    ([rejectedDocName]) =>
      rejectedDocName.includes(`child_${idx}`) &&
      !APPLICATION_FORM_DOCUMENT_NAMES.includes(
        rejectedDocName.split('_')[2] as any,
      ) &&
      !['powerOfAttorneyUploaded', 'powerOfAttorney'].includes(rejectedDocName),
  );
  const [create] = useMutation(GQL.DOCUMENT.CREATE);
  const hasUnaploadedDocs = !!Object.values(documents).filter(docFiles =>
    docFiles.some(({ isUploaded }) => !isUploaded),
  ).length;

  useAlert(t('youHaveDocumentsCurrentlyBeingUploaded'), isUploading);
  useAlert(t('youHaveDocumentsNotUploaded'), hasUnaploadedDocs, true);

  const addDocuments = (acceptedFiles: FileState[], documentName: string) => {
    setDocuments(prevState => {
      const newFiles = acceptedFiles.filter(
        ({ name }) =>
          !prevState[documentName]?.some(file => file.name === name),
      );
      return {
        ...prevState,
        [documentName]: [...(prevState[documentName] || []), ...newFiles],
      };
    });
  };

  const addDocument = (acceptedFile: FileState, documentName: string) => {
    const isFileExist = documents[documentName]?.some(
      ({ name }) => acceptedFile.name === name,
    );
    if (!isFileExist) {
      setDocuments(prevState => ({
        ...prevState,
        [documentName]: [...(prevState[documentName] || []), acceptedFile],
      }));
    }
  };

  const removeDocument = (acceptedFile: FileState, documentName: string) => {
    setDocuments(prevState => ({
      ...prevState,
      [documentName]: (prevState[documentName] || []).filter(
        ({ name }) => acceptedFile.name !== name,
      ),
    }));
  };

  const updateDocument = (acceptedFile: FileState, documentName: string) => {
    setDocuments(prevState => ({
      ...prevState,
      [documentName]: [
        ...(prevState[documentName] || []).filter(
          ({ name }) => acceptedFile.name !== name,
        ),
        acceptedFile,
      ],
    }));
  };

  const uploadDocument = async (
    file: FileState,
    documentName,
  ): Promise<void> => {
    if (file.isUploaded) {
      return;
    }

    file.isLoading = true;
    file.isUploaded = false;
    file.error = undefined;
    updateDocument(file, documentName);
    try {
      const {
        data: {
          documentMutations: { create: document },
        },
      } = await create({
        update: (
          cache,
          {
            data: {
              documentMutations: { create: createdDocument },
            },
          },
        ) => {
          const insuranceCaseCached = cache.readFragment<InsuranceCaseFragment>(
            {
              fragment: InsuranceCaseFrag,
              fragmentName: 'InsuranceCaseFragment',
              id: `InsuranceCase:${insuranceCase.id}`,
            },
          );

          if (insuranceCaseCached) {
            cache.writeFragment<InsuranceCaseFragment>({
              data: {
                ...insuranceCaseCached,
                documents: [...insuranceCaseCached.documents, createdDocument],
              },
              fragment: InsuranceCaseFrag,
              fragmentName: 'InsuranceCaseFragment',
              id: `InsuranceCase:${insuranceCase.id}`,
            });
          }
        },
        variables: {
          documentContainerId: insuranceCase.documentContainerId,
          documentName,
          fileName: file.name,
        },
      });
      const uploadResponse = await fetch(document.uploadUrl, {
        body: file,
        headers: { 'Content-Type': file.type },
        method: 'PUT',
      });

      if (!uploadResponse.ok) {
        throw Error();
      }
      file.isUploaded = true;
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      file.error = label || t('error.unableToUploadFile');
    } finally {
      file.isLoading = false;
      updateDocument(file, documentName);
    }
  };

  const uploadDocuments = async () => {
    setIsUploading(true);
    try {
      await Promise.all(
        Object.entries(documents)
          .map(([documentName, documentFiles]) =>
            documentFiles.map(file => uploadDocument(file, documentName)),
          )
          .flat(),
      );
    } finally {
      setIsUploading(false);
    }
  };

  const onSubmit = async () => {
    try {
      await uploadDocuments();
      goNext?.();
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  return (
    <>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.documents')}</Typography>
      </StepTitle>
      <Grid container spacing={2} alignItems="stretch">
        {APPLICATION_FORM_DOCUMENT_NAMES.map(name => (
          <ConditionalFields key={name} fieldNames={[`child_${idx}_${name}`]}>
            <Grid item xs={12} md={6} xl={4}>
              <UploadFiles
                files={documents[`child_${idx}_${name}`]}
                onDrop={acceptedFiles =>
                  addDocuments(acceptedFiles, `child_${idx}_${name}`)
                }
                onRemove={file => removeDocument(file, `child_${idx}_${name}`)}
                onUpload={file => addDocument(file, `child_${idx}_${name}`)}
                title={getLabel(`child_${idx}_${name}`, t)}
                isRejected={
                  rejectedDocuments?.[`child_${idx}_${name}`]?.isRejected
                }
              />
            </Grid>
          </ConditionalFields>
        ))}
        {customRequestedDocumentNames.map(([documentName]) => (
          <Grid key={documentName} item xs={12} md={6} xl={4}>
            <UploadFiles
              files={documents[documentName]}
              onDrop={acceptedFiles =>
                addDocuments(acceptedFiles, documentName)
              }
              onRemove={file => removeDocument(file, documentName)}
              onUpload={file => addDocument(file, documentName)}
              title={getLabel(documentName, t)}
              isRejected={rejectedDocuments?.[documentName]?.isRejected}
            />
          </Grid>
        ))}
        <Grid item xs={12} md={6} xl={4}>
          <UploadFiles
            files={documents[`child_${idx}_otherDocuments`]}
            onDrop={acceptedFiles =>
              addDocuments(acceptedFiles, `child_${idx}_otherDocuments`)
            }
            onRemove={file =>
              removeDocument(file, `child_${idx}_otherDocuments`)
            }
            onUpload={file => addDocument(file, `child_${idx}_otherDocuments`)}
            title={getLabel(`child_${idx}_otherDocuments`, t)}
            isRejected={
              rejectedDocuments?.[`child_${idx}_otherDocuments`]?.isRejected
            }
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          marginTop: 2,
        }}
      >
        <Button
          sx={{ marginRight: 2, width: 100 }}
          variant="outlined"
          onClick={goBack}
        >
          {t('back')}
        </Button>
        <LoadingButton
          sx={{ width: 100 }}
          variant="contained"
          onClick={onSubmit}
          loading={isUploading}
        >
          {t('continue')}
        </LoadingButton>
      </Box>
    </>
  );
};

ChildDocumentsStep.getValidationSchema = getValidationSchema;
ChildDocumentsStep.getActiveFields = getActiveFields;
