import {
  AdditionalInformationStep,
  BirthInformationStep,
  ChildBirthInfoStep,
  ChildDocumentsStep,
  ChildGeneralStep,
  ChildOccupationStep,
  ChildPreviousInsuranceStep,
  DependantsStep,
  DocumentsStep,
  EmploymentInformationStep,
  OccupationStep,
  OtherPurposeInformationStep,
  PensionInformationStep,
  PersonalInformationStep,
  PreviousInsuranceStep,
  ResidenceStep,
  SpouseBirthInformationStep,
  SpouseDocumentsStep,
  SpouseGeneralInformationStep,
  SpouseOccupationStep,
  SpousePreviousInsuranceStep,
  StudyInformationStep,
  UnemploymentInformationStep,
  ConfirmationStep,
} from '@components/forms/ApplicationForm/steps';
import {
  ConfirmationStep as DraftConfirmationStep,
  DependantsVoidStep,
  OccupationVoidStep,
  PowerOfAttorneyStep,
  PreviousInsuranceVoidStep,
} from '@components/forms/ApplicationDraftForm/steps';
import { GetStepsArguments, Step } from '@components/forms/types';
import { getConditionalArr } from '@shared/helpers';
import { DependantsType, InsurancePurpose, PoaStatus } from '@shared/constants';
import { createNumberOfSteps } from '@utils/createNumberOfSteps';

export type MissingInfo = 'occupation' | 'previousInsurance' | 'dependants';
export const getMissingInfo = (values: Record<string, any>): MissingInfo[] => {
  // Advertiser should have everything once poa exist
  if ([PoaStatus.Generating, PoaStatus.Uploading].includes(values.poaStatus)) {
    return [];
  }
  return [
    ...getConditionalArr(!values.main_0_insurancePurposes?.length, [
      'occupation',
    ]),
    ...getConditionalArr(values.main_0_hasPreviousInsurance === undefined, [
      'previousInsurance',
    ]),
    ...getConditionalArr(
      values.main_0_hasWillingToCoverDependants === undefined,
      ['dependants'],
    ),
  ];
};

export const getSteps = ({ t, formValues }: GetStepsArguments): Step[] => {
  const missingInfo = getMissingInfo(formValues);
  return [
    {
      component: PowerOfAttorneyStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'powerOfAttorney',
      stepNameSidebar: t('powerOfAttorney'),
    },
    {
      component: PersonalInformationStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'personal-information',
      stepNameSidebar: t('personalInformation'),
    },
    {
      component: BirthInformationStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'birth-information',
      stepNameSidebar: t('birthDateAndPlace'),
    },
    {
      component: ResidenceStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'residence',
      stepNameSidebar: t('residence'),
    },
    {
      component: missingInfo.includes('occupation')
        ? OccupationVoidStep
        : OccupationStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'occupation',
      stepNameSidebar: t('occupation'),
    },
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.some(purpose =>
        [
          InsurancePurpose.Employment,
          InsurancePurpose.Trainee,
          InsurancePurpose.Partner,
        ].includes(purpose),
      ),
      [
        {
          component: EmploymentInformationStep,
          group: t('applicationDraft.group.applicant'),
          stepName: 'employment-purpose',
          stepNameSidebar: t('employmentPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(InsurancePurpose.Study),
      [
        {
          component: StudyInformationStep,
          group: t('applicationDraft.group.applicant'),
          stepName: 'study-purpose',
          stepNameSidebar: t('studyPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(
        InsurancePurpose.Pension,
      ),
      [
        {
          component: PensionInformationStep,
          group: t('applicationDraft.group.applicant'),
          stepName: 'pension-purpose',
          stepNameSidebar: t('pensionPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(InsurancePurpose.Other),
      [
        {
          component: OtherPurposeInformationStep,
          group: t('applicationDraft.group.applicant'),
          stepName: 'other-purpose',
          stepNameSidebar: t('otherPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(
        InsurancePurpose.Unemployment,
      ),
      [
        {
          component: UnemploymentInformationStep,
          group: t('applicationDraft.group.applicant'),
          stepName: 'unemployment-purpose',
          stepNameSidebar: t('unemploymentPurposeInformation'),
        },
      ],
    ),
    {
      component: missingInfo.includes('previousInsurance')
        ? PreviousInsuranceVoidStep
        : PreviousInsuranceStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'previous-insurance',
      stepNameSidebar: t('previousInsurance'),
    },
    {
      component: AdditionalInformationStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'additional-information',
      stepNameSidebar: t('additionalInformation'),
    },
    {
      component: missingInfo.includes('dependants')
        ? DependantsVoidStep
        : DependantsStep,
      group: t('applicationDraft.group.applicant'),
      stepName: 'dependants',
      stepNameSidebar: t('dependants'),
    },
    {
      component: DocumentsStep,
      group: t('applicationDraft.group.applicant'),
      hideControls: true,
      meta: { poaStatus: formValues.poaStatus },
      stepName: 'documents',
      stepNameSidebar: t('documents'),
    },
    ...getConditionalArr(
      [DependantsType.Spouse, DependantsType.ChildrenAndSpouse].includes(
        formValues['main_0_dependantsType'],
      ),
      () => [
        {
          component: SpouseGeneralInformationStep,
          group: t('applicationDraft.group.spouse'),
          stepName: 'spouse-general-information',
          stepNameSidebar: t('personalInformation'),
        },
        {
          component: SpouseBirthInformationStep,
          group: t('applicationDraft.group.spouse'),
          stepName: 'spouse-birth-information',
          stepNameSidebar: t('birthDateAndPlace'),
        },
        {
          component: SpousePreviousInsuranceStep,
          group: t('applicationDraft.group.spouse'),
          stepName: 'spouse-previous-insurance',
          stepNameSidebar: t('previousInsurance'),
        },
        {
          component: SpouseOccupationStep,
          group: t('applicationDraft.group.spouse'),
          stepName: 'spouse-occupation',
          stepNameSidebar: t('occupation'),
        },
        {
          component: SpouseDocumentsStep,
          group: t('applicationDraft.group.spouse'),
          hideControls: true,
          stepName: 'spouse-documents',
          stepNameSidebar: t('documents'),
        },
      ],
    ),
    ...createNumberOfSteps(formValues.main_0_childrenCount, idx => [
      {
        component: ChildGeneralStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-general-information`,
        stepNameSidebar: t(`personalInformation`),
      },
      {
        component: ChildBirthInfoStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-birth-information`,
        stepNameSidebar: t(`birthDateAndPlace`),
      },
      {
        component: ChildPreviousInsuranceStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-previous-insurance-step`,
        stepNameSidebar: t(`previousInsurance`),
      },
      {
        component: ChildOccupationStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-occupation-step`,
        stepNameSidebar: t(`occupation`),
      },
      {
        component: ChildDocumentsStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        hideControls: true,
        meta: { idx },
        stepName: `child-${idx}-documents`,
        stepNameSidebar: t('documents'),
      },
    ]),
    {
      component:
        formValues.poaStatus === PoaStatus.NotAvailable
          ? DraftConfirmationStep
          : ConfirmationStep,
      group:
        formValues.poaStatus === PoaStatus.NotAvailable
          ? t('sendToApplicant')
          : t('submitApplication'),
      hideControls: true,
      stepName: 'confirmation',
      stepNameSidebar:
        formValues.poaStatus === PoaStatus.NotAvailable
          ? t('submitForm')
          : t('submitApplication'),
    },
  ];
};
