export enum UserRole {
  Administrator = 'Administrator',
  Advertiser = 'Advertiser',
  AdvertiserMember = 'AdvertiserMember',
  Applicant = 'Applicant',
  BranchOffice = 'BranchOffice',
  HeadOffice = 'HeadOffice',
  ServicePoint = 'ServicePoint',
}

export enum OfficeMemberType {
  HeadOffice = 'HeadOffice',
  ServicePoint = 'ServicePoint',
  BranchOffice = 'BranchOffice',
}

export const USER_ROLE_BY_OFFICE_MEMBER_TYPE: Record<
  OfficeMemberType,
  UserRole
> = {
  [OfficeMemberType.BranchOffice]: UserRole.BranchOffice,
  [OfficeMemberType.HeadOffice]: UserRole.HeadOffice,
  [OfficeMemberType.ServicePoint]: UserRole.ServicePoint,
};

export enum OrderDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum InsuranceCaseStatus {
  ApplicationFilling = 'ApplicationFilling',
  Approved = 'Approved',
  ChangesRequested = 'ChangesRequested',
  PoaSign = 'PoaSign',
  Covered = 'Covered',
  Created = 'Created',
  DocumentsGenerating = 'DocumentsGenerating',
  DocumentsSigning = 'DocumentsSigning',
  Draft = 'Draft',
  NotInsurable = 'NotInsurable',
  PaymentSent = 'PaymentSent',
  Pending = 'Pending',
  Reviewing = 'Reviewing',
}

export enum InsuranceCaseFieldReviewStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Changed = 'Changed', // applicant resubmit the field after reject
}

export enum InsuranceCaseDocumentReviewStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Changed = 'Changed', // applicant resubmit the field after reject
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Indeterminate = 'Indeterminate',
  Other = 'Other',
}

export enum SalaryRange {
  Low = 'Low',
  High = 'High',
}

export enum ChildRelationship {
  AdoptedChild = 'AdoptedChild',
  Daughter = 'Daughter',
  FosterChild = 'FosterChild',
  GrandChild = 'GrandChild',
  Son = 'Son',
  StepChild = 'StepChild',
}

export enum StudyType {
  ElementarySchool = 'ElementarySchool',
  SecondarySchool = 'SecondarySchool',
  SecondaryModernSchool = 'SecondaryModernSchool',
  GrammarSchool = 'GrammarSchool',
  ComprehensiveSchool = 'ComprehensiveSchool',
  University = 'University',
  TechnicalCollege = 'TechnicalCollege',
  UniversityOfAppliedSciences = 'UniversityOfAppliedSciences',
}

export enum InsurancePurpose {
  Employment = 'Employment',
  Partner = 'Partner',
  Pension = 'Pension',
  Study = 'Study',
  Trainee = 'Trainee',
  Unemployment = 'Unemployment',
  Other = 'Other',
}

export enum InsuranceType {
  FamilyMember = 'FamilyMember',
  MainMember = 'MainMember',
  NotStatutory = 'NotStatutory',
}

export enum DependantsType {
  Spouse = 'Spouse',
  Children = 'Children',
  ChildrenAndSpouse = 'ChildrenAndSpouse',
}

export enum PoaStatus {
  Generating = 'Generating',
  NotAvailable = 'NotAvailable',
  Uploading = 'Uploading',
}

export enum PoaGeneratingStatus {
  Generated = 'Generated',
  Signed = 'Signed',
  NotGenerated = 'NotGenerated',
  Rejected = 'Rejected',
}

export enum BankAccountType {
  SWIFT = 'SWIFT',
  IBAN = 'IBAN',
}

export enum AdvertiserStatus {
  PROFILE = 'PROFILE', // has to fill out a profile )
  PENDING = 'PENDING', // has filled out a profile and is waiting/not verified by code
  CONTRACT_SIGNING = 'CONTRACT_SIGNING', // has to sign contract to get approved
  ADDENDUM_SIGNING = 'ADDENDUM_SIGNING', // need to sign addendum
  APPROVED = 'APPROVED', // has full access to the system
}

export enum AdvertiserType {
  Main = 'Main',
  Under = 'Under',
}

export enum InsuranceCaseEmailRecipient {
  Applicant = 'Applicant',
  Advertiser = 'Advertiser',
  BranchOffice = 'BranchOffice',
  ServicePoint = 'ServicePoint',
  ResponsiblePerson = 'ResponsiblePerson',
}

export const APPLICATION_FORM_DOCUMENT_NAMES = [
  'birthCertificate',
  'currentInsuranceCard',
  'enrollmentCertificate',
  'marriageCertificate',
  'passport',
  'photography',
  'sepaMandate',
  'workContract',
] as const;

export const DEFAULT_DOCUMENT_NAMES = [
  'barmerApplication',
  'birthCertificate',
  'currentInsuranceCard',
  'enrollmentCertificate',
  'marriageCertificate',
  'passport',
  'photography',
  'sepaMandate',
  'workContract',
  'powerOfAttorneyUploaded',
  'powerOfAttorney',
] as const;
export type DefaultDocumentNamesType = typeof DEFAULT_DOCUMENT_NAMES[number];

export const GLOBAL_MIN_DATE = '1900-01-01';

export enum DocumentTemplateType {
  Document = 'Document',
  Pdf = 'Pdf',
  Html = 'Html',
}

export enum DocumentTemplateName {
  barmerAdvertiserAddendumAgreement = 'barmerAdvertiserAddendumAgreement',
  barmerAdvertiserAgreement = 'barmerAdvertiserAgreement',
  barmerApplication = 'barmerApplication',
}

export enum OfficeMemberProfileStatus {
  Updated = 'Updated',
  UpdateRequired = 'UpdateRequired',
}

export const PRIVACY_POLICY_LINK_DEFAULT = 'https://visaright.eu/privacy';
export const MAP_LANGUAGE_CODE_TO_PRIVACY_POLICY_LINK: Record<string, string> =
  {
    'de-DE': 'https://visaright.eu/privacy',
    'en-GB': PRIVACY_POLICY_LINK_DEFAULT,
  };

export enum HelpRequestCategory {
  Insurance = 'Insurance',
  Platform = 'Platform',
}

export enum SlackChannel {
  Errors = 'Errors',
  Notifications = 'Notifications',
}

export interface SlackMessageOptions {
  channel?: SlackChannel;
  isMarkdown?: boolean;
  mentionedSlackEmails?: string[];
  mentionedSlackIds?: string[];
  shouldAlertChannel?: boolean;
}

// todo not sure if we should keep it in shared constants
// since they are all go to main bundle
export enum AdvertiserPaymentStatus {
  Calculating = 'Calculating',
  Failed = 'Failed',
  Finished = 'Finished',
  Initialized = 'Initialized',
  Canceled = 'Canceled',
  Paid = 'Paid',
  Pending = 'Pending',
  PlanApprovalPending = 'PlanApprovalPending',
  Skipped = 'Skipped', // no cases to pay for date,
}

export enum AdvertiserPaymentsPlanStatus {
  ApprovalPending = 'ApprovalPending',
  Executing = 'Executing',
  Filling = 'Filling',
  Finished = 'Finished',
  Initialized = 'Initialized',
  Interrupted = 'Interrupted',
}

export enum AdvertiserPaymentMethod {
  PentaManual = 'PentaManual',
  Revolut = 'Revolut',
}

export enum InviteCodeStatus {
  Active = 'Active',
  Used = 'Used',
  Revoked = 'Revoked',
}

export const MAXIMUM_ADVERTISER_PROVISION = 106.05;
