import { FC, useRef, useState } from 'react';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useLocales } from '@hooks/useLocales';
import { useTranslation } from 'react-i18next';
import { MenuPopover, MIconButton } from '../../components';

export const LanguagePopover: FC = () => {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { locales, currentLang, changeLanguage, currentLangObj } = useLocales();

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          height: 44,
          padding: 0,
          width: 44,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <img src={currentLangObj.icon} alt={t(currentLangObj.labelKey)} />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {locales.map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang}
              onClick={() => {
                changeLanguage(option.value);
                setOpen(false);
              }}
              sx={{ px: 2.5, py: 1 }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  alt={t(option.labelKey)}
                  src={option.icon}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {t(option.labelKey)}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
};
