import { InMemoryCache } from '@apollo/client';
// import { persistCache, PersistentStorage } from 'apollo3-cache-persist';

async function initCache(): Promise<InMemoryCache> {
  const cache: InMemoryCache = new InMemoryCache({
    // todo: implement type policy
    // typePolicies: TypePolicy,
  });

  // await persistCache({
  //   cache,
  //   storage: window.localStorage as PersistentStorage<any>,
  //   debug: process.env.NODE_ENV === 'local',
  // });

  return cache;
}
export default initCache;
