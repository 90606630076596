import { gql } from '@apollo/client';

const AdvertiserMemberFrag = gql`
  fragment AdvertiserMemberFragment on AdvertiserMemberGQL {
    id
    publicId
    email
    firstName
    lastName
  }
`;

const AdvertiserFrag = gql`
  fragment AdvertiserFragment on Advertiser {
    id
    branchOfficeId
    publicId
    createdAt
    updatedAt
    name
    status
    phone
    email
    provision
    address {
      place
      zipCode
      country
      streetName
      houseNumber
    }
    bankAccount {
      ... on BankAccountIBAN {
        code
        type
        vatId
      }
      ... on BankAccountSWIFT {
        abaRoutingTransitNumber
        account
        address
        beneficiary
        bsbCode
        clabeNumber
        code
        country
        ifscCode
        name
        type
      }
    }
    contactPerson {
      firstName
      lastName
    }
    casesCount
    type
    formContainerId
    documentContainerId
    documents {
      fileName
      documentName
      id
      signedSlots
      createdAt
    }
  }
`;

export const ADVERTISER = {
  ADVERTISER: gql`
    query GetAdvertiser($advertiserId: String!) {
      advertiser(advertiserId: $advertiserId) {
        ...AdvertiserFragment
      }
    }
    ${AdvertiserFrag}
  `,
  ADVERTISERS_ASSIGN_PAGED: gql`
    query GetAdvertisersAssignPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $assignedToIds: [ID]
      $branchOfficeIds: [ID]
      $filterBy: String
      $filterValues: [String!]
    ) {
      advertisersPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
        assignedToIds: $assignedToIds
        branchOfficeIds: $branchOfficeIds
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
          endCursor
          startCursor
        }
        edges {
          node {
            id
            publicId
            casesCount
            servicePoint {
              id
              name
            }
          }
        }
      }
    }
  `,
  ADVERTISERS_ASSIGN_WITH_BRANCH_PAGED: gql`
    query GetAdvertisersAssignWithBranchPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $assignedToIds: [ID]
      $branchOfficeIds: [ID]
      $filterBy: String
      $filterValues: [String!]
    ) {
      advertisersPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
        assignedToIds: $assignedToIds
        branchOfficeIds: $branchOfficeIds
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
          endCursor
          startCursor
        }
        edges {
          node {
            id
            publicId
            casesCount
            branchOffice {
              id
              name
            }
            servicePoint {
              id
              name
            }
          }
        }
      }
    }
  `,
  ADVERTISERS_PAGED: gql`
    query GetAdvertisersPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      advertisersPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
          endCursor
          startCursor
        }
        edges {
          node {
            id
            createdAt
            name
            status
            provision
            contactPerson {
              firstName
              lastName
            }
            casesCount
          }
        }
      }
    }
  `,
  ASSIGN_SERVICE_POINT: gql`
    mutation AssignServicePointToAdvertiser(
      $advertiserId: String!
      $officeMemberId: String
    ) {
      advertiserMutations {
        assignServicePoint(
          advertiserId: $advertiserId
          officeMemberId: $officeMemberId
        ) {
          id
          publicId
          servicePoint {
            id
            name
          }
        }
      }
    }
  `,
  DELETE: gql`
    mutation DeleteAdvertiser($advertiserId: String!) {
      advertiserMutations {
        deleteAdvertiser(advertiserId: $advertiserId)
      }
    }
  `,
  DELETE_ADVERTISER_MEMBER: gql`
    mutation DeleteAdvertiserMember($advertiserMemberId: String!) {
      advertiserMutations {
        deleteAdvertiserMember(advertiserMemberId: $advertiserMemberId)
      }
    }
  `,
  INVITE_MEMBER: gql`
    mutation InviteAdvertiserMember(
      $advertiserId: String!
      $email: String!
      $firstName: String!
      $lastName: String!
    ) {
      advertiserMutations {
        inviteAdvertiserMember(
          advertiserId: $advertiserId
          email: $email
          firstName: $firstName
          lastName: $lastName
        ) {
          ...AdvertiserMemberFragment
        }
      }
    }
    ${AdvertiserMemberFrag}
  `,
  MEMBERS_PAGED: gql`
    query GetAdvertiserMembersPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
      $advertiserId: String!
    ) {
      advertiserMembersPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
        advertiserId: $advertiserId
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
          endCursor
          startCursor
        }
        edges {
          node {
            ...AdvertiserMemberFragment
            casesCount
          }
        }
      }
    }
    ${AdvertiserMemberFrag}
  `,
  UNDERS_PAGED: gql`
    query GetAdvertiserUndersPaged(
      $advertiserId: String
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      undersPaged(
        advertiserId: $advertiserId
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
          endCursor
          startCursor
        }
        edges {
          node {
            id
            status
            provision
            publicId
            casesCount
          }
        }
      }
    }
  `,
  UPDATE: gql`
    mutation UpdateAdvertiser(
      $advertiserId: String!
      $input: UpdateAdvertiserInput!
    ) {
      advertiserMutations {
        update(advertiserId: $advertiserId, input: $input) {
          ...AdvertiserFragment
        }
      }
    }
    ${AdvertiserFrag}
  `,
  UPDATE_MAX_PROVISION: gql`
    mutation UpdateAdvertiserProvisionSeed {
      advertiserMutations {
        updateMaxProvision
      }
    }
  `,
  UPDATE_PROFILE: gql`
    mutation UpdateAdvertiserProfile(
      $advertiserId: String!
      $input: UpdateAdvertiserProfileInput!
    ) {
      advertiserMutations {
        updateProfile(advertiserId: $advertiserId, input: $input) {
          ...AdvertiserFragment
        }
      }
    }
    ${AdvertiserFrag}
  `,
};
