import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Badge(theme: Theme): Record<string, any> {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          borderRadius: '50%',
          height: 10,
          width: 10,
        },
      },
    },
  };
}
