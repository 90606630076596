import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PaidIcon from '@mui/icons-material/Paid';
import QrCodeIcon from '@mui/icons-material/QrCode';
import LinkIcon from '@mui/icons-material/Link';
import { AdvertiserType, UserRole } from '@shared/constants';
import { NavSectionProps } from '@components/NavSection';
import { TFunction } from 'i18next';
import { getConditionalArr } from '@shared/helpers';
import { FolderShared } from '@mui/icons-material';
import { ROUTES } from '@common/routes';
import { GetAdvertiser_advertiser as Advertiser } from '@queries/types/GetAdvertiser';
import { Maybe } from '@shared/types';

const ICONS = {
  analytics: <AddReactionIcon />,
  dashboard: <DashboardIcon />,
  ecommerce: <AddReactionIcon />,
  user: <PersonIcon />,
};

const sidebarConfig = (
  role: UserRole,
  t: TFunction,
  advertiser: Maybe<Advertiser>,
): NavSectionProps['navConfig'] => {
  if ([UserRole.Advertiser, UserRole.AdvertiserMember].includes(role)) {
    return [
      {
        items: [
          {
            icon: <FolderShared />,
            path: ROUTES.ADVERTISER.applicants(),
            title: t('sidebar.applicants'),
          },
          ...getConditionalArr(role === UserRole.Advertiser, [
            {
              icon: <LinkIcon />,
              path: ROUTES.ADVERTISER.refLinks(),
              title: t('sidebar.refLinks'),
            },
          ]),
        ],
        subheader: t('sidebar.subheader.applicants'),
      },
      ...getConditionalArr(
        advertiser?.type === AdvertiserType.Main &&
          role === UserRole.Advertiser,
        [
          {
            items: [
              {
                icon: <AccountBoxIcon />,
                path: ROUTES.ADVERTISER.unders(),
                title: t('sidebar.subAdvertisers'),
              },
              {
                icon: <QrCodeIcon />,
                path: ROUTES.ADVERTISER.inviteCodes(),
                title: t('sidebar.inviteCodes'),
              },
            ],
            subheader: t('sidebar.subheader.subAdvertisers'),
          },
        ],
      ),
      ...getConditionalArr(role === UserRole.Advertiser, [
        {
          items: [
            {
              icon: <AssignmentIcon />,
              path: ROUTES.ADVERTISER.members(),
              title: t('sidebar.advertiserMembers'),
            },
            {
              icon: <HistoryEduIcon />,
              path: ROUTES.ADVERTISER.documents(),
              title: t('sidebar.advertiser.documents'),
            },
          ],
          subheader: t('sidebar.subheader.advertiserCompany'),
        },
      ]),
    ];
  }

  return [
    {
      items: [
        ...getConditionalArr(
          [
            UserRole.Administrator,
            UserRole.BranchOffice,
            UserRole.HeadOffice,
            UserRole.ServicePoint,
          ].includes(role),
          [
            {
              icon: ICONS.user,
              path: ROUTES.BRANCH.applicants(),
              title: t('sidebar.applicants'),
            },
          ],
        ),
        ...getConditionalArr(
          [UserRole.HeadOffice, UserRole.Administrator].includes(role),
          [
            {
              icon: <BusinessIcon />,
              path: ROUTES.HEAD_MANAGEMENT.branchOffices(),
              title: t('branchOffices'),
            },
            {
              icon: <GroupIcon />,
              path: ROUTES.HEAD_MANAGEMENT.officeMembers(),
              title: t('officeMembers'),
            },
          ],
        ),
        ...getConditionalArr([UserRole.Administrator].includes(role), [
          {
            icon: <GroupIcon />,
            path: ROUTES.HEAD_MANAGEMENT.advertisers(),
            title: t('advertisers'),
          },
        ]),
        ...getConditionalArr(
          [
            UserRole.Administrator,
            UserRole.HeadOffice,
            UserRole.BranchOffice,
          ].includes(role),
          [
            {
              icon: <AssignmentIndIcon />,
              path: ROUTES.HEAD_MANAGEMENT.servicePoints(),
              title: t('servicePoints'),
            },
          ],
        ),
        ...getConditionalArr(
          [UserRole.Administrator, UserRole.BranchOffice].includes(role),
          [
            {
              icon: <AssignmentIcon />,
              path: ROUTES.BRANCH.advertisersAssign(),
              title: t('sidebar.servicePointsResponsibility'),
            },
            {
              icon: <QrCodeIcon />,
              path: ROUTES.BRANCH.inviteCodes(),
              title: t('sidebar.inviteCodes'),
            },
          ],
        ),
        ...getConditionalArr([UserRole.HeadOffice].includes(role), [
          {
            icon: <AssignmentIcon />,
            path: ROUTES.HEAD_MANAGEMENT.assignments(),
            title: t('sidebar.servicePointsResponsibility'),
          },
        ]),
        ...getConditionalArr(role === UserRole.Administrator, [
          {
            icon: <PaidIcon />,
            path: ROUTES.ADMINISTRATOR.advertisersPaymentsPlans(),
            title: t('sidebar.advertiserPayments'),
          },
          {
            icon: <PaidIcon />,
            path: ROUTES.ADMINISTRATOR.revolutTransactions(),
            title: t('sidebar.revolutTransactions'),
          },
        ]),
      ],
    },
  ];
};

export default sidebarConfig;
