import { MenuItem, Stack, Typography } from '@mui/material';
import { TextField } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { Checkboxes, Select } from 'mui-rff';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { StepComponent, StepMeta } from '@components/forms/types';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { Gender } from '@shared/constants';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction, { idx }: StepMeta = {}): AnySchema =>
  Yup.object().shape({
    [`child_${idx}_gender`]: Yup.string().nullable(),
    [`child_${idx}_firstName`]: Yup.string()
      .nullable()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .required(t('validation.required')),
    [`child_${idx}_addressIsDifferentFromMain`]: Yup.boolean().nullable(),
    [`child_${idx}_lastName`]: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    [`child_${idx}_previousLastName`]: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    [`child_${idx}_isLastNameChanged`]: Yup.boolean().nullable(),
    [`child_${idx}_addressOfResidenceIsDifferentFromMain`]:
      Yup.boolean().nullable(),
    [`child_${idx}_residenceCountry`]: Yup.string().nullable(),
    [`child_${idx}_pensionInsuranceNumber`]: Yup.string()
      .test('len', t('validation.shouldBeExactly', { characters: 12 }), val =>
        val ? val.length === 12 : true,
      )
      .nullable(),
  });

const getActiveFields = (values, { idx }: StepMeta = {}) => {
  const isAddressDifferentFromMain =
    !!values[`child_${idx}_addressIsDifferentFromMain`];
  return {
    [`child_${idx}_address.country`]: isAddressDifferentFromMain,
    [`child_${idx}_address.place`]: isAddressDifferentFromMain,
  };
};

export const ChildGeneralStep: StepComponent = props => {
  const { t } = useTranslation();

  const idx = props.meta?.idx;

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.generalInformation')}
        </Typography>
      </StepTitle>
      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <TextField
          name={`child_${idx}_firstName`}
          label={getLabel(`child_${idx}_firstName`, t)}
        />
        <TextField
          name={`child_${idx}_lastName`}
          label={getLabel(`child_${idx}_lastName`, t)}
        />
      </Stack>

      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <TextField
          name={`child_${idx}_previousLastName`}
          label={getLabel(`child_${idx}_previousLastName`, t)}
        />
        <Select
          name={`child_${idx}_gender`}
          label={getLabel(`child_${idx}_gender`, t)}
        >
          <MenuItem value={Gender.Male}>{t('gender.male')}</MenuItem>
          <MenuItem value={Gender.Female}>{t('gender.female')}</MenuItem>
          <MenuItem value={Gender.Indeterminate}>
            {t('gender.indeterminate')}
          </MenuItem>
          <MenuItem value={Gender.Other}>{t('gender.other')}</MenuItem>
        </Select>
      </Stack>

      <Checkboxes
        name={`child_${idx}_addressIsDifferentFromMain`}
        data={{
          label: getLabel(`child_${idx}_addressIsDifferentFromParent`, t),
          value: false,
        }}
      />

      <ConditionalFields
        fieldNames={[
          `child_${idx}_address.country`,
          `child_${idx}_address.place`,
        ]}
      >
        <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
          <CountrySelect
            name={`child_${idx}_address.country`}
            label={getLabel(`child_${idx}_address.country`, t)}
          />
          <TextField
            label={getLabel(`child_${idx}_address.place`, t)}
            name={`child_${idx}_address.place`}
          />
        </Stack>
      </ConditionalFields>
      <TextField
        name={`child_${idx}_pensionInsuranceNumber`}
        label={getLabel(`child_${idx}_pensionInsuranceNumber`, t)}
      />
    </Stack>
  );
};

ChildGeneralStep.getValidationSchema = getValidationSchema;
ChildGeneralStep.getActiveFields = getActiveFields;
