import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Switch(theme: Theme): Record<string, any> {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.grey[isLight ? 400 : 600],
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            backgroundColor: `${theme.palette.action.disabledBackground} !important`,
            opacity: 1,
          },
          '&:not(:.Mui-checked)': {
            color: theme.palette.grey[isLight ? 100 : 300],
          },
          left: 0,
          right: 'auto',
        },
        thumb: {
          boxShadow: theme.customShadows.z1,
        },
        track: {
          backgroundColor: theme.palette.grey[500],
          opacity: 1,
        },
      },
    },
  };
}
