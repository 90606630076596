import React, { FC, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentAdvertiser, useToggle } from '@common/hooks';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { TextField } from '@common/forms/fields';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { GQL } from '@queries';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useSnackbar } from 'notistack';
import { NOT_BARMER_STRING, ONLY_DIGITS_AND_LETTERS } from '@shared/regex';

export const CreateRefLinkButton: FC = () => {
  const { t } = useTranslation();
  const advertiser = useCurrentAdvertiser();
  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpened, { toggleOn: openDialog, toggleOff: closeDialog }] =
    useToggle();
  const [createAdvertiserRefLink] = useMutation(GQL.ADVERTISER_REF_LINK.CREATE);

  const onSubmit = async ({ linkValue }) => {
    try {
      await createAdvertiserRefLink({
        awaitRefetchQueries: true,
        refetchQueries: [GQL.ADVERTISER_REF_LINK.ADVERTISER_REF_LINKS_PAGED],
        variables: {
          linkValue,
        },
      });

      enqueueSnackbar(t('createRefLinkDialog.created'), { variant: 'success' });
      closeDialog();
    } catch (error: any) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          linkValue: Yup.string()
            .required(t('validation.required'))
            .matches(
              ONLY_DIGITS_AND_LETTERS,
              t('validation.onlyDigitsAndLetters'),
            )
            .matches(NOT_BARMER_STRING, t('validation.forbiddenWords')),
        }) as any,
      ),
    [t],
  );

  return (
    <>
      <Button
        variant="contained"
        onClick={openDialog}
        startIcon={<Icon icon={plusFill} />}
      >
        {t('createRefLinkButton')}
      </Button>
      <Dialog fullWidth maxWidth="sm" open={isDialogOpened}>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ submitting, handleSubmit }) => (
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogTitle sx={{ mb: 2 }}>
                {t('createRefLinkDialog.title')}
              </DialogTitle>
              <DialogContent>
                <Typography>{t('createRefLinkDialog.prompt')}</Typography>
                <Stack direction="row" gap={1} p={1}>
                  <Typography pt={1.1} variant="body1" color="GrayText">
                    {window.origin}/{advertiser?.publicId}/
                  </Typography>
                  <TextField
                    name="linkValue"
                    size="small"
                    label={t('refLinkPostfix')}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} disabled={submitting}>
                  {t('cancel')}
                </Button>
                <LoadingButton
                  loading={submitting}
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleSubmit}
                >
                  {t('create')}
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    </>
  );
};
