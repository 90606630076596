import { gql } from '@apollo/client';
import {
  InsuranceCaseDocumentReviewFrag,
  InsuranceCaseFieldReviewFrag,
} from './insuranceCaseReview.queries';

// todo move requiredSignaturesInfo from main fragment
export const InsuranceCaseFrag = gql`
  fragment InsuranceCaseFragment on InsuranceCase {
    id
    documentContainerId
    status
    internalCRMNumber
    formContainerId
    refLink
    poaStatus
    assignedTo {
      id
      name
    }
    advertiser {
      publicId
    }
    profile {
      email
      firstName
      lastName
    }
    spouseProfile {
      firstName
      lastName
    }
    childProfiles {
      firstName
      lastName
      index
    }
    documents {
      fileName
      documentName
      id
      signedSlots
    }
    requiredSignaturesInfo {
      documentName
      slotsInfo {
        firstName
        lastName
        slotNames
      }
    }
  }
`;

export const InsuranceCaseLightFrag = gql`
  fragment InsuranceCaseLightFragment on InsuranceCase {
    id
    status
    submittedAt
    paymentReceivedAt
    poaStatus
    assignedTo {
      id
      name
    }
    advertiser {
      publicId
    }
    profile {
      email
      firstName
      lastName
    }
    spouseProfile {
      firstName
      lastName
    }
    childProfiles {
      firstName
      lastName
      index
    }
  }
`;

export const INSURANCE_CASE = {
  CHANGE_EMAIL: gql`
    mutation UpdateInsuranceCaseEmail(
      $insuranceCaseId: String!
      $email: String!
    ) {
      insuranceCaseMutations {
        changeEmail(insuranceCaseId: $insuranceCaseId, email: $email) {
          id
          profile {
            email
          }
          refLink
        }
      }
    }
  `,
  CHANGE_GP_NUMBER: gql`
    mutation UpdateInsuranceCaseGpNumber(
      $insuranceCaseId: String!
      $internalCRMNumber: String!
    ) {
      insuranceCaseMutations {
        changeGPNumber(
          insuranceCaseId: $insuranceCaseId
          internalCRMNumber: $internalCRMNumber
        ) {
          id
          internalCRMNumber
        }
      }
    }
  `,
  CHANGE_STATUS: gql`
    mutation UpdateInsuranceCaseStatus(
      $insuranceCaseId: String!
      $status: InsuranceCaseStatus!
    ) {
      insuranceCaseMutations {
        changeStatus(insuranceCaseId: $insuranceCaseId, status: $status) {
          id
          status
        }
      }
    }
  `,
  CREATE: gql`
    mutation CreateInsuranceCase(
      $advertiserId: String!
      $data: CreateInsuranceCaseInput!
    ) {
      insuranceCaseMutations {
        create(advertiserId: $advertiserId, data: $data) {
          ...InsuranceCaseFragment
        }
      }
    }
    ${InsuranceCaseFrag}
  `,
  CURRENT_USER_INSURANCE_CASE: gql`
    query GetCurrentUserInsuranceCase {
      currentUserInsuranceCase {
        ...InsuranceCaseFragment
      }
    }
    ${InsuranceCaseFrag}
  `,
  DELETE: gql`
    mutation DeleteInsuranceCase($insuranceCaseId: ID!) {
      insuranceCaseMutations {
        delete(insuranceCaseId: $insuranceCaseId)
      }
    }
  `,
  DOCUMENTS_GENERATING_STATUS: gql`
    query GetInsuranceCaseDocumentsGeneratingStatus($insuranceCaseId: String!) {
      insuranceCase(insuranceCaseId: $insuranceCaseId) {
        id
        isApplicationGenerating
        refLink
      }
    }
  `,
  INSURANCE_CASE: gql`
    query GetInsuranceCase($insuranceCaseId: String!) {
      insuranceCase(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseFragment
      }
    }
    ${InsuranceCaseFrag}
  `,
  INSURANCE_CASES_PAGED: gql`
    query GetInsuranceCasesPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
      $assignedToIds: [ID]
      $advertiserRefLinkIds: [ID!]
      $advertiserIds: [ID!]
    ) {
      insuranceCasesPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
        assignedToIds: $assignedToIds
        advertiserRefLinkIds: $advertiserRefLinkIds
        advertiserIds: $advertiserIds
      ) {
        pageInfo {
          count
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...InsuranceCaseLightFragment
          }
        }
      }
    }
    ${InsuranceCaseLightFrag}
  `,
  INSURANCE_CASE_AND_REVIEWS: gql`
    query GetInsuranceCaseAndReviews($insuranceCaseId: String!) {
      insuranceCase(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseFragment
      }
      insuranceCaseFieldReviews(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseFieldReviewFragment
      }
      insuranceCaseDocumentReviews(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseDocumentReviewFragment
      }
    }
    ${InsuranceCaseDocumentReviewFrag}
    ${InsuranceCaseFieldReviewFrag}
    ${InsuranceCaseFrag}
  `,
  PUBLISH_DRAFT: gql`
    mutation PublishInsuranceCaseDraft(
      $advertiserId: String!
      $insuranceCaseId: String!
    ) {
      insuranceCaseMutations {
        publishDraft(
          advertiserId: $advertiserId
          insuranceCaseId: $insuranceCaseId
        ) {
          id
          status
        }
      }
    }
  `,
  SEND_INVITE_EMAIL: gql`
    mutation SendInviteEmail($insuranceCaseId: String!) {
      insuranceCaseMutations {
        sendInviteEmail(insuranceCaseId: $insuranceCaseId)
      }
    }
  `,
  SET_ASSIGNED_SERVICE_POINT: gql`
    mutation SetInsuranceCaseAssignedServicePoint(
      $insuranceCaseId: ID!
      $officeMemberId: ID
    ) {
      insuranceCaseMutations {
        setAssignedServicePoint(
          insuranceCaseId: $insuranceCaseId
          officeMemberId: $officeMemberId
        ) {
          id
          assignedTo {
            id
            name
          }
        }
      }
    }
  `,
  START_APPLICATION: gql`
    mutation StartInsuranceCaseApplicationForm {
      insuranceCaseMutations {
        startApplicationForm
      }
    }
  `,
  SUBMIT_APPLICATION: gql`
    mutation SubmitInsuranceCaseApplication($insuranceCaseId: String!) {
      insuranceCaseMutations {
        submitApplication(insuranceCaseId: $insuranceCaseId) {
          id
        }
      }
    }
  `,
  SUBMIT_APPLICATION_REFILL: gql`
    mutation SubmitInsuranceCaseApplicationRefill($insuranceCaseId: String!) {
      insuranceCaseMutations {
        submitApplicationRefill(insuranceCaseId: $insuranceCaseId) {
          id
        }
      }
    }
  `,
  _GENERATE_BARMER_APPLICATION: gql`
    mutation _GenerateBarmerApplication($insuranceCaseId: String!) {
      insuranceCaseMutations {
        _generateBarmerApplication(insuranceCaseId: $insuranceCaseId)
      }
    }
  `,
};
