import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperTextProps,
} from '@mui/material';
import React from 'react';
import { Field, FieldProps } from 'react-final-form';
import {
  ErrorMessage,
  ShowErrorFunc,
  showErrorOnChange,
  useFieldForErrors,
} from './util';

export interface CheckboxesProps
  extends Partial<Omit<MuiCheckboxProps, 'onChange'>> {
  name: string;
  label: string | JSX.Element;
  required?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  formControlLabelProps?: Partial<FormControlLabelProps> & {
    label: string | number | React.ReactElement;
  };
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
}

export const Checkbox: React.FC<CheckboxesProps> = (props: CheckboxesProps) => {
  const {
    required,
    label,
    disabled,
    indeterminate,
    name,
    helperText,
    fieldProps,
    formControlProps,
    formControlLabelProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    ...restCheckboxProps
  } = props;
  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} {...formControlProps}>
      <FormControlLabel
        name={name}
        label={label}
        disabled={disabled}
        control={
          <Field
            type="checkbox"
            name={name}
            render={({
              input: {
                name: inputName,
                value,
                onChange,
                checked,
                ...restInput
              },
            }) => (
              <MuiCheckbox
                name={inputName}
                value={value}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
                inputProps={{ required, ...restInput }}
                indeterminate={indeterminate}
                {...restCheckboxProps}
              />
            )}
            {...fieldProps}
          />
        }
        {...formControlLabelProps}
      />
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
};
