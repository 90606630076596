import { gql } from '@apollo/client';

export const AdvertiserPaymentFrag = gql`
  fragment AdvertiserPaymentFragment on AdvertiserPayment {
    id
    createdAt
    updatedAt
    fixationDate
    finishedAt
    status
    amount
    invoiceDocument {
      id
    }
    vatAmount
    totalAmount
  }
`;

export const ADVERTISER_PAYMENT = {
  ADVERTISER_PAYMENT: gql`
    query advertiserPayment($advertiserPaymentId: ID!) {
      advertiserPayment(advertiserPaymentId: $advertiserPaymentId) {
        ...AdvertiserPaymentFragment
      }
    }
    ${AdvertiserPaymentFrag}
  `,
  ADVERTISER_PAYMENTS_PAGED: gql`
    query advertiserPaymentsPaged(
      $advertiserId: String
      $advertiserPaymentsPlanId: String
      $after: String
      $first: Int
      $from: String
      $to: String
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      advertiserPaymentsPaged(
        advertiserId: $advertiserId
        advertiserPaymentsPlanId: $advertiserPaymentsPlanId
        after: $after
        first: $first
        from: $from
        to: $to
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          count
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...AdvertiserPaymentFragment
          }
        }
      }
    }
    ${AdvertiserPaymentFrag}
  `,
  ADVERTISER_PAYMENTS_PAGED_ADMINISTRATOR: gql`
    query advertiserPaymentsPagedAdministrator(
      $advertiserId: String
      $advertiserPaymentsPlanId: String
      $after: String
      $first: Int
      $from: String
      $to: String
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      advertiserPaymentsPaged(
        advertiserId: $advertiserId
        advertiserPaymentsPlanId: $advertiserPaymentsPlanId
        from: $from
        to: $to
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          count
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...AdvertiserPaymentFragment
            advertiser {
              id
              name
            }
          }
        }
      }
    }
    ${AdvertiserPaymentFrag}
  `,
  CANCEL: gql`
    mutation cancelAdvertiserPayment($advertiserPaymentId: String!) {
      advertiserPaymentMutations {
        cancel(advertiserPaymentId: $advertiserPaymentId) {
          id
          canceledAt
          status
        }
      }
    }
  `,
  CONFIRM_PAID: gql`
    mutation confirmPaidAdvertiserPayment($advertiserPaymentId: String!) {
      advertiserPaymentMutations {
        confirmPaid(advertiserPaymentId: $advertiserPaymentId) {
          id
          finishedAt
          status
        }
      }
    }
  `,
};
