import { useMemo, FC } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { useLocales } from '@hooks/useLocales';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const ThemeConfig: FC = ({ children }) => {
  const { currentLangObj } = useLocales();
  const isLight = true;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      palette: isLight
        ? { ...palette.light, mode: 'light' }
        : { ...palette.dark, mode: 'dark' },
      shadows: isLight ? shadows.light : shadows.dark,
      shape,
      typography,
    }),
    [isLight],
  );

  const theme = createTheme(themeOptions, currentLangObj.systemValue);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
