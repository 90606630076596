import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Progress(theme: Theme): Record<string, any> {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          borderRadius: 4,
        },
        buffer: {
          backgroundColor: 'transparent',
        },
        colorPrimary: {
          backgroundColor:
            theme.palette.primary[isLight ? 'lighter' : 'darker'],
        },
        root: {
          borderRadius: 4,
          overflow: 'hidden',
        },
      },
    },
  };
}
