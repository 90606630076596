import React, { FC, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@common/hooks';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@common/forms/fields';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { GQL } from '@queries';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useSnackbar } from 'notistack';
import {
  UpdateInsuranceCaseGpNumber,
  UpdateInsuranceCaseGpNumberVariables,
} from '@queries/types/UpdateInsuranceCaseGpNumber';

interface Props {
  insuranceCaseId: string;
}

export const ChangeGPNumberButton: FC<Props> = ({ insuranceCaseId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpened, { toggleOn: openDialog, toggleOff: closeDialog }] =
    useToggle();
  const [changeGPNumber] = useMutation<
    UpdateInsuranceCaseGpNumber,
    UpdateInsuranceCaseGpNumberVariables
  >(GQL.INSURANCE_CASE.CHANGE_GP_NUMBER);

  const onSubmit = async ({ internalCRMNumber }) => {
    try {
      await changeGPNumber({
        variables: {
          insuranceCaseId,
          internalCRMNumber,
        },
      });
      enqueueSnackbar(t('gpNumberChanged'), { variant: 'success' });
      closeDialog();
    } catch (error: any) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          internalCRMNumber: Yup.string()
            .required(t('validation.required'))
            .test(
              'len',
              t('validation.shouldBeExactly', { characters: 9 }),
              val => (val ? val.length === 9 : true),
            ),
        }) as any,
      ),
    [t],
  );

  return (
    <>
      <Button variant="contained" onClick={openDialog} startIcon={<EditIcon />}>
        {t('changeGPNumber')}
      </Button>
      <Dialog fullWidth maxWidth="xs" open={isDialogOpened}>
        <DialogTitle sx={{ mb: 2 }}>
          {t('changeGPNumberDialog.title')}
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={onSubmit} validate={validate}>
            {({ submitting, handleSubmit }) => (
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack p={1}>
                  <TextField
                    label={t('internalCRMNumber')}
                    name="internalCRMNumber"
                  />
                </Stack>
                <Stack sx={{ mt: 2 }} direction="row" gap={2}>
                  <Button onClick={closeDialog} disabled={submitting}>
                    {t('cancel')}
                  </Button>
                  <LoadingButton
                    loading={submitting}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {t('change')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
