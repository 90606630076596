import {
  FC,
  forwardRef,
  memo,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
} from '@mui/material';
import { useCollapseDrawer } from '@hooks/useCollapseDrawer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { arrayGroupBy } from '@shared/helpers';
import { StepWithMeta } from '@components/forms/types';
import { useParams, useNavigate } from 'react-router';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { scrollBarStyled } from '@src/theme/globalStyles';
import { useTranslation } from 'react-i18next';
import { MHidden } from '../@material-extend';
import { Logo } from '../index';

export const DRAWER_WIDTH = 320;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const StepNumberStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: '55%',
  display: 'inline-flex',
  height: 22,
  justifyContent: 'center',
  marginRight: theme.spacing(2),
  minWidth: 22,
}));

const ListContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 55px)',
  overflowY: 'auto',
  ...scrollBarStyled(theme, { width: theme.spacing(0.5) }),
}));

const ListSubheaderStyle = styled(({ ...props }) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(
  ({ theme }) =>
    ({
      ...theme.typography.overline,
      alignItems: 'center',
      color: theme.palette.text.primary,
      display: 'flex',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
    } as any), // TODO: fix type
);

const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.primary.main,
  display: 'flex',
  height: 22,
  justifyContent: 'center',
  width: 22,
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(
  ({ theme }) =>
    ({
      ...theme.typography.body2,
      '&:before': {
        backgroundColor: theme.palette.primary.main,
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        right: 0,
        top: 0,
        width: 3,
      },
      color: theme.palette.text.secondary,
      height: 48,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(2.5),
      position: 'relative',
      textTransform: 'capitalize',
    } as any), // TODO: fix type
);

interface Props {
  ref?: any;
  header?: React.ReactNode;
  stepsWithMeta: StepWithMeta[];
}

export const FormStepsSidebar: FC<Props> = memo(
  forwardRef(({ header, stepsWithMeta }, ref) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { ['*']: currentStepName } = useParams();
    const stepsByGroup = useMemo(
      () => arrayGroupBy<StepWithMeta>(stepsWithMeta as any, 'group'),
      [stepsWithMeta],
    );
    const currentGroup = useMemo(
      () => stepsWithMeta.find(({ isActive }) => isActive)?.group,
      [stepsWithMeta, t],
    );
    const [currentSelectedGroup, setCurrentSelectedGroup] = useState<string>(
      Object.keys(stepsByGroup)?.[0] || '',
    );
    const iOS =
      typeof navigator !== 'undefined' &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);

    useEffect(() => {
      if (currentSelectedGroup === currentGroup || !currentGroup) {
        return;
      }
      setCurrentSelectedGroup(currentGroup);
    }, [currentStepName, t]);

    const { isOpen, openSidebar, closeSidebar } = useCollapseDrawer();

    useImperativeHandle(
      ref,
      () => ({
        openSidebar: (groupName?: string) => {
          openSidebar();
          console.log({ groupName });
          if (groupName) {
            setCurrentSelectedGroup(groupName);
          }
        },
      }),
      [],
    );

    const renderContent = (
      <Box>
        <Stack
          spacing={3}
          sx={{
            pb: 2,
            pt: 3,
            px: 2.5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ display: 'inline-flex', pl: '22px' }}>
              <Logo />
            </Box>
          </Stack>
        </Stack>
        {header}
        {/*todo: move to separate component and clean*/}
        <ListContainer>
          {Object.entries(stepsByGroup).map(([group, steps], index) => (
            <List key={group} disablePadding>
              <Box
                onClick={() => {
                  setCurrentSelectedGroup(group);
                  const groupSteps = stepsByGroup[group];
                  const defaultNavigationStep = groupSteps.find(
                    ({ isDisabled, isPassed }) => !isDisabled && !isPassed,
                  );
                  if (defaultNavigationStep) {
                    navigate(defaultNavigationStep.stepName);
                  }
                }}
              >
                <ListSubheaderStyle
                  sx={{
                    cursor: 'pointer',
                    margin: theme =>
                      `${theme.spacing(2)} ${theme.spacing(3)} 0`,
                    padding: theme => `${theme.spacing(1)} ${theme.spacing(2)}`,
                    ...(group === currentSelectedGroup
                      ? {
                          border: `1px solid transparent`,
                          borderRadius: '5px',
                        }
                      : {
                          color: 'text.disabled',
                        }),
                  }}
                >
                  <StepNumberStyle>{index + 1}</StepNumberStyle>
                  {group}
                </ListSubheaderStyle>
              </Box>
              <Collapse in={currentSelectedGroup === group} unmountOnExit>
                {steps.map(
                  (
                    {
                      isActive,
                      isRejected,
                      isPassed,
                      isDisabled,
                      stepName,
                      stepNameSidebar,
                    },
                    i,
                    stepsArr,
                  ) => (
                    <ListItemStyle
                      key={stepName}
                      component={RouterLink}
                      disabled={isDisabled}
                      to={stepName}
                    >
                      <ListItemIconStyle>
                        {isActive && (
                          <ArrowForwardIcon
                            color={isRejected ? 'error' : 'primary'}
                          />
                        )}
                        {isRejected &&
                          !isActive &&
                          !isDisabled &&
                          // next step isn't disabled
                          !stepsArr[i + 1 === steps.length ? i : i + 1]
                            .isDisabled && <InfoOutlinedIcon color="error" />}
                        {isPassed && !isRejected && !isActive && <CheckIcon />}
                      </ListItemIconStyle>
                      <ListItemText
                        disableTypography
                        sx={{
                          color: isDisabled ? 'text.disabled' : 'text.primary',
                        }}
                        primary={stepNameSidebar || 'ERROR_PUT_NAME'}
                      />
                    </ListItemStyle>
                  ),
                )}
              </Collapse>
            </List>
          ))}
        </ListContainer>
      </Box>
    );

    return (
      <RootStyle
        sx={{
          width: {
            lg: DRAWER_WIDTH,
          },
        }}
      >
        <MHidden width="lgDown">
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                bgcolor: 'background.default',
                overflow: 'hidden',
                width: DRAWER_WIDTH,
              },
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
        <MHidden width="lgUp">
          <SwipeableDrawer
            open={isOpen}
            onOpen={openSidebar}
            onClose={closeSidebar}
            variant="temporary"
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            PaperProps={{
              sx: {
                overflow: 'hidden',
              },
            }}
          >
            {renderContent}
          </SwipeableDrawer>
        </MHidden>
      </RootStyle>
    );
  }),
);
