import * as Yup from 'yup';
import { MenuItem, Stack, Typography } from '@mui/material';
import { Checkboxes, Select } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import { StepComponent } from '@components/forms/types';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { StepTitle } from '@components/forms/StepTitle';
import { ConditionalFields } from '@components/forms';
import { Gender } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { TextField } from '@common/forms/fields';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    spouse_0_address: Yup.object({
      country: Yup.string()
        .nullable()
        .when('spouse_0_addressIsDifferentFromMain', {
          is: true,
          then: Yup.string().nullable().required(t('validation.required')),
        }),
      place: Yup.string()
        .nullable()
        .when('spouse_0_addressIsDifferentFromMain', {
          is: true,
          then: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
        }),
    })
      .nullable()
      .default({}),
    spouse_0_addressIsDifferentFromMain: Yup.string().nullable(),
    spouse_0_firstName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable()
      .required(t('validation.required')),
    spouse_0_gender: Yup.string().nullable().required(t('validation.required')),
    spouse_0_lastName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable()
      .required(t('validation.required')),
    spouse_0_pensionInsuranceNumber: Yup.string()
      .test('len', t('validation.shouldBeExactly', { characters: 12 }), val =>
        val ? val.length === 12 : true,
      )
      .nullable(),
    spouse_0_previousLastName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
  });

const getActiveFields = values => {
  const isAddressDifferentFromMain =
    !!values[`spouse_0_addressIsDifferentFromMain`];

  return {
    'spouse_0_address.country': isAddressDifferentFromMain,
    'spouse_0_address.place': isAddressDifferentFromMain,
  };
};

export const SpouseGeneralInformationStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.spousePersonalInformation')}
        </Typography>
      </StepTitle>
      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <TextField
          name="spouse_0_firstName"
          label={getLabel('spouse_0_firstName', t)}
        />
        <TextField
          name="spouse_0_lastName"
          label={getLabel('spouse_0_lastName', t)}
        />
      </Stack>

      <TextField
        name="spouse_0_previousLastName"
        label={getLabel('spouse_0_previousLastName', t)}
      />

      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <Select name="spouse_0_gender" label={getLabel('spouse_0_gender', t)}>
          <MenuItem value={Gender.Male}>{t('gender.male')}</MenuItem>
          <MenuItem value={Gender.Female}>{t('gender.female')}</MenuItem>
          <MenuItem value={Gender.Indeterminate}>
            {t('gender.indeterminate')}
          </MenuItem>
          <MenuItem value={Gender.Other}>{t('gender.other')}</MenuItem>
        </Select>
      </Stack>

      <Checkboxes
        name="spouse_0_addressIsDifferentFromMain"
        data={{
          label: getLabel('spouse_0_addressIsDifferentFromMain', t),
          value: false,
        }}
      />

      <ConditionalFields
        fieldNames={[`spouse_0_address.country`, `spouse_0_address.place`]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <CountrySelect
            name="spouse_0_address.country"
            label={getLabel('spouse_0_address.country', t)}
          />
          <TextField
            name="spouse_0_address.place"
            label={getLabel('spouse_0_address.place', t)}
          />
        </Stack>
      </ConditionalFields>
      <TextField
        name="spouse_0_pensionInsuranceNumber"
        label={getLabel('spouse_0_pensionInsuranceNumber', t)}
      />
    </Stack>
  );
};

SpouseGeneralInformationStep.getValidationSchema = getValidationSchema;
SpouseGeneralInformationStep.getActiveFields = getActiveFields;
