import { FC, ReactNode } from 'react';
import { isString } from 'lodash';
import { Box, Typography, Link } from '@mui/material';
import { MBreadcrumbs } from './@material-extend';
import { MBreadcrumbsProps } from './@material-extend/MBreadcrumbs';

interface Props extends MBreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  moreLink?: string | string[];
}

export const HeaderBreadcrumbs: FC<Props> = ({
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}) => (
  <Box sx={sx}>
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h4">{heading}</Typography>
        {links.length ? (
          <MBreadcrumbs links={links} {...other} sx={{ mt: 1 }} />
        ) : null}
      </Box>
      {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
    </Box>

    <Box sx={{ mt: 2 }}>
      {isString(moreLink) ? (
        <Link href={moreLink} target="_blank" variant="body2">
          {moreLink}
        </Link>
      ) : (
        moreLink.map(href => (
          <Link
            noWrap
            key={href}
            href={href}
            variant="body2"
            target="_blank"
            sx={{ display: 'table' }}
          >
            {href}
          </Link>
        ))
      )}
    </Box>
  </Box>
);
