import createCalculateDecorator from 'final-form-calculate';
import { InsurancePurpose } from '@shared/constants';

export const calculateFields = createCalculateDecorator({
  field: 'main_0_insurancePurposes',
  updates: (value, field, allValues) => {
    const isEmploymentDetailsRequired = value?.some(item =>
      [
        InsurancePurpose.Employment,
        InsurancePurpose.Partner,
        InsurancePurpose.Trainee,
      ].includes(item),
    );

    if (isEmploymentDetailsRequired) {
      return allValues;
    }

    return {
      main_0_employerAddress: null,
      main_0_employerName: null,
      main_0_hasFamilyRelationshipToEmployer: null,
      main_0_jobStartDate: null,
      main_0_salaryRange: null,
    };
  },
} as any);
