import { FC, useCallback, useMemo, useState } from 'react';
import { Button, styled, Stack, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeValidate, TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { Maybe } from '@shared/types';
import * as Yup from 'yup';
import { InsuranceCaseDocumentReviewFragment } from '@common/queries/types/InsuranceCaseDocumentReviewFragment';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { InsuranceCaseDocumentReviewStatus } from '@shared/constants';
import { useDocumentReviewActions } from './useDocumentReviewActions';

const StyledLoadingButton = styled(LoadingButton)(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    height: 34,
    textTransform: 'none',
  }),
  () => ({
    color: 'inherit',
    variant: 'text',
  }),
);

interface Props {
  documentName: string;
  documentReview: Maybe<InsuranceCaseDocumentReviewFragment>;
  insuranceCaseId: string;
}

export const DocumentItemReviewActions: FC<Props> = ({
  insuranceCaseId,
  documentName,
  documentReview,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<Maybe<HTMLElement>>(null);

  const openUpsertPopup = useCallback(event => setAnchorEl(event.target), []);
  const closeUpsertPopup = useCallback(() => setAnchorEl(null), []);

  const {
    upsertDocumentReview,
    deleteDocumentReview,
    isUpsertDocumentReviewLoading,
  } = useDocumentReviewActions();

  const onRequestSubmit = async ({ rejectionReason }) => {
    try {
      closeUpsertPopup();
      await upsertDocumentReview({
        documentName,
        insuranceCaseId,
        rejectionReason,
      });
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const onDiscardClick = async () => {
    try {
      if (!documentReview) {
        throw new Error('document review does not exist');
      }

      await deleteDocumentReview({
        documentReviewId: documentReview.id,
        insuranceCaseId,
      });
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          rejectionReason: Yup.string().required(t('validation.required')),
        }) as any,
      ),
    [t],
  );

  return (
    <Stack direction="row" spacing={1}>
      {documentReview?.status ===
        InsuranceCaseDocumentReviewStatus.Rejected && (
        <>
          <StyledLoadingButton
            loading={isUpsertDocumentReviewLoading}
            disabled={isUpsertDocumentReviewLoading}
            onClick={openUpsertPopup}
          >
            {t('edit')}
          </StyledLoadingButton>
          <StyledLoadingButton onClick={onDiscardClick}>
            {t('discard')}
          </StyledLoadingButton>
        </>
      )}
      {(!documentReview ||
        documentReview.status ===
          InsuranceCaseDocumentReviewStatus.Changed) && (
        <StyledLoadingButton
          loading={isUpsertDocumentReviewLoading}
          disabled={isUpsertDocumentReviewLoading}
          onClick={openUpsertPopup}
        >
          {t('requestToResubmit')}
        </StyledLoadingButton>
      )}
      <Popover
        PaperProps={{
          sx: { minWidth: 400 },
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Form
          initialValues={{
            rejectionReason: documentReview?.rejectionReason ?? '',
          }}
          validate={validate}
          onSubmit={onRequestSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stack direction="column" spacing={2} sx={{ p: 2 }}>
                <Typography variant="h6">
                  {t('requestApplicantToResubmitThisDoc')}
                </Typography>
                <TextField
                  autoFocus
                  multiline
                  label={t('rejectionReason')}
                  minRows={3}
                  maxRows={6}
                  name="rejectionReason"
                />
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button color="inherit" onClick={() => setAnchorEl(null)}>
                    {t('cancel')}
                  </Button>
                  <LoadingButton
                    loading={isUpsertDocumentReviewLoading}
                    disabled={isUpsertDocumentReviewLoading}
                    type="submit"
                    variant="contained"
                  >
                    {t('request')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </form>
          )}
        </Form>
      </Popover>
    </Stack>
  );
};
