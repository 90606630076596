import { REVOLUT_TRANSACTIONS } from '@common/queries/revolutTransactions.queries';
import { BRANCH_OFFICE } from './branchOffice.queries';
import { INSURANCE_CASE } from './insuranceCase.queries';
import { DOCUMENT } from './document.queries';
import { FORM_STATE } from './formState.queries';
import { ADVERTISER } from './advertiser.queries';
import { INSURANCE_CASE_REVIEW } from './insuranceCaseReview.queries';
import { OFFICE_MEMBER } from './officeMember.queries';
import { HELP_REQUEST } from './helpRequest.queries';
import { ADVERTISER_PAYMENTS_PLAN } from './advertiserPaymentsPlan.queries';
import { ADVERTISER_PAYMENT } from './advertiserPayment.queries';
import { INVITE_CODE } from './inviteCode.queries';
import { ADVERTISER_REF_LINK } from './advertiserRefLink.queries';

export const GQL = {
  ADVERTISER,
  ADVERTISER_PAYMENT,
  ADVERTISER_PAYMENTS_PLAN,
  ADVERTISER_REF_LINK,
  BRANCH_OFFICE,
  DOCUMENT,
  FORM_STATE,
  HELP_REQUEST,
  INSURANCE_CASE,
  INSURANCE_CASE_REVIEW,
  INVITE_CODE,
  OFFICE_MEMBER,
  REVOLUT_TRANSACTIONS,
};
