import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import {
  FC,
  memo,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { makeValidate } from 'mui-rff';
import { Navigate, useNavigate, useParams } from 'react-router';
import { InitialValidation } from '@components/forms/InitialValidation';
import { FormValuesSetter } from '@components/forms/FormValuesSetter';
import {
  GetStepsArguments,
  PersistenceVariables,
  RejectedDocuments,
  RejectedFields,
  Step,
  StepWithMeta,
  FormWrapperProps,
} from '@components/forms/types';
import { ActiveFieldsProvider } from '@components/forms/ActiveFieldsProvider';
import { ObjectSchema } from 'yup';
import { Decorator } from 'final-form';
import { AutoSave } from './AutoSave';
import { withRejectedFieldsValidation } from './withRejectedFieldsValidation';

const MemoizedStep = memo(({ children, ...rest }: any) => children(rest));

const DefaultWrapper: FC<FormWrapperProps> = ({ children }) => <>{children}</>;

export function stitchFieldNamesFromSchema(
  obj: Record<string, any>,
  key = '',
): string[] {
  const curFieldNames = [key];
  return obj.fields
    ? Object.entries(obj.fields || {})
        .map(([fn, value]) =>
          stitchFieldNamesFromSchema(
            value as Record<string, any>,
            key ? `${key}.${fn}` : fn,
          ),
        )
        .flat()
    : curFieldNames;
}

interface Props {
  /**
   * Comes from form service (form state)
   */
  initialValues?: Record<string, any>;
  /**
   * Comes from form service (form state)
   */
  initialStepName?: string | null;
  /**
   * children as a function
   * @param {Step} currentStep
   * @param {StepWithMeta[]} stepsWithMeta
   * @return {React.ReactNode}
   */
  children: ({
    currentStep,
    stepsWithMeta,
    goNext,
    goBack,
  }: {
    currentStep: Step;
    goBack: VoidFunction;
    goNext: VoidFunction;
    stepsWithMeta: StepWithMeta[];
  }) => ReactNode;
  /**
   * on submit callback (usefull to make request/sides which dependant
   * on current page
   * @param {Record<string, any>} values
   */
  onSubmit: (values: Record<string, any>) => void;
  /**
   * Disables validation sidebar blocks and force redirects
   */
  isInitialValidationEnabled?: boolean;
  /**
   * Disables validation, default is "true"
   */
  isValidationEnabled?: boolean;
  /**
   * func which returns array of steps used in form
   * @param {TFunction} t
   * @param {Record<string, any>} formValues
   * @return {Step[]}
   */
  getSteps: ({ t, formValues }: GetStepsArguments) => Step[];
  /**
   * query data for save current state
   */
  persistenceData?: PersistenceVariables;
  /**
   * Map with arr-like keys - represents rejected fields state by field groups
   */
  rejectedFields?: Map<string[], RejectedFields>;
  /**
   * values which have been rejected by reviewer (usefull to determine if applicant has changed it since)
   */
  rejectedValues?: Record<string, any>;
  /**
   * contains metadata which will be passed form validation and form steps
   */
  meta?: Record<string, any>;

  decorators?: Decorator[];
  /**
   * Custom form wrapper
   */
  FormWrapperComponent?: FC<FormWrapperProps>;
}

export const FormStepper: FC<Props> = ({
  initialStepName,
  initialValues = {},
  isValidationEnabled = true,
  isInitialValidationEnabled = true,
  children,
  onSubmit,
  getSteps,
  persistenceData,
  rejectedFields = new Map(),
  rejectedValues = {},
  meta = {},
  decorators = [],
  FormWrapperComponent = DefaultWrapper,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ['*']: currentStepName } = useParams();
  const [formValues, setFormValues] = useState(initialValues);
  const steps = useMemo(() => getSteps({ formValues, t }), [formValues, t]);
  const initialStepIndex = steps.findIndex(
    ({ stepName }) => initialStepName === stepName,
  );
  const [lastEnabledStepIndex, setLastEnabledStepIndex] = useState(
    ~initialStepIndex ? initialStepIndex : 0,
  );
  const [passedStepNames, setPassedStepNames] = useState(
    steps
      .slice(
        0,
        meta?.isRefilling
          ? steps.length - 1
          : ~initialStepIndex
          ? initialStepIndex
          : 0,
      )
      .map(({ stepName }) => stepName),
  );
  const lastEnabledStepName =
    steps[
      lastEnabledStepIndex >= steps.length
        ? steps.length - 1
        : lastEnabledStepIndex
    ].stepName;
  const rejectedFieldGroups = [...rejectedFields.keys()] as any;
  const currentStep = useMemo(
    () => steps.find(({ stepName }) => stepName === currentStepName),
    [currentStepName],
  );
  const stepsWithMeta = useMemo(
    () =>
      steps.map((step, index) => {
        const stepSchema = withRejectedFieldsValidation(
          step.component.getValidationSchema?.(t, { ...step.meta, ...meta }),
        )({
          rejectedFieldGroups,
          rejectedValues,
          t,
        }) as ObjectSchema<Record<string, any>>;
        const stepFieldNames = stitchFieldNamesFromSchema(stepSchema || {});
        // crutch-couple: Documents step rejected IF
        // 1. step fieldnames (documentNames) includes rejected document name and documents haven't changed OR
        // 2. requested documents are not loaded
        const isDocumentsStepRejected = Object.entries(
          (meta.rejectedDocuments || {}) as Record<string, RejectedDocuments>,
        ).filter(
          ([documentName, { isRejected }]) =>
            stepFieldNames.includes(documentName) && isRejected,
        ).length;
        const isCurrentStepSchemaValid = stepSchema
          ? stepSchema.isValidSync(formValues)
          : true;

        return {
          ...step,
          isActive: currentStepName === step.stepName,
          isDisabled: meta?.isRefilling ? false : lastEnabledStepIndex < index,
          isPassed: passedStepNames.includes(step.stepName),
          isRejected: !isCurrentStepSchemaValid || !!isDocumentsStepRejected,
          stepFieldNames,
        };
      }),
    [
      steps,
      lastEnabledStepIndex,
      currentStepName,
      rejectedFields,
      meta,
      passedStepNames,
    ],
  );
  const prevStepsWithMeta = useRef<any[]>([]);

  useEffect(() => {
    if (
      currentStepName &&
      stepsWithMeta.find(
        ({ stepName, isRejected, isDisabled }) =>
          stepName === currentStepName && !isRejected && !isDisabled,
      )
    )
      setPassedStepNames(prevState => [
        ...new Set([...prevState, currentStepName as string]),
      ]);
  }, [currentStepName]);
  // handles dynamic steps (removed steps)
  useEffect(() => {
    const removedEnabledSteps = prevStepsWithMeta.current?.filter(
      ({ stepName, isDisabled }) =>
        !isDisabled &&
        !stepsWithMeta.some(({ stepName: sn }) => sn === stepName),
    );
    const addedSteps = stepsWithMeta.filter(
      ({ stepName, isDisabled }) =>
        !isDisabled &&
        prevStepsWithMeta.current.length &&
        !prevStepsWithMeta.current.some(({ stepName: sn }) => sn === stepName),
    );

    // handles removed steps by removing it's from passed step names and moves index of last enabled step
    if (removedEnabledSteps.length) {
      setPassedStepNames(prevState =>
        prevState.filter(
          sn => !removedEnabledSteps.some(({ stepName }) => sn === stepName),
        ),
      );
      setLastEnabledStepIndex(
        prevIndex => prevIndex - removedEnabledSteps.length,
      );
    }

    // handles added steps by moving index of last enabled step forward
    if (addedSteps.length) {
      setLastEnabledStepIndex(prevIndex => prevIndex + addedSteps.length);
    }

    // undisables all disabled passed steps (validation check)
    if (
      !stepsWithMeta.filter(
        ({ isRejected, isDisabled }) => isRejected && !isDisabled,
      ).length
    ) {
      setLastEnabledStepIndex(
        stepsWithMeta.map(({ isPassed }) => isPassed).lastIndexOf(true) + 1,
      );
    }

    // disables all disabled passed steps (only if validation enabled)
    if (
      !rejectedFieldGroups.length &&
      !Object.keys(meta.rejectedDocuments || {}).length &&
      isValidationEnabled &&
      stepsWithMeta.filter(
        ({ isRejected, isDisabled }) => isRejected && !isDisabled,
      ).length
    ) {
      setLastEnabledStepIndex(
        stepsWithMeta.findIndex(
          ({ isRejected, isDisabled }) => isRejected && !isDisabled,
        ),
      );
    }

    prevStepsWithMeta.current = stepsWithMeta;
  }, [stepsWithMeta]);

  if (!currentStep) {
    return <Navigate to={lastEnabledStepName} replace />;
  }

  const currentStepIndex = steps.findIndex(
    ({ stepName }) => stepName === currentStep.stepName,
  );

  const currentStepSchema = withRejectedFieldsValidation(
    currentStep.component.getValidationSchema?.(t, {
      ...currentStep.meta,
      ...meta,
    }),
  )({ rejectedFieldGroups, rejectedValues, t });

  const validate = currentStepSchema
    ? makeValidate(currentStepSchema as any)
    : () => ({});
  const isLastStep = currentStepIndex === steps.length - 1;

  const goNext = () => {
    setPassedStepNames(prevState => [
      ...new Set([...prevState, steps[currentStepIndex].stepName]),
    ]);
    navigate(steps[currentStepIndex + 1].stepName);
    setLastEnabledStepIndex(prevState =>
      currentStepIndex === prevState ? currentStepIndex + 1 : prevState,
    );
  };

  const goBack = () => {
    navigate(steps[currentStepIndex - 1].stepName);
  };

  const onSubmitForm = values => {
    if (isLastStep) {
      // Return to first founded invalid step if some exist
      const rejectedStepIndex = stepsWithMeta.findIndex(
        ({ isRejected }) => isRejected,
      );
      if (~rejectedStepIndex && isValidationEnabled) {
        return navigate(steps[rejectedStepIndex].stepName);
      }
      return onSubmit(values);
    } else {
      goNext();
    }
  };

  return (
    <Form
      initialValues={initialValues}
      validate={isValidationEnabled ? validate : undefined}
      onSubmit={onSubmitForm}
      decorators={decorators}
    >
      {({ handleSubmit, submitting, form }) => (
        <>
          {persistenceData && (
            <AutoSave
              persistenceData={persistenceData}
              lastEnabledStepName={lastEnabledStepName}
              isOnPreviousEnabledStep={lastEnabledStepIndex > currentStepIndex}
            />
          )}
          {isValidationEnabled && (
            <InitialValidation
              isInitialValidationEnabled={
                isInitialValidationEnabled && !rejectedFieldGroups.length
              }
              currentStep={currentStep}
              steps={steps}
              setLastEnabledStepIndex={setLastEnabledStepIndex}
              formApi={form}
              formMeta={meta}
              rejectedFieldGroups={rejectedFieldGroups}
              rejectedValues={rejectedValues}
            />
          )}

          <FormValuesSetter setFormValues={setFormValues} />
          <FormWrapperComponent
            context={{
              currentStep: stepsWithMeta.find(
                ({ stepName }) => stepName === currentStepName,
              ),
              formValues,
              rejectedDocuments: meta?.rejectedDocuments,
              rejectedFields,
            }}
          >
            <form onSubmit={handleSubmit}>
              <ActiveFieldsProvider
                rejectedFields={rejectedFields}
                stepsWithMeta={stepsWithMeta}
                formMeta={meta}
              >
                <MemoizedStep
                  currentStep={currentStep}
                  stepsWithMeta={stepsWithMeta}
                  goNext={handleSubmit}
                  goBack={goBack}
                >
                  {children}
                </MemoizedStep>
              </ActiveFieldsProvider>
              {!currentStep?.hideControls && (
                <Box
                  sx={{
                    marginTop: 2,
                  }}
                >
                  {!!currentStepIndex && (
                    <Button
                      sx={{ marginRight: 2, width: 100 }}
                      variant="outlined"
                      onClick={goBack}
                    >
                      {t('back')}
                    </Button>
                  )}
                  <LoadingButton
                    sx={{ width: 100 }}
                    variant="contained"
                    type="submit"
                    loading={submitting}
                  >
                    {t(!isLastStep ? 'continue' : 'submit')}
                  </LoadingButton>
                </Box>
              )}
            </form>
          </FormWrapperComponent>
        </>
      )}
    </Form>
  );
};
