import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import { OrderDirection } from '@shared/constants';
import { Maybe } from '@shared/types';
import { FilterMenu, FilterOptions } from '@components/FilterMenu';
import { Column } from './EntityTable';
import { CustomFilterHeaderButton } from './CustomFilterHeaderButton';

type Props<TEntity> = {
  columns: Column<TEntity>[];
  customQueryVariables: Record<string, any>;
  filterOptions: FilterOptions;
  onRequestFilter: (filterOptions: FilterOptions) => void;
  onRequestSort: (property: string) => void;
  sortBy: Maybe<string>;
  sortDirection: OrderDirection;
  updateCustomQueryVariables: (queryVariables: Record<string, any>) => void;
};

const StyledCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[0],
}));

export const TableHeader = <TEntity,>({
  columns,
  customQueryVariables,
  filterOptions,
  onRequestFilter,
  onRequestSort,
  sortBy,
  sortDirection,
  updateCustomQueryVariables,
}: Props<TEntity>): ReactElement => {
  const lowercaseSortDir = sortDirection?.toLowerCase() as 'desc' | 'asc';
  return (
    <TableHead>
      <TableRow>
        {columns.map(
          ({
            id,
            filterValues,
            filterView,
            renderFilterView,
            alignHeader = 'left',
            isSortable = false,
            disablePadding = false,
            label,
            width = `${100 / columns.length}%`,
            ...rest
          }) => (
            <StyledCell
              key={id}
              align={alignHeader}
              padding={disablePadding ? 'none' : 'normal'}
              sortDirection={sortBy === id ? lowercaseSortDir : false}
              width={width}
            >
              <>
                {isSortable ? (
                  <>
                    <TableSortLabel
                      active={sortBy === id}
                      direction={sortBy === id ? lowercaseSortDir : 'asc'}
                      onClick={() => onRequestSort(id)}
                    >
                      {label}
                    </TableSortLabel>
                  </>
                ) : (
                  label
                )}
                {filterValues?.length && (
                  <FilterMenu
                    filterView={filterView}
                    filterBy={id}
                    filterValues={filterValues}
                    onCloseMenu={onRequestFilter}
                  />
                )}
                {renderFilterView && (
                  <CustomFilterHeaderButton>
                    {({ anchorEl, closeFilterView }) =>
                      renderFilterView({
                        anchorEl,
                        closeFilterView,
                        customQueryVariables,
                        updateCustomQueryVariables,
                      })
                    }
                  </CustomFilterHeaderButton>
                )}
              </>
            </StyledCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};
