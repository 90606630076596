export const edgeNodePolyfill = (): void => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    (Node as any).prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments as any);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    (Node as any).prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments as any);
    };
  }
};
