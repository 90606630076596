import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-GB';

export const pickerProviderWrapper = (
  component: JSX.Element,
  locale: Record<string, any>,
): JSX.Element => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={locale ?? enLocale}
    >
      {component}
    </LocalizationProvider>
  );
};
