export enum ErrorCode {
  DocumentTemplateTypeIsNotSupported = 'DocumentTemplateTypeIsNotSupported',
  AdvertiserNotApproved = 'AdvertiserNotApproved',
  BadData = 'BadData',
  CouldntChangeStatusAlreadyComplete = 'CouldntChangeStatusAlreadyComplete',
  CouldntChangeStatusWrongOne = 'CouldntChangeStatusWrongOne',
  DocumentMapperNotFound = 'DocumentMapperNotFound',
  InvalidDocumentInput = 'InvalidDocumentInput',
  DocumentNotFound = 'DocumentNotFound',
  SignatureSlotIsSigned = 'SignatureSlotIsSigned',
  RefLinkIsNotUnique = 'RefLinkIsNotUnique',
  ForbiddenWords = 'ForbiddenWords',
  EmailTaken = 'EmailTaken',
  InviteCodeRevoked = 'InviteCodeRevoked',
  BranchOfficeNotFound = 'BranchOfficeNotFound',
  WrongProvision = 'WrongProvision',
  MaxProvisionAmountExceeded = 'MaxProvisionAmountExceeded',
}
