export class BaseError extends Error {
  code: string;
  expose = true;
  statusCode: number;

  constructor(
    message: string,
    code: string,
    options: { statusCode?: number; expose?: boolean } = {},
  ) {
    super(message);

    this.code = code;
    this.statusCode = options.statusCode ?? 500;

    // for middyy http-error-handler mw
    this.expose = options.expose ?? true;
  }
}
