import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { Stack, Typography } from '@mui/material';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { DatePicker, TextField } from '@common/forms/fields';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { GLOBAL_MIN_DATE } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    spouse_0_birthCountry: Yup.string().nullable(),
    spouse_0_birthDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate'))
      .min(
        new Date(GLOBAL_MIN_DATE),
        t('validation.shouldNotBeBefore', {
          date: GLOBAL_MIN_DATE,
        }),
      )
      .max(new Date(), t('validation.shouldNotBeAfterToday'))
      .required(t('validation.required')),
    spouse_0_birthPlace: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    spouse_0_nationality: Yup.string()
      .nullable()
      .required(t('validation.required')),
    spouse_0_previousNationality: Yup.string().nullable(),
  });

export const SpouseBirthInformationStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.spouseBirthInformation')}
        </Typography>
      </StepTitle>
      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <CountrySelect
          name="spouse_0_birthCountry"
          label={getLabel('spouse_0_birthCountry', t)}
        />
        <TextField
          label={getLabel('spouse_0_birthPlace', t)}
          name="spouse_0_birthPlace"
        />
      </Stack>
      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <CountrySelect
          name="spouse_0_nationality"
          label={getLabel('spouse_0_nationality', t)}
        />
        <CountrySelect
          name="spouse_0_previousNationality"
          label={getLabel('spouse_0_previousNationality', t)}
        />
      </Stack>
      <DatePicker
        name="spouse_0_birthDate"
        label={getLabel('spouse_0_birthDate', t)}
      />
    </Stack>
  );
};

SpouseBirthInformationStep.getValidationSchema = getValidationSchema;
