import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Stack, Typography } from '@mui/material';
import { DatePicker } from '@common/forms/fields';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { useTranslation } from 'react-i18next';
import { StepComponent } from '@components/forms/types';
import { AnySchema } from 'yup';
import { ConditionalFields, StepTitle } from '@components/forms';
import { QuestionField } from '@common/forms/fields/QuestionField';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_isUnemploymentBenefits: Yup.boolean().nullable(),
    main_0_unemploymentBenefitStartDate: Yup.date()
      .nullable()
      .when('main_0_isUnemploymentBenefits', {
        is: true,
        then: Yup.string().nullable().required(t('validation.required')),
      }),
    main_0_unemploymentStartDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
  });

const getActiveFields = values => ({
  main_0_unemploymentBenefitStartDate: !!values.main_0_isUnemploymentBenefits,
});

export const UnemploymentInformationStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.unemploymentInformation')}
        </Typography>
      </StepTitle>

      <DatePicker
        name="main_0_unemploymentStartDate"
        label={getLabel('main_0_unemploymentStartDate', t)}
      />
      <QuestionField
        question={getLabel('main_0_isUnemploymentBenefits', t, { isDraft })}
        name="main_0_isUnemploymentBenefits"
      />
      <ConditionalFields fieldNames={['main_0_unemploymentBenefitStartDate']}>
        <DatePicker
          label={getLabel('main_0_unemploymentBenefitStartDate', t)}
          name="main_0_unemploymentBenefitStartDate"
        />
      </ConditionalFields>
    </Stack>
  );
};

UnemploymentInformationStep.getValidationSchema = getValidationSchema;
UnemploymentInformationStep.getActiveFields = getActiveFields;
