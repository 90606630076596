import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { MenuItem, Stack, Typography } from '@mui/material';
import { Select } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { DependantsType } from '@shared/constants';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_childrenCount: Yup.string()
      .nullable()
      .when('main_0_dependantsType', {
        is: value =>
          [DependantsType.ChildrenAndSpouse, DependantsType.Children].includes(
            value,
          ),
        then: Yup.string().nullable().required(t('validation.required')),
      }),
    main_0_dependantsType: Yup.string()
      .nullable()
      .when('main_0_hasWillingToCoverDependants', {
        is: true,
        then: Yup.string().nullable().required(t('validation.required')),
      }),
    main_0_hasWillingToCoverDependants: Yup.boolean().nullable(),
  });

const getActiveFields = values => ({
  main_0_childrenCount: [
    DependantsType.ChildrenAndSpouse,
    DependantsType.Children,
  ].includes(values.main_0_dependantsType),
  main_0_dependantsType: !!values.main_0_hasWillingToCoverDependants,
});

export const DependantsStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.dependants')}</Typography>
      </StepTitle>

      <QuestionField
        name="main_0_hasWillingToCoverDependants"
        question={getLabel('main_0_hasWillingToCoverDependants', t, {
          isDraft,
        })}
      />

      <ConditionalFields fieldNames={['main_0_dependantsType']}>
        <Select
          name="main_0_dependantsType"
          label={getLabel('main_0_dependantsType', t, { isDraft })}
        >
          <MenuItem value={DependantsType.Spouse}>
            {getLabel('mySpouse', t, { isDraft })}
          </MenuItem>
          <MenuItem value={DependantsType.Children}>
            {getLabel('myChildren', t, { isDraft })}
          </MenuItem>
          <MenuItem value={DependantsType.ChildrenAndSpouse}>
            {getLabel('myChildrenAndSpouse', t, { isDraft })}
          </MenuItem>
        </Select>

        <ConditionalFields fieldNames={['main_0_childrenCount']}>
          <Select
            name="main_0_childrenCount"
            label={getLabel('main_0_childrenCount', t)}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </ConditionalFields>
      </ConditionalFields>
    </Stack>
  );
};

DependantsStep.getValidationSchema = getValidationSchema;
DependantsStep.getActiveFields = getActiveFields;
