import { FC, memo, useEffect, useRef } from 'react';
import { useFormState } from 'react-final-form';
import { GQL } from '@queries';
import {
  UpsertFormState,
  UpsertFormStateVariables,
} from '@queries/types/UpsertFormState';
import { useParams } from 'react-router';
import { PersistenceVariables } from '@components/forms/types';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useDebounce } from '@common/hooks';
import { diff } from 'deep-object-diff';
import { GetFormState } from '@queries/types/GetFormState';

interface Props {
  persistenceData: PersistenceVariables;
  lastEnabledStepName: string;
  isOnPreviousEnabledStep: boolean;
}

const getDifferenceRecursively = (differencesObj, source) =>
  Object.keys(differencesObj).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        source[key] instanceof Object && !(source[key] instanceof Array)
          ? getDifferenceRecursively(differencesObj[key], source[key])
          : source[key],
    }),
    {},
  );

export const AutoSave: FC<Props> = memo(
  ({ persistenceData, lastEnabledStepName, isOnPreviousEnabledStep }) => {
    const { values, invalid } = useFormState({
      subscription: {
        invalid: true,
        values: true,
      },
    });

    const [valuesDebounced] = useDebounce(
      values,
      persistenceData.debounceTime || 1000,
    );
    const previousValues = useRef<any>(null);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { ['*']: currentStepName } = useParams();
    const [upsertFormState] = useMutation<
      UpsertFormState,
      UpsertFormStateVariables
    >(GQL.FORM_STATE.UPSERT);

    useEffect(() => {
      if (invalid) {
        return;
      }
      if (
        !previousValues.current ||
        !Object.keys(previousValues.current).length
      ) {
        previousValues.current = valuesDebounced;
        return;
      }
      const difference = diff(previousValues.current, valuesDebounced);
      if (!difference || !Object.keys(difference).length) {
        return;
      }
      const result = getDifferenceRecursively(difference, valuesDebounced);
      const nullifiedResult = Object.entries(result).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value === undefined ? null : value,
        }),
        {},
      );
      updateFormState(nullifiedResult);
      previousValues.current = valuesDebounced;
    }, [valuesDebounced, persistenceData, currentStepName]);

    const updateFormState = async (differencedValues: Record<string, any>) => {
      try {
        await upsertFormState({
          // update: cache => {
          //   const data = cache.readQuery<GetFormState>({
          //     query: GQL.FORM_STATE.FORM_STATE,
          //     variables: {
          //       formContainerId: persistenceData.formContainerId,
          //       formType: persistenceData.formType,
          //     },
          //   });
          //   cache.writeQuery({
          //     data: {
          //       formState: {
          //         ...data?.formState,
          //         state: {
          //           values: {
          //             ...data?.formState?.state?.values,
          //             ...differencedValues,
          //           },
          //         },
          //       },
          //     },
          //     query: GQL.FORM_STATE.FORM_STATE,
          //     variables: {
          //       formContainerId: persistenceData.formContainerId,
          //       formType: persistenceData.formType,
          //     },
          //   });
          // },
          variables: {
            currentStepName: isOnPreviousEnabledStep
              ? lastEnabledStepName
              : currentStepName,
            formContainerId: persistenceData.formContainerId,
            state: {
              values: differencedValues,
            },
            type: persistenceData.formType,
          },
        });
        persistenceData.onDataUpdated?.(differencedValues);
      } catch (error) {
        const label = getErrorLabelTranslated(error, t) || t('error.generic');
        enqueueSnackbar(label, { variant: 'error' });
      }
    };

    return null;
  },
);
