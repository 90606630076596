import { HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Auth from '@aws-amplify/auth';

const uri = process.env.REACT_APP_GQL_API_URL;

export const serverLink: HttpLink = new HttpLink({
  uri,
});

export const authLink = setContext(async (_, { headers }) => {
  let token;
  try {
    const credentials = await Auth.currentSession();
    token = credentials?.getIdToken().getJwtToken();
  } catch (error) {
    //swallow
  }

  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

export const serverLinkWithAuth = authLink.concat(serverLink);
