import { Avatar, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { InsuranceCaseDocumentReviewFragment } from '@common/queries/types/InsuranceCaseDocumentReviewFragment';

interface Props {
  documentReview: InsuranceCaseDocumentReviewFragment;
}

export const DocumentRejectionReason: FC<Props> = ({
  documentReview: { rejectionReason, createdBy },
}) => {
  const { firstName, lastName } = createdBy ?? {};
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: 'center',
        background: theme => theme.palette.grey[300],
        borderRadius: theme => theme.spacing(2, 2, 2, 0),
        paddingX: 2,
        paddingY: 1,
      }}
    >
      <Avatar sx={{ fontSize: 12, height: 24, width: 24 }}>
        {firstName?.[0]}
        {lastName?.[0]}
      </Avatar>
      <Typography variant="body2">{rejectionReason}</Typography>
    </Stack>
  );
};
