import { createContext, useState, useEffect, FC } from 'react';
// material
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type CollapseDrawerContextProps = {
  isOpen: boolean;
  openSidebar: VoidFunction;
  closeSidebar: VoidFunction;
  toggleSidebar: VoidFunction;
  isCollapse?: boolean;
  collapseClick: boolean;
  collapseHover: boolean;
  onToggleCollapse: VoidFunction;
  onHoverEnter: VoidFunction;
  onHoverLeave: VoidFunction;
};

const initialState: CollapseDrawerContextProps = {
  closeSidebar: () => null,
  collapseClick: false,
  collapseHover: false,
  isOpen: false,
  onHoverEnter: () => null,
  onHoverLeave: () => null,
  onToggleCollapse: () => null,
  openSidebar: () => null,
  toggleSidebar: () => null,
};

export const CollapseDrawerContext = createContext(initialState);

export const CollapseDrawerProvider: FC = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isOpen, setIsOpen] = useState(false);
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });

  useEffect(() => {
    if (isMobile) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isMobile]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        closeSidebar: () => setIsOpen(false),
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        isCollapse: collapse.click && !collapse.hover,
        isOpen,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
        onToggleCollapse: handleToggleCollapse,
        openSidebar: () => setIsOpen(true),
        toggleSidebar: () => setIsOpen(v => !v),
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
};
