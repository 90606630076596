import { Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import { Maybe } from '@shared/types';
import { DocumentPreview } from './DocumentPreview';

interface Props {
  customActions?: React.ReactNode;
  onClose: (event: any) => any;
  url: Maybe<string>;
  zIndex?: number;
}

export const DocumentPreviewModal = React.memo<Props>(
  ({ customActions, url, onClose, zIndex }: Props) => {
    return (
      <Modal onClose={onClose} open={!!url} style={zIndex ? { zIndex } : {}}>
        <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              right: theme => theme.spacing(2),
              top: theme => theme.spacing(2),
              zIndex: 9999,
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            {customActions}
          </Box>
          <Box
            sx={{
              height: '90%',
              left: '50%',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
            }}
          >
            <DocumentPreview url={url} />
          </Box>
        </>
      </Modal>
    );
  },
);
