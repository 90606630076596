import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Page } from '@components/Page';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Container, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { UpdateAdvertiserBankAccountForm } from '@components/forms/UpdateAdvertiserBankAccountForm';
import { useQuery } from '@apollo/client';
import { GetAdvertiser } from '@queries/types/GetAdvertiser';
import { GQL } from '@queries';
import { UpdateAdvertiserProfileForm } from '@components/forms/UpdateAdvertiserProfileForm';

enum TabName {
  Profile = 'Profile',
  BankAccount = 'BankAccount',
}

const TabHeader = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const TabPanelStyled = styled(TabPanel)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
}));

export const AdvertiserSettingsPage: FC = () => {
  const { t } = useTranslation();
  const { advertiserId } = useCurrentUser();
  const [tabIndex, setTabIndex] = useState(TabName.Profile);
  const queryState = useQuery<GetAdvertiser>(GQL.ADVERTISER.ADVERTISER, {
    variables: {
      advertiserId,
    },
  });

  const handleTabChange = (event: unknown, index: TabName) => {
    setTabIndex(index);
  };

  return (
    <Page title={t('pageTitle.settings')}>
      <Container>
        <QueryLoaderComponent
          queryState={queryState}
          renderLoading={() => <CircularProgress />}
          renderData={({ advertiser }) => (
            <>
              <HeaderBreadcrumbs heading={t('pageTitle.settings')} links={[]} />
              <Paper elevation={3}>
                <TabContext value={tabIndex}>
                  <TabHeader>
                    <TabList onChange={handleTabChange}>
                      <Tab label={t('profile')} value={TabName.Profile} />
                      <Tab
                        label={t('bankAccount')}
                        value={TabName.BankAccount}
                      />
                    </TabList>
                  </TabHeader>
                  <TabPanelStyled value={TabName.Profile}>
                    <UpdateAdvertiserProfileForm
                      advertiserId={advertiserId as string}
                      initialValues={advertiser}
                    />
                  </TabPanelStyled>
                  <TabPanelStyled value={TabName.BankAccount}>
                    <UpdateAdvertiserBankAccountForm
                      advertiserId={advertiserId as string}
                      initialValues={advertiser.bankAccount || {}}
                    />
                  </TabPanelStyled>
                </TabContext>
              </Paper>
            </>
          )}
        />
      </Container>
    </Page>
  );
};
