import { styled } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';

interface Props {
  isSelected: boolean;
}

export const Dot = styled('div', { shouldForwardProp: isPropValid })<Props>(
  ({ theme, isSelected }) => ({
    backgroundColor: isSelected
      ? theme.palette.primary.main
      : theme.palette.grey[500],
    borderRadius: '50%',
    height: 8,
    width: 8,
  }),
);
