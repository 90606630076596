import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { useMutation } from '@apollo/client';
import { GQL } from '@common/queries';
import { useSnackbar } from 'notistack';

interface Props {
  advertiserMemberId: string;
  children: (props: { onDeleteClick: () => void; isDeleting: boolean }) => any;
}

export const DeleteAdvertiserMemberAction: React.FC<Props> = ({
  advertiserMemberId,
  children,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [deleteAdvertiserMember, { loading }] = useMutation(
    GQL.ADVERTISER.DELETE_ADVERTISER_MEMBER,
  );

  const onDeleteClick = async () => {
    confirm({
      description: t('deleteAdvertiserMemberConfirmation.description'),
      title: t('deleteAdvertiserMemberConfirmation.title'),
    }).then(async () => {
      try {
        await deleteAdvertiserMember({
          refetchQueries: [GQL.ADVERTISER.MEMBERS_PAGED],
          variables: { advertiserMemberId },
        });
        enqueueSnackbar(t('success'), {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(t('error.generic'), { variant: 'error' });
      }
    });
  };

  return children({
    isDeleting: loading,
    onDeleteClick,
  });
};
