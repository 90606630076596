import { FC, forwardRef, ReactElement, Ref, useState } from 'react';
import { MHidden } from '@src/components';
import { keyframes, styled } from '@mui/material/styles';
import {
  Box,
  AppBar,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Stack,
  Grow,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { FormRejections } from '@components/forms/FormRejectionsSidebar/FormRejections';
import { scrollBarStyled } from '@src/theme/globalStyles';
import AnnouncementIcon from '@mui/icons-material/Announcement';

export const COMMENTS_SIDEBAR_WIDTH = 280;

const opacityTransition = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledHintBox = styled(Box)(({ theme }) => ({
  ['& svg']: {
    color: theme.palette.error.main,
    height: 30,
    width: 24,
  },
  alignItems: 'center',
  animation: `${opacityTransition} 2s infinite`,
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  right: 0,
  top: theme.spacing(9),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('xxl')]: {
    width: COMMENTS_SIDEBAR_WIDTH + parseInt(theme.spacing(10)),
  },
  [theme.breakpoints.down('xxl')]: {
    width: COMMENTS_SIDEBAR_WIDTH + parseInt(theme.spacing(6)),
  },
  [theme.breakpoints.down('xl')]: {
    width: COMMENTS_SIDEBAR_WIDTH,
  },
  borderBottomRightRadius: 0,
  borderRadius: theme.spacing(3),
  borderTopRightRadius: 0,
  flexShrink: 0,
  height: '55%',
  overflow: 'hidden',
  position: 'absolute',
  right: 0,
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.complex,
  }),
  ...scrollBarStyled(theme),
}));

const StyledRejectionCommentsWrapper = styled('div')(({ theme }) => ({
  ...scrollBarStyled(theme, { width: theme.spacing(0.5) }),
  // fixed height because of header
  height: 'calc(100% - 75px)',

  overflow: 'auto',

  padding: theme.spacing(3),
  paddingTop: 0,
}));

interface Props {
  currentStepRejections: [string[], Record<string, any>][];
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FormRejectionsSidebar: FC<Props> = ({ currentStepRejections }) => {
  const { t } = useTranslation();
  const [isCommentsDialogOpened, setIsCommentsDialogOpened] = useState(false);
  return (
    <>
      <MHidden width="lgUp">
        <Box sx={{ position: 'relative' }}>
          <StyledHintBox>
            {/*<Typography*/}
            {/*  variant="body2"*/}
            {/*  color="error"*/}
            {/*  sx={{ whiteSpace: 'pre' }}*/}
            {/*>*/}
            {/*  {t('somethingWasRejected')}*/}
            {/*</Typography>*/}
            <IconButton onClick={() => setIsCommentsDialogOpened(true)}>
              <AnnouncementIcon />
            </IconButton>
          </StyledHintBox>
        </Box>
        <Dialog
          sx={{
            top: 'auto',
          }}
          PaperProps={{
            sx: {
              m: {
                xs: 0,
              },
            },
          }}
          fullScreen
          open={isCommentsDialogOpened}
          onClose={() => setIsCommentsDialogOpened(false)}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsCommentsDialogOpened(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                {t('checkOutTheFollowingComments')}
              </Typography>
            </Toolbar>
          </AppBar>
          <Stack sx={{ maxHeight: 500, overflow: 'auto', p: 2 }}>
            <FormRejections currentStepRejections={currentStepRejections} />
          </Stack>
        </Dialog>
      </MHidden>

      <MHidden width="lgDown">
        <Grow in={!!currentStepRejections.length} unmountOnExit mountOnEnter>
          <StyledPaper elevation={3}>
            <Typography sx={{ maxHeight: 75, mb: 1, p: 3 }} variant="h6">
              {t('checkOutTheFollowingComments')}
            </Typography>
            <StyledRejectionCommentsWrapper>
              <FormRejections currentStepRejections={currentStepRejections} />
            </StyledRejectionCommentsWrapper>
          </StyledPaper>
        </Grow>
      </MHidden>
    </>
  );
};
