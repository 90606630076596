import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme): Record<string, any> {
  return {
    MuiButton: {
      styleOverrides: {
        containedError: {
          boxShadow: theme.customShadows.error,
        },

        containedInfo: {
          boxShadow: theme.customShadows.info,
        },

        // contained
        containedInherit: {
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
          boxShadow: theme.customShadows.z8,
          color: theme.palette.grey[800],
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        // outlined
        outlinedInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          border: `1px solid ${theme.palette.grey[500_32]}`,
        },

        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          textTransform: 'none',
        },

        sizeLarge: {
          height: 48,
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
