// ----------------------------------------------------------------------

const DISTANCE = 720;
const IN = { opacity: 1, scale: 1 };
const OUT = { opacity: 0, scale: 0 };

const TRANSITION_ENTER = {
  duration: 0.64,
  ease: [0.43, 0.13, 0.23, 0.96],
};

export const varZoomOut = {
  animate: { scale: 0, transition: TRANSITION_ENTER },
  initial: { scale: 1 },
};

export const varZoomOutUp = {
  animate: { ...OUT, transition: TRANSITION_ENTER, translateY: -DISTANCE },
  initial: IN,
};

export const varZoomOutDown = {
  animate: { ...OUT, transition: TRANSITION_ENTER, translateY: DISTANCE },
  initial: IN,
};

export const varZoomOutLeft = {
  animate: { ...OUT, transition: TRANSITION_ENTER, translateX: -DISTANCE },
  initial: IN,
};

export const varZoomOutRight = {
  animate: { ...OUT, transition: TRANSITION_ENTER, translateX: DISTANCE },
  initial: IN,
};
