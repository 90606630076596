import { InviteCodeStatus } from '@shared/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Theme } from '@mui/system';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useMutation } from '@apollo/client';
import { GQL } from '@queries';
import { InsuranceCaseStatus } from '../../../types/globalTypes';

const getColorsByStatus = (
  status: InviteCodeStatus,
  theme: Theme,
): { color: any; backgroundColor: any } =>
  ((
    {
      [InviteCodeStatus.Active]: {
        backgroundColor: theme.palette.success.lighter,
        color: theme.palette.success.dark,
      },
      [InviteCodeStatus.Revoked]: {
        backgroundColor: theme.palette.info.lighter,
        color: theme.palette.warning.dark,
      },
      [InviteCodeStatus.Used]: {
        backgroundColor: theme.palette.info.lighter,
        color: theme.palette.info.dark,
      },
    } as { [key in InviteCodeStatus]?: { color: any; backgroundColor: any } }
  )[status] ?? {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  });

interface AdditionalProps {
  status: InsuranceCaseStatus;
}

const StyledTypography = styled(({ status, ...props }: any) => (
  <Typography {...props} noWrap variant="subtitle2" />
))<AdditionalProps>(({ theme, status }) => ({
  borderRadius: theme.spacing(0.5),
  display: 'inline-block',
  flexGrow: 0,
  fontSize: 12,
  padding: theme.spacing('1px', 1),
  textOverflow: 'ellipsis',
  textTransform: 'none',
  ...getColorsByStatus(status, theme),
}));

interface Props {
  id: string;
  status: InviteCodeStatus;
}

export const InviteCodeStatusBadge: FC<Props> = ({ id, status }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [changeInviteCodeStatus, { loading: isLoading }] = useMutation(
    GQL.INVITE_CODE.CHANGE_STATUS,
  );

  const onCodeStatusChange = async () => {
    confirm({
      description:
        status === InviteCodeStatus.Active
          ? t('areYouSureYouWantToRevokeInviteCode')
          : t('areYouSureYouWantToActivateInviteCode'),
    }).then(async () => {
      try {
        await changeInviteCodeStatus({
          variables: {
            inviteCodeId: id,
            status:
              status === InviteCodeStatus.Active
                ? InviteCodeStatus.Revoked
                : InviteCodeStatus.Active,
          },
        });
      } catch (error) {
        const label = getErrorLabelTranslated(error, t) || t('error.generic');
        enqueueSnackbar(label, { variant: 'error' });
      }
    });
  };

  return (
    <Box sx={{ alignItems: 'center ', display: 'flex', gap: 1 }}>
      <StyledTypography component="div" status={status}>
        {t(status)}
      </StyledTypography>
      <IconButton disabled={isLoading} onClick={onCodeStatusChange}>
        {isLoading ? (
          <CircularProgress size={12} />
        ) : (
          <>
            {status === InviteCodeStatus.Active && (
              <DoDisturbAltIcon fontSize="small" color="error" />
            )}
            {status === InviteCodeStatus.Revoked && (
              <RestartAltIcon fontSize="small" color="primary" />
            )}
          </>
        )}
      </IconButton>
    </Box>
  );
};
