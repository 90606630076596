import {
  AdditionalInformationStep,
  BirthInformationStep,
  ChildBirthInfoStep,
  ChildDocumentsStep,
  ChildGeneralStep,
  ChildOccupationStep,
  ChildPreviousInsuranceStep,
  ConfirmationStep,
  DependantsStep,
  DocumentsStep,
  EmploymentInformationStep,
  OccupationStep,
  OtherPurposeInformationStep,
  PensionInformationStep,
  PersonalInformationStep,
  PreviousInsuranceStep,
  ResidenceStep,
  SpouseDocumentsStep,
  SpouseGeneralInformationStep,
  SpouseOccupationStep,
  StudyInformationStep,
  UnemploymentInformationStep,
  WelcomeStep,
} from '@components/forms/ApplicationForm/steps';
import { GetStepsArguments, Step } from '@components/forms/types';
import { getConditionalArr } from '@shared/helpers';
import { SpouseBirthInformationStep } from '@components/forms/ApplicationForm/steps/SpouseBirthInformationStep';
import { SpousePreviousInsuranceStep } from '@components/forms/ApplicationForm/steps/SpousePreviousInsurance';
import { DependantsType, InsurancePurpose } from '@shared/constants';
import { createNumberOfSteps } from '@utils/createNumberOfSteps';

export const getSteps = ({ t, formValues }: GetStepsArguments): Step[] => {
  return [
    {
      component: WelcomeStep,
      hideControls: true,
      stepName: 'welcome',
    },
    {
      component: PersonalInformationStep,
      group: t('yourApplication'),
      stepName: 'personal-information',
      stepNameSidebar: t('personalInformation'),
    },
    {
      component: BirthInformationStep,
      group: t('yourApplication'),
      stepName: 'birth-information',
      stepNameSidebar: t('birthDateAndPlace'),
    },
    {
      component: ResidenceStep,
      group: t('yourApplication'),
      stepName: 'residence',
      stepNameSidebar: t('residence'),
    },
    {
      component: OccupationStep,
      group: t('yourApplication'),
      stepName: 'occupation',
      stepNameSidebar: t('occupation'),
    },
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.some(purpose =>
        [
          InsurancePurpose.Employment,
          InsurancePurpose.Trainee,
          InsurancePurpose.Partner,
        ].includes(purpose),
      ),
      [
        {
          component: EmploymentInformationStep,
          group: t('yourApplication'),
          stepName: 'employment-purpose',
          stepNameSidebar: t('employmentPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(InsurancePurpose.Study),
      [
        {
          component: StudyInformationStep,
          group: t('yourApplication'),
          stepName: 'study-purpose',
          stepNameSidebar: t('studyPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(
        InsurancePurpose.Pension,
      ),
      [
        {
          component: PensionInformationStep,
          group: t('yourApplication'),
          stepName: 'pension-purpose',
          stepNameSidebar: t('pensionPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(InsurancePurpose.Other),
      [
        {
          component: OtherPurposeInformationStep,
          group: t('yourApplication'),
          stepName: 'other-purpose',
          stepNameSidebar: t('otherPurposeInformation'),
        },
      ],
    ),
    ...getConditionalArr(
      formValues['main_0_insurancePurposes']?.includes(
        InsurancePurpose.Unemployment,
      ),
      [
        {
          component: UnemploymentInformationStep,
          group: t('yourApplication'),
          stepName: 'unemployment-purpose',
          stepNameSidebar: t('unemploymentPurposeInformation'),
        },
      ],
    ),
    {
      component: PreviousInsuranceStep,
      group: t('yourApplication'),
      stepName: 'previous-insurance',
      stepNameSidebar: t('previousInsurance'),
    },
    {
      component: AdditionalInformationStep,
      group: t('yourApplication'),
      stepName: 'additional-information',
      stepNameSidebar: t('additionalInformation'),
    },
    {
      component: DependantsStep,
      group: t('yourApplication'),
      stepName: 'dependants',
      stepNameSidebar: t('dependants'),
    },
    {
      component: DocumentsStep,
      group: t('yourApplication'),
      hideControls: true,
      stepName: 'documents',
      stepNameSidebar: t('documents'),
    },
    ...getConditionalArr(
      [DependantsType.Spouse, DependantsType.ChildrenAndSpouse].includes(
        formValues['main_0_dependantsType'],
      ),
      () => [
        {
          component: SpouseGeneralInformationStep,
          group: t('yourSpouse'),
          stepName: 'spouse-general-information',
          stepNameSidebar: t('personalInformation'),
        },
        {
          component: SpouseBirthInformationStep,
          group: t('yourSpouse'),
          stepName: 'spouse-birth-information',
          stepNameSidebar: t('birthDateAndPlace'),
        },
        {
          component: SpousePreviousInsuranceStep,
          group: t('yourSpouse'),
          stepName: 'spouse-previous-insurance',
          stepNameSidebar: t('previousInsurance'),
        },
        {
          component: SpouseOccupationStep,
          group: t('yourSpouse'),
          stepName: 'spouse-occupation',
          stepNameSidebar: t('occupation'),
        },
        {
          component: SpouseDocumentsStep,
          group: t('yourSpouse'),
          hideControls: true,
          stepName: 'spouse-documents',
          stepNameSidebar: t('documents'),
        },
      ],
    ),
    ...createNumberOfSteps(formValues.main_0_childrenCount, idx => [
      {
        component: ChildGeneralStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-general-information`,
        stepNameSidebar: t(`personalInformation`),
      },
      {
        component: ChildBirthInfoStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-birth-information`,
        stepNameSidebar: t(`birthDateAndPlace`),
      },
      {
        component: ChildPreviousInsuranceStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-previous-insurance-step`,
        stepNameSidebar: t(`previousInsurance`),
      },
      {
        component: ChildOccupationStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        meta: { idx },
        stepName: `child-${idx}-occupation-step`,
        stepNameSidebar: t(`occupation`),
      },
      {
        component: ChildDocumentsStep,
        group: t('childIdxGroup', { idx: idx + 1 }),
        hideControls: true,
        meta: { idx },
        stepName: `child-${idx}-documents`,
        stepNameSidebar: t('documents'),
      },
    ]),
    {
      component: ConfirmationStep,
      group: t('submitApplication'),
      hideControls: true,
      stepName: 'confirmation',
      stepNameSidebar: t('submitApplication'),
    },
  ];
};
