import { Container } from '@mui/material';
import { FC, useEffect } from 'react';
import { Page } from '@components/Page';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { LoadingScreen } from '@components/LoadingScreen';
import { useQuery } from '@apollo/client';
import { GQL } from '@queries';
import { ApplicationFormPage } from '@pages/ApplicationFormPage/ApplicationFormPage';
import { InsuranceCaseStatus, PoaStatus } from '@shared/constants';
import InsuranceCaseDetailsPage from '@pages/InsuranceCaseDetailsPage';
import {
  GetInsuranceCase,
  GetInsuranceCaseVariables,
} from '@queries/types/GetInsuranceCase';
import { useParams } from 'react-router-dom';
import { SignApplication } from '../ApplicantInsuranceCasePage/SignApplication';
import { DocumentsGenerationLoading } from '../ApplicantInsuranceCasePage/DocumentsGenerationLoading';

export const AdvertiserInsuranceCasePage: FC = () => {
  const { insuranceCaseId } = useParams();
  const queryState = useQuery<GetInsuranceCase, GetInsuranceCaseVariables>(
    GQL.INSURANCE_CASE.INSURANCE_CASE,
    {
      skip: !insuranceCaseId,
      variables: {
        insuranceCaseId: insuranceCaseId as string,
      },
    },
  );

  // todo poll only status, not whole case
  const caseStatus = queryState?.data?.insuranceCase?.status;
  const startPolling = () => queryState.startPolling(400);

  useEffect(() => {
    // todo figure out how to remove this if
    // race condition apperas without it
    startPolling();
    if (
      [
        InsuranceCaseStatus.DocumentsGenerating,
        InsuranceCaseStatus.Draft,
        InsuranceCaseStatus.Created,
      ].includes(caseStatus as InsuranceCaseStatus)
    ) {
      return;
    }
    queryState.stopPolling();
  }, [caseStatus]);

  return (
    <QueryLoaderComponent
      queryState={queryState}
      renderLoading={() => (
        <Page>
          <Container>
            <LoadingScreen />
          </Container>
        </Page>
      )}
      renderData={({ insuranceCase }) => {
        if (!insuranceCase) {
          return <div />;
        }
        const { status, poaStatus } = insuranceCase;
        const hasPoa = [PoaStatus.Uploading, PoaStatus.Generating].includes(
          poaStatus,
        );
        if (hasPoa) {
          return (
            <Page>
              {status === InsuranceCaseStatus.DocumentsGenerating && (
                <Container>
                  <DocumentsGenerationLoading
                    onMounted={startPolling}
                    onTimeout={() => queryState.stopPolling()}
                  />
                </Container>
              )}
              {status === InsuranceCaseStatus.DocumentsSigning && (
                <Container>
                  <SignApplication
                    insuranceCase={insuranceCase}
                    onAllSigned={startPolling}
                  />
                </Container>
              )}
              {status === InsuranceCaseStatus.ChangesRequested && (
                <ApplicationFormPage
                  insuranceCase={insuranceCase}
                  isRefilling={true}
                  onSubmitted={startPolling}
                />
              )}
              {status === InsuranceCaseStatus.Created && (
                <Container>
                  <LoadingScreen />
                </Container>
              )}
              {/*default*/}
              {![
                InsuranceCaseStatus.DocumentsGenerating,
                InsuranceCaseStatus.DocumentsSigning,
                InsuranceCaseStatus.ChangesRequested,
                InsuranceCaseStatus.Created,
              ].includes(status) && <InsuranceCaseDetailsPage />}
            </Page>
          );
        }

        return <InsuranceCaseDetailsPage />;
      }}
    />
  );
};
