import React, { ChangeEvent, ReactNode, useCallback } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperTextProps,
  Radio as MuiRadio,
  RadioGroup,
  RadioGroupProps,
  RadioProps as MuiRadioProps,
  Stack,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Field, FieldProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  ShowErrorFunc,
  showErrorOnChange,
  useFieldForErrors,
} from './util';

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid`,
  borderRadius: theme.spacing(1),
  margin: 0,
  padding: theme.spacing(0.5),
  width: '100%',
}));

export interface RadiosProps extends Partial<Omit<MuiRadioProps, 'onChange'>> {
  name: string;
  question?: ReactNode;
  description?: ReactNode;
  leftQuestionProps?: Partial<FormControlLabelProps> & {
    label: string | number | React.ReactElement;
  };
  rightQuestionProps?: Partial<FormControlLabelProps> & {
    label: string | number | React.ReactElement;
  };
  required?: boolean;
  helperText?: string;
  questionTypographyProps?: Partial<TypographyProps>;
  descriptionTypographyProps?: Partial<TypographyProps>;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  radioGroupProps?: Partial<RadioGroupProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
}

export const QuestionField: React.FC<RadiosProps> = (props: RadiosProps) => {
  const { t } = useTranslation();
  const {
    name,
    description,
    required,
    helperText,
    questionTypographyProps,
    descriptionTypographyProps,
    leftQuestionProps = { label: t('yes') as string, value: true },
    rightQuestionProps = { label: t('no') as string, value: false },
    fieldProps,
    formControlProps,
    radioGroupProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    question,
    ...restRadios
  } = props;

  const field = useFieldForErrors(name);
  const isError = showError(field);

  const transformRadioValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>): string | boolean => {
      const currentTarget = e.target;
      if (currentTarget.value.includes('true')) {
        return true;
      }
      if (currentTarget.value.includes('false')) {
        return false;
      }

      return currentTarget.value;
    },
    [],
  );

  return (
    <FormControl required={required} error={isError} {...formControlProps}>
      {question && (
        <Typography
          color="text.primary"
          variant="subtitle2"
          {...questionTypographyProps}
          sx={{ mb: 1 }}
        >
          {question}
        </Typography>
      )}
      {description && (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ mb: 1 }}
          {...descriptionTypographyProps}
        >
          {description}
        </Typography>
      )}
      <Field
        name={name}
        render={({
          input: {
            name: inputName,
            value: fieldValue,
            onChange,
            checked,
            ...restInput
          },
        }) => (
          <RadioGroup
            value={fieldValue}
            onChange={e => onChange(transformRadioValue(e))}
            {...radioGroupProps}
            {...restInput}
          >
            <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
              <FormControlLabelStyled
                sx={{
                  borderColor: theme =>
                    (isError && theme.palette.error.main) ||
                    (fieldValue === leftQuestionProps.value &&
                      theme.palette.primary.main) ||
                    theme.palette.grey['300'],
                }}
                control={<MuiRadio {...restRadios} />}
                {...leftQuestionProps}
              />
              <FormControlLabelStyled
                sx={{
                  borderColor: theme =>
                    (isError && theme.palette.error.main) ||
                    (fieldValue === rightQuestionProps.value &&
                      theme.palette.primary.main) ||
                    theme.palette.grey['300'],
                }}
                control={<MuiRadio {...restRadios} />}
                {...rightQuestionProps}
              />
            </Stack>
          </RadioGroup>
        )}
        {...fieldProps}
      />
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
};
