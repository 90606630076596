import { LoadingScreen } from '@components/LoadingScreen';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

interface Props {
  onMounted?: VoidFunction;
  onTimeout?: VoidFunction;
}

export const DocumentsGenerationLoading: FC<Props> = ({
  onMounted,
  onTimeout,
}) => {
  const [isTimedOut, setIsTimedOut] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    onMounted?.();

    const timerId = setTimeout(() => {
      setIsTimedOut(true);
      onTimeout?.();
    }, 15000);

    () => {
      clearTimeout(timerId);
    };
  }, []);

  return isTimedOut ? (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
      }}
    >
      <Typography variant="h6" color="error">
        {t('documentGenerationLoading.timedOutRefreshPageOrTryLater')}
      </Typography>
    </Box>
  ) : (
    <LoadingScreen
      text={t('documentGenerationLoading.weGeneratingYouApplication')}
    />
  );
};
