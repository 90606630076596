import { FC, ReactNode, useState } from 'react';
import { Icon } from '@iconify/react';
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  BoxProps,
  Collapse,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled(props => (
  <ListSubheader disableSticky disableGutters {...props} />
))(
  ({ theme }) =>
    ({
      ...theme.typography.overline,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
    } as any), // TODO: fix type
);

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(
  ({ theme }) =>
    ({
      ...theme.typography.body2,
      '&:before': {
        backgroundColor: theme.palette.primary.main,
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        right: 0,
        top: 0,
        width: 3,
      },
      color: theme.palette.text.secondary,
      height: 48,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(2.5),
      position: 'relative',
      textTransform: 'capitalize',
    } as any), // TODO: fix type
);

const ListItemIconStyle = styled(ListItemIcon)({
  alignItems: 'center',
  display: 'flex',
  height: 22,
  justifyContent: 'center',
  width: 22,
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

function NavItem({
  item,
  isShow,
}: {
  item: NavItemProps;
  isShow?: boolean | undefined;
}) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path
    ? !!matchPath({ end: false, path }, pathname)
    : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    '&:before': { display: 'block' },
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ height: 16, ml: 1, width: 16 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map(childItem => {
                const { title: childTitle, path: childPath } = childItem;
                const isActiveSub = childPath
                  ? !!matchPath({ end: false, path: childPath }, pathname)
                  : false;

                return (
                  <ListItemStyle
                    key={childTitle}
                    component={RouterLink}
                    to={childPath}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          alignItems: 'center',
                          bgcolor: 'text.disabled',
                          borderRadius: '50%',
                          display: 'flex',
                          height: 4,
                          justifyContent: 'center',
                          transition: _theme =>
                            _theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            bgcolor: 'primary.main',
                            transform: 'scale(2)',
                          }),
                          width: 4,
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={childTitle} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

export interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    key?: string;
    subheader?: string;
    items: NavItemProps[];
  }[];
}

export const NavSection: FC<NavSectionProps> = ({
  navConfig,
  isShow = true,
  ...other
}) => {
  return (
    <Box {...other}>
      {navConfig.map((list, index) => {
        const { subheader, items, key } = list;
        return (
          <List key={key ?? index} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
};
