import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Stack, Typography } from '@mui/material';
import { DatePicker, TextField } from '@common/forms/fields';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { useTranslation } from 'react-i18next';
import { StepComponent } from '@components/forms/types';
import { AnySchema } from 'yup';
import { StepTitle } from '@components/forms';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_otherInsurancePurpose: Yup.string().nullable(),
    main_0_otherInsurancePurposeStartDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
  });

export const OtherPurposeInformationStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.otherInsurancePurposeInformation')}
        </Typography>
      </StepTitle>

      <TextField
        name="main_0_otherInsurancePurpose"
        label={getLabel('main_0_otherInsurancePurpose', t)}
      />
      <DatePicker
        label={getLabel('main_0_otherInsurancePurposeStartDate', t)}
        name="main_0_otherInsurancePurposeStartDate"
      />
    </Stack>
  );
};

OtherPurposeInformationStep.getValidationSchema = getValidationSchema;
