import { FC } from 'react';
import { Box } from '@mui/system';

export const StepTitle: FC<any> = ({ children, ...props }) => {
  return (
    <Box sx={{ mb: 2 }} {...props}>
      <Box sx={{ pb: 1 }}>
        <img
          width={72}
          src="/static/illustrations/illustration_guide_girl.svg"
        />
      </Box>
      {children}
    </Box>
  );
};
