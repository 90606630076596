export const LATIN_REG_EXP =
  /^[a-z0-9äöüÄÖÜß\n][a-z0-9,.\-+@ ;äöüÄÖÜßéąĄćĆęĘłŁńŃóÓśŚźŹżŻ\n]*$/i;

export const ONLY_DIGITS_AND_LETTERS = /^[a-z0-9-]*$/i;

export const NOT_BARMER_STRING = /^((?!(barmer)).)*$/i;

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const PHONE_REGEX = /^[+]*[(]?[0-9]{1,3}[)]?[-\s./0-9]*$/g;
