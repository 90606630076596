import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { GQL } from '@common/queries';
import { useSnackbar } from 'notistack';
import { Maybe } from '@shared/types';
import { useTranslation } from 'react-i18next';

type RenderProps = {
  url: Maybe<string>;
  loading: boolean;
  error: boolean;
  fetchURL: () => Promise<void>;
  clearURL: () => void;
};

type Props = {
  id: string;
  documentContainerId?: string;
  children: (props: RenderProps) => JSX.Element;
};

export const DocumentSignedURL: React.FC<Props> = ({
  documentContainerId,
  id,
  children,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [url, setURL] = useState<Maybe<string>>(null);
  const [getSignedUrl, { loading, error }] = useMutation(
    GQL.DOCUMENT.GET_SIGNED_URL,
  );

  const fetchURL = async () => {
    try {
      const result = await getSignedUrl({
        variables: { documentKey: { documentContainerId, id } },
      });

      setURL(result.data.documentMutations.getSignedUrl);
    } catch (err: unknown) {
      enqueueSnackbar(t('error.generic'), { variant: 'error' });
    }
  };

  return children({
    clearURL: () => setURL(null),
    error: !!error,
    fetchURL,
    loading,
    url,
  });
};
