import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';
import { GQL } from '@queries';
import { useQuery } from '@apollo/client';
import { ApplicationDraftForm } from '@components/forms/ApplicationDraftForm/ApplicationDraftForm';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { Navigate, useNavigate, useParams } from 'react-router';
import { ROUTES } from '@common/routes';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { useSnackbar } from 'notistack';
import { InsuranceCaseStatus } from '@shared/constants';

// todo: is there any way to omit double loading?
export const AdvertiserInsuranceCaseDraftPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { advertiserId } = useCurrentUser();
  const { insuranceCaseId } = useParams();
  const queryState = useQuery(GQL.INSURANCE_CASE.INSURANCE_CASE, {
    variables: {
      insuranceCaseId,
    },
  });

  return (
    <Box display="flex" justifyContent="center">
      <QueryLoaderComponent
        renderLoading={() => <CircularProgress />}
        queryState={queryState}
        renderData={({ insuranceCase }) => {
          if (insuranceCase.status !== InsuranceCaseStatus.Draft) {
            return (
              <Navigate
                to={ROUTES.ADVERTISER.insuranceCaseDetails({ insuranceCaseId })}
                replace
              />
            );
          }
          return (
            <ApplicationDraftForm
              insuranceCase={insuranceCase}
              advertiserId={advertiserId as string}
              onComplete={() => {
                navigate(ROUTES.ADVERTISER.applicants.path);
                enqueueSnackbar(t('applicantInvitedSuccessfully'), {
                  color: 'success',
                });
              }}
            />
          );
        }}
      />
    </Box>
  );
};
