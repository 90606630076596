import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Stack,
  Button,
  StandardTextFieldProps,
} from '@mui/material';
import { GQL } from '@queries';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { GetAdvertiser_advertiser_bankAccount } from '@queries/types/GetAdvertiser';
import { Form } from 'react-final-form';
import { Autocomplete, TextField } from '@common/forms/fields';
import { useSnackbar } from 'notistack';
import { UpdateAdvertiserProfile_advertiserMutations_updateProfile as AdvertiserUpdateResult } from '@queries/types/UpdateAdvertiserProfile';
import { UpdateAdvertiser } from '@queries/types/UpdateAdvertiser';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { BankAccountType } from '@shared/constants';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { IBANCodeSchema } from '@common/validationSchemas/IBANCodeSchema';
import { useConfirm } from 'material-ui-confirm';
import { LATIN_REG_EXP } from '@src/../../../shared/regex';

type Props = {
  advertiserId: string;
  initialValues?: Partial<GetAdvertiser_advertiser_bankAccount>;
  onComplete?: (data: AdvertiserUpdateResult) => void;
};

export const UpdateAdvertiserBankAccountForm: React.FC<Props> = ({
  advertiserId,
  initialValues,
  onComplete,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [update] = useMutation<UpdateAdvertiser>(GQL.ADVERTISER.UPDATE_PROFILE);
  const confirm = useConfirm();

  const onSubmit = async values => {
    const {
      abaRoutingTransitNumber,
      account,
      address,
      beneficiary,
      bsbCode,
      clabeNumber,
      code,
      country,
      ifscCode,
      name,
      type,
      vatId,
    } = values;
    const MAP_BANK_ACCOUNT_TYPE_TO_KEY = {
      [BankAccountType.IBAN]: 'iban',
      [BankAccountType.SWIFT]: 'swift',
    };
    const MAP_BANK_ACCOUNT_TYPE_TO_VALUES = {
      [BankAccountType.IBAN]: {
        code,
        vatId,
      },
      [BankAccountType.SWIFT]: {
        abaRoutingTransitNumber,
        account,
        address,
        beneficiary,
        bsbCode,
        clabeNumber,
        code,
        country,
        ifscCode,
        name,
      },
    };

    confirm({ description: t('willNeedToSignAddendum') }).then(async () => {
      try {
        const { data } = await update({
          variables: {
            advertiserId,
            input: {
              bankAccount: {
                [MAP_BANK_ACCOUNT_TYPE_TO_KEY[type]]:
                  MAP_BANK_ACCOUNT_TYPE_TO_VALUES[type],
              },
            },
          },
        });
        if (data) {
          onComplete?.(data.advertiserMutations.update);
        }
        enqueueSnackbar(t('advertiserSuccessfullyUpdated'), {
          variant: 'success',
        });
        setIsEditing(false);
      } catch (error) {
        const label = getErrorLabelTranslated(error, t) || t('error.generic');
        enqueueSnackbar(label, { variant: 'error' });
      }
    });
  };

  const InputProps: Partial<StandardTextFieldProps['InputProps']> = {
    disableUnderline: !isEditing,
    onClick: () => (!isEditing ? setIsEditing(true) : null),
    readOnly: !isEditing,
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          abaRoutingTransitNumber: Yup.string()
            .nullable()
            .when('country', {
              is: 'US',
              then: Yup.string()
                .nullable()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            }),
          account: Yup.string()
            .nullable()
            .when('type', {
              is: BankAccountType.SWIFT,
              then: Yup.string()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            }),
          address: Yup.string()
            .nullable()
            .when('type', {
              is: BankAccountType.SWIFT,
              then: Yup.string()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            }),
          beneficiary: Yup.string()
            .nullable()
            .when('type', {
              is: BankAccountType.SWIFT,
              then: Yup.string()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            }),
          bsbCode: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
          clabeNumber: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
          code: Yup.string()
            .nullable()
            .when('type', {
              is: BankAccountType.IBAN,
              then: IBANCodeSchema(t),
            })
            .when('type', {
              is: BankAccountType.SWIFT,
              then: Yup.string()
                .nullable()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            })
            .required(),
          country: Yup.string()
            .nullable()
            .when('type', {
              is: BankAccountType.SWIFT,
              then: Yup.string()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            }),
          ifscCode: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
          name: Yup.string()
            .nullable()
            .when('type', {
              is: BankAccountType.SWIFT,
              then: Yup.string()
                .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
                .required(t('validation.required')),
            }),
          vatId: Yup.string()
            .nullable()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
        }) as any,
      ),
    [t],
  ) as any;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ pristine: true, submitting: true, values: true }}
      validate={validate}
    >
      {({ handleSubmit, form, submitting, values, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                textFieldProps={{
                  InputProps,
                  variant: 'standard',
                }}
                name="type"
                label={t('paymentProvider')}
                getOptionValue={({ value }) => value}
                options={Object.keys(BankAccountType).map(value => ({
                  label: value,
                  value,
                }))}
              />
            </Grid>
            {values.type === BankAccountType.IBAN && (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    name="code"
                    label={t('ibanCode')}
                    InputProps={InputProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    name="vatId"
                    label={t('ibanVatNumber')}
                    InputProps={InputProps}
                  />
                </Grid>
              </>
            )}
            {values.type === BankAccountType.SWIFT && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    name="code"
                    label={t('swiftCode')}
                    InputProps={InputProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    name="account"
                    label={t('swiftBankAccount')}
                    InputProps={InputProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    name="name"
                    label={t('swiftBankName')}
                    InputProps={InputProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    name="beneficiary"
                    label={t('swiftBeneficiary')}
                    InputProps={InputProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    name="address"
                    label={t('address')}
                    InputProps={InputProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CountrySelect
                    name="country"
                    label={t('country')}
                    textFieldProps={{ InputProps, variant: 'standard' }}
                  />
                </Grid>
                {values.country === 'US' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('abaRoutingTransitNumber')}
                      name="abaRoutingTransitNumber"
                      InputProps={InputProps}
                      variant="standard"
                    />
                  </Grid>
                )}
                {values.country === 'MX' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('clabeNumber')}
                      name="clabeNumber"
                      InputProps={InputProps}
                      variant="standard"
                    />
                  </Grid>
                )}
                {values.country === 'IN' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('ifscCode')}
                      name="ifscCode"
                      InputProps={InputProps}
                      variant="standard"
                    />
                  </Grid>
                )}
                {values.country === 'AU' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('bsbCode')}
                      name="bsbCode"
                      InputProps={InputProps}
                      variant="standard"
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            {isEditing ? (
              <Stack direction="row" spacing={2}>
                <Button
                  type="button"
                  onClick={() => {
                    form.reset();
                    setIsEditing(false);
                  }}
                >
                  {t('cancel')}
                </Button>
                <LoadingButton
                  loading={submitting}
                  disabled={pristine}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Stack>
            ) : (
              <Button
                type="button"
                variant="contained"
                onClick={() => setIsEditing(true)}
              >
                {t('edit')}
              </Button>
            )}
          </Box>
        </form>
      )}
    </Form>
  );
};
