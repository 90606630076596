import { Step } from '@components/forms/types';
import { GetStepsArguments } from '@components/forms/types';
import {
  AdvertiserVerificationBillingStep,
  AdvertiserVerificationGeneralStep,
} from './steps';

export const getSteps = ({ t, formValues }: GetStepsArguments): Step[] => {
  return [
    {
      component: AdvertiserVerificationGeneralStep,
      group: t('advertiserVerification.formStepper.title'),
      stepName: 'AdvertiserVerificationGeneral',
      stepNameSidebar: t('general'),
    },
    {
      component: AdvertiserVerificationBillingStep,
      group: t('advertiserVerification.formStepper.title'),
      stepName: 'AdvertiserVerificationBilling',
      stepNameSidebar: t('billing'),
    },
  ];
};
