import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, Paper } from '@mui/material';
import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Page } from '@components/Page';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { LoadingScreen } from '@components/LoadingScreen';
import { useApolloClient, useQuery } from '@apollo/client';
import { GQL } from '@queries';
import { useTranslation } from 'react-i18next';
import { InsuranceCasePageNavHeader } from '@components/InsuranceCasePageNavHeader';
import { ROUTES } from '@common/routes';
import { PageActionsFooter } from '@components/PageActionsFooter';
import {
  GetInsuranceCase,
  GetInsuranceCaseVariables,
} from '@queries/types/GetInsuranceCase';
import { useCurrentUser } from '@common/hooks/useCurrentUser';
import { UserRole } from '@shared/constants';
import { InsuranceCaseStatus } from '@src/../types/globalTypes';
import { ChangeGPNumberButton } from '@pages/InsuranceCaseDetailsPage/ChangeGPNumberButton';
import { InsuranceCaseApplication } from './InsuranceCaseApplication';
import { InsuranceCaseDocuments } from './InsuranceCaseDocuments';
import { FinalStatusChangeAction } from './FinalStatusChangeAction';
import { ServicePointAssign } from './ServicePointAssign';

interface DocumentsGeneratingStatusContext {
  startPolling: (pollInterval: number) => void;
  stopPolling: VoidFunction;
  isRegenerationInProcess: boolean;
}

export const DocumentsGeneratingStatusContext = createContext<
  DocumentsGeneratingStatusContext | undefined
>(undefined);

export const InsuranceCaseDetailsPage: FC = () => {
  const { role } = useCurrentUser();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { insuranceCaseId } = useParams();
  const navigate = useNavigate();
  const [isRegenerationInProcess, setIsRegenerationInProcess] = useState(false);

  const queryState = useQuery<GetInsuranceCase, GetInsuranceCaseVariables>(
    GQL.INSURANCE_CASE.INSURANCE_CASE,
    {
      variables: {
        insuranceCaseId: insuranceCaseId as string,
      },
    },
  );
  const { startPolling, stopPolling, data } = useQuery(
    GQL.INSURANCE_CASE.DOCUMENTS_GENERATING_STATUS,
    {
      variables: {
        insuranceCaseId: insuranceCaseId as string,
      },
    },
  );
  const currentGeneratingStatus = data?.insuranceCase?.isApplicationGenerating;
  const previousGeneratingStatus = useRef(currentGeneratingStatus);

  /**
   * Once created - insurance case does not have barmer application yet, so waiting for initial generation
   */
  useEffect(() => {
    if (!queryState.data?.insuranceCase) {
      return;
    }
    const { documents } = queryState.data.insuranceCase;
    const barmerApplicationDoc = documents.find(
      ({ documentName }) => documentName === 'barmerApplication',
    );
    if (!barmerApplicationDoc?.id) {
      queryState.startPolling(1000);
    } else {
      queryState.stopPolling();
    }
  }, [queryState]);

  const startPollingWrapped = useCallback((pollingInterval: number) => {
    startPolling(pollingInterval);
    setIsRegenerationInProcess(true);
  }, []);

  const stopPollingWrapped = useCallback(() => {
    stopPolling();
    setIsRegenerationInProcess(false);
  }, []);

  /**
   * Stop polling and refetch application once generation is completed
   */
  useEffect(() => {
    if (!currentGeneratingStatus && previousGeneratingStatus.current) {
      stopPollingWrapped();
      client.refetchQueries({
        include: [GQL.INSURANCE_CASE_REVIEW.FIELD_REVIEWS_WITH_SIGNED_URL],
      });
    }
    if (currentGeneratingStatus !== previousGeneratingStatus) {
      previousGeneratingStatus.current = currentGeneratingStatus;
    }
  }, [data]);

  return (
    <DocumentsGeneratingStatusContext.Provider
      value={{
        isRegenerationInProcess,
        startPolling: startPollingWrapped,
        stopPolling: stopPollingWrapped,
      }}
    >
      <Page
        sx={{
          display: 'block',
          height: '100%',
          width: '100%',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <QueryLoaderComponent
            queryState={queryState}
            renderLoading={() => <LoadingScreen />}
            renderData={({ insuranceCase }) => {
              const { status, internalCRMNumber } = insuranceCase;
              console.log(internalCRMNumber);

              // todo check permissions by ability instead of role

              const isReviewsVisible =
                [
                  InsuranceCaseStatus.Reviewing,
                  InsuranceCaseStatus.ChangesRequested,
                ] &&
                [UserRole.ServicePoint, UserRole.Administrator].includes(role);

              const isReviewing =
                status === InsuranceCaseStatus.Reviewing &&
                [UserRole.ServicePoint, UserRole.Administrator].includes(role);

              const isFinalStatusChangeAwaiting =
                [
                  InsuranceCaseStatus.NotInsurable,
                  InsuranceCaseStatus.ChangesRequested,
                  InsuranceCaseStatus.Approved,
                  InsuranceCaseStatus.Pending,
                  InsuranceCaseStatus.Covered,
                ].includes(status) &&
                [UserRole.ServicePoint, UserRole.Administrator].includes(role);

              const isChangeGpNumberVisible =
                !!internalCRMNumber &&
                [UserRole.ServicePoint, UserRole.Administrator].includes(role);

              return (
                <>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <InsuranceCasePageNavHeader
                      isReviewing={isReviewing}
                      insuranceCase={insuranceCase}
                    />
                    {role === UserRole.BranchOffice && (
                      <ServicePointAssign
                        insuranceCaseId={insuranceCase.id}
                        assignedTo={(insuranceCase as any).assignedTo}
                      />
                    )}
                  </Box>
                  <Paper
                    elevation={7}
                    sx={{
                      borderRadius: theme => theme.spacing(2, 2, 0, 0),
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      height: '100%',
                      overflowY: 'hidden',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flex: 1,
                        overflowY: 'hidden',
                      }}
                    >
                      <Box
                        sx={{
                          borderRight: theme =>
                            `1px solid ${theme.palette.grey[300]}`,
                          height: '100%',
                          width: '55%',
                        }}
                      >
                        <InsuranceCaseApplication
                          isReviewsVisible={isReviewsVisible}
                          isReviewing={isReviewing}
                          insuranceCase={insuranceCase}
                        />
                      </Box>
                      <Box sx={{ width: '45%' }}>
                        <InsuranceCaseDocuments
                          isReviewsVisible={isReviewsVisible}
                          isReviewing={isReviewing}
                          insuranceCase={insuranceCase}
                        />
                      </Box>
                    </Box>
                    {(isChangeGpNumberVisible ||
                      isFinalStatusChangeAwaiting ||
                      isReviewing) && (
                      <PageActionsFooter>
                        {isFinalStatusChangeAwaiting && (
                          <FinalStatusChangeAction
                            insuranceCase={insuranceCase}
                          />
                        )}
                        {isReviewing && (
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate(
                                ROUTES.BRANCH.insuranceCaseFinishReview({
                                  insuranceCaseId,
                                }),
                              )
                            }
                          >
                            {t('insuranceCaseReview.continueReivew')}
                          </Button>
                        )}
                        {isChangeGpNumberVisible && (
                          <ChangeGPNumberButton
                            insuranceCaseId={insuranceCase.id}
                          />
                        )}
                      </PageActionsFooter>
                    )}
                  </Paper>
                </>
              );
            }}
          />
        </Container>
      </Page>
    </DocumentsGeneratingStatusContext.Provider>
  );
};
