import { useResize } from '@common/hooks';
import { InsuranceCaseFieldReviewFragment } from '@common/queries/types/InsuranceCaseFieldReviewFragment';
import { Maybe } from '@shared/types';
import { FC, useRef, useState, MutableRefObject } from 'react';
import { Page as PDFPage } from 'react-pdf';
import { FieldReview } from './FieldReview';
import { getBlockedReviewPDFFieldNames } from './getBlockedReviewPDFFieldNames';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

interface Props {
  insuranceCaseId: string;
  isReviewing: boolean;
  isReviewsVisible: boolean;
  pageNumber: number;
  reviewByPdfFieldName: Record<string, InsuranceCaseFieldReviewFragment>;
  reviewFieldPrefixes: string[];
  wrapperRef: MutableRefObject<HTMLElement>;
}

const getBasePdfFieldName = (fullPdfFieldName: string) =>
  fullPdfFieldName.split('_').slice(0, 3).join('_');

const getOutOfBounds = (element: HTMLElement) =>
  element.scrollWidth > element.clientWidth;

// doesn't work currently due some race rerender conditions, but we may think of
// make it works
// const fitText = (element: HTMLElement) => {
//   const reducer = el => {
//     const fontSize = window.getComputedStyle(element).fontSize;
//     element.style.fontSize = parseFloat(fontSize) - 0.5 + 'px';
//     console.log('reducer', el.value, fontSize);
//     if (getOutOfBounds(el)) {
//       return reducer(el);
//     }
//     return fontSize;
//   };

//   if (getOutOfBounds(element)) {
//     return reducer(element);
//   }
//   return window.getComputedStyle(element).fontSize;
// };

export const PDFReviewPage: FC<Props> = ({
  reviewByPdfFieldName,
  pageNumber,
  wrapperRef,
  reviewFieldPrefixes,
  isReviewing,
  isReviewsVisible,
  insuranceCaseId,
}) => {
  const pdfPageRef = useRef<any>();
  const [annotationsParams, setAnnotationParams] = useState<
    {
      height: number;
      id: string;
      left: number;
      pdfFieldName: string;
      pdfFieldNameFull: string;
      shouldShowTooltip: boolean;
      top: number;
      type: string;
      value: Maybe<string | boolean>;
      width: number;
    }[]
  >([]);

  const setAnnotations = () => {
    const wrapperRefRect = wrapperRef.current?.getBoundingClientRect();
    const sections = pdfPageRef.current?.getElementsByTagName('section');

    const params = Array.from(sections)
      .filter((section: any) =>
        reviewFieldPrefixes.some(prefix =>
          section.childNodes[0].name.startsWith(prefix),
        ),
      )
      .map((section: any) => {
        const sectionRect = section.getBoundingClientRect();
        const input = section.childNodes[0];

        return {
          height: sectionRect.height,
          id: input.id,
          left: sectionRect.left - wrapperRefRect.left,
          pdfFieldName: getBasePdfFieldName(input.name),
          pdfFieldNameFull: input.name,
          shouldShowTooltip: getOutOfBounds(input) && input.type === 'text',
          top: sectionRect.top - wrapperRefRect.top,
          type: input.type,
          value: input.type === 'text' ? input.value : input.checked,
          width: sectionRect.width,
        };
      });

    const values = params.reduce(
      (acc, { pdfFieldNameFull, value }) => ({
        ...acc,
        [pdfFieldNameFull]: value,
      }),
      {},
    );
    const blockedFieldNames = getBlockedReviewPDFFieldNames(values);

    setAnnotationParams(
      params.filter(
        ({ pdfFieldNameFull }) => !blockedFieldNames.includes(pdfFieldNameFull),
      ),
    );
  };

  // todo add debounce?
  useResize(setAnnotations);

  return (
    <>
      <PDFPage
        width={wrapperRef.current?.getBoundingClientRect().width}
        loading={() => null}
        renderAnnotationLayer
        renderTextLayer={false}
        inputRef={pdfPageRef}
        renderInteractiveForms
        onGetAnnotationsSuccess={setAnnotations}
        pageNumber={pageNumber}
      />

      {isReviewsVisible &&
        annotationsParams.map(
          ({
            height,
            id: inputId,
            left,
            pdfFieldName,
            top,
            shouldShowTooltip,
            width: annotWidth,
            value,
            type,
          }) => {
            const rawPdfFieldValue =
              type === 'checkbox'
                ? annotationsParams
                    .filter(
                      ({
                        pdfFieldNameFull,
                        type: fieldType,
                        value: fieldValue,
                      }) =>
                        fieldType === 'checkbox' &&
                        pdfFieldNameFull.startsWith(pdfFieldName) &&
                        !!fieldValue,
                    )
                    .map(({ pdfFieldNameFull }) => pdfFieldNameFull)
                : value;

            return (
              <FieldReview
                isDisabled={!isReviewing}
                value={value}
                rawPdfFieldValue={rawPdfFieldValue as any}
                isTooltipShown={shouldShowTooltip}
                insuranceCaseId={insuranceCaseId}
                height={height}
                id={inputId}
                pdfFieldType={type}
                fieldReview={reviewByPdfFieldName[pdfFieldName]}
                key={inputId}
                left={left}
                pdfFieldName={pdfFieldName}
                top={top}
                width={annotWidth}
              />
            );
          },
        )}
    </>
  );
};
