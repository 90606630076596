import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { Page } from '@components/Page';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { GQL } from '@queries';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { Toggle } from '@components/Toggle';
import { CreateInsuranceCaseForm } from '@components/forms/CreateInsuranceCaseForm';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router';
import { ROUTES } from '@common/routes';
import { AdvertiserInsuranceCasesTable } from '@pages/AdvertiserApplicantsPage/AdvertiserInsuranceCasesTable';
import {
  AdvertiserStatus,
  InsuranceCaseStatus,
} from '../../../types/globalTypes';

interface Props {
  status: AdvertiserStatus;
}

export const AdvertiserApplicantsPage: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  return (
    <Page title={t('pageTitle.applicants')}>
      <Container>
        <HeaderBreadcrumbs
          heading={t('applicantsList')}
          links={[]}
          action={
            <Toggle>
              {([isEnabled, { toggleOn, toggleOff }]) => (
                <Box mx={1}>
                  <Button
                    variant="contained"
                    onClick={toggleOn}
                    disabled={status !== AdvertiserStatus.APPROVED}
                    startIcon={<Icon icon={plusFill} />}
                  >
                    {t('addApplication')}
                  </Button>
                  <Dialog
                    fullScreen={isMobile}
                    PaperProps={{
                      sx: {
                        m: {
                          xs: 0,
                        },
                      },
                    }}
                    open={isEnabled}
                    // maxWidth="sm"
                    fullWidth={true}
                    onClose={toggleOff}
                  >
                    <DialogTitle sx={{ mb: 2 }}>
                      {t('inviteApplicantByAdvertiserForm.title')}
                    </DialogTitle>
                    <DialogContent>
                      <CreateInsuranceCaseForm
                        onDismiss={() => toggleOff()}
                        onComplete={({ id, status: caseStatus }) => {
                          toggleOff();
                          client.refetchQueries({
                            include: [GQL.INSURANCE_CASE.INSURANCE_CASES_PAGED],
                          });
                          if (caseStatus === InsuranceCaseStatus.Draft) {
                            navigate(
                              ROUTES.ADVERTISER.insuranceCaseDraft({
                                insuranceCaseId: id,
                              }),
                            );
                          } else {
                            navigate(
                              ROUTES.ADVERTISER.insuranceCaseDetails({
                                insuranceCaseId: id,
                              }),
                            );
                          }
                        }}
                      />
                    </DialogContent>
                  </Dialog>
                </Box>
              )}
            </Toggle>
          }
        />
        <Paper elevation={3}>
          <AdvertiserInsuranceCasesTable />
        </Paper>
      </Container>
    </Page>
  );
};
