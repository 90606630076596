import React, { FC, useMemo, useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  StandardTextFieldProps,
} from '@mui/material';
import { Maybe } from '@shared/types';
import { IMaskMixin } from 'react-imask';
import { Form } from 'react-final-form';
import { makeValidate, TextField } from '@common/forms/fields';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  UpdateAdvertiser,
  UpdateAdvertiserVariables,
} from '@queries/types/UpdateAdvertiser';
import { GQL } from '@queries';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import EuroIcon from '@mui/icons-material/Euro';

interface Props {
  provision: Maybe<string>;
  advertiserId: string;
  mainAdvertiserProvision: Maybe<string>;
}

export const UnderAdvertiserProvisionCell: FC<Props> = ({
  provision,
  advertiserId,
  mainAdvertiserProvision,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const confirm = useConfirm();

  const initialValues = useMemo(() => ({ provision }), [provision]);
  const [update, { loading }] = useMutation<
    UpdateAdvertiser,
    UpdateAdvertiserVariables
  >(GQL.ADVERTISER.UPDATE);

  const onSubmit = async (values, form) => {
    const { value, pristine } = form.getFieldState('provision');
    // fragile :)
    if (!value.match(/\.\d{2}/)) {
      form.change('provision', parseFloat(value).toFixed(2));
    }
    if (pristine || !parseFloat(value)) {
      return;
    }
    confirm({ description: t('underWillNeedToResignAddendum') })
      .then(async () => {
        try {
          await update({
            variables: {
              advertiserId,
              input: {
                provision: (+values.provision).toFixed(2),
              },
            },
          });
          enqueueSnackbar(t('text.advertiserCommissionRateHasBeenUpdated'), {
            variant: 'success',
          });
        } catch (error: any) {
          const label = getErrorLabelTranslated(error, t) || t('error.generic');
          enqueueSnackbar(label, { variant: 'error' });
        }
      })
      .catch(() => setTimeout(form.reset, 1));
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          provision: Yup.number()
            .required(t('validation.required'))
            .min(0)
            .max(
              Number(mainAdvertiserProvision ?? 0),
              t('validation.maxCommissionRateCouldNotBeMoreThanMainHolding'),
            ),
        }) as any,
      ),
    [t],
  ) as any;

  const InputProps: Partial<StandardTextFieldProps['InputProps']> = {
    endAdornment: loading && (
      <InputAdornment position="end">
        <CircularProgress color="primary" size={16} />
      </InputAdornment>
    ),
    onClick: () => (!isEditing ? setIsEditing(true) : null),
    readOnly: !isEditing,
    startAdornment: (
      <InputAdornment position="start">
        <EuroIcon fontSize="small" color="action" />
      </InputAdornment>
    ),
  };

  const MaskedInput = IMaskMixin((props: any) => <TextField {...props} />);

  return (
    <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{ pristine: true, submitting: true }}
        validate={validate}
      >
        {({ handleSubmit }) => (
          <MaskedInput
            mask="0[0][0].00"
            radix="."
            placeholder="00.00"
            disabled={loading}
            onBlur={handleSubmit}
            variant="standard"
            name="provision"
            label={null}
            InputProps={InputProps}
          />
        )}
      </Form>
    </Stack>
  );
};
