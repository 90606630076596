import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Stack, Typography } from '@mui/material';
import { DatePicker } from '@common/forms/fields';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { useTranslation } from 'react-i18next';
import { StepComponent } from '@components/forms/types';
import { AnySchema } from 'yup';
import { StepTitle } from '@components/forms';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_studyEndDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
    main_0_studyStartDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
  });

export const StudyInformationStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.studyInformation')}</Typography>
      </StepTitle>

      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <DatePicker
          name="main_0_studyStartDate"
          label={getLabel('main_0_studyStartDate', t)}
        />
        <DatePicker
          name="main_0_studyEndDate"
          label={getLabel('main_0_studyEndDate', t)}
        />
      </Stack>
    </Stack>
  );
};

StudyInformationStep.getValidationSchema = getValidationSchema;
