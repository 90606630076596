import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import * as Yup from 'yup';
import { StepComponent, StepMeta } from '@components/forms/types';
import { MenuItem, Stack, Typography } from '@mui/material';
import { DatePicker, Select, TextField } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { InsuranceType } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction, { idx }: StepMeta = {}): AnySchema =>
  Yup.object().shape({
    [`child_${idx}_hasPreviousInsurance`]: Yup.boolean()
      .nullable()
      .required(t('validation.required')),
    [`child_${idx}_previousInsuranceType`]: Yup.string().nullable(),
    [`child_${idx}_previousInsuranceFamilyMemberFirstName`]: Yup.string()
      .nullable()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters')),
    [`child_${idx}_previousInsuranceFamilyMemberLastName`]:
      Yup.string().nullable(),
    [`child_${idx}_previousInsuranceStillExist`]: Yup.boolean().nullable(),
    [`child_${idx}_previousInsuranceProviderName`]: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    [`child_${idx}_previousInsuranceEndDate`]: Yup.string().nullable(),
  });

const getActiveFields = (values, { idx }: StepMeta = {}) => {
  const previousInsuranceType = values[`child_${idx}_previousInsuranceType`];
  const hasPreviousInsurance =
    values[`child_${idx}_hasPreviousInsurance`] === true;
  const isPreviousInsuranceMember =
    previousInsuranceType === InsuranceType.FamilyMember;
  const isPreviousInsuranceExpired =
    values[`child_${idx}_previousInsuranceStillExist`] === false;
  return {
    [`child_${idx}_previousInsuranceType`]: hasPreviousInsurance,
    [`child_${idx}_previousInsuranceFamilyMemberFirstName`]:
      isPreviousInsuranceMember,
    [`child_${idx}_previousInsuranceFamilyMemberLastName`]:
      isPreviousInsuranceMember,
    [`child_${idx}_previousInsuranceStillExist`]:
      hasPreviousInsurance && previousInsuranceType,
    [`child_${idx}_previousInsuranceProviderName`]:
      hasPreviousInsurance && previousInsuranceType,
    [`child_${idx}_previousInsuranceEndDate`]:
      hasPreviousInsurance && isPreviousInsuranceExpired,
  };
};

export const ChildPreviousInsuranceStep: StepComponent = ({ meta }) => {
  const idx = meta?.idx;
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.childPreviousInsurance')}
        </Typography>
      </StepTitle>
      <QuestionField
        name={`child_${idx}_hasPreviousInsurance`}
        question={getLabel(`child_${idx}_hasPreviousInsurance`, t, { isDraft })}
      />
      <ConditionalFields fieldNames={[`child_${idx}_previousInsuranceType`]}>
        <Select
          name={`child_${idx}_previousInsuranceType`}
          label={getLabel(`child_${idx}_previousInsuranceType`, t, { isDraft })}
        >
          <MenuItem value={InsuranceType.MainMember}>
            {t('membership')}
          </MenuItem>
          <MenuItem value={InsuranceType.FamilyMember}>
            {t('familyInsurance')}
          </MenuItem>
          <MenuItem value={InsuranceType.NotStatutory}>
            {t('notStatutory')}
          </MenuItem>
        </Select>
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          `child_${idx}_previousInsuranceFamilyMemberFirstName`,
          `child_${idx}_previousInsuranceFamilyMemberLastName`,
        ]}
      >
        <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
          <TextField
            name={`child_${idx}_previousInsuranceFamilyMemberFirstName`}
            label={getLabel(
              `child_${idx}_previousInsuranceFamilyMemberFirstName`,
              t,
            )}
          />
          <TextField
            name={`child_${idx}_previousInsuranceFamilyMemberLastName`}
            label={getLabel(
              `child_${idx}_previousInsuranceFamilyMemberFirstName`,
              t,
            )}
          />
        </Stack>
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          `child_${idx}_previousInsuranceStillExist`,
          `child_${idx}_previousInsuranceProviderName`,
        ]}
      >
        <QuestionField
          name={`child_${idx}_previousInsuranceStillExist`}
          question={getLabel(`child_${idx}_previousInsuranceStillExist`, t)}
        />
        <TextField
          name={`child_${idx}_previousInsuranceProviderName`}
          label={getLabel(`child_${idx}_previousInsuranceProviderName`, t)}
        />
      </ConditionalFields>
      <ConditionalFields fieldNames={[`child_${idx}_previousInsuranceEndDate`]}>
        <DatePicker
          name={`child_${idx}_previousInsuranceEndDate`}
          label={getLabel(`child_${idx}_previousInsuranceEndDate`, t)}
        />
      </ConditionalFields>
    </Stack>
  );
};

ChildPreviousInsuranceStep.getValidationSchema = getValidationSchema;
ChildPreviousInsuranceStep.getActiveFields = getActiveFields;
