import { FC, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@common/hooks';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { EMAIL_REGEX, LATIN_REG_EXP } from '@shared/regex';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { TextField } from '@common/forms/fields';
import {
  compose,
  removeSpaces,
  toLowerCase,
} from '@common/forms/normalization';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { GQL } from '@queries';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { useSnackbar } from 'notistack';

export const InviteAdvertiserMemberButton: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpened, { toggleOn: openDialog, toggleOff: closeDialog }] =
    useToggle();
  const [inviteAdvertiserMember] = useMutation(GQL.ADVERTISER.INVITE_MEMBER);
  const { advertiserId } = useCurrentUser();

  const onSubmit = async values => {
    try {
      await inviteAdvertiserMember({
        variables: {
          advertiserId,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      });
      enqueueSnackbar(t('inviteSent'), { variant: 'success' });
      closeDialog();
    } catch (error: any) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          email: Yup.string()
            .matches(EMAIL_REGEX, t('validation.validEmail'))
            .required(t('validation.required')),
          firstName: Yup.string().matches(
            LATIN_REG_EXP,
            t('validation.onlyLatinCharacters'),
          ),
          lastName: Yup.string().matches(
            LATIN_REG_EXP,
            t('validation.onlyLatinCharacters'),
          ),
          // .required(t('validation.required')),
        }) as any,
      ),
    [t],
  );

  return (
    <>
      <Button
        variant="contained"
        onClick={openDialog}
        startIcon={<Icon icon={plusFill} />}
      >
        {t('inviteAdvertiserMember')}
      </Button>
      <Dialog open={isDialogOpened}>
        <DialogTitle sx={{ mb: 2 }}>
          {t('inviteAdvertiserMemberDialog.title')}
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={onSubmit} validate={validate}>
            {({ submitting, handleSubmit, values }) => (
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack gap={2} direction="row">
                  <TextField
                    autoFocus
                    name="firstName"
                    margin="dense"
                    label={t('firstName')}
                  />
                  <TextField
                    autoFocus
                    name="lastName"
                    margin="dense"
                    label={t('lastName')}
                  />
                </Stack>
                <TextField
                  name="email"
                  margin="dense"
                  type="email"
                  label={t('email')}
                  parse={compose(removeSpaces, toLowerCase)}
                />
                <Stack sx={{ mt: 2 }} direction="row" gap={2}>
                  <Button onClick={closeDialog} disabled={submitting}>
                    {t('cancel')}
                  </Button>
                  <LoadingButton
                    loading={submitting}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {t('invite')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
