import { GQL } from '@queries';
import { Column, EntityTable } from '@components/EntityTable';
import React, { FC } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { GetAdvertiserMembersPaged_advertiserMembersPaged_edges_node as AdvertiserMember } from '@queries/types/GetAdvertiserMembersPaged';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteAdvertiserMemberAction } from '@pages/AdvertiserDetailsPage/DeleteAdvertiserMemberAction';

const getColumns = ({
  t,
  advertiserPublicId,
}: {
  t: TFunction;
  advertiserPublicId: string;
}): Column<AdvertiserMember>[] => [
  {
    id: 'publicId',
    isSortable: true,
    label: t('advertiserPublicId'),
    renderCell: ({ data: { publicId } }) => `${advertiserPublicId}-${publicId}`,
  },
  {
    id: 'email',
    isSortable: true,
    label: t('email'),
    renderCell: ({ data: { email } }) => email,
  },
  {
    id: 'firstName',
    isSortable: true,
    label: t('fullName'),
    renderCell: ({ data: { firstName, lastName } }) =>
      [firstName, lastName].join(' ') || '-',
  },
  {
    id: 'casesCount',
    isSortable: true,
    label: t('casesCount'),
    renderCell: ({ data: { casesCount } }) => casesCount,
  },
  {
    id: 'actions',
    isSortable: false,
    label: t('actions'),
    renderCell: ({ data: { id } }) => (
      <DeleteAdvertiserMemberAction advertiserMemberId={id}>
        {({ onDeleteClick, isDeleting }) => (
          <IconButton
            onClick={event => {
              event.stopPropagation();
              onDeleteClick();
            }}
            disabled={isDeleting}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </DeleteAdvertiserMemberAction>
    ),
  },
];

interface Props {
  publicId: string;
  advertiserId: string;
}

export const AdvertiserMembersTable: FC<Props> = ({
  publicId,
  advertiserId,
}) => {
  const { t } = useTranslation();
  return (
    <EntityTable<AdvertiserMember>
      query={GQL.ADVERTISER.MEMBERS_PAGED}
      columns={getColumns({ advertiserPublicId: publicId, t })}
      baseQueryOptions={{
        variables: {
          advertiserId,
          orderBy: 'createdAt',
          orderDirection: 'Desc',
        },
      }}
    />
  );
};
