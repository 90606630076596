import React, { FC } from 'react';
import { Page } from '@components/Page';
import { useTranslation } from 'react-i18next';
import { Container, Paper } from '@mui/material';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { AdvertiserInsuranceCasesTable } from '@pages/AdvertiserApplicantsPage/AdvertiserInsuranceCasesTable';
import { useParams } from 'react-router';

export const AdvertiserRefLinkDetailsPage: FC = () => {
  const { t } = useTranslation();
  const { refLinkId } = useParams();

  return (
    <Page title={t('pageTitle.applicantsList')}>
      <Container>
        <HeaderBreadcrumbs heading={t('pageTitle.applicantsList')} links={[]} />
        <Paper elevation={3}>
          <AdvertiserInsuranceCasesTable
            advertiserRefLinkIds={[refLinkId as string]}
          />
        </Paper>
      </Container>
    </Page>
  );
};
