import {
  FormWrapperProps,
  RejectedDocuments,
  RejectedFields,
} from '@components/forms/types';
import { FormRejectionsSidebar } from '@components/forms/FormRejectionsSidebar/FormRejectionsSidebar';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { PoaStatus } from '@shared/constants';

const APP_BAR_DESKTOP = 92;
const FORM_SIDEBAR_LENGTH = 320;

interface StyledProps {
  isRejected: boolean;
}

const MainStyle = styled('div', {
  shouldForwardProp: isPropValid,
})<StyledProps>(({ theme, isRejected }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  overflow: 'hidden',
  paddingBottom: theme.spacing(10),
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.easeInOut,
  }),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: isRejected ? 0 : `${FORM_SIDEBAR_LENGTH}px`,
    paddingTop: APP_BAR_DESKTOP + 24,
    // paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(4),
    // paddingLeft: parseInt(theme.spacing(8)),
    // paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(2),
  },
}));

interface ApplicationFormWrapperProps {
  rejectedFields: Map<string[], RejectedFields>;
  rejectedDocuments: Record<string, RejectedDocuments>;
}

export const ApplicationFormWrapper: FC<FormWrapperProps> = ({
  children,
  context,
}) => {
  const { currentStep, formValues } = context;
  const { rejectedFields, rejectedDocuments }: ApplicationFormWrapperProps =
    context as any;
  const currentStepRejections = [
    ...rejectedFields.entries(),
    ...(Object.entries(rejectedDocuments).map(([documentName, meta]) => [
      [documentName],
      meta,
    ]) as any),
  ].filter(([fieldsGroup]: [string[]]) => {
    return fieldsGroup.some(
      groupFieldName =>
        (groupFieldName.includes('powerOfAttorney') &&
          formValues.poaStatus === PoaStatus.Generating) ||
        currentStep?.stepFieldNames?.includes(groupFieldName),
    );
  });

  return (
    <MainStyle isRejected={!!currentStepRejections.length}>
      {children}
      {!!currentStepRejections.length && (
        <FormRejectionsSidebar currentStepRejections={currentStepRejections} />
      )}
    </MainStyle>
  );
};
