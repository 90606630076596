import React, { FC, useState } from 'react';
import { Box, Container, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { AdvertiserSignAgreement } from '@pages/AdvertiserDocumentsPage/AdvertiserSignAgreement';
import { styled } from '@mui/material/styles';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { Page } from '@src/components';
import { useCurrentUser } from '@common/hooks/useCurrentUser';
import { AdvertiserPaymentsTable } from '@pages/AdvertiserDocumentsPage/AdvertiserPaymentsTable';

enum TabName {
  General = 'General',
  Billing = 'Billing',
}

const TabHeader = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const TabPanelStyled = styled(TabPanel)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
}));

export const AdvertiserDocumentsPage: FC = () => {
  const { t } = useTranslation();
  const { advertiserId } = useCurrentUser();
  const [tabIndex, setTabIndex] = useState(TabName.General);
  const handleTabChange = (event: unknown, index: TabName) => {
    setTabIndex(index);
  };

  return (
    <Page title={t('pageTitle.documents')}>
      <Container>
        <HeaderBreadcrumbs heading={t('pageTitle.documents')} links={[]} />
        <Paper elevation={3}>
          <TabContext value={tabIndex}>
            <TabHeader>
              <TabList onChange={handleTabChange}>
                <Tab label={t('general')} value={TabName.General} />
                <Tab label={t('billing')} value={TabName.Billing} />
              </TabList>
            </TabHeader>
            {/*todo: routes*/}
            {/*todo: move that to separate page and display it by the condition in the AdvertiserRoutes.tsx*/}
            <TabPanelStyled value={TabName.General}>
              <AdvertiserSignAgreement />
            </TabPanelStyled>
            <TabPanel value={TabName.Billing} sx={{ pt: 1 }}>
              <AdvertiserPaymentsTable advertiserId={advertiserId as string} />
            </TabPanel>
          </TabContext>
        </Paper>
      </Container>
    </Page>
  );
};
