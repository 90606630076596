import { SignaturePad } from '@components/SignaturePad';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useClipboard } from '@hooks/useClipboard';
import { FC, useMemo, useState } from 'react';
import { Maybe } from '@shared/types';
import { GQL } from '@queries';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useToggle } from '@hooks/useToggle';
import { Form } from 'react-final-form';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeValidate } from 'mui-rff';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import {
  compose,
  removeSpaces,
  toLowerCase,
} from '@common/forms/normalization';
import { TextField } from '@common/forms/fields';
import { useMutation } from '@apollo/client';
import { EMAIL_REGEX } from '@shared/regex';
import { DocumentKeyInput } from '../../types/globalTypes';

const SignedContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.grey[200],
  borderRadius: 12,
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  fontSize: '86px',
  height: 200,
  justifyContent: 'center',
  maxWidth: 400,
  width: '100%',
}));

interface Props {
  documentKey?: DocumentKeyInput;
  fullName?: string;
  isSigned?: boolean;
  onSubmitStart?: () => any;
  onSubmitted?: () => any;
  showMoreOptions?: boolean;
  slotNames: string[];
  token?: string;
  locked?: boolean;
  disabled?: boolean;
}

export const SignatureForm: FC<Props> = ({
  documentKey,
  fullName,
  isSigned,
  onSubmitStart,
  onSubmitted,
  showMoreOptions,
  slotNames,
  token,
  locked,
  disabled,
}) => {
  if (!token && !documentKey) {
    throw new Error('token or documentKey should be provided!');
  }

  if (token && documentKey) {
    throw new Error('Either token or documentKey should be provided!');
  }

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const copyToClipboard = useClipboard();
  const [imageData, setImageData] = useState<Maybe<string>>(null);
  const [
    isEmailDialogOpened,
    { toggleOn: openEmailDialog, toggleOff: closeDialog },
  ] = useToggle(false);
  const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] =
    useState<Maybe<HTMLButtonElement>>(null);

  const openMoreOptionsMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMoreOptionsMenuAnchorEl(event.currentTarget);
  const closeMoreOptionsMenu = () => {
    setMoreOptionsMenuAnchorEl(null);
  };

  const closeEmailDialog = () => {
    closeDialog();
    setMoreOptionsMenuAnchorEl(null);
  };

  const [sign, { loading: isSigning }] = useMutation(GQL.DOCUMENT.SIGN);
  const [signShared, { loading: isSharedSigning }] = useMutation(
    GQL.DOCUMENT.SIGN_SHARED,
  );
  const [getSignSharedLink, { loading: isSignSharedLinkLoading }] = useMutation(
    GQL.DOCUMENT.GET_SIGN_SHARED_LINK,
  );
  const [sendSignSharedEmail, { loading: isEmailSending }] = useMutation(
    GQL.DOCUMENT.SEND_SIGN_SHARED_EMAIL,
  );

  const onSubmit = async () => {
    onSubmitStart?.();
    if (token) {
      await signShared({
        variables: {
          signatureBase64: imageData,
          slotNames,
          token,
        },
      });
    } else {
      await sign({
        variables: {
          documentKey,
          signatureBase64: imageData,
          slotNames,
        },
      });
    }

    onSubmitted?.();
  };

  const onCopySignSharedLinkClick = async () => {
    try {
      const res = await getSignSharedLink({
        variables: {
          documentKey,
          slotsInfo: [
            {
              slotAssigneeName: fullName,
              slotNames,
            },
          ],
        },
      });

      const {
        data: {
          documentMutations: { getSignSharedLink: url },
        },
      } = res;

      copyToClipboard(url);
      enqueueSnackbar(
        t('signSharedDocumentLinkCopied', {
          url,
        }),
        { variant: 'success' },
      );
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const onSendSignSharedEmailSubmit = async ({ email }, form) => {
    try {
      await sendSignSharedEmail({
        variables: {
          documentKey,
          slotsInfo: [
            {
              slotAssigneeName: fullName,
              slotNames,
            },
          ],
          to: email,
        },
      });
      form.reset();
      closeEmailDialog();

      enqueueSnackbar(t('signSharedDocumentEmailSent'), { variant: 'success' });
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          email: Yup.string()
            .matches(EMAIL_REGEX, t('validation.validEmail'))
            .required(t('validation.required')),
        }) as any,
      ),
    [t],
  );

  return (
    <Box sx={{ maxWidth: 400 }}>
      {fullName && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
          sx={{ height: 28 * 2 }}
        >
          <Typography variant="h6" sx={{ mb: 1 }}>
            {t('fullNameSignature', { fullName })}
          </Typography>
        </Stack>
      )}
      {isSigned && (
        <SignedContainer>
          <CheckCircleOutlineIcon color="inherit" fontSize="inherit" />
          <Typography>{t('signed')}</Typography>
        </SignedContainer>
      )}
      {!isSigned && (
        <>
          <SignaturePad onChange={setImageData} />
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item>
              <LoadingButton
                disabled={!imageData || disabled}
                variant="contained"
                loading={isSigning || isSharedSigning || locked}
                onClick={onSubmit}
              >
                {t('sign')}
              </LoadingButton>
            </Grid>
            {showMoreOptions && (
              <Grid item>
                <LoadingButton
                  loading={isSignSharedLinkLoading}
                  onClick={openMoreOptionsMenu}
                >
                  {t('moreOptions')}
                </LoadingButton>
                <Menu
                  anchorEl={moreOptionsMenuAnchorEl}
                  onClose={closeMoreOptionsMenu}
                  open={!!moreOptionsMenuAnchorEl}
                >
                  <MenuItem
                    onClick={() => {
                      onCopySignSharedLinkClick();
                      closeMoreOptionsMenu();
                    }}
                  >
                    {t('copyLink')}
                  </MenuItem>
                  <MenuItem onClick={openEmailDialog}>
                    {t('sendToEmail')}
                  </MenuItem>
                  <Dialog
                    fullWidth
                    onClose={() => !isEmailSending && closeEmailDialog()}
                    open={isEmailDialogOpened}
                  >
                    <DialogTitle>{t('signShareDialog.title')}</DialogTitle>
                    <Form
                      initialValues={{ email: '' }}
                      onSubmit={onSendSignSharedEmailSubmit}
                      validate={validate}
                    >
                      {({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <DialogContentText>
                              {t('signShareDialog.prompt')}
                            </DialogContentText>
                            <Box sx={{ mt: 1 }}>
                              <TextField
                                disabled={submitting}
                                label={t('email')}
                                name="email"
                                type="email"
                                parse={compose(removeSpaces, toLowerCase)}
                              />
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              disabled={isEmailSending}
                              onClick={closeEmailDialog}
                            >
                              {t('cancel')}
                            </Button>
                            <LoadingButton
                              disabled={submitting}
                              color="primary"
                              variant="text"
                              type="submit"
                              loading={submitting}
                            >
                              {t('send')}
                            </LoadingButton>
                          </DialogActions>
                        </form>
                      )}
                    </Form>
                  </Dialog>
                </Menu>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};
