export const getConditionalArr = <TArray extends any[]>(
  condition: boolean,
  arrOrGetArr: TArray | (() => TArray),
): TArray | any[] => {
  if (!condition) {
    return [];
  }

  return typeof arrOrGetArr === 'function' && condition
    ? arrOrGetArr()
    : (arrOrGetArr as TArray);
};
