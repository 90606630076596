import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

// todo more all badges to some generic component
export const DocumentNewBadge: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="subtitle2"
      component="div"
      noWrap
      sx={{
        alignSelf: 'flex-start',
        background: theme => theme.palette.warning.lighter,
        borderRadius: theme => theme.spacing(0.5),
        color: theme => theme.palette.warning.dark,
        display: 'inline-block',
        fontSize: 12,
        mr: 2,
        padding: theme => theme.spacing('1px', 1),
        paddingX: 1,
        textOverflow: 'ellipsis',
        textTransform: 'none',
      }}
    >
      {t('documentReview.newDocument')}
    </Typography>
  );
});
