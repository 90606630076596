import React, { FC } from 'react';
import { Page } from '@components/Page';
import { useTranslation } from 'react-i18next';
import { Container, Paper } from '@mui/material';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { AdvertiserMembersTable } from '@pages/AdvertiserMembersPage/AdvertiserMembersTable';
import { InviteAdvertiserMemberButton } from '@components/InviteAdvertiserMemberButton';

interface Props {
  publicId: string;
  advertiserId: string;
}

export const AdvertiserMembersPage: FC<Props> = ({
  publicId,
  advertiserId,
}) => {
  const { t } = useTranslation();

  return (
    <Page title={t('pageTitle.advertiserMembers')}>
      <Container>
        <HeaderBreadcrumbs
          action={<InviteAdvertiserMemberButton />}
          heading={t('advertiserMembersList')}
          links={[]}
        />
        <Paper elevation={3}>
          <AdvertiserMembersTable
            advertiserId={advertiserId}
            publicId={publicId}
          />
        </Paper>
      </Container>
    </Page>
  );
};
