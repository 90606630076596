import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  Stack,
  StandardTextFieldProps,
} from '@mui/material';
import { GQL } from '@queries';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { GetAdvertiser_advertiser } from '@queries/types/GetAdvertiser';
import { Form } from 'react-final-form';
import { TextField } from '@common/forms/fields';
import { useSnackbar } from 'notistack';
import {
  UpdateAdvertiserProfile,
  UpdateAdvertiserProfile_advertiserMutations_updateProfile as AdvertiserUpdateResult,
} from '@queries/types/UpdateAdvertiserProfile';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import {
  compose,
  removeSpaces,
  toLowerCase,
} from '@common/forms/normalization';
import { useConfirm } from 'material-ui-confirm';
import { LATIN_REG_EXP, PHONE_REGEX } from '../../../../../../shared/regex';

type Props = {
  advertiserId: string;
  initialValues?: Partial<GetAdvertiser_advertiser>;
  onComplete?: (data: AdvertiserUpdateResult) => void;
};
export const UpdateAdvertiserProfileForm: React.FC<Props> = ({
  advertiserId,
  initialValues,
  onComplete,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [update] = useMutation<UpdateAdvertiserProfile>(
    GQL.ADVERTISER.UPDATE_PROFILE,
  );
  const onSubmit = async values => {
    const { name, phone, address, contactPerson } = values;
    confirm({ description: t('willNeedToSignAddendum') }).then(async () => {
      try {
        const { data } = await update({
          variables: {
            advertiserId,
            input: {
              address: {
                country: address.country,
                houseNumber: address.houseNumber,
                place: address.place,
                streetName: address.streetName,
                zipCode: address.zipCode,
              },
              contactPerson: {
                firstName: contactPerson.firstName,
                lastName: contactPerson.lastName,
              },
              name,
              phone,
            },
          },
        });
        if (data) {
          onComplete?.(data.advertiserMutations.updateProfile);
        }
        enqueueSnackbar(t('successfullyUpdated'), {
          variant: 'success',
        });
        setIsEditing(false);
      } catch (error) {
        const label = getErrorLabelTranslated(error, t) || t('error.generic');
        enqueueSnackbar(label, { variant: 'error' });
      }
    });
  };

  const InputProps: Partial<StandardTextFieldProps['InputProps']> = {
    disableUnderline: !isEditing,
    onClick: () => (!isEditing ? setIsEditing(true) : null),
    readOnly: !isEditing,
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          address: Yup.object().shape({
            country: Yup.string()
              .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
              .required(t('validation.required')),
            houseNumber: Yup.string()
              .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
              .required(t('validation.required')),
            place: Yup.string()
              .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
              .required(t('validation.required')),
            streetName: Yup.string()
              .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
              .required(t('validation.required')),
            zipCode: Yup.string()
              .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
              .required(t('validation.required')),
          }),
          contactPerson: Yup.object().shape({
            firstName: Yup.string()
              .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
              .required(t('validation.required')),
            lastName: Yup.string()
              .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
              .required(t('validation.required')),
          }),
          name: Yup.string()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
          phone: Yup.string()
            .matches(PHONE_REGEX, t('validation.invalidPhoneNumber'))
            .required(t('validation.required')),
        }) as any,
      ),
    [t],
  ) as any;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ pristine: true, submitting: true }}
      validate={validate}
    >
      {({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="name"
                label={t('companyName')}
                InputProps={InputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="phone"
                label={t('phone')}
                InputProps={InputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="email"
                label={t('email')}
                InputProps={InputProps}
                disabled={true}
                parse={compose(removeSpaces, toLowerCase)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CountrySelect
                name="address.country"
                label={t('country')}
                textFieldProps={{ InputProps, variant: 'standard' }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="address.zipCode"
                label={t('zipCode')}
                InputProps={InputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="address.place"
                label={t('place')}
                InputProps={InputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="address.streetName"
                label={t('streetName')}
                InputProps={InputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="address.houseNumber"
                label={t('houseNumber')}
                InputProps={InputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="contactPerson.firstName"
                label={t('firstName')}
                InputProps={InputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="contactPerson.lastName"
                label={t('lastName')}
                InputProps={InputProps}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            {isEditing ? (
              <Stack direction="row" spacing={2}>
                <Button
                  type="button"
                  onClick={() => {
                    form.reset();
                    setIsEditing(false);
                  }}
                >
                  {t('cancel')}
                </Button>
                <LoadingButton
                  loading={submitting}
                  disabled={pristine}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Stack>
            ) : (
              <Button
                type="button"
                variant="contained"
                onClick={() => setIsEditing(true)}
              >
                {t('edit')}
              </Button>
            )}
          </Box>
        </form>
      )}
    </Form>
  );
};
