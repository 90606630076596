import { useQuery } from '@apollo/client';
import { LoadingScreen } from '@components/LoadingScreen';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { Box, IconButton, LinearProgress, styled } from '@mui/material';
import { GQL } from '@queries';
import { InsuranceCaseFragment } from '@queries/types/InsuranceCaseFragment';
import { getConditionalArr } from '@shared/helpers';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import { Document, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { GetInsuranceCaseFieldReviewsWithSignedUrl } from '@queries/types/GetInsuranceCaseFieldReviewsWithSignedUrl';
import DownloadIcon from '@mui/icons-material/Download';
import { clickOnDownloadUrl } from '@utils/clickOnDownloadUrl';
import { DocumentsGeneratingStatusContext } from '@pages/InsuranceCaseDetailsPage/InsuranceCaseDetailsPage';
import isPropValid from '@emotion/is-prop-valid';
import { PDFReviewPage } from './PDFReviewPage';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PreviewContainerProps {
  isRegenerationInProcess: boolean;
}

const PreviewContainer = styled('div', {
  shouldForwardProp: isPropValid,
})<PreviewContainerProps>(({ isRegenerationInProcess }) => ({
  [`& .react-pdf__Page`]: {
    opacity: isRegenerationInProcess ? 0.5 : 1,
  },
  [`& div button`]: {
    opacity: 0,
  },
  [`&&&& input`]: {
    backgroundColor: 'transparent',
    border: 'none',
    pointerEvents: 'none',
    textOverflow: 'ellipsis',
  },
  [`&:hover div button`]: {
    opacity: 1,
  },
  alignItems: 'center',
  borderRadius: '4px',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const StyledWrapper = styled('div')`
  & button {
    opacity: 0;
  }
  &:hover button {
    opacity: 1;
  }
`;

const StyledButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(1),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest,
  }),
  zIndex: 1400,
}));

interface Props {
  insuranceCase: InsuranceCaseFragment;
  isReviewing: boolean;
  isReviewsVisible: boolean;
}

export const InsuranceCaseApplication: FC<Props> = ({
  insuranceCase,
  isReviewing,
  isReviewsVisible,
}) => {
  const wrapperRef = useRef<any>(null);
  const context = useContext(DocumentsGeneratingStatusContext);
  const isRegenerationInProcess = useMemo(
    () => context?.isRegenerationInProcess,
    [context],
  );
  const [pagesCount, setPagesCount] = useState(null);

  const { id: insuranceCaseId, documentContainerId, documents } = insuranceCase;
  const barmerApplicationDoc = documents.find(
    ({ documentName }) => documentName === 'barmerApplication',
  );

  const queryState = useQuery<GetInsuranceCaseFieldReviewsWithSignedUrl>(
    GQL.INSURANCE_CASE_REVIEW.FIELD_REVIEWS_WITH_SIGNED_URL,
    {
      skip: !barmerApplicationDoc?.id,
      variables: {
        documentKey: {
          documentContainerId,
          id: barmerApplicationDoc?.id,
        },
        insuranceCaseId,
      },
    },
  );

  const onDocumentLoadSuccess = ({ numPages }) => setPagesCount(numPages);

  return (
    <QueryLoaderComponent
      queryState={queryState}
      renderEmpty={() => <LoadingScreen />}
      renderLoading={() => <LoadingScreen />}
      renderData={({ signedUrl, insuranceCaseFieldReviews }) => {
        // todo move to own component to apply memo?
        const reviewByPdfFieldName = insuranceCaseFieldReviews.reduce(
          (acc, fieldReview) => ({
            ...acc,
            [fieldReview.pdfFieldName]: fieldReview,
          }),
          {},
        );

        return (
          <StyledWrapper
            sx={{
              height: '100%',
              position: 'relative',
            }}
          >
            {!isRegenerationInProcess && (
              <StyledButton onClick={() => clickOnDownloadUrl(signedUrl, true)}>
                <DownloadIcon />
              </StyledButton>
            )}
            <Box
              sx={{
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <PreviewContainer
                isRegenerationInProcess={!!context?.isRegenerationInProcess}
                ref={wrapperRef}
              >
                <Document
                  file={signedUrl}
                  loading={() => <LinearProgress sx={{ width: '100vw' }} />}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {isRegenerationInProcess && <LinearProgress />}
                  {Array.from(new Array(pagesCount), (el, index) => (
                    <PDFReviewPage
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      wrapperRef={wrapperRef}
                      reviewFieldPrefixes={[
                        'main_0_',
                        ...getConditionalArr(!!insuranceCase?.spouseProfile, [
                          'spouse_0_',
                        ]),
                        ...insuranceCase.childProfiles.map(
                          ({ index: childIndex }) => `child_${childIndex}_`,
                        ),
                      ]}
                      insuranceCaseId={insuranceCaseId}
                      reviewByPdfFieldName={reviewByPdfFieldName}
                      isReviewsVisible={isReviewsVisible}
                      isReviewing={isReviewing}
                    />
                  ))}
                </Document>
              </PreviewContainer>
            </Box>
          </StyledWrapper>
        );
      }}
    />
  );
};
