import * as Yup from 'yup';
import { IBANCodes } from '@components/forms/AdvertiserVerificationForm/IBANCodes';
import { TFunction } from 'i18next';
import { AnySchema } from 'yup';
import { LATIN_REG_EXP } from '@src/../../../shared/regex';

export const IBANCodeSchema = (t: TFunction): AnySchema =>
  Yup.string()
    .test({
      name: 'IBAN',
      test: (value, ctx) => {
        if (!value) return true;
        const alpha2 = value.slice(0, 2);
        const requiredLength = IBANCodes[alpha2];
        if (!requiredLength)
          return ctx.createError({
            message: t('invalidIBAN.countryCode'),
          });

        if (value.length !== requiredLength)
          return ctx.createError({
            message: t('invalidIBAN.length', { requiredLength }),
          });
        return true;
      },
    })
    .nullable()
    .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
    .required(t('validation.required'));
