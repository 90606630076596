import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { advertiserPaymentsPaged_advertiserPaymentsPaged_edges_node as AdvertiserPayment } from '@queries/types/advertiserPaymentsPaged';
import { OrderDirection } from '@shared/constants';
import { GQL } from '@queries';
import { Column, EntityTable } from '@components/EntityTable';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { DocumentSignedURL } from '@components/DocumentSignedURL';
import { DocumentPreviewModal } from '@components/DocumentPreviewModal';

import { styled, IconButton, Tooltip } from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[2],
  height: 34,
  width: 34,
}));

const getColumns = ({ t }: { t: TFunction }): Column<AdvertiserPayment>[] => [
  {
    id: 'finishedAt',
    isSortable: true,
    label: t('paidAt'),
    renderCell: ({ data: { finishedAt } }) =>
      DateTime.fromISO(finishedAt).toFormat('dd.MM.yyyy HH:mm'),
  },
  {
    id: 'amount',
    isSortable: true,
    label: t('total'),
    renderCell: ({ data: { totalAmount } }) => `€${totalAmount}`,
  },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    renderCell: ({ data: { invoiceDocument } }) => {
      return invoiceDocument ? (
        <DocumentSignedURL id={invoiceDocument.id}>
          {({ fetchURL, clearURL, url, loading }) => (
            <>
              <Tooltip title={t('downloadInvoice') as string}>
                <StyledIconButton
                  disabled={!invoiceDocument}
                  onClick={fetchURL}
                >
                  <CloudDownloadOutlinedIcon />
                </StyledIconButton>
              </Tooltip>
              {url && <DocumentPreviewModal url={url} onClose={clearURL} />}
            </>
          )}
        </DocumentSignedURL>
      ) : null;
    },
  },
];

type Props = {
  advertiserId: string;
};

export const AdvertiserPaymentsTable: FC<Props> = ({ advertiserId }) => {
  const { t } = useTranslation();

  return (
    <>
      <EntityTable<AdvertiserPayment>
        tableSize="small"
        defaultSortOptions={{
          orderBy: 'finishedAt',
          orderDirection: OrderDirection.Desc,
        }}
        isSearchEnabled={false}
        baseQueryOptions={{
          variables: {
            advertiserId,
          },
        }}
        query={GQL.ADVERTISER_PAYMENT.ADVERTISER_PAYMENTS_PAGED}
        columns={getColumns({
          t,
        })}
      />
    </>
  );
};
