import { ChangeEvent, FC, MouseEvent, ReactElement, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  Stack,
  Tooltip,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export interface FilterOptions {
  filterBy?: string;
  filterValues?: string[];
  filterView?: (value: any, t: TFunction) => string | number | ReactElement;
}

interface Props {
  filterBy: string;
  filterValues: string[];
  filterView?: (value: any, t: TFunction) => string | number | ReactElement;
  onCloseMenu: (filterOptions: FilterOptions) => void;
}

export const FilterMenu: FC<Props> = ({
  filterBy,
  filterValues,
  filterView,
  onCloseMenu,
}) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    if (filterBy && filterValues.length) {
      onCloseMenu({
        filterBy,
        filterValues: selectedValues,
      });
    }
  };

  const onSelectValue = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (event.target.checked) {
      setSelectedValues(prevState => [...prevState, value]);
    } else {
      setSelectedValues(prevState =>
        prevState.filter(selectedValue => selectedValue !== value),
      );
    }
  };

  return (
    <>
      <Tooltip title={t('tooltip.filter') as string}>
        <IconButton
          size="small"
          id="table-filter-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpenMenu}
        >
          <FilterListIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Menu
        id="table-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Stack spacing={1}>
            <FormGroup>
              {filterValues.map(value => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      checked={selectedValues.includes(value)}
                      onChange={e => onSelectValue(e, value)}
                    />
                  }
                  label={filterView ? filterView(value, t) : value}
                />
              ))}
            </FormGroup>
            {!!filterValues?.length && (
              <Button onClick={() => setSelectedValues([])}>
                {t('clear')}
              </Button>
            )}
          </Stack>
        </Box>
      </Menu>
    </>
  );
};
