import {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  ToolbarProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DocumentNode, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { OrderDirection } from '@shared/constants';
import { Maybe } from '@shared/types';
import { SearchInput } from '@components/SearchInput';
import { styled, useTheme } from '@mui/material/styles';
import { useDebounce } from '@hooks/useDebounce';
import { FilterOptions } from '@components/FilterMenu';
import { TFunction } from 'i18next';
import { TableHeader } from './TableHeader';

// todo: move them to gql helpers later on
const getNodes = (gqlConnection: any) =>
  gqlConnection.edges.map(({ node }) => node);

// todo rework -= very fragile
const getQueryName = query =>
  query.definitions[0].selectionSet.selections[0].name.value;

const StyledToolbar = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

export interface Column<TEntity> {
  align?: 'right' | 'left' | 'center';
  alignHeader?: 'right' | 'left' | 'center';
  disablePadding?: boolean;
  filterValues?: string[];
  filterView?: (value: any, t: TFunction) => string | number | ReactElement;
  renderFilterView?: ({
    anchorEl,
    closeFilterView,
    customQueryVariables,
    updateCustomQueryVariables,
  }: {
    anchorEl: Maybe<HTMLElement>;
    closeFilterView: () => void;
    customQueryVariables: Record<string, any>;
    updateCustomQueryVariables: (queryVariables: Record<string, any>) => void;
  }) => React.ReactNode;
  id: string;
  isSortable?: boolean;
  label: string;
  renderCell?: ({ data }: { data: TEntity }) => ReactNode;
  width?: number | string;
  isWrappable?: boolean;
}

export interface Props<TEntity> {
  baseQueryOptions?: Record<string, any>;
  defaultSortOptions?: {
    orderBy?: string;
    orderDirection?: OrderDirection;
  };
  columns: Column<TEntity>[];
  isSearchEnabled?: boolean;
  onRowClick?: ({ data }: { data: TEntity }) => void;
  pageSize?: number;
  progressVariant?: 'circular' | 'linear';
  query: DocumentNode;
  tableSize?: 'medium' | 'small';
  renderToolbar?: (searchValue: string, setSearchValue) => JSX.Element;
  toolbarProps?: ToolbarProps;
}

const getPropertyByPath = (path: string, value: any, defaultValue?: string) => {
  return String(path)
    .split('.')
    .reduce((acc, v) => {
      try {
        acc = acc[v];
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value);
};

export const EntityTable = <TEntity,>({
  columns,
  baseQueryOptions = {},
  isSearchEnabled = true,
  defaultSortOptions = {},
  onRowClick,
  tableSize,
  renderToolbar,
  toolbarProps = {},
  pageSize = 10,
  progressVariant = 'linear',
  query,
}: Props<TEntity>): ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(
    defaultSortOptions.orderDirection || OrderDirection.Asc,
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueDebounced] = useDebounce(searchValue, 500);
  const [orderBy, setOrderBy] = useState<Maybe<string>>(
    defaultSortOptions.orderBy,
  );
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [currentPage, setCurrentPage] = useState(1);
  const generalTheme = useTheme();
  const isMobile = useMediaQuery(generalTheme.breakpoints.down('sm'));
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [customQueryVariables, setCustomQueryVariables] = useState({});

  const updateCustomQueryVariables = useCallback(
    queryVariables =>
      setCustomQueryVariables(prev => ({ ...prev, ...queryVariables })),
    [],
  );

  useEffect(() => {
    isMobile && setRowsPerPage(5);
  }, [isMobile]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValueDebounced]);

  const {
    data,
    fetchMore,
    loading: isLoading,
  } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    ...baseQueryOptions,
    variables: {
      ...filterOptions,
      ...(baseQueryOptions.variables || {}),
      orderBy,
      orderDirection,
      searchValue: searchValueDebounced,
      ...customQueryVariables,
      after: undefined,
      first: rowsPerPage,
    },
  });

  const connection = data && data[getQueryName(query)];
  const rows = connection ? getNodes(connection) : [];
  const hasNextPage = connection?.pageInfo.hasNextPage;
  const totalRowsCount = connection?.pageInfo.count || 0;

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && orderDirection === OrderDirection.Asc;
    setOrderDirection(isAsc ? OrderDirection.Desc : OrderDirection.Asc);
    setOrderBy(property);
    setCurrentPage(1);
  };

  const handleRequestFilter = ({ filterBy, filterValues }: FilterOptions) => {
    setFilterOptions({ filterBy, filterValues });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = async (newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  useEffect(() => {
    if (!connection || !hasNextPage) {
      return;
    }

    const fetchMoreRows = async () => {
      try {
        await fetchMore({
          updateQuery: (currentQuery: any, { fetchMoreResult }) => ({
            [getQueryName(query)]: {
              ...currentQuery[getQueryName(query)],
              ...fetchMoreResult[getQueryName(query)],
              edges: [
                ...currentQuery[getQueryName(query)].edges,
                ...fetchMoreResult[getQueryName(query)].edges,
              ],
            },
          }),
          variables: {
            after: connection.pageInfo.endCursor,
            first: rowsPerPage,
          },
        });
      } catch (error) {
        enqueueSnackbar(
          t('error.unableToFetchData', {
            variant: 'error',
          }),
        );
      }
    };

    fetchMoreRows();
  }, [currentPage, rowsPerPage]);

  // Avoid a layout jump when reaching the last page with empty SORTING_SELECTING_TABLE.
  const emptyRows =
    isLoading && !data ? rowsPerPage : currentPage * rowsPerPage - rows.length;

  return (
    <>
      <StyledToolbar {...toolbarProps}>
        {renderToolbar ? (
          renderToolbar(searchValue, setSearchValue)
        ) : isSearchEnabled ? (
          <SearchInput
            size="small"
            value={searchValue}
            onChange={setSearchValue}
            placeholder={t('search')}
          />
        ) : null}
      </StyledToolbar>
      <Box sx={{ position: 'relative' }}>
        {isLoading && (
          <>
            {progressVariant === 'circular' ? (
              <Box
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  right: '50%',
                  top: '100px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <LinearProgress
                sx={{
                  borderRadius: '2px 2px 10px 10px',
                  margin: '0 auto',
                  position: 'absolute',
                  top: 60,
                  width: '98.2%',
                }}
              />
            )}
          </>
        )}
        {!rows.length && (
          <Box
            sx={{
              position: 'absolute',
              textAlign: 'center',
              top: '45%',
              width: '100%',
            }}
          >
            <Typography sx={{ color: 'grey.400' }} variant="subtitle1">
              {isLoading ? t('table.loading') : t('table.emptyHereYet')}
            </Typography>
          </Box>
        )}

        <TableContainer>
          <Table size={tableSize}>
            <TableHeader<TEntity>
              sortDirection={orderDirection}
              sortBy={orderBy}
              customQueryVariables={customQueryVariables}
              updateCustomQueryVariables={updateCustomQueryVariables}
              filterOptions={filterOptions}
              onRequestSort={handleRequestSort}
              onRequestFilter={handleRequestFilter}
              columns={columns}
            />
            <TableBody>
              {rows
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  (currentPage - 1) * rowsPerPage + rowsPerPage,
                )
                .map(row => (
                  <TableRow
                    sx={
                      onRowClick && {
                        '&:hover': theme => ({
                          backgroundColor: theme.palette.grey[100],
                        }),
                        cursor: 'pointer',
                      }
                    }
                    onClick={() => onRowClick?.({ data: row })}
                    key={row.id}
                  >
                    {columns.map(
                      ({ id, align, renderCell, width, isWrappable }) => (
                        <TableCell
                          key={id}
                          align={align}
                          sx={{
                            lineBreak: isWrappable ? 'anywhere' : 'inherit',
                            width,
                          }}
                        >
                          {renderCell
                            ? renderCell({ data: row })
                            : getPropertyByPath(id, row)}
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  sx={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            component="div"
            count={totalRowsCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
            page={currentPage - 1}
            onPageChange={(e, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('rowsPerPage')}
          />
        </Box>
      </Box>
    </>
  );
};
