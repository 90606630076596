import { InMemoryCache, ApolloClient } from '@apollo/client';
import link from './links';
import initCache from './cache';

let client: ApolloClient<Record<string, any>>;

export const getApolloClient = async (): Promise<
  ApolloClient<Record<string, any>>
> => {
  if (client) return client;

  const cache: InMemoryCache = await initCache();
  const apolloClient: ApolloClient<any> = new ApolloClient({
    cache,
    connectToDevTools: ['local', 'development'].includes(process.env.NODE_ENV),
    link,
    // todo: implement typedefes
    // typeDefs,
  });

  client = apolloClient;

  return apolloClient;
};
