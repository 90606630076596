import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { FC, useState } from 'react';
import {
  Box,
  IconButton,
  ListItemIcon,
  Stack,
  Typography,
  styled,
  Menu,
  MenuItem,
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { GQL } from '@common/queries';
import { clickOnDownloadUrl } from '@common/utils/clickOnDownloadUrl';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { InsuranceCaseDocumentReviewStatus } from '@shared/constants';
import { Maybe } from '@shared/types';
import { DocumentFragment } from '@common/queries/types/DocumentFragment';
import { InsuranceCaseDocumentReviewFragment } from '@common/queries/types/InsuranceCaseDocumentReviewFragment';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useMutation } from '@apollo/client';
import { DocumentRejectionReason } from './DocumentRejectionReason';
import { DocumentReviewStatusBadge } from './DocumentReviewStatusBadge';
import { DocumentItemReviewActions } from './DocumentItemReviewActions';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[2],
  height: 34,
  width: 34,
}));

interface Props {
  documentContainerId: string;
  documentReview: Maybe<InsuranceCaseDocumentReviewFragment>;
  documents: (DocumentFragment & { isUploadedAfterReview: boolean })[];
  insuranceCaseId: string;
  isReviewing: boolean;
  onPreviewClick: (documentName: string) => any;
}

export const DocumentItem: FC<Props> = ({
  documentContainerId,
  documentReview,
  documents,
  insuranceCaseId,
  isReviewing,
  onPreviewClick,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { documentName } = documents[0];
  const [fileMenuAnchorEl, setFileMenuAnchorEl] = useState(null);

  const isRejected =
    documentReview?.status === InsuranceCaseDocumentReviewStatus.Rejected;
  const isChanged =
    documentReview?.status === InsuranceCaseDocumentReviewStatus.Changed;

  const [getSignedUrl, { loading: isSignedUrlLoading }] = useMutation(
    GQL.DOCUMENT.GET_SIGNED_URL,
  );

  const downloadDocumentFile = async documentId => {
    try {
      const result = await getSignedUrl({
        variables: {
          documentKey: {
            documentContainerId,
            id: documentId,
          },
        },
      });
      clickOnDownloadUrl(result.data.documentMutations.getSignedUrl, true);
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const onDownloadIconClick = async event => {
    if (documents.length === 1) {
      await downloadDocumentFile(documents[0].id);
    } else {
      setFileMenuAnchorEl(event.target);
    }
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        backgroundColor: theme =>
          isRejected || isChanged ? undefined : theme.palette.grey[200],
        border: theme => {
          let border = isRejected
            ? `1px dashed ${theme.palette.error.main}`
            : undefined;
          if (isChanged) {
            border = `1px solid ${theme.palette.warning.light}`;
          }

          return border;
        },
        borderRadius: 2,
        paddingX: 2,
        paddingY: 1.5,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <InsertDriveFileIcon color="action" sx={{ mr: 2 }} />
        <Box display="flex" flexDirection="column">
          <Typography sx={{ mr: 1.5 }} noWrap>
            {getLabel(documentName, t)}
          </Typography>
          <DocumentReviewStatusBadge
            status={documentReview?.status}
            sx={{ mr: 1.5 }}
          />
        </Box>
        <Stack direction="row" sx={{ marginLeft: 'auto' }} spacing={2}>
          <Stack direction="row" spacing={1}>
            <StyledIconButton onClick={() => onPreviewClick(documentName)}>
              <RemoveRedEyeOutlinedIcon />
            </StyledIconButton>
            <StyledIconButton
              disabled={isSignedUrlLoading}
              onClick={onDownloadIconClick}
            >
              <CloudDownloadOutlinedIcon />
            </StyledIconButton>
            <Menu
              anchorEl={fileMenuAnchorEl}
              open={!!fileMenuAnchorEl}
              onClose={() => setFileMenuAnchorEl(null)}
            >
              {documents.map(({ id, fileName, isUploadedAfterReview }) => (
                <MenuItem
                  key={id}
                  onClick={() => {
                    downloadDocumentFile(id);
                    setFileMenuAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon
                      sx={{
                        color: theme =>
                          isUploadedAfterReview
                            ? theme.palette.warning.dark
                            : theme.palette.action.active,
                      }}
                    />
                  </ListItemIcon>
                  {fileName}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
          {isReviewing && (
            <DocumentItemReviewActions
              documentName={documentName}
              documentReview={documentReview}
              insuranceCaseId={insuranceCaseId}
            />
          )}
        </Stack>
      </Box>
      {(isRejected || isChanged) && (
        <DocumentRejectionReason documentReview={documentReview} />
      )}
    </Stack>
  );
};
