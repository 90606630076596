import { styled } from '@mui/material/styles';
import { Container, LinearProgress, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FC } from 'react';
import { useLocales } from '@hooks/useLocales';
import { useQuery } from '@apollo/client';
import {
  GetAdvertiser,
  GetAdvertiserVariables,
} from '@queries/types/GetAdvertiser';
import { GQL } from '@queries';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { AdvertiserStatus } from '@shared/constants';
import { Suspender } from '@components/Suspender';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@common/routes';

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  minHeight: '100%',
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(15),
}));

export const AdvertiserVerificationPendingPage: FC = () => {
  const { advertiserId } = useCurrentUser();
  const { t } = useLocales();
  const navigate = useNavigate();
  const advertiserQuery = useQuery<GetAdvertiser, GetAdvertiserVariables>(
    GQL.ADVERTISER.ADVERTISER,
    {
      skip: !advertiserId,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        advertiserId: advertiserId!,
      },
    },
  );

  if (advertiserQuery.error) {
    throw advertiserQuery.error;
  }
  if (!advertiserQuery?.data?.advertiser) {
    return <Suspender />;
  }

  if (advertiserQuery.data.advertiser.status !== AdvertiserStatus.PENDING) {
    navigate(ROUTES.ADVERTISER.root());
  }

  return (
    <RootStyle title="Verifying">
      <Container sx={{ textAlign: 'center' }}>
        <CheckCircleIcon color="primary" sx={{ height: '6em', width: '6em' }} />
        <Typography variant="h3" paragraph>
          {t('thankYou!')}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }} gutterBottom>
          {t(`weAreVerifying`)}
        </Typography>
        <LinearProgress />
      </Container>
    </RootStyle>
  );
};
