import { Dispatch, FC, memo, SetStateAction, useEffect, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { debounce } from 'debounce';

interface Props {
  setFormValues: Dispatch<SetStateAction<Record<string, any>>>;
  debounceTimeout?: number;
}

export const FormValuesSetter: FC<Props> = memo(
  ({ setFormValues, debounceTimeout = 200 }) => {
    const { values } = useFormState({
      subscription: {
        values: true,
      },
    });
    const setFormValuesDebounced = useMemo(
      () => debounce(formValues => setFormValues(formValues), debounceTimeout),
      [],
    );
    useEffect(() => {
      setFormValuesDebounced(values);
    }, [values]);

    return null;
  },
);
