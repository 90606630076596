import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/lab';
import TextField from '@mui/material/TextField';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { format, isDate, isValid } from 'date-fns';
import { ShowErrorFunc, showErrorOnChange } from './util';
import { pickerProviderWrapper } from './PickerProvider';

export interface DatePickerProps
  extends Partial<Omit<MuiDatePickerProps, 'onChange'>> {
  name: string;
  locale?: any;
  fieldProps?: Partial<FieldProps<any, any>>;
  required?: boolean;
  showError?: ShowErrorFunc;
}

export const DatePicker: React.FC<DatePickerProps> = (
  props: DatePickerProps,
) => {
  const { name, fieldProps, required, ...rest } = props;

  return (
    <Field
      name={name}
      parse={date =>
        isDate(date) && isValid(date) ? format(date, 'yyyy-MM-dd') : date
      }
      render={fieldRenderProps => (
        <DatePickerWrapper
          required={required}
          {...fieldRenderProps}
          {...rest}
        />
      )}
      {...fieldProps}
    />
  );
};

interface DatePickerWrapperProps extends FieldRenderProps<MuiDatePickerProps> {
  required?: boolean;
  locale?: any;
}

const DatePickerWrapper: React.FC<DatePickerWrapperProps> = (
  props: DatePickerWrapperProps,
) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    locale,
    required,
    showError = showErrorOnChange,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  const { helperText, ...lessrest } = rest;
  return pickerProviderWrapper(
    <MuiDatePicker
      onChange={onChange}
      value={(value as any) === '' ? null : value}
      {...lessrest}
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          fullWidth={
            typeof inputProps.InputProps?.fullWidth === 'boolean'
              ? inputProps.InputProps.fullWidth
              : true
          }
          helperText={isError ? error || submitError : helperText}
          error={inputProps.error || isError}
          name={name}
          required={required}
          inputProps={{
            ...inputProps.inputProps,
            onBlur: event => {
              inputProps.inputProps?.onBlur?.(event);
              restInput.onBlur(event);
            },
            onFocus: event => {
              inputProps.inputProps?.onFocus?.(event);
              restInput.onFocus(event);
            },
          }}
        />
      )}
    />,
    locale,
  );
};
