import { gql } from '@apollo/client';

export const InsuranceCaseFieldReviewFrag = gql`
  fragment InsuranceCaseFieldReviewFragment on InsuranceCaseFieldReview {
    fieldValues
    formFieldNames
    id
    pdfFieldName
    rejectedRawPdfFieldValue
    rejectionReason
    status
  }
`;

export const InsuranceCaseDocumentReviewFrag = gql`
  fragment InsuranceCaseDocumentReviewFragment on InsuranceCaseDocumentReview {
    documentName
    id
    rejectionReason
    rejectedDocuments {
      id
    }
    status
    createdBy {
      firstName
      id
      lastName
    }
  }
`;

export const INSURANCE_CASE_REVIEW = {
  DELETE_DOCUMENT_REVIEW: gql`
    mutation DeleteInsuranceCaseDocumentReview($documentReviewId: ID!) {
      insuranceCaseReviewMutations {
        deleteDocumentReview(documentReviewId: $documentReviewId)
      }
    }
  `,
  DELETE_FIELD_REVIEW: gql`
    mutation DeleteInsuranceCaseFieldReview(
      $fieldReviewId: ID!
      $insuranceCaseId: ID!
    ) {
      insuranceCaseReviewMutations {
        deleteFieldReview(
          fieldReviewId: $fieldReviewId
          insuranceCaseId: $insuranceCaseId
        )
      }
    }
  `,
  DOCUMENT_REVIEWS: gql`
    query GetInsuranceCaseDocumentReviews($insuranceCaseId: String!) {
      insuranceCaseDocumentReviews(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseDocumentReviewFragment
      }
    }
    ${InsuranceCaseDocumentReviewFrag}
  `,
  FIELD_REVIEWS: gql`
    query GetInsuranceCaseFieldReviews($insuranceCaseId: String!) {
      insuranceCaseFieldReviews(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseFieldReviewFragment
      }
    }
    ${InsuranceCaseFieldReviewFrag}
  `,
  FIELD_REVIEWS_WITH_SIGNED_URL: gql`
    query GetInsuranceCaseFieldReviewsWithSignedUrl(
      $documentKey: DocumentKeyInput!
      $insuranceCaseId: String!
    ) {
      signedUrl(documentKey: $documentKey)
      insuranceCaseFieldReviews(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseFieldReviewFragment
      }
    }
    ${InsuranceCaseFieldReviewFrag}
  `,
  SUBMIT_FINAL_STATUS: gql`
    mutation SubmitInsuranceCaseFinalStatus(
      $insuranceCaseId: String!
      $status: InsuranceCaseStatus!
    ) {
      insuranceCaseReviewMutations {
        submitFinalStatus(insuranceCaseId: $insuranceCaseId, status: $status) {
          id
          status
        }
      }
    }
  `,
  SUBMIT_REVIEW: gql`
    mutation SubmitInsuranceCaseReview(
      $insuranceCaseId: String!
      $status: InsuranceCaseStatus!
      $internalCRMNumber: String
    ) {
      insuranceCaseReviewMutations {
        submitReview(
          insuranceCaseId: $insuranceCaseId
          internalCRMNumber: $internalCRMNumber
          status: $status
        ) {
          id
          internalCRMNumber
          status
        }
      }
    }
  `,
  UPSERT_DOCUMENT_REVIEW: gql`
    mutation UpsertInsuranceCaseDocumentReview(
      $documentName: String!
      $insuranceCaseId: String!
      $rejectionReason: String
      $status: InsuranceCaseDocumentReviewStatus!
    ) {
      insuranceCaseReviewMutations {
        upsertDocumentReview(
          documentName: $documentName
          insuranceCaseId: $insuranceCaseId
          rejectionReason: $rejectionReason
          status: $status
        ) {
          ...InsuranceCaseDocumentReviewFragment
        }
      }
    }
    ${InsuranceCaseDocumentReviewFrag}
  `,
  UPSERT_FIELD_REVIEW: gql`
    mutation UpsertInsuranceCaseFieldReview(
      $insuranceCaseId: String!
      $pdfFieldName: String!
      $rejectionReason: String
      $rejectedRawPdfFieldValue: JSON
      $status: InsuranceCaseFieldReviewStatus!
    ) {
      insuranceCaseReviewMutations {
        upsertFieldReview(
          insuranceCaseId: $insuranceCaseId
          pdfFieldName: $pdfFieldName
          rejectedRawPdfFieldValue: $rejectedRawPdfFieldValue
          rejectionReason: $rejectionReason
          status: $status
        ) {
          ...InsuranceCaseFieldReviewFragment
        }
      }
    }
    ${InsuranceCaseFieldReviewFrag}
  `,
};
