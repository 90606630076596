import { IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router';
import {
  PageHeaderNavItem,
  Props as NavItemProps,
} from './PageNavHeaderNavItem';

interface Props {
  title?: string | React.ReactNode;
  navItemsConfig?: NavItemProps[];
  backTo?: string;
}

export const PageNavHeader: FC<Props> = memo(
  ({ backTo, title, navItemsConfig }) => {
    const navigate = useNavigate();

    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          height: theme => theme.spacing(9),
        }}
      >
        {backTo && (
          <IconButton onClick={() => navigate(backTo)} size="large">
            <ArrowBackIcon />
          </IconButton>
        )}
        <Stack direction="row" spacing={2} alignItems="baseline">
          {typeof title === 'string' ? (
            <Typography variant="h5">{title}</Typography>
          ) : (
            title
          )}
          {navItemsConfig?.map(props => (
            <PageHeaderNavItem key={props.title} {...props} />
          ))}
        </Stack>
      </Stack>
    );
  },
);
