import { DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReplayIcon from '@mui/icons-material/Replay';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import { ACCEPTABLE_DOCUMENT_MIME_TYPES_AND_EXTENSIONS } from '@common/constants';

const Container = styled('div')(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.72,
  },
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  lineBreak: 'anywhere',
  outline: 'none',
  overflow: 'hidden',
  padding: theme.spacing(5, 2),
  position: 'relative',
  textAlign: 'center',
  transition: theme.transitions.create('padding'),
  width: '100%',
}));

export const MAP_ERROR_CODE_TO_TRANSLATION_KEY: Record<ErrorCode, string> = {
  [ErrorCode.FileInvalidType]: 'validation.fileInvalidType',
  [ErrorCode.FileTooLarge]: 'validation.fileTooLarge',
  [ErrorCode.FileTooSmall]: 'validation.fileTooSmall',
  [ErrorCode.TooManyFiles]: 'validation.tooManyFiles',
};

export type FileState = File & {
  isLoading?: boolean;
  isUploaded?: boolean;
  error?: string;
};

export type Props = DropzoneOptions & {
  files?: FileState[];
  isRejected?: boolean;
  onRemove: (file: File) => void;
  onUpload?: (file: File) => void;
  illustration?: JSX.Element;
  title: string;
  showPreview?: boolean;
};

export const UploadFiles: React.FC<Props> = ({
  files = [],
  illustration,
  onRemove,
  onUpload,
  title,
  showPreview = false,
  isRejected = false,
  ...dropZoneProps
}) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: Object.values(ACCEPTABLE_DOCUMENT_MIME_TYPES_AND_EXTENSIONS),
    multiple: true,
    ...dropZoneProps,
  });

  return (
    <Container
      {...getRootProps()}
      sx={{
        borderColor: theme =>
          isRejected ? theme.palette.error.light : theme.palette.grey['400'],
        borderWidth: () => (isRejected ? '3px' : '1px'),
      }}
    >
      <input {...getInputProps()} />
      <Stack direction="column" alignItems="center" mb={3}>
        {illustration || (
          <CloudUploadIcon
            sx={{ height: 32, mb: 1, opacity: 0.25, width: 32 }}
          />
        )}
        <Typography variant="caption" color="text.secondary">
          {t('uploadFiles.pleaseDragAndDropFilesHere')}
        </Typography>
      </Stack>

      <Typography>{title}</Typography>
      <List disablePadding sx={{ mt: 2, width: '100%' }}>
        {fileRejections.map(({ file, errors }, idx) => {
          const { name } = file;
          const error = t(MAP_ERROR_CODE_TO_TRANSLATION_KEY[errors[0]?.code]);
          return (
            <ListItem
              key={`${name}_${idx}`}
              sx={{
                bgcolor: 'background.paper',
                border: 'solid 1px',
                borderColor: theme => theme.palette.error.light,
                borderRadius: 1,
                my: 1,
                px: 2,
                py: 0.75,
              }}
            >
              <ListItemIcon>
                <ErrorIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primary={name}
                secondary={error}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
            </ListItem>
          );
        })}
        {files.map((file, idx) => {
          const { name, isLoading, error, isUploaded } = file;
          const icon = Object.entries({
            error: <ErrorIcon color="error" />,
            isLoading: <CircularProgress size={24} />,
            isUploaded: <CheckCircleIcon color="primary" />,
          }).reduce(
            (prev, [key, value]) => (file[key] ? value : prev),
            showPreview ? (
              <Box
                component="img"
                alt="file preview"
                src={URL.createObjectURL(file)}
                sx={{
                  borderRadius: theme => theme.spacing(1),
                  height: '35px',
                  objectFit: 'cover',
                  width: '35px',
                }}
              />
            ) : (
              <InsertDriveFileIcon />
            ),
          );

          return (
            <ListItem
              key={name + idx}
              sx={{
                bgcolor: 'background.paper',
                border: 'solid 1px',
                borderColor: theme =>
                  error ? theme.palette.error.light : theme.palette.divider,
                borderRadius: 1,
                my: 1,
                px: 2,
                py: 0.75,
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={name}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
              <ListItemSecondaryAction>
                <Box display="flex" alignItems="center">
                  {error && onUpload && (
                    <IconButton
                      edge="end"
                      size="small"
                      disabled={isLoading}
                      onClick={event => {
                        event.stopPropagation();
                        onUpload(file);
                      }}
                    >
                      <ReplayIcon fontSize="small" color="action" />
                    </IconButton>
                  )}
                  {!isUploaded && (
                    <IconButton
                      edge="end"
                      size="small"
                      disabled={isLoading}
                      onClick={event => {
                        event.stopPropagation();
                        onRemove(file);
                      }}
                    >
                      <Icon icon={closeFill} />
                    </IconButton>
                  )}
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};
