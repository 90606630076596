import { gql } from '@apollo/client';

export const OfficeMemberFrag = gql`
  fragment OfficeMemberFragment on OfficeMember {
    branchOfficeId
    email
    firstName
    id
    profileStatus
    invitedByUserId
    jobTitle
    lastName
    name
    phone
    type
    userId
  }
`;

export const OFFICE_MEMBER = {
  CREATE: gql`
    mutation CreateOfficeMember($data: CreateOfficeMemberInput!) {
      officeMemberMutations {
        create(data: $data) {
          ...OfficeMemberFragment
        }
      }
    }
    ${OfficeMemberFrag}
  `,
  CURRENT_OFFICE_MEMBER: gql`
    query GetCurrentOfficeMember {
      currentOfficeMemberProfile {
        ...OfficeMemberFragment
        branchOffice {
          id
          name
        }
      }
    }
    ${OfficeMemberFrag}
  `,
  OFFICE_MEMBER: gql`
    query GetOfficeMember($officeMemberId: String!) {
      officeMember(officeMemberId: $officeMemberId) {
        ...OfficeMemberFragment
      }
    }
    ${OfficeMemberFrag}
  `,
  OFFICE_MEMBERS_PAGED: gql`
    query GetOfficeMembersPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $officeMemberTypes: [OfficeMemberType!]
      $branchOfficeIds: [String]
      $filterBy: String
      $filterValues: [String!]
      $withBranchOfficeName: Boolean!
    ) {
      officeMembersPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        officeMemberTypes: $officeMemberTypes
        branchOfficeIds: $branchOfficeIds
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          count
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...OfficeMemberFragment
            ... on OfficeMember @include(if: $withBranchOfficeName) {
              branchOffice {
                id
                name
              }
            }
          }
        }
      }
    }
    ${OfficeMemberFrag}
  `,
  UPDATE: gql`
    mutation UpdateOfficeMember(
      $officeMemberId: String!
      $data: UpdateOfficeMemberInput!
    ) {
      officeMemberMutations {
        update(officeMemberId: $officeMemberId, data: $data) {
          ...OfficeMemberFragment
        }
      }
    }
    ${OfficeMemberFrag}
  `,
  UPDATE_PROFILE: gql`
    mutation UpdateOfficeMemberProfile(
      $officeMemberId: String!
      $data: UpdateOfficeMemberProfileInput!
    ) {
      officeMemberMutations {
        updateProfile(officeMemberId: $officeMemberId, data: $data) {
          ...OfficeMemberFragment
        }
      }
    }
    ${OfficeMemberFrag}
  `,
};
