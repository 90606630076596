import { Step } from '@components/forms/types';

export function createNumberOfSteps(
  n: number,
  stepMapper: (idx: number) => Step[],
): Step[] {
  return Array.from({ length: n }, (v, i) => i)
    .map(stepMapper)
    .flat(1);
}
