import React, { FC, memo, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentAdvertiser, useToggle } from '@common/hooks';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField } from '@common/forms/fields';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { GQL } from '@queries';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useSnackbar } from 'notistack';
import { IMaskMixin } from 'react-imask';
import { Checkbox } from '@common/forms/fields/Checkbox';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  CreateInviteCode,
  CreateInviteCodeVariables,
} from '@queries/types/CreateInviteCode';
import { MAXIMUM_ADVERTISER_PROVISION } from '@shared/constants';
import EuroIcon from '@mui/icons-material/Euro';

const InfoBadge: FC = memo(() => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = event => setAnchorEl(event.target);
  const closePopover = () => setAnchorEl(null);

  return (
    <>
      <IconButton size="small" onClick={openPopover}>
        <InfoOutlinedIcon color="info" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closePopover}
        PaperProps={{
          sx: { p: 2, width: theme => theme.breakpoints.values.sm },
        }}
      >
        <Typography sx={{ pb: 1 }} variant="h6">
          {t('infoBadge.createInviteCode')}
        </Typography>
        <Typography>{t('infoBadge.createInviteCodeDescription')}</Typography>
      </Popover>
    </>
  );
});

export const CreateInviteCodeButton: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpened, { toggleOn: openDialog, toggleOff: closeDialog }] =
    useToggle();
  const [createInviteCode, { loading: isLoading }] = useMutation<
    CreateInviteCode,
    CreateInviteCodeVariables
  >(GQL.INVITE_CODE.CREATE);
  const currentAdvertiser = useCurrentAdvertiser();

  const onSubmit = async ({ inputs }) => {
    try {
      await createInviteCode({
        awaitRefetchQueries: true,
        refetchQueries: [GQL.INVITE_CODE.PAGED],
        variables: {
          inputs: inputs.map(({ provision, count, isMulti }) => ({
            count: +count,
            isMulti,
            provision,
          })),
        },
      });
      enqueueSnackbar(t('inviteCodeCreated'), { variant: 'success' });
      closeDialog();
    } catch (error: any) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          inputs: Yup.array(
            Yup.object().shape({
              count: Yup.number()
                .required(t('validation.required'))
                .min(1, t('validation.inviteCodeCount')),
              isMulti: Yup.boolean(),
              provision: Yup.number()
                .required(t('validation.required'))
                .min(0)
                .max(
                  currentAdvertiser?.provision
                    ? (+currentAdvertiser.provision as number)
                    : MAXIMUM_ADVERTISER_PROVISION,
                  t('notGreaterThan', {
                    than: currentAdvertiser?.provision
                      ? (+currentAdvertiser.provision as number)
                      : MAXIMUM_ADVERTISER_PROVISION,
                  }),
                ),
            }),
          ),
        }) as any,
      ),
    [t],
  );

  const initialValues = useMemo(() => ({ inputs: [{}] }), []);

  const MaskedInput = IMaskMixin((props: any) => <TextField {...props} />);

  return (
    <>
      <Button
        variant="contained"
        onClick={openDialog}
        startIcon={<Icon icon={plusFill} />}
      >
        {t('createInviteCode')}
      </Button>
      <Dialog fullWidth maxWidth="xs" open={isDialogOpened}>
        <DialogTitle sx={{ mb: 2 }}>
          {t('createInviteCodeDialog.title')}
          <InfoBadge />
        </DialogTitle>
        <DialogContent>
          <Form
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            onSubmit={onSubmit}
            validate={validate}
          >
            {({
              submitting,
              handleSubmit,
              form: {
                mutators: { push },
              },
            }) => (
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack gap={2} justifyContent="center">
                  <FieldArray
                    name="inputs"
                    render={({ fields }) => (
                      <>
                        {fields.map((name, index) => (
                          <Stack key={index} gap={2}>
                            <Stack direction="row" gap={2}>
                              <MaskedInput
                                mask="0[0][0].00"
                                radix="."
                                placeholder="00.00"
                                disabled={isLoading}
                                variant="standard"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <EuroIcon fontSize="small" />
                                    </InputAdornment>
                                  ),
                                }}
                                name={`${name}.provision`}
                                label={t('commissionRate')}
                              />
                              <MaskedInput
                                mask={Number}
                                placeholder="0"
                                initialValue={1}
                                disabled={isLoading}
                                variant="standard"
                                name={`${name}.count`}
                                label={t('count')}
                              />
                              <IconButton
                                onClick={() => {
                                  fields.remove(index);
                                }}
                              >
                                <ClearIcon color="error" fontSize="large" />
                              </IconButton>
                            </Stack>
                            <Checkbox
                              label={t('reusable')}
                              disabled={isLoading}
                              name={`${name}.isMulti`}
                            />
                          </Stack>
                        ))}

                        <Button
                          onClick={() => {
                            push('inputs', {});
                          }}
                        >
                          <AddCircleIcon color="primary" fontSize="large" />
                        </Button>
                      </>
                    )}
                  />
                </Stack>
                <Stack
                  sx={{ mt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  gap={2}
                >
                  <Button onClick={closeDialog} disabled={submitting}>
                    {t('cancel')}
                  </Button>
                  <LoadingButton
                    loading={submitting}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {t('create')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
