import * as Yup from 'yup';
import { MenuItem, Stack, Typography } from '@mui/material';
import { Select, TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import { StepComponent } from '@components/forms/types';
import { PhoneNumberField } from '@common/forms/fields';
import { StepTitle } from '@components/forms/StepTitle';
import { Gender } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_firstName: Yup.string()
      .nullable()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .required(t('validation.required')),
    main_0_gender: Yup.string().nullable(),
    main_0_lastName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    main_0_mobilePhone: Yup.string().nullable(),
    main_0_phone: Yup.string().nullable(),
    main_0_previousLastName: Yup.string().nullable(),
  });

export const PersonalInformationStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.personalInformation')}
        </Typography>
      </StepTitle>
      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <TextField
          name="main_0_firstName"
          label={getLabel('main_0_firstName', t)}
        />
        <TextField
          name="main_0_lastName"
          label={getLabel('main_0_lastName', t)}
        />
      </Stack>

      <TextField
        name="main_0_previousLastName"
        label={getLabel('main_0_previousLastName', t)}
      />

      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <Select name="main_0_gender" label={getLabel('main_0_gender', t)}>
          <MenuItem value={Gender.Male}>{t('gender.male')}</MenuItem>
          <MenuItem value={Gender.Female}>{t('gender.female')}</MenuItem>
          <MenuItem value={Gender.Indeterminate}>
            {t('gender.indeterminate')}
          </MenuItem>
          <MenuItem value={Gender.Other}>{t('gender.other')}</MenuItem>
        </Select>
      </Stack>

      <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
        <PhoneNumberField
          name="main_0_phone"
          variant="outlined"
          label={getLabel('main_0_phone', t)}
        />
        <PhoneNumberField
          name="main_0_mobilePhone"
          variant="outlined"
          label={getLabel('main_0_mobilePhone', t)}
        />
      </Stack>
    </Stack>
  );
};

PersonalInformationStep.getValidationSchema = getValidationSchema;
