import { FC } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledCommentary = styled('div')(({ theme }) => ({
  background: theme.palette.grey['200'],
  borderBottomLeftRadius: 12,
  borderRadius: 24,
  borderTopRightRadius: 0,
  lineBreak: 'anywhere',
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}));

interface Props {
  currentStepRejections: [string[], Record<string, any>][];
}

export const FormRejections: FC<Props> = ({ currentStepRejections }) => {
  const { t } = useTranslation();
  return (
    <>
      {currentStepRejections.map(([fieldsGroup, meta]) => (
        <Stack sx={{ mb: 2 }} key={fieldsGroup.join()}>
          <Stack direction="row" spacing={2}>
            {fieldsGroup.map((fieldName, index) => (
              <Typography key={fieldName}>
                {getLabel(fieldName, t)}
                {index !== fieldsGroup.length - 1 && ','}
              </Typography>
            ))}
          </Stack>
          <StyledCommentary>
            <Typography variant="body2">{meta.rejectionReason}</Typography>
            {/* TODO: Rejected by? */}
            <Stack spacing={1} mt={1} direction="row" alignItems="center">
              <Avatar
                sx={{
                  fontSize: 10,
                  height: 16,
                  width: 16,
                }}
              >
                B
              </Avatar>
              <Typography variant="caption" color="text.secondary">
                BARMER
              </Typography>
            </Stack>
          </StyledCommentary>
        </Stack>
      ))}
    </>
  );
};
