import { ToggleHandlers, useToggle } from '@hooks/useToggle';

type Props = {
  isEnabledInitial?: boolean;
  children: (props: [boolean, ToggleHandlers]) => JSX.Element;
};

export const Toggle: React.FC<Props> = ({
  isEnabledInitial = false,
  children,
}) => {
  const [isEnabled, handlers] = useToggle(isEnabledInitial);
  return children([isEnabled, handlers]);
};
