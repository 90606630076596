import { ErrorResponse } from '@apollo/client/link/error';
import { TFunction } from 'i18next';
import { getErrorCodes } from '@utils/getErrorCodes';
import { getErrorCodeLabel } from '@common/constants';
import { Maybe } from '@shared/types';

export const getErrorLabelTranslated = (
  error: ErrorResponse | any,
  t: TFunction,
  options: { separator: string } = { separator: '\n' },
): Maybe<string> => {
  const { separator } = options;
  const codes = getErrorCodes(error);
  return codes.map(code => getErrorCodeLabel(code, t)).join(separator);
};
