import { LoadingScreen } from '@components/LoadingScreen';
import { useTranslation } from 'react-i18next';
import AmplifyAuth from '@aws-amplify/auth';

import { FC, useEffect, useState } from 'react';
import { useAuth } from '@common/hooks';

const REFRESH_TIMEOUT = 1_000;

// refresh CognitoUser in loop
const refreshCongitoUser = (setNewTimeoutId, setCognitoUser) => {
  setNewTimeoutId(
    setTimeout(async () => {
      const cognitoUser = await AmplifyAuth.currentUserInfo();
      setCognitoUser(cognitoUser);
      console.log('User entity updated', cognitoUser);
      refreshCongitoUser(setNewTimeoutId, setCognitoUser);
    }, REFRESH_TIMEOUT),
  );
};

export const FetchCurrentUser: FC = () => {
  const { t } = useTranslation();
  const { setCognitoUser } = useAuth();
  const [refreshTimerId, setRefreshTimerId] = useState<number | undefined>();

  useEffect(() => {
    refreshCongitoUser(setRefreshTimerId, setCognitoUser);
    return () => {
      clearTimeout(refreshTimerId);
    };
  }, []);
  // polling amplify
  return <LoadingScreen text={t('settingUpNewUser')} />;
};
