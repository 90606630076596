export const checkIsMobilePlatform = (userAgent: string): boolean => {
  const matches = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return matches.filter(match => navigator.userAgent.match(match)).length > 0;
};
