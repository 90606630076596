import { useQuery } from '@apollo/client';
import { useCurrentUser } from '@common/hooks/useCurrentUser';
import { GQL } from '@common/queries';
import { AdvertiserFragment } from '@common/queries/types/AdvertiserFragment';
import {
  GetAdvertiser,
  GetAdvertiserVariables,
} from '@common/queries/types/GetAdvertiser';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';

interface Props {
  children: (advertiser: AdvertiserFragment) => JSX.Element;
}

export const LoadCurrentAdvertiser = ({ children }: Props): JSX.Element => {
  const { advertiserId } = useCurrentUser();

  const advertiserQuery = useQuery<GetAdvertiser, GetAdvertiserVariables>(
    GQL.ADVERTISER.ADVERTISER,
    {
      skip: !advertiserId,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        advertiserId: advertiserId!,
      },
    },
  );

  return (
    <QueryLoaderComponent
      queryState={advertiserQuery}
      renderData={data => children(data.advertiser)}
    />
  );
};
