import { TFunction } from 'i18next';
import { DefaultDocumentNamesType } from '@shared/constants';

const getLabelByFieldName = (
  fieldName: string,
  t: TFunction,
  options: { isDraft?: boolean } = {},
): string => {
  const { isDraft } = options;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [profileType, index, baseFieldName] = fieldName.split('_');

  // object entities here
  if (baseFieldName?.split('.')[1]) {
    const [objectName, child] = baseFieldName.split('.');

    return (
      {
        country: t('country'),
        houseNumber: t('houseNumber'),
        place: t('city'),
        streetName: t('streetName'),
        zipCode: t('zipCode'),
      }[child] ??
      {}[objectName]?.[child] ??
      'NOT_FOUND'
    );
  }

  const documentLabels: Record<DefaultDocumentNamesType, string> = {
    barmerApplication: t('document.barmerApplication'),
    birthCertificate: t('document.birthCertficiate'),
    currentInsuranceCard: t('document.currentInsuranceCard'),
    enrollmentCertificate: t('document.enrollmentCertificate'),
    marriageCertificate: t('document.marriageCertificate'),
    passport: t('document.passport'),
    photography: t('document.photography'),
    powerOfAttorney: t('document.powerOfAttorney'),
    powerOfAttorneyUploaded: t('document.powerOfAttorney'),
    sepaMandate: t('document.sepaMandate'),
    workContract: t('document.workContract'),
  };

  return (
    {
      ...documentLabels,
      iHaveAppliedForPension: isDraft
        ? t('draft.iHaveAppliedForPension')
        : t('iHaveAppliedForPension'),
      iRecieveStatutoryPension: isDraft
        ? t('draft.iRecieveStatutoryPension')
        : t('iRecieveStatutoryPension'),
      iWasCoveredByFamilyInsurancePolicy: isDraft
        ? t('draft.iWasCoveredByFamilyInsurancePolicy')
        : t('iWasCoveredByFamilyInsurancePolicy'),
      iWasTheMainHolderOfMyPreviousIns: isDraft
        ? t('draft.iWasTheMainHolderOfMyPreviousIns')
        : t('iWasTheMainHolderOfMyPreviousIns'),
      myChildren: isDraft ? t('draft.myChildren') : t('myChildren'),
      myChildrenAndSpouse: isDraft
        ? t('draft.myChildrenAndSpouse')
        : t('myChildrenAndSpouse'),
      mySpouse: isDraft ? t('draft.mySpouse') : t('mySpouse'),
    }[fieldName] ??
    {
      child_hasPreviousInsurance: isDraft
        ? t('draft.childHasPreviousInsurance')
        : t('childHasPreviousInsurance'),
      child_isDoingMilitaryOrVoluntary: isDraft
        ? t('draft.childIsDoingMilitaryOrVoluntary')
        : t('childIsDoingMilitaryOrVoluntary'),
      child_isEmployed: isDraft
        ? t('draft.childIsEmployed')
        : t('childIsEmployed'),
      child_isEmployedFullTime: isDraft
        ? t('draft.childIsEmployedFullTime')
        : t('childIsEmployedFullTime'),
      child_isHavingAdditionalIncome: isDraft
        ? t('draft.childIsHavingAdditionalIncome')
        : t('childIsHavingAdditionalIncome'),
      child_isStudying: isDraft
        ? t('draft.childIsStudying')
        : t('childIsStudying'),
      child_previousInsuranceType: isDraft
        ? t('draft.childPreviousInsuranceType')
        : t('childPreviousInsuranceType'),
      spouse_hasPreviousInsurance: isDraft
        ? t('draft.spouseHasPreviousInsurance')
        : t('spouseHasPreviousInsurance'),
      spouse_isDoingMilitaryOrVoluntary: isDraft
        ? t('draft.isSpouseDoingMilitaryOrVoluntary')
        : t('isSpouseDoingMilitaryOrVoluntary'),
      spouse_isEmployment: isDraft
        ? t('draft.spouseIsEmployment')
        : t('spouseIsEmployment'),
      spouse_isEmploymentFullTime: isDraft
        ? t('draft.isEmploymentFullTime')
        : t('isEmploymentFullTime'),
      spouse_isHavingAdditionalIncome: isDraft
        ? t('draft.isSpouseHavingAdditionalIncome')
        : t('isSpouseHavingAdditionalIncome'),
      spouse_isStudying: isDraft
        ? t('draft.isSpouseStudying')
        : t('isSpouseStudying'),
      spouse_previousInsuranceType: isDraft
        ? t('draft.spousePreviousInsuranceType')
        : t('spousePreviousInsuranceType'),
    }[`${profileType}_${baseFieldName}`] ??
    {
      addressIsDifferentFromMain: t('addressIsDifferentFromMain'),
      addressIsDifferentFromParent: t('addressIsDifferentFromParent'),
      birthCountry: t('birthCountry'),
      birthDate: t('birthDate'),
      birthPlace: t('birthPlace'),
      childRelationship: t('childRelationship'),
      childrenCount: t('childrenCount'),
      dependantsType: isDraft ? t('draft.dependantsType') : t('dependantsType'),
      employerName: t('employerName'),
      firstName: t('firstName'),
      gender: t('gender'),
      gradeOrSemester: t('gradeOrSemester'),
      hasEmployerPensionOrLumpSum: isDraft
        ? t('draft.hasEmployerPensionOrLumpSumQuestion')
        : t('hasEmployerPensionOrLumpSumQuestion'),
      hasFamilyRelationshipToEmployer: isDraft
        ? t('draft.hasFamilyRelationshipToEmployer')
        : t('hasFamilyRelationshipToEmployer'),
      hasPreviousInsurance: isDraft
        ? t('draft.hasPreviousInsuranceQuestion')
        : t('hasPreviousInsuranceQuestion'),
      hasWillingCommunicateEnglish: isDraft
        ? t('draft.hasWillingCommunicateEnglishCheckbox')
        : t('hasWillingCommunicateEnglishCheckbox'),
      hasWillingToBeReferral: isDraft
        ? t('draft.hasWillingToBeReferralCheckbox')
        : t('hasWillingToBeReferralCheckbox'),
      hasWillingToCoverDependants: isDraft
        ? t('draft.hasWillingToCoverDependantsQuestion')
        : t('hasWillingToCoverDependantsQuestion'),
      insurancePurpose: t('insurancePurpose'),
      isAgriculturalEntrepreneur: isDraft
        ? t('draft.isAgriculturalEntrepreneurQuestion')
        : t('isAgriculturalEntrepreneurQuestion'),

      isDoingMilitaryOrVoluntary: t('isDoingMilitaryOrVoluntary'),
      isEmployed: t('isEmployed'),
      isEmployedFullTime: t('isEmployedFullTime'),
      isEmployment: t('isEmployment'),
      isEmploymentFullTime: t('isEmploymentFullTime'),
      isHavingAdditionalIncome: isDraft
        ? t('draft.isHavingAdditionalIncomeQuestion')
        : t('isHavingAdditionalIncomeQuestion'),
      isLastNameChanged: t('isLastNameChanged'),
      isSpouseParent: t('isSpouseParent'),
      isStudying: t('isStudying'),
      isUnemploymentBenefits: isDraft
        ? t('draft.isUnemploymentBenefits')
        : t('isUnemploymentBenefits'),
      jobEndDate: t('jobEndDate'),
      jobIncome: t('jobIncome'),
      jobStartDate: t('jobStartDate'),
      lastName: t('lastName'),
      militaryOrVoluntaryEndDate: t('militaryOrVoluntaryEndDate'),
      militaryOrVoluntaryStartDate: t('militaryOrVoluntaryStartDate'),
      miniJobEndDate: t('miniJobEndDate'),
      miniJobIncome: t('miniJobIncome'),
      miniJobStartDate: t('miniJobStartDate'),
      mobilePhone: t('mobilePhone'),
      nationality: t('nationality'),
      otherDocuments: t('otherDocuments'),
      otherIncome: t('otherIncome'),
      otherInsurancePurpose: t('otherInsurancePurpose'),
      otherInsurancePurposeStartDate: t('otherInsurancePurposeStartDate'),
      pensionAppliedStartDate: t('pensionAppliedStartDate'),
      pensionInsuranceNumber: t('pensionInsuranceNumberOptional'),
      pensionStartDate: t('pensionStartDate'),
      pensionType: isDraft
        ? t('draft.pensionTypeQuestion')
        : t('pensionTypeQuestion'),
      phone: t('phone'),
      previousInsuranceEndDate: t('previousInsuranceEndDate'),
      previousInsuranceFamilyMemberBirthDate: t('familyMemberBirthDate'),
      previousInsuranceFamilyMemberFirstName: t('familyMemberFirstName'),
      previousInsuranceFamilyMemberLastName: t('familyMemberLastName'),
      previousInsuranceFamilyMemberPolicyNumber: t(
        'previousInsuranceFamilyMemberPolicyNumber',
      ),
      previousInsuranceProviderName: t('previousInsuranceProviderName'),
      previousInsuranceStartDate: t('startDate'),
      previousInsuranceStillExist: t('previousInsuranceStillExist'),
      previousInsuranceType: isDraft
        ? t('draft.previousInsuranceTypeQuestion')
        : t('previousInsuranceTypeQuestion'),
      previousLastName: t('previousLastName'),
      previousNationality: t('previousNationality'),
      salaryRange: isDraft ? t('draft.salaryRange') : t('salaryRange'),
      secondNationality: t('secondNationality'),
      studyEndDate: t('studyEndDate'),
      studyGradeOrSemester: t('studyOrGradeSemester'),
      studyStartDate: t('studyStartDate'),
      studyType: t('studyType'),
      unemploymentBenefitStartDate: t('unemploymentBenefitStartDate'),
      unemploymentStartDate: t('unemploymentStartDate'),
      ...documentLabels,
    }[baseFieldName] ??
    `${baseFieldName || fieldName}`
  );
};

export { getLabelByFieldName as getLabel };
