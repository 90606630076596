import { useContext } from 'react';
import { UserInterface } from '@common/providers/AuthProvider';
import { CurrentUserContext } from '../providers/CurrentUserProvider';

export const useCurrentUser = (): UserInterface => {
  const context = useContext(CurrentUserContext);
  // this way components always see context with values instead of context|null
  if (context === undefined) {
    throw Error('Current user have to be defined');
  }
  return context;
};
