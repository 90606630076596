import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ConfirmUserDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack sx={{ alignItems: 'center', justifyContent: 'center', p: 5 }}>
        <ForwardToInboxIcon color="primary" sx={{ fontSize: 64, mb: 1 }} />
        <Typography variant="h4">{t('signup.accountCreatedTitle')}</Typography>
        <DialogContentText sx={{ mb: 3 }}>
          {t('signup.accountCreatedText')}
        </DialogContentText>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t('close')}
        </Button>
      </Stack>
    </Dialog>
  );
};
