import { useToggle } from '@common/hooks';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  createFilterOptions,
  MenuItem,
} from '@mui/material';
import { makeValidate, TextField as TextFieldRFF } from 'mui-rff';
import { FC, useMemo, useState } from 'react';
import { InsuranceCaseDocumentReviewFragment } from '@common/queries/types/InsuranceCaseDocumentReviewFragment';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { DEFAULT_DOCUMENT_NAMES } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import {
  getProfileTypeWithFullName,
  getInsProfileDocumentFullLabel,
} from '@src/helpers';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useDocumentReviewActions } from './useDocumentReviewActions';

const filter = createFilterOptions();

interface Props {
  excludedDocumentNames: string[];
  documentReviews: InsuranceCaseDocumentReviewFragment[];
  insuranceCaseId: string;
  isDisabled?: boolean;
  insuranceCase: any;
}

export const AdditionalDocumentsPicker: FC<Props> = ({
  excludedDocumentNames,
  insuranceCaseId,
  insuranceCase,
  isDisabled,
  documentReviews,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isDialogOpened, { toggleOn: openDialog, toggleOff: closeDialog }] =
    useToggle();

  const [formInitialValues, setFormInitialValues] = useState<any>({
    documentName: '',
    rejectionReason: '',
  });

  const { upsertDocumentReview, deleteDocumentReview } =
    useDocumentReviewActions();

  const upsertReview = async ({
    documentName,
    documentNameInitial,
    rejectionReason,
    profileType,
  }) => {
    const finalBaseDocumentName =
      getLabel(documentNameInitial, t) === documentName
        ? documentNameInitial
        : documentName;

    try {
      closeDialog();
      await upsertDocumentReview({
        documentName: `${profileType}_${finalBaseDocumentName}`,
        insuranceCaseId,
        rejectionReason: rejectionReason ?? '',
      });
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const deleteReview = (documentReviewId: string) => {
    try {
      deleteDocumentReview({ documentReviewId, insuranceCaseId });
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          documentName: Yup.string()
            .required(t('validation.required'))
            .notOneOf(
              excludedDocumentNames,
              t('validation.documentNotAllowedInAdditionalDocuments'),
            ),
          profileType: Yup.string().required(t('validation.required')),
          rejectionReason: Yup.string().required(t('validation.required')),
        }) as any,
      ),
    [t, excludedDocumentNames],
  );

  const options = useMemo(
    () =>
      DEFAULT_DOCUMENT_NAMES.filter(
        documentName => !excludedDocumentNames.includes(documentName),
      )
        .map(documentName => ({
          documentName,
          label: getLabel(documentName, t),
        }))
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }),
    [t, excludedDocumentNames],
  );

  const onChange = (event, values) => {
    if (values.length < documentReviews.length) {
      const reviewToDelete = documentReviews.find(
        ({ documentName }) =>
          !values.some(({ documentName: docName }) => docName === documentName),
      );
      if (!reviewToDelete) {
        throw new Error('Didnt find document review to delete');
      }
      deleteReview(reviewToDelete.id);
      return;
    }

    const newValue = values[values.length - 1];
    if (!newValue) {
      return;
    }

    let documentName: string;
    if (typeof newValue === 'string') {
      documentName = newValue;
    } else if (newValue && newValue.inputValue) {
      documentName = newValue.inputValue;
    } else {
      documentName = newValue.documentName;
    }

    setFormInitialValues({
      documentName: getLabel(documentName, t),
      documentNameInitial: documentName,
    });
    openDialog();
  };

  const getOptionLabel = (option: any) => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option.label) {
      return option.label;
    }
    if (option.inputValue) {
      return option.inputValue;
    }

    return getInsProfileDocumentFullLabel({
      documentName: option.documentName,
      insuranceCase,
      t,
    });
  };

  const filterOptions = (opts, params) => {
    const filtered = filter(opts, params as any);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        label: t('addDocumentName', {
          documentName: params.inputValue,
        }),
      });
    }

    return filtered;
  };

  const { spouseProfile, childProfiles } = insuranceCase;
  const hasDependants = !![spouseProfile, ...childProfiles].filter(Boolean)
    .length;

  return (
    <>
      <Autocomplete
        multiple
        freeSolo
        value={documentReviews as any}
        options={options}
        onChange={onChange}
        disabled={isDisabled}
        filterSelectedOptions
        selectOnFocus
        clearOnBlur
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        handleHomeEndKeys
        renderInput={params => (
          <TextField
            {...params}
            label={t('requestAdditionalDocuments')}
            placeholder="Document"
          />
        )}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isDialogOpened}
        onClose={closeDialog}
      >
        <Form
          initialValues={{
            ...formInitialValues,
            profileType: hasDependants ? undefined : 'main_0',
          }}
          onSubmit={upsertReview}
          validate={validate}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle sx={{ pb: 2 }}>
                {t('requestAdditionalDocument')}
              </DialogTitle>
              <DialogContent>
                <Field component={() => null} name="documentNameInitial" />
                <TextFieldRFF
                  autoFocus
                  label={t('documentName')}
                  margin="dense"
                  name="documentName"
                />
                <TextFieldRFF
                  label={t('document for')}
                  disabled={!hasDependants}
                  margin="dense"
                  name="profileType"
                  select
                >
                  <MenuItem value="main_0">
                    {getProfileTypeWithFullName({
                      insuranceCase,
                      profileType: 'main',
                      t,
                    })}
                  </MenuItem>
                  {spouseProfile && (
                    <MenuItem value="spouse_0">
                      {getProfileTypeWithFullName({
                        insuranceCase,
                        profileType: 'spouse',
                        t,
                      })}
                    </MenuItem>
                  )}
                  {childProfiles.map(({ index }) => (
                    <MenuItem key={`child_${index}`} value={`child_${index}`}>
                      {getProfileTypeWithFullName({
                        index,
                        insuranceCase,
                        profileType: 'child',
                        t,
                      })}
                    </MenuItem>
                  ))}
                </TextFieldRFF>
                <TextFieldRFF
                  rows={4}
                  multiline
                  label={t('documentDescription')}
                  margin="dense"
                  name="rejectionReason"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog}>{t('cancel')}</Button>
                <Button type="submit">{t('request')}</Button>
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    </>
  );
};
