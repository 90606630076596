import { GQL } from '@queries';
import { Column, EntityTable } from '@components/EntityTable';
import React, { FC } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
// import { GetInviteCodesPaged_inviteCodesPaged_edges_node as InviteCode } from '@common/queries/types/GetInviteCodesPaged';
import { OrderDirection } from '@shared/constants';
import { DateTime } from 'luxon';
import { AdvertiserRefLinkActionsCell } from './AdvertiserRefLinkActionsCell';
import { AdvertiserRefLinkLinkCell } from './AdvertiserRefLinkLinkCell';

const getColumns = ({ t }: { t: TFunction }): Column<any>[] => [
  {
    id: 'link',
    isSortable: false,
    label: t('advertiserRefLinkTable.referralLink'),
    renderCell: ({ data: { link } }) => (
      <AdvertiserRefLinkLinkCell link={link} />
    ),
    width: 1200,
  },
  {
    id: 'createdAt',
    isSortable: true,
    label: t('createdAt'),
    renderCell: ({ data: { createdAt } }) =>
      DateTime.fromISO(createdAt).toFormat('dd.MM.yyyy'),
    width: 600,
  },
  {
    id: 'casesCount',
    isSortable: true,
    label: t('advertiserRefLinkTable.casesCount'),
    renderCell: ({ data: { casesCount } }) => casesCount,
    width: 600,
  },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    renderCell: ({ data }) => (
      <AdvertiserRefLinkActionsCell advertiserRefLink={data} />
    ),
    width: 90,
  },
];

export const AdvertiserRefLinksTable: FC = () => {
  const { t } = useTranslation();
  return (
    <EntityTable
      isSearchEnabled={false}
      query={GQL.ADVERTISER_REF_LINK.ADVERTISER_REF_LINKS_PAGED}
      columns={getColumns({ t })}
      defaultSortOptions={{
        orderBy: 'createdAt',
        orderDirection: OrderDirection.Desc,
      }}
      baseQueryOptions={{
        fetchPolicy: 'network-only',
      }}
    />
  );
};
