import SimpleBarReact, { Props } from 'simplebar-react';
import { alpha, styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';

const RootStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
  '& .simplebar-scrollbar': {
    '&.simplebar-visible:before': {
      opacity: 1,
    },
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  maxHeight: '100%',
}));

export const Scrollbar: FC<BoxProps & Props> = ({ children, sx, ...other }) => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
};
