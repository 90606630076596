import { FC, memo, useEffect } from 'react';
import { useAuth } from '@common/hooks';

interface Props {
  hideCategorySelect?: boolean;
  hideHelpCenterButton?: boolean;
  hideHelpRequestButton?: boolean;
}

export const HelpFab: FC<Props> = memo(
  ({ hideCategorySelect, hideHelpCenterButton, hideHelpRequestButton }) => {
    const { currentUser } = useAuth();

    useEffect(() => {
      (window as any).Intercom('boot', {
        // todo move to env
        api_base: 'https://api-iam.eu.intercom.io',
        // todo move to env
        app_id: 'zorw26zx',
        ...(currentUser
          ? {
              ins_internalLocale: currentUser.locale,
              user_hash: currentUser.intercomHash,
              user_id: currentUser.id,
            }
          : {}),
      });
    }, [currentUser]);

    return null;
  },
);
