import * as React from 'react';
import { useMemo } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Autocomplete, AutocompleteProps } from '../Autocomplete';
import countries from './countries.json';

interface CountryData {
  alpha2: string;
  alpha3: string;
  name: string;
  nameDE: string;
}

interface Option {
  value: string;
  label: string;
}

const forceReplaceFlag = (country: string): string =>
  ({
    EA: 'ES',
    IC: 'ES',
  }[country] ?? country);

interface Props<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'options' | 'label'
  > {
  label?: string;
  name: string;
  excludeCountries?: string[];
  includeCountries?: string[];
}

export const CountrySelect = React.forwardRef(
  (
    {
      label,
      name,
      excludeCountries: excludeCountryCodes = [],
      includeCountries: includeCountryCodes = [],
      ...restProps
    }: Props<boolean, boolean, boolean>,
    ref,
  ) => {
    const { t } = useTranslation();
    const options: Option[] = useMemo(() => {
      const mapCountryDataToFormFieldOption = ({
        name: countryName,
        alpha2,
      }: CountryData) => ({
        label: countryName,
        value: alpha2,
      });
      const includedCountries = countries.filter(data =>
        includeCountryCodes.includes(data.alpha2),
      );

      if (excludeCountryCodes.includes('all')) {
        return includedCountries
          .map(mapCountryDataToFormFieldOption)
          .sort((a, b) => a.label.localeCompare(b.label));
      }

      return [...includedCountries, ...countries]
        .filter(({ alpha2 }) => !excludeCountryCodes.includes(alpha2))
        .map(mapCountryDataToFormFieldOption)
        .sort((a, b) => a.label.localeCompare(b.label));
    }, [includeCountryCodes, excludeCountryCodes]);

    return (
      <Autocomplete
        {...restProps}
        sx={{ width: '100%' }}
        label={label || t('selectCountry')}
        name={name}
        options={options}
        getOptionLabel={(selectedOption: Option) => selectedOption?.label || ''}
        getOptionValue={(selectedOption: Option) => selectedOption?.value || ''}
        isOptionEqualToValue={(option: Option, selectedOption) =>
          option.value === selectedOption?.value
        }
        renderOption={(props, option: Option) => {
          const CountryFlag = Flags[forceReplaceFlag(option.value)];
          if (!CountryFlag) {
            return option.label;
          }
          return (
            <Box
              {...props}
              key={option.value}
              sx={{ p: 1 }}
              component="span"
              display="flex"
              alignItems="center"
            >
              <Box component="span" marginRight={1}>
                <CountryFlag width={18} />
              </Box>
              {option.label}
            </Box>
          );
        }}
        ref={ref}
      />
    );
  },
);
