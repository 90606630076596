import { GetAdvertiserUndersPaged_undersPaged_edges_node as UnderAdvertiser } from '@queries/types/GetAdvertiserUndersPaged';
import { GQL } from '@queries';
import { Column, EntityTable } from '@components/EntityTable';
import React, { FC } from 'react';
import { TFunction } from 'i18next';
import { AdvertiserStatusBadge } from '@components/AdvertiserStatusBadge';
import { useTranslation } from 'react-i18next';
import { UnderAdvertiserProvisionCell } from '@src/components';
import { Maybe } from '@shared/types';

const getColumns = ({
  t,
  mainAdvertiserProvision,
}: {
  t: TFunction;
  mainAdvertiserProvision: Maybe<string>;
}): Column<UnderAdvertiser>[] => [
  {
    id: 'publicId',
    isSortable: true,
    label: t('advertiserPublicId'),
    renderCell: ({ data: { publicId } }) => publicId,
  },
  { id: 'casesCount', label: t('casesCount') },
  {
    id: 'provision',
    isSortable: true,
    label: t('commissionRate'),
    renderCell: ({ data: { provision, id } }) => (
      <UnderAdvertiserProvisionCell
        provision={provision}
        advertiserId={id}
        mainAdvertiserProvision={mainAdvertiserProvision}
      />
    ),
  },
  {
    id: 'status',
    isSortable: true,
    label: t('status'),
    renderCell: ({ data: { status } }) => (
      <AdvertiserStatusBadge status={status} />
    ),
  },
];

interface Props {
  advertiserId?: string;
  mainAdvertiserProvision: Maybe<string>;
}

export const AdvertiserUndersTable: FC<Props> = ({
  advertiserId,
  mainAdvertiserProvision,
}) => {
  const { t } = useTranslation();

  return (
    <EntityTable<UnderAdvertiser>
      query={GQL.ADVERTISER.UNDERS_PAGED}
      columns={getColumns({ mainAdvertiserProvision, t })}
      baseQueryOptions={{
        variables: {
          advertiserId,
          orderBy: 'createdAt',
          orderDirection: 'Desc',
        },
      }}
    />
  );
};
