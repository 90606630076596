import { TFunction } from 'i18next';

// this shit mostly to avoid autodelete of unusted keys on
// yarn i18n:extract
export const getPdfFieldLabel = (
  pdfFieldName: string,
  t: TFunction,
  shouldMatchPostix?: boolean,
): string => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [type, index, fieldName, postfix] = pdfFieldName.split('_');

  const labels = {
    'address.country,address.place': t(
      'pdfFieldLabel.address.country,address.place',
    ),
    'address.place': t('pdfFieldLabel.address.place'),
    'address.streetName,address.houseNumber': t(
      'pdfFieldLabel.address.streetName,address.houseNumber',
    ),
    'address.zipCode': t('pdfFieldLabel.address.zipCode'),
    'birthCountry,birthPlace': t('pdfFieldLabel.birthCountry,birthPlace'),
    birthDate: t('pdfFieldLabel.birthDate'),
    birthPlace: t('pdfFieldLabel.birthPlace'),
    currentInsuraneProviderName: t('pdfFieldLabel.currentInsuraneProviderName'),
    email: t('pdfFieldLabel.email'),
    'employerAddress.zipCode,employerAddress.place,employerAddress.streetName,employerAddress.houseNumber':
      t(
        'pdfFieldLabel.employerAddress.zipCode,employerAddress.place,employerAddress.streetName,employerAddress.houseNumber',
      ),
    employerName: t('pdfFieldLabel.employerName'),
    firstName: t('pdfFieldLabel.firstName'),
    gender: t('pdfFieldLabel.gender'),
    gender_female: t('gender.female'),
    gender_indeterminate: t('gender.indeterminate'),
    gender_male: t('gender.male'),
    gender_other: t('gender.other'),
    hasEmployerPensionOrLumpSum: t('pdfFieldLabel.hasEmployerPensionOrLumpSum'),
    hasFamilyRelationshipToEmployer: t(
      'pdfFieldLabel.hasFamilyRelationshipToEmployer',
    ),
    hasPreviousInsurance: t('pdfFieldLabel.hasPreviousInsurance'),
    hasPreviousInsurance_no: t('pdfFieldLabel.hasPreviousInsurance_no'),
    hasPreviousInsurance_yes: t('pdfFieldLabel.hasPreviousInsurance_yes'),
    hasWillingCommunicateEnglish: t(
      'pdfFieldLabel.hasWillingCommunicateEnglish',
    ),
    hasWillingToBeReferral: t('pdfFieldLabel.hasWillingToBeReferral'),
    insurancePurposes: t('pdfFieldLabel.insurancePurpose'),
    insurancePurposes_employment: t('employment'),
    insurancePurposes_partner: t('partner'),
    insurancePurposes_pension: t('pension'),
    insurancePurposes_study: t('study'),
    insurancePurposes_trainee: t('trainee'),
    insurancePurposes_unemployment: t('unemployment'),
    isAgriculturalEntrepreneur: t('pdfFieldLabel.isAgriculturalEntrepreneur'),
    isEmployed: t('pdfFieldLabel.isEmployed'),
    isEmployed_no: t('pdfFieldLabel.isEmployed_no'),
    isEmployed_yes: t('pdfFieldLabel.isEmployed_yes'),
    isSpouseParent: t('pdfFieldLabel.isSpouseParent'),
    isSpouseParent_no: t('pdfFieldLabel.isSpouseParent_no'),
    jobIncome: t('pdfFieldLabel.jobIncome'),
    jobStartDate: t('pdfFieldLabel.jobStartDate'),
    'jobStartDate,jobEndDate': t('pdfFieldLabel.jobStartDate,jobEndDate'),
    lastName: t('pdfFieldLabel.lastName'),
    'lastName,firstName': t('pdfFieldLabel.lastName,firstName'),
    'militaryOrVoluntaryStartDate,militaryOrVoluntaryEndDate': t(
      'pdfFieldLabel.militaryOrVoluntaryStartDate,militaryOrVoluntaryEndDate',
    ),
    miniJobIncome: t('pdfFieldLabel.miniJobIncome'),
    'miniJobStartDate,miniJobEndDate': t(
      'pdfFieldLabel.miniJobStartDate,miniJobEndDate',
    ),
    mobilePhone: t('pdfFieldLabel.mobilePhone'),
    'nationality,secondNationality': t(
      'pdfFieldLabel.nationality,secondNationality',
    ),
    otherIncome: t('pdfFieldLabel.otherIncome'),
    otherInsurancePurpose: t('pdfFieldLabel.otherInsurancePurpose'),
    otherInsurancePurposeStartDate: t(
      'pdfFieldLabel.otherInsurancePurposeStartDate',
    ),
    pensionAppliedStartDate: t('pdfFieldLabel.pensionAppliedStartDate'),
    pensionInsuranceNumber: t('pdfFieldLabel.pensionInsuranceNumber'),
    pensionStartDate: t('pdfFieldLabel.pensionStartDate'),
    phone: t('pdfFieldLabel.phone'),
    previousInsuranceEndDate: t('pdfFieldLabel.previousInsuranceEndDate'),
    previousInsuranceFamilyMemberBirthDate: t(
      'pdfFieldLabel.previousInsuranceFamilyMemberBirthDate',
    ),
    previousInsuranceFamilyMemberFirstName: t(
      'pdfFieldLabel.previousInsuranceFamilyMemberFirstName',
    ),
    previousInsuranceFamilyMemberLastName: t(
      'pdfFieldLabel.previousInsuranceFamilyMemberLastName',
    ),
    'previousInsuranceFamilyMemberLastName,previousInsuranceFamilyMemberFirstName':
      t(
        'pdfFieldLabel.previousInsuranceFamilyMemberLastName,previousInsuranceFamilyMemberFirstName',
      ),
    previousInsuranceFamilyMemberPolicyNumber: t(
      'pdfFieldLabel.previousInsuranceFamilyMemberPolicyNumber',
    ),
    previousInsuranceProviderName: t(
      'pdfFieldLabel.previousInsuranceProviderName',
    ),
    previousInsuranceStartDate: t('pdfFieldLabel.previousInsuranceStartDate'),
    previousInsuranceType: t('pdfFieldLabel.previousInsuranceType'),
    previousInsuranceType_familyMember: t('insuranceType.familyInsurance'),
    previousInsuranceType_mainMember: t('insuranceType.membership'),
    previousInsuranceType_notStatutory: t('insuranceType.notStatutory'),
    previousInsuraneEndDate: t('pdfFieldLabel.previousInsuraneEndDate'),
    previousInsuraneProviderName: t(
      'pdfFieldLabel.previousInsuraneProviderName',
    ),
    previousLastName: t('pdfFieldLabel.previousLastName'),
    relationship: t('pdfFieldLabel.relationship'),
    salaryRange: t('pdfFieldLabel.salaryRange'),
    studyEndDate: t('pdfFieldLabel.studyEndDate'),
    studyGradeOrSemester: t('pdfFieldLabel.studyGradeOrSemester'),
    studyStartDate: t('pdfFieldLabel.studyStartDate'),
    'studyStartDate,studyEndDate': t(
      'pdfFieldLabel.studyStartDate,studyEndDate',
    ),
    studyType: t('pdfFieldLabel.studyType'),
    unemploymentBenefitStartDate: t(
      'pdfFieldLabel.unemploymentBenefitStartDate',
    ),
    unemploymentStartDate: t('pdfFieldLabel.unemploymentStartDate'),
  };

  return shouldMatchPostix
    ? labels[`${fieldName}_${postfix}`] ?? labels[fieldName]
    : labels[fieldName] ?? fieldName;
};
