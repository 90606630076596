import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Column, EntityTable } from '@components/EntityTable';
import {
  Avatar,
  Checkbox,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { GQL } from '@queries';
import { TFunction } from 'i18next';
import { GetInsuranceCasesPaged_insuranceCasesPaged_edges_node as InsuranceCase } from '@queries/types/GetInsuranceCasesPaged';
import { InsuranceCaseStatusBadge } from '@components/InsuranceCaseStatusBadge';
import { useNavigate } from 'react-router';
import { ROUTES } from '@common/routes';
import { getConditionalArr } from '@shared/helpers';
import { OrderDirection, PoaStatus, UserRole } from '@shared/constants';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { DateTime } from 'luxon';
import { InsuranceCaseActionsCell } from '../BranchApplicantsPage/InsuranceCaseActionsCell';
import { InsuranceCaseStatus } from '../../../types/globalTypes';

const hiddenStatuses = new Set([InsuranceCaseStatus.DocumentsGenerating]);
const filterByStatusValues = Object.values(InsuranceCaseStatus).filter(
  val => !hiddenStatuses.has(val),
);

const getColumns = ({
  t,
  isMobile,
  role,
}: {
  t: TFunction;
  isMobile: boolean;
  role: UserRole;
}): Column<InsuranceCase>[] => [
  {
    id: 'profile.firstName',
    isSortable: true,
    label: t('name'),
    renderCell: ({ data }) => {
      const { firstName, lastName } = data.profile;
      const fullName = [firstName, lastName].join(' ').trim();

      return (
        <Stack direction="row" spacing={1.5} alignItems="center">
          {fullName && (
            <Avatar sx={{ fontSize: 14, height: 32, width: 32 }}>
              {fullName.slice(0, 2).toUpperCase()}
            </Avatar>
          )}
          <Typography variant="body2">{fullName || '-'}</Typography>
        </Stack>
      );
    },
  },
  ...getConditionalArr(role === UserRole.Administrator, [
    { id: 'profile.email', isSortable: true, label: t('email') },
  ]),
  ...getConditionalArr(!isMobile, [
    {
      id: 'dependants',
      isSortable: false,
      label: t('dependants'),
      renderCell: ({ data: { childProfiles, spouseProfile, status } }) => (
        <Typography>
          {[
            InsuranceCaseStatus.ApplicationFilling,
            InsuranceCaseStatus.Draft,
            InsuranceCaseStatus.Created,
          ].includes(status)
            ? t('tbd')
            : childProfiles.length + Number(!!spouseProfile)}
        </Typography>
      ),
    },
  ]),
  ...getConditionalArr(!isMobile, [
    {
      id: 'submittedAt',
      isSortable: true,
      label: t('submittedAt'),
      renderCell: ({ data: { submittedAt } }) => (
        <Typography variant="body2">
          {submittedAt
            ? DateTime.fromISO(submittedAt).toFormat('dd.MM.yyyy HH:mm')
            : '-'}
        </Typography>
      ),
    },
  ]),
  ...getConditionalArr(!isMobile, [
    {
      id: 'poaStatus',
      isSortable: true,
      label: t('powerOfAttorney'),
      renderCell: ({ data: { poaStatus } }) => (
        <Checkbox
          onClick={event => event.stopPropagation()}
          checked={poaStatus !== PoaStatus.NotAvailable}
        />
      ),
    },
  ]),
  {
    filterValues: filterByStatusValues,
    filterView: status => <InsuranceCaseStatusBadge status={status} />,
    id: 'status',
    isSortable: true,
    label: t('status'),
    renderCell: ({ data: { status } }) => (
      <InsuranceCaseStatusBadge status={status} />
    ),
  },
  ...getConditionalArr([UserRole.Administrator].includes(role), [
    {
      id: 'actions',
      isSortable: true,
      renderCell: ({ data }) => (
        <InsuranceCaseActionsCell userRole={role} insuranceCase={data} />
      ),
    },
  ]),
];

type Props = {
  advertiserRefLinkIds?: string[];
  advertiserIds?: string[];
};

export const AdvertiserInsuranceCasesTable: FC<Props> = ({
  advertiserRefLinkIds = [],
  advertiserIds = [],
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { role } = useCurrentUser();

  return (
    <EntityTable<InsuranceCase>
      defaultSortOptions={{
        orderBy: 'submittedAt',
        orderDirection: OrderDirection.Desc,
      }}
      baseQueryOptions={{
        variables: {
          ...(advertiserRefLinkIds?.length ? { advertiserRefLinkIds } : {}),
          ...(advertiserIds?.length ? { advertiserIds } : {}),
        },
      }}
      query={GQL.INSURANCE_CASE.INSURANCE_CASES_PAGED}
      columns={getColumns({ isMobile, role, t })}
      onRowClick={({ data: { id, status: caseStatus } }) => {
        if (caseStatus === InsuranceCaseStatus.Draft) {
          navigate(
            ROUTES.ADVERTISER.insuranceCaseDraft({ insuranceCaseId: id }),
          );
        } else {
          window.open(
            ROUTES.ADVERTISER.insuranceCaseDetails({
              insuranceCaseId: id,
            }),
            '_blank',
          );
        }
      }}
    />
  );
};
