import { gql } from '@apollo/client';

const AdvertiserRefLinkFrag = gql`
  fragment AdvertiserRefLinkFragment on AdvertiserRefLink {
    casesCount
    createdAt
    id
    link
  }
`;

export const ADVERTISER_REF_LINK = {
  ADVERTISER_REF_LINKS_PAGED: gql`
    query GetAdvertiserRefLinksPaged(
      $after: String
      $first: Int
      $orderBy: String
      $orderDirection: OrderDirection
    ) {
      advertiserRefLinksPaged(
        after: $after
        first: $first
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
          endCursor
          startCursor
        }
        edges {
          node {
            ...AdvertiserRefLinkFragment
          }
        }
      }
    }
    ${AdvertiserRefLinkFrag}
  `,
  CHECK_IS_VALID: gql`
    mutation CheckIsAdvertiserRefLinkValid($linkValue: String!) {
      advertiserRefLinkMutations {
        checkIsValid(linkValue: $linkValue)
      }
    }
  `,
  CREATE: gql`
    mutation CreateAdvertiserRefLink($linkValue: String!) {
      advertiserRefLinkMutations {
        create(linkValue: $linkValue) {
          ...AdvertiserRefLinkFragment
        }
      }
    }
    ${AdvertiserRefLinkFrag}
  `,
  DELETE: gql`
    mutation DeleteAdvertiserRefLink($advertiserRefLinkId: String!) {
      advertiserRefLinkMutations {
        delete(advertiserRefLinkId: $advertiserRefLinkId)
      }
    }
  `,
};
