import { motion } from 'framer-motion';
import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';
import { varSmallClick, varMediumClick } from './variants';

interface ButtonAnimateProps extends BoxProps {
  mediumClick?: boolean;
}

export const ButtonAnimate: FC<ButtonAnimateProps> = ({
  mediumClick = false,
  children,
  sx,
  ...other
}) => (
  <Box
    component={motion.div}
    whileTap="tap"
    whileHover="hover"
    variants={mediumClick ? varMediumClick : varSmallClick}
    sx={{ display: 'inline-flex', ...sx }}
    {...other}
  >
    {children}
  </Box>
);
