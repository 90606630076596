import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from '@common/routes';

declare const Reamaze: any;
declare let _support: any;

const SCRIPT_ID = 'reamaze';

// todo more permission check
// todo remove script on logout
export const HelpCenterPage: FC = () => {
  // const { currentUser } = useAuth();
  const navigate = useNavigate();
  // const location = useLocation();
  // const {
  //   i18n: { language },
  // } = useTranslation();
  // const role = currentUser?.role;

  useEffect(() => {
    navigate(ROUTES.AUTH.login());
  }, []);

  return null;
};
