import { FC, useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { GQL } from '@queries';
import { useTranslation } from 'react-i18next';
import { makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { Checkboxes, Select, TextField } from '@common/forms/fields';
import { DependantsType, InsurancePurpose, PoaStatus } from '@shared/constants';
import { useToggle } from '@common/hooks';
import { CreateInsuranceCase } from '@queries/types/CreateInsuranceCase';
import { InsuranceCaseFragment } from '@queries/types/InsuranceCaseFragment';
import createCalculateDecorator from 'final-form-calculate';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useMutation } from '@apollo/client';
import {
  compose,
  removeSpaces,
  toLowerCase,
} from '@common/forms/normalization';
import { EMAIL_REGEX, LATIN_REG_EXP } from '@shared/regex';

const MISSING_INFO_VALUES_LIMIT = 3;
const MISSING_INFO_VALUE = 'MISSING_INFO_VALUE';

type FormValues = {
  email?: string;
  firstName?: string;
  childrenCount?: number;
  insurancePurposes?: InsurancePurpose[];
  poaStatus?: PoaStatus.NotAvailable;
  isInsurancePurposeKnown?: 'yes' | 'no';
  previousInsurance?: 'yes' | 'no' | typeof MISSING_INFO_VALUE;
  dependantsType?: DependantsType | 'no' | typeof MISSING_INFO_VALUE;
};

type Props = {
  initialValues?: Partial<FormValues>;
  onComplete?: (entity: InsuranceCaseFragment) => void;
  onDismiss?: () => void;
};

const OverlayContainer = styled(Box)`
  & {
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const calculateFields = createCalculateDecorator(
  {
    field: 'poaStatus',
    updates: {
      dependantsType: () => null,
      insurancePurposes: () => [],
      isInsurancePurposeKnown: () => null,
      previousInsurance: () => null,
    },
  },
  {
    field: 'dependantsType',
    updates: {
      childrenCount: (fieldValue, allValues: any) =>
        ![DependantsType.ChildrenAndSpouse, DependantsType.Children].includes(
          fieldValue,
        )
          ? undefined
          : allValues.childrenCount,
    },
  },
  {
    field: 'isInsurancePurposeKnown',
    updates: {
      insurancePurposes: fieldValue => [],
    },
  },
);

export const CreateInsuranceCaseForm: FC<Props> = ({
  initialValues = {},
  onComplete,
  onDismiss,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [
    isConfirmationOpened,
    { toggleOn: openConfirmation, toggleOff: closeConfirmation },
  ] = useToggle();
  const { advertiserId } = useCurrentUser();
  const [createInsuranceCase] = useMutation<CreateInsuranceCase>(
    GQL.INSURANCE_CASE.CREATE,
  );

  const onSubmit = async (values: FormValues) => {
    try {
      const dependantsType =
        DependantsType[values.dependantsType as string] ||
        { no: null }[values.dependantsType as string];
      const insurancePurposes = values.insurancePurposes;
      const hasPreviousInsurance = { no: false, yes: true }[
        values.previousInsurance as string
      ];
      const { email, childrenCount, firstName, poaStatus } = values;
      const { data } = await createInsuranceCase({
        variables: {
          advertiserId,
          data: {
            childrenCount,
            dependantsType,
            email,
            firstName,
            hasPreviousInsurance,
            insurancePurposes,
            poaStatus,
          },
        },
      });
      enqueueSnackbar(t('applicationCreatedSuccessfully'), {
        variant: 'success',
      });
      const entity = data?.insuranceCaseMutations?.create;
      if (entity) {
        onComplete?.(entity);
      }
    } catch (error: any) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          childrenCount: Yup.number()
            .nullable()
            .when('dependantsType', {
              is: value =>
                [
                  DependantsType.Children,
                  DependantsType.ChildrenAndSpouse,
                ].includes(value),
              then: Yup.number()
                .nullable()
                .min(
                  1,
                  t('validation.valueShouldBeGreaterThanOrEqual', {
                    number: 1,
                  }),
                )
                .max(
                  10,
                  t('validation.valueShouldBeLowerThanOrEqual', { number: 10 }),
                )
                .required(t('validation.required')),
            }),
          dependantsType: Yup.string()
            .nullable()
            .when('poaStatus', {
              is: value => value === PoaStatus.NotAvailable,
              then: Yup.string().nullable().required(t('validation.required')),
            }),
          email: Yup.string()
            .matches(EMAIL_REGEX, t('validation.validEmail'))
            .required(t('validation.required')),
          firstName: Yup.string()
            .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
            .required(t('validation.required')),
          insurancePurposes: Yup.array()
            .ensure()
            .when('isInsurancePurposeKnown', {
              is: value => value === 'yes',
              then: Yup.array().ensure().min(1, t('validation.required')),
            }),
          isInsurancePurposeKnown: Yup.string()
            .nullable()
            .when('poaStatus', {
              is: value => value === PoaStatus.NotAvailable,
              then: Yup.string().nullable().required(t('validation.required')),
            }),
          poaStatus: Yup.string().required(t('validation.required')),
          previousInsurance: Yup.string()
            .nullable()
            .when('poaStatus', {
              is: value => value === PoaStatus.NotAvailable,
              then: Yup.string().nullable().required(t('validation.required')),
            }),
        }) as any,
      ),
    [t],
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      decorators={[calculateFields]}
    >
      {({ submitting, handleSubmit, values, valid }) => {
        const missingValuesCount = ['previousInsurance', 'dependantsType']
          .filter(key =>
            Array.isArray(values[key])
              ? values[key].includes(MISSING_INFO_VALUE)
              : values[key] === MISSING_INFO_VALUE,
          )
          .concat(
            values.isInsurancePurposeKnown === 'no'
              ? ['insurancePurposes']
              : [],
          ).length;
        const isConfirmationRequired =
          missingValuesCount === MISSING_INFO_VALUES_LIMIT &&
          valid &&
          values.poaStatus === 'NotAvailable';
        const hasChildren = (
          [
            DependantsType.ChildrenAndSpouse,
            DependantsType.Children,
          ] as string[]
        ).includes(values.dependantsType as string);

        return (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            style={{ position: 'relative' }}
          >
            {isConfirmationOpened ? (
              <OverlayContainer>
                <LoadingButton
                  loading={submitting}
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ mb: 1 }}
                >
                  {t('sendToApplicantForFilling')}
                </LoadingButton>
                <Button
                  onClick={() =>
                    onDismiss ? onDismiss() : closeConfirmation()
                  }
                  disabled={submitting}
                >
                  {t('discardAndQuit')}
                </Button>
              </OverlayContainer>
            ) : (
              <>
                {values.poaStatus === PoaStatus.NotAvailable && (
                  <Typography gutterBottom={true}>
                    {t('createInsuranceCaseForm.prompt')}
                  </Typography>
                )}
                <Stack spacing={2} sx={{ mb: 2 }}>
                  <TextField
                    autoFocus
                    name="firstName"
                    margin="dense"
                    label={t('firstName')}
                  />
                  <TextField
                    name="email"
                    margin="dense"
                    type="email"
                    label={t('email')}
                    parse={compose(removeSpaces, toLowerCase)}
                  />
                  <Select
                    name="poaStatus"
                    label={t('isPowerOfAttorneyAvailable')}
                  >
                    <MenuItem value={PoaStatus.Uploading}>
                      {t('yesIwantToUpload')}
                    </MenuItem>
                    <MenuItem value={PoaStatus.Generating}>
                      {t('generateAndSendToApplicant')}
                    </MenuItem>
                    <MenuItem value={PoaStatus.NotAvailable}>
                      {t('noIWantDelegate')}
                    </MenuItem>
                  </Select>
                  {values.poaStatus === PoaStatus.NotAvailable && (
                    <>
                      <Select
                        name="isInsurancePurposeKnown"
                        label={t('isInsurancePurposeKnown')}
                      >
                        <MenuItem value="yes">{t('yes')}</MenuItem>
                        <MenuItem value="no">
                          {t('notSureAskApplicant')}
                        </MenuItem>
                      </Select>
                      {values.isInsurancePurposeKnown === 'yes' && (
                        <Checkboxes
                          name="insurancePurposes"
                          data={[
                            {
                              label: t('study') as string,
                              value: InsurancePurpose.Study,
                            },
                            {
                              label: t('pension') as string,
                              value: InsurancePurpose.Pension,
                            },
                            {
                              label: t('unemployment') as string,
                              value: InsurancePurpose.Unemployment,
                            },
                            {
                              label: t('employment') as string,
                              value: InsurancePurpose.Employment,
                            },
                            {
                              label: t('partner') as string,
                              value: InsurancePurpose.Partner,
                            },
                            {
                              label: t('trainee') as string,
                              value: InsurancePurpose.Trainee,
                            },
                            {
                              label: t('occupation.other') as string,
                              value: InsurancePurpose.Other,
                            },
                          ]}
                        />
                      )}
                      <Select
                        name="previousInsurance"
                        label={t('hasApplicantPreviouslyBeenInsuredInGermany')}
                      >
                        <MenuItem value="yes">{t('yes')}</MenuItem>
                        <MenuItem value="no">{t('no')}</MenuItem>
                        <MenuItem value={MISSING_INFO_VALUE}>
                          {t('notSureAskApplicant')}
                        </MenuItem>
                      </Select>
                      <Select
                        name="dependantsType"
                        label={t(
                          'text.willThereBeDependentsCoveredByThisInsurance',
                        )}
                      >
                        <MenuItem value="no">{t('no')}</MenuItem>
                        <MenuItem value={DependantsType.Spouse}>
                          {t('spouse')}
                        </MenuItem>
                        <MenuItem value={DependantsType.Children}>
                          {t('children')}
                        </MenuItem>
                        <MenuItem value={DependantsType.ChildrenAndSpouse}>
                          {t('childrenAndSpouse')}
                        </MenuItem>
                        <MenuItem value={MISSING_INFO_VALUE}>
                          {t('notSureAskApplicant')}
                        </MenuItem>
                      </Select>
                      {hasChildren && (
                        <Select name="childrenCount" label={t('childrenCount')}>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                        </Select>
                      )}
                    </>
                  )}
                </Stack>
                {missingValuesCount > 0 && (
                  <Alert sx={{ mb: 2 }} severity="info">
                    {t(
                      'text.onceYouSentWeWillAskApplicantToProvideMissingInfo',
                    )}
                  </Alert>
                )}
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  {onDismiss && (
                    <Button onClick={() => onDismiss?.()} disabled={submitting}>
                      {t('cancel')}
                    </Button>
                  )}
                  <LoadingButton
                    loading={submitting}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() =>
                      isConfirmationRequired
                        ? openConfirmation()
                        : handleSubmit()
                    }
                  >
                    {t('createApplication')}
                  </LoadingButton>
                </Stack>
              </>
            )}
          </form>
        );
      }}
    </Form>
  );
};
