import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PageActionsFooter = styled(props => (
  <Stack {...props} spacing={2} direction="row" />
))(({ theme }) => ({
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  flexDirection: 'row',
  height: theme.spacing(9),
  padding: theme.spacing(0, 4),
}));
