import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { Stack, Typography } from '@mui/material';
import { DatePicker, TextField } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { InsuranceType } from '@shared/constants';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_hasPreviousInsurance: Yup.boolean()
      .nullable()
      .required(t('validation.required')),
    main_0_previousInsuranceEndDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
    main_0_previousInsuranceFamilyMemberBirthDate: Yup.string().nullable(),
    main_0_previousInsuranceFamilyMemberFirstName: Yup.string().nullable(),
    main_0_previousInsuranceFamilyMemberLastName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    // Family covered
    main_0_previousInsuranceFamilyMemberPolicyNumber: Yup.string().nullable(),
    main_0_previousInsuranceProviderName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    main_0_previousInsuranceStartDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
    main_0_previousInsuranceType: Yup.string().nullable(),
  });

const previousInsuranceFields = [
  'main_0_previousInsuranceType',
  'main_0_previousInsuranceStartDate',
  'main_0_previousInsuranceEndDate',
  'main_0_previousInsuranceProviderName',
];

const previousInsuranceFamilyFields = [
  'main_0_previousInsuranceFamilyMemberFirstName',
  'main_0_previousInsuranceFamilyMemberLastName',
  'main_0_previousInsuranceFamilyMemberPolicyNumber',
  'main_0_previousInsuranceFamilyMemberBirthDate',
];

const getActiveFields = values => ({
  ...previousInsuranceFields.reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: !!values.main_0_hasPreviousInsurance,
    }),
    {},
  ),
  ...previousInsuranceFamilyFields.reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]:
        values.main_0_previousInsuranceType === InsuranceType.FamilyMember,
    }),
    {},
  ),
});

export const PreviousInsuranceStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.previousInsurance')}</Typography>
      </StepTitle>
      <QuestionField
        name="main_0_hasPreviousInsurance"
        question={getLabel('main_0_hasPreviousInsurance', t, { isDraft })}
      />

      <ConditionalFields fieldNames={previousInsuranceFields}>
        <QuestionField
          name="main_0_previousInsuranceType"
          question={getLabel('main_0_previousInsuranceType', t, { isDraft })}
          rightQuestionProps={{
            label: getLabel('iWasTheMainHolderOfMyPreviousIns', t, {
              isDraft,
            }),
            value: InsuranceType.MainMember,
          }}
          leftQuestionProps={{
            label: getLabel('iWasCoveredByFamilyInsurancePolicy', t, {
              isDraft,
            }),
            value: InsuranceType.FamilyMember,
          }}
        />

        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name="main_0_previousInsuranceStartDate"
            label={getLabel('main_0_previousInsuranceStartDate', t)}
          />
          <DatePicker
            name="main_0_previousInsuranceEndDate"
            label={getLabel('main_0_previousInsuranceEndDate', t)}
          />
        </Stack>

        <TextField
          name="main_0_previousInsuranceProviderName"
          label={getLabel('main_0_previousInsuranceProviderName', t)}
        />

        <ConditionalFields fieldNames={previousInsuranceFamilyFields}>
          <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
            <TextField
              name="main_0_previousInsuranceFamilyMemberFirstName"
              label={getLabel(
                'main_0_previousInsuranceFamilyMemberFirstName',
                t,
              )}
            />
            <TextField
              name="main_0_previousInsuranceFamilyMemberLastName"
              label={getLabel(
                'main_0_previousInsuranceFamilyMemberLastName',
                t,
              )}
            />
          </Stack>

          <TextField
            name="main_0_previousInsuranceFamilyMemberPolicyNumber"
            label={getLabel(
              'main_0_previousInsuranceFamilyMemberPolicyNumber',
              t,
            )}
          />

          <DatePicker
            name="main_0_previousInsuranceFamilyMemberBirthDate"
            label={getLabel('main_0_previousInsuranceFamilyMemberBirthDate', t)}
          />
        </ConditionalFields>
      </ConditionalFields>
    </Stack>
  );
};

PreviousInsuranceStep.getValidationSchema = getValidationSchema;
PreviousInsuranceStep.getActiveFields = getActiveFields;
