declare module '@mui/material/styles' {
  interface Theme {
    shape: {
      borderRadius: number | string;
      borderRadiusSm: number | string;
      borderRadiusMd: number | string;
    };
  }
}

const shape = {
  borderRadius: 8,
  borderRadiusMd: 16,
  borderRadiusSm: 12,
};

export default shape;
