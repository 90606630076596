import { createContext, FC, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/browser';
import AmplifyAuth, { CognitoUser } from '@aws-amplify/auth';
import { useApolloClient } from '@apollo/client';
import { UserRole } from '@shared/constants';
import { Maybe } from '@shared/types';

export interface AuthContextInterface {
  completePassword: (arg: {
    password: string;
    attributes?: Record<string, string>;
  }) => Promise<void>;
  confirmEmail: (arg: { username: string; code: string }) => Promise<void>;
  login: (arg: { email: string; password: string }) => Promise<void>;
  resendConfirmationEmail: (email: string) => Promise<any>;
  sendPasswordRecoveryEmail: (email: string) => Promise<any>;
  logout: () => Promise<void>;
  resetPassword: (arg: {
    username: string;
    password: string;
    code: string;
  }) => Promise<string>;
  signUp: (arg: {
    email: string;
    password: string;
    attributes: Record<string, string>;
  }) => Promise<void>;
  setCognitoUser: (user: CognitoUserInterface | null) => void;
  isRequiredToChangePassword: boolean;
  isInitializing: boolean;
  currentUser: UserInterface | undefined;
  updateUserAttributes: (attributes: Record<string, string>) => void;
}

export const AuthContext = createContext<AuthContextInterface | undefined>(
  undefined,
);

export interface UserInterface {
  role: UserRole;
  email: string;
  id: string; // cognito user id
  advertiserId?: string;
  branchOfficeId?: string;
  privacyPolicyAgree: boolean;
  locale: string;
  intercomHash: string;
}

export interface CognitoUserAttributesInterface {
  'custom:privacyPolicyAgree': boolean;
  'custom:branchOfficeId': string;
  'custom:advertiserId': string;
  'custom:role': UserRole;
  email: string;
  sub: string;
  locale: string;
}

export interface CognitoUserInterface {
  id: any;
  attributes: CognitoUserAttributesInterface;
  username: string;
  challengeName?: string;
  signInUserSession: any;
}

// todo: check if we can reuse logic from services/user-service/src/lib/cognito.ts
const getCognitoUserAttributes = (
  cognitoUser: Maybe<CognitoUserInterface>,
): UserInterface | undefined => {
  if (!cognitoUser?.attributes) {
    return undefined;
  }
  const { attributes, signInUserSession } = cognitoUser;
  return {
    advertiserId: attributes['custom:advertiserId'],
    branchOfficeId: attributes['custom:branchOfficeId'],
    email: attributes.email,
    id: attributes.sub,
    intercomHash: signInUserSession?.idToken?.payload?.intercomHash,
    locale: attributes.locale,
    privacyPolicyAgree: Boolean(attributes['custom:privacyPolicyAgree']),
    role: attributes['custom:role'],
  };
};

// TODO: add errors handling
export const AuthProvider: FC = ({ children }) => {
  const apolloClient = useApolloClient();
  const [cognitoUser, setCognitoUser] = useState<CognitoUserInterface | null>(
    null,
  );
  const [isInitializing, setIsInitializing] = useState(true);

  const currentUser = useMemo(() => {
    if (!cognitoUser) return undefined;
    return getCognitoUserAttributes(cognitoUser);
  }, [cognitoUser]);

  useEffect(() => {
    (async () => {
      try {
        const authenticatedCognitoUser =
          await AmplifyAuth.currentAuthenticatedUser();
        setCognitoUser(authenticatedCognitoUser);
      } catch (e) {
        switch (e) {
          case 'The user is not authenticated':
            break;
          default:
            throw e;
        }
      } finally {
        setIsInitializing(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production' || !currentUser) {
      Sentry.setUser(null);
      return;
    }
    try {
      const { role, email, id } = currentUser;
      Sentry.setUser({ email, id, role });
    } catch (e) {
      console.error(e);
    }
  }, [cognitoUser]);

  const login: AuthContextInterface['login'] = async ({ email, password }) => {
    (window as any).Intercom('shutdown');
    const signedInCognitoUser = await AmplifyAuth.signIn({
      password,
      username: email,
    });
    setCognitoUser(signedInCognitoUser);
  };

  const logout: AuthContextInterface['logout'] = async () => {
    (window as any).Intercom('shutdown');
    await apolloClient.clearStore();
    AmplifyAuth.signOut();
    setCognitoUser(null);
  };

  const updateUserAttributes: AuthContextInterface['updateUserAttributes'] =
    async (attributes: Record<string, string>): Promise<CognitoUser> => {
      await AmplifyAuth.updateUserAttributes(cognitoUser, attributes);
      const cognitoUserUpdated = await AmplifyAuth.currentAuthenticatedUser();
      setCognitoUser(cognitoUserUpdated);

      return cognitoUserUpdated;
    };

  const signUp: AuthContextInterface['signUp'] = async ({
    email,
    password,
    attributes,
  }) => {
    await AmplifyAuth.signUp({
      attributes,
      password,
      username: email,
    });
  };

  const resendConfirmationEmail: AuthContextInterface['resendConfirmationEmail'] =
    (email: string) => AmplifyAuth.resendSignUp(email);

  const confirmEmail: AuthContextInterface['confirmEmail'] = ({
    username,
    code,
  }) => AmplifyAuth.confirmSignUp(username, code);

  const sendPasswordRecoveryEmail: AuthContextInterface['sendPasswordRecoveryEmail'] =
    email => AmplifyAuth.forgotPassword(email);

  const resetPassword: AuthContextInterface['resetPassword'] = ({
    username,
    code,
    password,
  }) => AmplifyAuth.forgotPasswordSubmit(username, code, password);

  const completePassword: AuthContextInterface['completePassword'] = async ({
    password,
    attributes = {},
  }) => {
    await AmplifyAuth.completeNewPassword(cognitoUser, password, attributes);
    const authenticatedCognitoUser =
      await AmplifyAuth.currentAuthenticatedUser();
    setCognitoUser(authenticatedCognitoUser);
  };

  // to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      completePassword,
      confirmEmail,
      currentUser,
      isInitializing,
      isRequiredToChangePassword:
        cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED',
      login,
      logout,
      resendConfirmationEmail,
      resetPassword,
      sendPasswordRecoveryEmail,
      setCognitoUser,
      signUp,
      updateUserAttributes,
    }),
    [cognitoUser, isInitializing, currentUser],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
