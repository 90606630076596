import 'array-flat-polyfill';
import { hydrate, render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import './core/i18n';
import Amplify from '@aws-amplify/auth';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Suspense } from 'react';
import { AuthProvider } from '@common/providers/AuthProvider';
import { connectSentry } from '@utils/connectSentry';
import DateFnsUtils from '@date-io/date-fns';
import { edgeNodePolyfill } from './core/polyfills/edgeNodePolyfill';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { LoadingScreen } from './components';
import { getApolloClient } from './core/apollo';
import { amplifyAuth } from './core/aws/amplifyAuth';

Amplify.configure(amplifyAuth);
connectSentry();
edgeNodePolyfill();

async function bootStrap() {
  const client = await getApolloClient();

  const AppBundle = (
    <HelmetProvider>
      <LocalizationProvider utils={DateFnsUtils} dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <Suspense
            fallback={
              <LoadingScreen
                sx={{
                  left: 0,
                  position: 'fixed',
                  top: 0,
                  width: 1,
                  zIndex: 9999,
                }}
              />
            }
          >
            <ApolloProvider client={client}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ApolloProvider>
          </Suspense>
        </BrowserRouter>
      </LocalizationProvider>
    </HelmetProvider>
  );

  const rootElement = document.getElementById('root');

  if (rootElement?.hasChildNodes()) {
    hydrate(AppBundle, rootElement);
  } else {
    render(AppBundle, rootElement);
  }
}

bootStrap();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))ss
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
