/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdvertiserPaymentStatus {
  Calculating = 'Calculating',
  Canceled = 'Canceled',
  Failed = 'Failed',
  Finished = 'Finished',
  Initialized = 'Initialized',
  Paid = 'Paid',
  Pending = 'Pending',
  PlanApprovalPending = 'PlanApprovalPending',
  Skipped = 'Skipped',
}

export enum AdvertiserPaymentsPlanStatus {
  ApprovalPending = 'ApprovalPending',
  Executing = 'Executing',
  Filling = 'Filling',
  Finished = 'Finished',
  Initialized = 'Initialized',
  Interrupted = 'Interrupted',
}

export enum AdvertiserStatus {
  ADDENDUM_SIGNING = 'ADDENDUM_SIGNING',
  APPROVED = 'APPROVED',
  CONTRACT_SIGNING = 'CONTRACT_SIGNING',
  PENDING = 'PENDING',
  PROFILE = 'PROFILE',
}

export enum AdvertiserType {
  Main = 'Main',
  Under = 'Under',
}

export enum BankAccountType {
  IBAN = 'IBAN',
  SWIFT = 'SWIFT',
}

export enum DependantsType {
  Children = 'Children',
  ChildrenAndSpouse = 'ChildrenAndSpouse',
  Spouse = 'Spouse',
}

export enum InsuranceCaseDocumentReviewStatus {
  Approved = 'Approved',
  Changed = 'Changed',
  Rejected = 'Rejected',
}

export enum InsuranceCaseFieldReviewStatus {
  Approved = 'Approved',
  Changed = 'Changed',
  Rejected = 'Rejected',
}

export enum InsuranceCaseStatus {
  ApplicationFilling = 'ApplicationFilling',
  Approved = 'Approved',
  ChangesRequested = 'ChangesRequested',
  Covered = 'Covered',
  Created = 'Created',
  DocumentsGenerating = 'DocumentsGenerating',
  DocumentsSigning = 'DocumentsSigning',
  Draft = 'Draft',
  NotInsurable = 'NotInsurable',
  PaymentSent = 'PaymentSent',
  Pending = 'Pending',
  PoaSign = 'PoaSign',
  Reviewing = 'Reviewing',
}

export enum InsurancePurpose {
  Employment = 'Employment',
  Other = 'Other',
  Partner = 'Partner',
  Pension = 'Pension',
  Study = 'Study',
  Trainee = 'Trainee',
  Unemployment = 'Unemployment',
}

export enum OfficeMemberProfileStatus {
  UpdateRequired = 'UpdateRequired',
  Updated = 'Updated',
}

export enum OfficeMemberType {
  BranchOffice = 'BranchOffice',
  HeadOffice = 'HeadOffice',
  ServicePoint = 'ServicePoint',
}

export enum OrderDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum PoaStatus {
  Generating = 'Generating',
  NotAvailable = 'NotAvailable',
  Uploading = 'Uploading',
}

export interface AddressInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  place?: string | null;
  zipCode?: string | null;
  country?: string | null;
  streetName?: string | null;
  houseNumber?: string | null;
}

export interface BankAccountIBANInput {
  code: string;
  vatId?: string | null;
}

export interface BankAccountInput {
  swift?: BankAccountSWIFTInput | null;
  iban?: BankAccountIBANInput | null;
}

export interface BankAccountSWIFTInput {
  code: string;
  beneficiary: string;
  account: string;
  name: string;
  address: string;
  country: string;
  abaRoutingTransitNumber?: string | null;
  clabeNumber?: string | null;
  ifscCode?: string | null;
  bsbCode?: string | null;
}

export interface ContactPersonInput {
  firstName?: string | null;
  lastName?: string | null;
}

export interface CreateBranchOfficeInput {
  name: string;
  email: string;
  phone: string;
  address: AddressInput;
}

export interface CreateInsuranceCaseInput {
  email: string;
  firstName?: string | null;
  insurancePurposes?: (InsurancePurpose | null)[] | null;
  dependantsType?: DependantsType | null;
  hasPreviousInsurance?: boolean | null;
  childrenCount?: number | null;
  poaStatus?: PoaStatus | null;
}

export interface CreateInviteCodeInput {
  isMulti?: boolean | null;
  provision: string;
  count: number;
}

export interface CreateOfficeMemberInput {
  jobTitle?: string | null;
  phone?: string | null;
  email: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  type: OfficeMemberType;
  branchOfficeId?: string | null;
}

export interface DocumentKeyInput {
  id: string;
  documentContainerId?: string | null;
}

export interface SlotsInfoInput {
  slotNames: string[];
  slotAssigneeName?: string | null;
}

export interface UpdateAdvertiserInput {
  name?: string | null;
  contactPerson?: ContactPersonInput | null;
  status?: AdvertiserStatus | null;
  phone?: string | null;
  email?: string | null;
  branchOfficeId?: string | null;
  provision?: string | null;
  address?: AddressInput | null;
  bankAccount?: BankAccountInput | null;
}

export interface UpdateAdvertiserProfileInput {
  name?: string | null;
  contactPerson?: ContactPersonInput | null;
  phone?: string | null;
  email?: string | null;
  address?: AddressInput | null;
  inviteCode?: string | null;
  bankAccount?: BankAccountInput | null;
}

export interface UpdateBranchOfficeInput {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: AddressInput | null;
}

export interface UpdateOfficeMemberInput {
  jobTitle?: string | null;
  name?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  branchOfficeId?: string | null;
}

export interface UpdateOfficeMemberProfileInput {
  jobTitle?: string | null;
  name?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
