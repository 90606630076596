import { FC } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/system';
import { getInsuranceCaseStatusTranslated } from '@utils/getInsuranceCaseStatusTranslated';
import { InsuranceCaseStatus } from '../../types/globalTypes';

const getColorsByStatus = (
  status: string,
  theme: Theme,
): { color: any; backgroundColor: any } =>
  ((
    {
      Approved: {
        backgroundColor: theme.palette.success.lighter,
        color: theme.palette.success.dark,
      },
      ChangesRequested: {
        backgroundColor: theme.palette.info.lighter,
        color: theme.palette.info.dark,
      },
      Covered: {
        backgroundColor: theme.palette.info.lighter,
        color: theme.palette.info.dark,
      },
      Draft: {
        backgroundColor: theme.palette.warning.lighter,
        color: theme.palette.warning.dark,
      },
      NotInsurable: {
        backgroundColor: theme.palette.error.lighter,
        color: theme.palette.error.dark,
      },
      PaymentSent: {
        backgroundColor: theme.palette.info.lighter,
        color: theme.palette.info.dark,
      },
      Pending: {
        backgroundColor: theme.palette.success.lighter,
        color: theme.palette.success.dark,
      },
      Rejected: {
        backgroundColor: theme.palette.warning.lighter,
        color: theme.palette.warning.dark,
      },
      Reviewing: {
        backgroundColor: theme.palette.info.lighter,
        color: theme.palette.info.dark,
      },
    } as { [key in InsuranceCaseStatus]?: { color: any; backgroundColor: any } }
  )[status] ?? {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  });

interface AdditionalProps {
  status: InsuranceCaseStatus;
}

export const StyledTypography = styled(({ status, ...props }: any) => (
  <Typography {...props} noWrap variant="subtitle2" />
))<AdditionalProps>(({ theme, status }) => ({
  borderRadius: theme.spacing(0.5),
  display: 'inline-block',
  flexGrow: 0,
  fontSize: 12,
  padding: theme.spacing('1px', 1),
  textOverflow: 'ellipsis',
  textTransform: 'none',
  ...getColorsByStatus(status, theme),
}));

interface Props {
  paymentReceivedAt?: string;
  status: InsuranceCaseStatus;
}

export const InsuranceCaseStatusBadge: FC<Props> = ({
  paymentReceivedAt,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={1}>
      <StyledTypography component="div" status={status}>
        {getInsuranceCaseStatusTranslated(status, t) ||
          `unknown status ${status}`}
      </StyledTypography>
      {paymentReceivedAt && <div>💰</div>}
    </Stack>
  );
};
