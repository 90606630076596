import { useMutation } from '@apollo/client';
import { GQL } from '@common/queries';
import { LoadingButton } from '@mui/lab';
import { FC } from 'react';
import { clickOnDownloadUrl } from '@common/utils/clickOnDownloadUrl';

interface Props {
  insuranceCaseId: string;
  documentContainerId: string;
}

// testing purpose only comopnent
export const RegenerateFormTestButton: FC<Props> = ({
  documentContainerId,
  insuranceCaseId,
}) => {
  const [generateForm, { loading }] = useMutation(
    GQL.INSURANCE_CASE._GENERATE_BARMER_APPLICATION,
  );
  const [getSignedUrl] = useMutation(GQL.DOCUMENT.GET_SIGNED_URL);

  if (
    !process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'prod'
  ) {
    return null;
  }

  const onGenerateClick = async () => {
    try {
      const {
        data: {
          insuranceCaseMutations: { _generateBarmerApplication: documentId },
        },
      } = await generateForm({
        variables: {
          insuranceCaseId,
        },
      });

      const result = await getSignedUrl({
        variables: {
          documentKey: {
            documentContainerId,
            id: documentId,
          },
        },
      });

      clickOnDownloadUrl(result.data.documentMutations.getSignedUrl, true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LoadingButton
      variant="text"
      color="warning"
      loading={loading}
      // sx={{ width: 200 }}
      onClick={onGenerateClick}
    >
      QA/DEV only: generate
    </LoadingButton>
  );
};
