import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { MenuItem, Stack, Typography } from '@mui/material';
import { DatePicker, Select, TextField } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { InsuranceType } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { TFunction } from 'i18next';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): Yup.AnySchema =>
  Yup.object().shape({
    spouse_0_hasPreviousInsurance: Yup.boolean()
      .required(t('validation.required'))
      .nullable(),
    spouse_0_previousInsuranceEndDate: Yup.string().nullable(),
    spouse_0_previousInsuranceFamilyMemberFirstName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    spouse_0_previousInsuranceFamilyMemberLastName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    spouse_0_previousInsuranceProviderName: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    spouse_0_previousInsuranceStillExist: Yup.boolean().nullable(),
    spouse_0_previousInsuranceType: Yup.string().nullable(),
  });

const getActiveFields = values => ({
  spouse_0_previousInsuranceEndDate:
    values.spouse_0_previousInsuranceStillExist === true,
  spouse_0_previousInsuranceFamilyMemberFirstName:
    values.spouse_0_previousInsuranceType === InsuranceType.FamilyMember,
  spouse_0_previousInsuranceFamilyMemberLastName:
    values.spouse_0_previousInsuranceType === InsuranceType.FamilyMember,
  spouse_0_previousInsuranceProviderName: values.spouse_0_previousInsuranceType,
  spouse_0_previousInsuranceStillExist: values.spouse_0_previousInsuranceType,
  spouse_0_previousInsuranceType: !!values.spouse_0_hasPreviousInsurance,
});

export const SpousePreviousInsuranceStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.spousePreviousInsurance')}
        </Typography>
      </StepTitle>
      <QuestionField
        name="spouse_0_hasPreviousInsurance"
        question={getLabel('spouse_0_hasPreviousInsurance', t, { isDraft })}
      />
      <ConditionalFields fieldNames={['spouse_0_previousInsuranceType']}>
        <Select
          name="spouse_0_previousInsuranceType"
          label={getLabel('spouse_0_previousInsuranceType', t, { isDraft })}
        >
          <MenuItem value={InsuranceType.MainMember}>
            {t('insuranceType.membership')}
          </MenuItem>
          <MenuItem value={InsuranceType.FamilyMember}>
            {t('insuranceType.familyInsurance')}
          </MenuItem>
          <MenuItem value={InsuranceType.NotStatutory}>
            {t('insuranceType.notStatutory')}
          </MenuItem>
        </Select>
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          'spouse_0_previousInsuranceFamilyMemberFirstName',
          'spouse_0_previousInsuranceFamilyMemberLastName',
        ]}
      >
        <Stack spacing={2} direction={{ sm: 'row', xs: 'column' }}>
          <TextField
            name="spouse_0_previousInsuranceFamilyMemberFirstName"
            label={getLabel(
              'spouse_0_previousInsuranceFamilyMemberFirstName',
              t,
            )}
          />
          <TextField
            name="spouse_0_previousInsuranceFamilyMemberLastName"
            label={getLabel(
              'spouse_0_previousInsuranceFamilyMemberLastName',
              t,
            )}
          />
        </Stack>
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          'spouse_0_previousInsuranceStillExist',
          'spouse_0_previousInsuranceProviderName',
        ]}
      >
        <QuestionField
          name="spouse_0_previousInsuranceStillExist"
          question={getLabel('spouse_0_previousInsuranceStillExist', t)}
        />
        <TextField
          name="spouse_0_previousInsuranceProviderName"
          label={getLabel('spouse_0_previousInsuranceProviderName', t)}
        />
      </ConditionalFields>
      <ConditionalFields fieldNames={['spouse_0_previousInsuranceEndDate']}>
        <DatePicker
          name="spouse_0_previousInsuranceEndDate"
          label={getLabel('spouse_0_previousInsuranceEndDate', t)}
        />
      </ConditionalFields>
    </Stack>
  );
};

SpousePreviousInsuranceStep.getValidationSchema = getValidationSchema;
SpousePreviousInsuranceStep.getActiveFields = getActiveFields;
