import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Grid(theme: Theme): Record<string, any> {
  return {
    MuiGrid: {
      styleOverrides: {},
    },
  };
}
