import { FormStepper } from '@components/forms/FormStepper';
import { FormStepsSidebar } from '@components/forms/FormStepsSidebar';
import { styled } from '@mui/material/styles';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  SubmitInsuranceCaseApplication,
  SubmitInsuranceCaseApplicationVariables,
} from '@queries/types/SubmitInsuranceCaseApplication';
import { GQL } from '@queries';
import { GetCurrentUserInsuranceCase_currentUserInsuranceCase as InsuranceCase } from '@queries/types/GetCurrentUserInsuranceCase';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { RejectedFields } from '@components/forms/types';
import {
  GetFormStateAndReviews_insuranceCaseDocumentReviews as DocumentReviews,
  GetFormStateAndReviews_insuranceCaseFieldReviews as FieldReviews,
} from '@queries/types/GetFormStateAndReviews';
import { RegenerateFormTestButton } from '@components/RegenerateFormTestButton';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { Box, Button } from '@mui/material';
import { ApplicationFormWrapper } from '@components/forms/ApplicationForm/ApplicationFormWrapper';
import { Maybe } from '@shared/types';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { PoaStatus, UserRole } from '@shared/constants';
import { getSteps } from '@components/forms/ApplicationForm/getSteps';
import { getSteps as advertiserGetSteps } from '@components/forms/ApplicationDraftForm/getSteps';
import { ROUTES } from '@common/routes';
import { useNavigate } from 'react-router';

const StepStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('xxl')]: {
    width: 1200,
  },
  [theme.breakpoints.down('xxl')]: {
    width: 700,
  },
  [theme.breakpoints.down('xl')]: {
    width: 500,
  },
  [theme.breakpoints.down('lg')]: {
    width: 800,
  },
  [theme.breakpoints.down('md')]: {
    width: 500,
  },
  [theme.breakpoints.down('sm')]: {
    width: 260,
  },
}));

interface Props {
  initialValues?: Record<string, any>;
  initialStepName?: Maybe<string>;
  onSubmitted: VoidFunction;
  insuranceCase: InsuranceCase;
  fieldReviews?: FieldReviews[];
  documentReviews?: DocumentReviews[];
  isRefilling?: boolean;
}

export const ApplicationForm: FC<Props> = ({
  initialValues,
  initialStepName,
  onSubmitted,
  insuranceCase,
  isRefilling = false,
  fieldReviews = [],
  documentReviews = [],
}) => {
  const { role } = useCurrentUser();
  const [poaStatus, setPoaStatus] = useState(
    initialValues?.poaStatus ?? insuranceCase.poaStatus,
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const formStepsSidebarRef = useRef<any>();
  const { t } = useTranslation();
  const [submitForm] = useMutation<
    SubmitInsuranceCaseApplication,
    SubmitInsuranceCaseApplicationVariables
  >(GQL.INSURANCE_CASE.SUBMIT_APPLICATION);
  const [submitApplicationRefill] = useMutation(
    GQL.INSURANCE_CASE.SUBMIT_APPLICATION_REFILL,
  );

  const onSubmit = async () => {
    try {
      await (isRefilling ? submitApplicationRefill : submitForm)({
        variables: {
          insuranceCaseId: insuranceCase.id,
        },
      });
      onSubmitted();
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };
  const hasPowerOfAttorney = poaStatus !== PoaStatus.NotAvailable;

  const rejectedFields: Map<string[], RejectedFields> = useMemo(
    () =>
      fieldReviews
        .filter(({ status }) => status === 'Rejected')
        .reduce(
          (map, { rejectionReason, formFieldNames }) =>
            map.set(formFieldNames, { rejectionReason }),
          new Map(),
        ),
    [fieldReviews],
  );
  const rejectedValues = fieldReviews.reduce(
    (acc, { fieldValues }) => ({ ...acc, ...fieldValues }),
    {},
  );
  const rejectedDocuments = useMemo(
    () =>
      documentReviews
        // NOTE: Poa review ignored if become not available or generating
        ?.filter(
          ({ documentName }) =>
            !(
              documentName === 'powerOfAttorneyUploaded' &&
              poaStatus !== PoaStatus.Uploading
            ),
        )
        .reduce(
          (
            acc,
            {
              documentName,
              rejectedDocuments: rejectedDocumentFiles,
              rejectionReason,
            },
          ) => {
            const currentDocumentFiles = insuranceCase.documents.filter(
              ({ documentName: docName }) => documentName === docName,
            );
            return {
              ...acc,
              [documentName]: {
                // Document is marked as currently rejected IF:
                // current insurance case document files length is equal to rejected document files length of same document name
                // NOTE: Poa valid if become not available
                isRejected:
                  currentDocumentFiles.length ===
                  currentDocumentFiles.filter(({ id }) =>
                    rejectedDocumentFiles.find(
                      ({ id: rejectedDocumentId }) => rejectedDocumentId === id,
                    ),
                  ).length,
                rejectedDocumentFiles,
                rejectionReason,
              },
            };
          },
          {},
        ),
    [documentReviews, insuranceCase.documents, poaStatus],
  );

  return (
    <FormStepper
      getSteps={
        [UserRole.Advertiser, UserRole.AdvertiserMember].includes(role)
          ? advertiserGetSteps
          : getSteps
      }
      initialValues={initialValues}
      initialStepName={initialStepName}
      persistenceData={{
        formContainerId: insuranceCase.formContainerId,
        formType: 'application',
        onDataUpdated: updatedValues =>
          updatedValues.poaStatus
            ? setPoaStatus(updatedValues.poaStatus)
            : null,
      }}
      onSubmit={onSubmit}
      rejectedFields={rejectedFields}
      rejectedValues={rejectedValues}
      isValidationEnabled={
        [UserRole.Advertiser, UserRole.AdvertiserMember].includes(role)
          ? hasPowerOfAttorney
          : true
      }
      meta={{
        documents: insuranceCase.documents,
        isRefilling,
        rejectedDocuments,
      }}
      FormWrapperComponent={ApplicationFormWrapper}
    >
      {({
        currentStep: { component: CurrentStep, stepNameSidebar, meta },
        goBack,
        goNext,
        stepsWithMeta,
      }) => (
        <Box>
          <FormStepsSidebar
            ref={formStepsSidebarRef}
            header={
              <RegenerateFormTestButton
                insuranceCaseId={insuranceCase.id}
                documentContainerId={insuranceCase.documentContainerId}
              />
            }
            stepsWithMeta={
              role === UserRole.Applicant
                ? stepsWithMeta
                : stepsWithMeta.map(step => {
                    // step.isRejected = false;
                    step.isDisabled = false;
                    // step.isPassed = false;
                    return step;
                  })
            }
          />
          <Helmet>
            <title>{stepNameSidebar}</title>
          </Helmet>
          <StepStyled>
            {[UserRole.Advertiser, UserRole.AdvertiserMember].includes(
              role,
            ) && (
              <Box sx={{ position: 'absolute', right: 0 }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(ROUTES.ADVERTISER.applicants.path)}
                >
                  {t('back')}
                </Button>
              </Box>
            )}
            <CurrentStep
              goBack={goBack}
              goNext={goNext}
              meta={{
                ...meta,
                insuranceCase,
                openReviewSidebar: () => {
                  formStepsSidebarRef?.current.openSidebar(
                    stepsWithMeta.find(({ group }) => !!group)?.group,
                  );
                },
                rejectedDocuments,
              }}
            />
          </StepStyled>
        </Box>
      )}
    </FormStepper>
  );
};
