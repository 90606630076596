import { compile, pathToRegexp } from 'path-to-regexp';

const defaultOptions = {};

const createRoute = (path: string) => {
  const pathRe = pathToRegexp(path);
  const toPath = compile(path);
  const fn = (options: any = {}) => toPath({ ...defaultOptions, ...options });

  fn.path = path;
  fn.exec = (str: string) => pathRe.exec(str);
  fn.absolutePath = path.replace('/', '');

  return fn;
};

export const ROOT_PATHS = {
  ADVERTISER: '/adv',
  APPLICANT: '/app',
  BRANCH: '/branch',
  HEAD_MANAGEMENT: '/mgm',
  SHARED_SIGN: '/sign',
  WIP: '/wip',
};

/* eslint-disable sort-keys */
export const ROUTES = {
  ADMINISTRATOR: {
    advertiserPaymentDetails: createRoute(`/payment/:advertiserPaymentId`),
    advertisersPaymentsPlan: createRoute(
      '/advertiser-payments-plans/:advertiserPaymentsPlanId',
    ),
    advertisersPaymentsPlans: createRoute('/advertiser-payments-plans'),
    revolutTransactions: createRoute('/revolut-transactions'),
  },
  ADVERTISER: {
    applicants: createRoute(`${ROOT_PATHS.ADVERTISER}/applicants`),
    applicationForm: createRoute(`${ROOT_PATHS.ADVERTISER}/form`),
    documents: createRoute(`${ROOT_PATHS.ADVERTISER}/documents`),
    insuranceCaseDetails: createRoute(
      `${ROOT_PATHS.ADVERTISER}/insurance-case/:insuranceCaseId`,
    ),
    insuranceCaseDraft: createRoute(
      `${ROOT_PATHS.ADVERTISER}/draft/:insuranceCaseId`,
    ),
    inviteCodes: createRoute(`${ROOT_PATHS.ADVERTISER}/invite_codes`),
    members: createRoute(`${ROOT_PATHS.ADVERTISER}/administrative-ambassadors`),
    refLinkDetails: createRoute(
      `${ROOT_PATHS.ADVERTISER}/ref-links/:refLinkId`,
    ),
    refLinks: createRoute(`${ROOT_PATHS.ADVERTISER}/ref-links`),
    root: createRoute(ROOT_PATHS.ADVERTISER),
    unders: createRoute(`${ROOT_PATHS.ADVERTISER}/sub-ambassadors`),
    verification: createRoute(`${ROOT_PATHS.ADVERTISER}/verification`),
    verificationPending: createRoute(
      `${ROOT_PATHS.ADVERTISER}/verification-pending`,
    ),
  },
  APPLICANT: {
    applicationForm: createRoute(`${ROOT_PATHS.APPLICANT}/form`),
    reviewStatusApproved: createRoute(
      `${ROOT_PATHS.APPLICANT}/status-approved`,
    ),
    reviewStatusInReview: createRoute(
      `${ROOT_PATHS.APPLICANT}/status-in-review`,
    ),
    reviewStatusNotInsurable: createRoute(
      `${ROOT_PATHS.APPLICANT}/status-not-insurable`,
    ),
    reviewStatusRequestChanges: createRoute(
      `${ROOT_PATHS.APPLICANT}/status-changes-requested`,
    ),
    root: createRoute(ROOT_PATHS.APPLICANT),
  },
  AUTH: {
    changePassword: createRoute(`/change-password`),
    forgotPassword: createRoute('/forgot-password'),
    login: createRoute(`/login`),
    refLinkSignUp: createRoute(`/:publicId/:linkValue`),
    resetPassword: createRoute(`/reset-password`),
    signUp: createRoute(`/sign-up`),
  },
  BRANCH: {
    advertisersAssign: createRoute(`${ROOT_PATHS.BRANCH}/assign`),
    applicants: createRoute(`${ROOT_PATHS.BRANCH}/applicants`),
    insuranceCaseDetails: createRoute(
      `${ROOT_PATHS.BRANCH}/insurance-case/:insuranceCaseId`,
    ),
    insuranceCaseFinishReview: createRoute(
      `${ROOT_PATHS.BRANCH}/insurance-case/:insuranceCaseId/finish-review`,
    ),
    inviteCodes: createRoute(`${ROOT_PATHS.BRANCH}/invite_codes`),
  },
  COMMON: {
    comingSoon: createRoute('/coming-soon'),
    help: createRoute('/help'),
    maintenance: createRoute('/maintenance'),
    profile: createRoute('/profile'),
    settings: createRoute('/settings'),
  },
  HEAD_MANAGEMENT: {
    advertiser: createRoute(
      `${ROOT_PATHS.HEAD_MANAGEMENT}/advertisers/:advertiserId`,
    ),
    advertisers: createRoute(`${ROOT_PATHS.HEAD_MANAGEMENT}/advertisers`),
    assignments: createRoute(`${ROOT_PATHS.HEAD_MANAGEMENT}/assignments`),
    branchOffice: createRoute(
      `${ROOT_PATHS.HEAD_MANAGEMENT}/branchOffice/:branchOfficeId`,
    ),
    branchOffices: createRoute(`${ROOT_PATHS.HEAD_MANAGEMENT}/branch-offices`),
    officeMembers: createRoute(`${ROOT_PATHS.HEAD_MANAGEMENT}/office-members`),
    servicePoints: createRoute(`${ROOT_PATHS.HEAD_MANAGEMENT}/service-points`),
  },
  SHARED_SIGN: {
    root: createRoute(ROOT_PATHS.SHARED_SIGN),
  },
};
