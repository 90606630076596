import { Box, Container, Stack, styled, Typography } from '@mui/material';
import { InsuranceCaseFragment } from 'common/queries/types/InsuranceCaseFragment';
import { FC, useEffect, useState } from 'react';
import { SignatureForm } from '@components/SignatureForm';
import { useTranslation } from 'react-i18next';
import { IllustrationMascot } from '@src/assets/illustration_mascot';
import { LoadingScreen } from '@components/LoadingScreen';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { UserRole } from '@shared/constants';

interface Props {
  insuranceCase: InsuranceCaseFragment;
  onAllSigned?: VoidFunction;
}

const BoxStyled = styled(Box)(({ theme }) => ({
  flex: '1 1 0px',
  margin: theme.spacing(2),
  maxWidth: 320,
  minWidth: 320,
  [theme.breakpoints.down('md')]: {
    maxWidth: 250,
    minWidth: 250,
  },
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    maxWidth: '100%',
    minWidth: '100%',
  },
}));

export const SignApplication: FC<Props> = ({ insuranceCase, onAllSigned }) => {
  const [isAllSigned, setIsAllSigned] = useState(false);
  const { role } = useCurrentUser();
  const { t } = useTranslation();
  const [isSignLocked, setSignLocked] = useState(false);
  const lockSign = () => setSignLocked(true);
  const unlockSign = () => setSignLocked(false);
  const { documentContainerId, documents, requiredSignaturesInfo } =
    insuranceCase;
  const barmerApplicationDoc = documents.find(
    ({ documentName }) => documentName === 'barmerApplication',
  );

  const requiredSlotsInfo = requiredSignaturesInfo.find(
    ({ documentName }) => documentName === 'barmerApplication',
  )?.slotsInfo;

  if (!requiredSlotsInfo || !barmerApplicationDoc) {
    throw new Error(
      'requiredSlotsInfo or barmer application document not found',
    );
  }

  useEffect(() => {
    // todo optimize
    if (
      requiredSlotsInfo
        .reduce(
          (acc, { slotNames }) => [...acc, ...(slotNames as any)] as any,
          [],
        )
        .some(slotName => !barmerApplicationDoc.signedSlots.includes(slotName))
    ) {
      return;
    }
    setIsAllSigned(true);
    onAllSigned?.();
  }, [barmerApplicationDoc.signedSlots]);

  const applicationDocumentKey = {
    documentContainerId,
    id: barmerApplicationDoc.id,
  };

  if (isAllSigned) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ textAlign: 'center' }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <IllustrationMascot />
          <Typography variant="h4">{t('signApplication.title')}</Typography>
          <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            {t('signApplication.secondaryTitle')}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ flexWrap: 'wrap' }}
        >
          {[UserRole.Advertiser, UserRole.AdvertiserMember].includes(role) ? (
            <BoxStyled>
              <SignatureForm
                showMoreOptions
                onSubmitStart={lockSign}
                onSubmitted={unlockSign}
                locked={isSignLocked}
                slotNames={requiredSlotsInfo.reduce<string[]>(
                  (acc, curr) => [...acc, ...curr.slotNames],
                  [],
                )}
                isSigned={
                  // todo optimize
                  !requiredSlotsInfo
                    .reduce<string[]>(
                      (acc, curr) => [...acc, ...curr.slotNames],
                      [],
                    )
                    .some(
                      slotName =>
                        !barmerApplicationDoc.signedSlots.includes(slotName),
                    )
                }
                documentKey={applicationDocumentKey}
              />
            </BoxStyled>
          ) : (
            requiredSlotsInfo.map(
              ({ slotNames, firstName, lastName }, index) => (
                <BoxStyled key={index}>
                  <SignatureForm
                    showMoreOptions
                    onSubmitStart={lockSign}
                    onSubmitted={unlockSign}
                    locked={isSignLocked}
                    fullName={`${firstName || ''} ${lastName || ''}`}
                    slotNames={slotNames}
                    isSigned={
                      // todo optimize
                      !slotNames.some(
                        slotName =>
                          !barmerApplicationDoc.signedSlots.includes(slotName),
                      )
                    }
                    documentKey={applicationDocumentKey}
                  />
                </BoxStyled>
              ),
            )
          )}
        </Stack>
      </Stack>
    </Container>
  );
};
