// material
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';
import { FC } from 'react';
import { Theme } from '@mui/system';

// ----------------------------------------------------------------------
export const scrollBarStyled = (
  theme: Theme,
  { width = '8px' } = {},
): Record<string, any> => ({
  ['::-webkit-scrollbar']: {
    backgroundColor: '#f5f5f5',
    width: `${width}`,
  },
  ['::-webkit-scrollbar-thumb']: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
  ['::-webkit-scrollbar-track']: {
    backgroundColor: theme.palette['grey.200'],
  },
});

export const GlobalStyles: FC = () => {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '#root': {
          height: '100%',
          width: '100%',
        },
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },

        '.MuiPhoneNumber-flagButton': {
          width: 'min-content', // fixes https://github.com/alexplumb/material-ui-phone-number/issues/110
        },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms',
        },

        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)',
        },

        body: {
          height: '100%',
          width: '100%',
        },

        html: {
          WebkitOverflowScrolling: 'touch',
          height: '100%',
          width: '100%',
        },

        img: { display: 'block', maxWidth: '100%' },
        input: {
          '&[type=number]': {
            '&::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '&::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            MozAppearance: 'textfield',
          },
        },
        textarea: {
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::-moz-placeholder': {
            color: theme.palette.text.disabled,
            opacity: 1,
          },
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::placeholder': {
            color: theme.palette.text.disabled,
          },
        },
      }}
    />
  );
};
