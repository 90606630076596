import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { TFunction } from 'i18next';
import { Stack, Typography } from '@mui/material';
import { DatePicker } from '@common/forms/fields';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { useTranslation } from 'react-i18next';
import { StepComponent } from '@components/forms/types';
import { ConditionalFields, StepTitle } from '@components/forms';
import { QuestionField } from '@common/forms/fields/QuestionField';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_pensionAppliedStartDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
    main_0_pensionStartDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate')),
    main_0_pensionType: Yup.string().nullable(),
  });

const getActiveFields = values => ({
  main_0_pensionAppliedStartDate: values.main_0_pensionType === 'applied',
  main_0_pensionStartDate: values.main_0_pensionType === 'statutory',
});

export const PensionInformationStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.pensionInformation')}
        </Typography>
      </StepTitle>

      <QuestionField
        name="main_0_pensionType"
        question={getLabel('main_0_pensionType', t, { isDraft })}
        rightQuestionProps={{
          label: getLabel('iHaveAppliedForPension', t, { isDraft }),
          value: 'applied',
        }}
        leftQuestionProps={{
          label: getLabel('iRecieveStatutoryPension', t, { isDraft }),
          value: 'statutory',
        }}
      />
      <ConditionalFields fieldNames={['main_0_pensionStartDate']}>
        <DatePicker
          label={getLabel('main_0_pensionStartDate', t)}
          name="main_0_pensionStartDate"
        />
      </ConditionalFields>
      <ConditionalFields fieldNames={['main_0_pensionAppliedStartDate']}>
        <DatePicker
          label={getLabel('main_0_pensionAppliedStartDate', t)}
          name="main_0_pensionAppliedStartDate"
        />
      </ConditionalFields>
    </Stack>
  );
};

PensionInformationStep.getValidationSchema = getValidationSchema;
PensionInformationStep.getActiveFields = getActiveFields;
