import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ButtonGroup(theme: Theme): Record<string, any> {
  const styleContained = (
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error',
  ) => ({
    props: { color, variant: 'contained' },
    style: { boxShadow: theme.customShadows[color] },
  });

  return {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },

      variants: [
        {
          props: { color: 'inherit', variant: 'contained' },
          style: { boxShadow: theme.customShadows.z8 },
        },
        styleContained('primary'),
        styleContained('secondary'),
        styleContained('info'),
        styleContained('success'),
        styleContained('warning'),
        styleContained('error'),

        {
          props: { disabled: true },
          style: {
            '& .MuiButtonGroup-grouped.Mui-disabled': {
              '&.MuiButton-contained': {
                backgroundColor: theme.palette.action.disabledBackground,
              },
              borderColor: `${theme.palette.action.disabledBackground} !important`,
              color: theme.palette.action.disabled,
            },
            boxShadow: 'none',
          },
        },
      ],
    },
  };
}
