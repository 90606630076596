import { useAuth } from '@common/hooks';
import { FC, lazy } from 'react';
import { Route, Routes } from 'react-router';
import { UserRole } from '@shared/constants';
import { FetchCurrentUser } from '@components/FetchCurrentUser';
import { CurrentUserProvider } from '@common/providers/CurrentUserProvider';
import { HelpFab } from '@components/HelpFab';
import { CurrentAdvertiserProvider } from '@common/providers/CurrentAdvertiserProvider';
import { Loadable } from './Loadable';

const ChangePasswordPage = Loadable(
  lazy(() => import('../../pages/ChangePasswordPage')),
);
const AuthRoutes = Loadable(lazy(() => import('./AuthRoutes')));
const UnauthRoutes = Loadable(lazy(() => import('./UnAuthRoutes')));

export const AppRoutes: FC = () => {
  const { currentUser, isRequiredToChangePassword } = useAuth();
  const advertiserId = currentUser?.advertiserId;

  if (currentUser?.role === UserRole.Advertiser && !advertiserId) {
    return <FetchCurrentUser />;
  }

  // TODO: refactor route interceptors
  if (isRequiredToChangePassword) {
    return (
      <>
        <HelpFab hideHelpCenterButton />
        <Routes>
          <Route path="*" element={<ChangePasswordPage />} />
        </Routes>
      </>
    );
  }

  return currentUser ? (
    <CurrentUserProvider>
      {currentUser.advertiserId ? (
        <CurrentAdvertiserProvider>
          <HelpFab hideCategorySelect />
          <AuthRoutes />
        </CurrentAdvertiserProvider>
      ) : (
        <>
          <HelpFab hideCategorySelect />
          <AuthRoutes />
        </>
      )}
    </CurrentUserProvider>
  ) : (
    <UnauthRoutes />
  );
};
