import { createContext, FC } from 'react';
import { LoadCurrentAdvertiser } from '@common/routes/LoadCurrentAdvertiser';
import { GetAdvertiser_advertiser } from '@queries/types/GetAdvertiser';

export const CurrentAdvertiserContext = createContext<
  GetAdvertiser_advertiser | undefined
>(undefined);

export const CurrentAdvertiserProvider: FC = ({ children }) => (
  <LoadCurrentAdvertiser>
    {advertiser => (
      <CurrentAdvertiserContext.Provider value={advertiser}>
        {children}
      </CurrentAdvertiserContext.Provider>
    )}
  </LoadCurrentAdvertiser>
);
