import { FC, useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Box, Drawer, Stack } from '@mui/material';
import { useCollapseDrawer } from '@hooks/useCollapseDrawer';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { Logo, NavSection, Scrollbar, MHidden } from '@src/components';
import { useCurrentAdvertiser } from '@common/hooks';
import sidebarConfig from './SidebarConfig';

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

type DashboardSidebarProps = {
  header?: React.ReactNode;
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export const DashboardSidebar: FC<DashboardSidebarProps> = ({
  header,
  isOpenSidebar,
  onCloseSidebar,
}) => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const { role } = useCurrentUser();
  const advertiser = useCurrentAdvertiser();

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  const sidebarMenuConfig = useMemo(
    () => sidebarConfig(role, t, advertiser),
    [role, i18n.language],
  );

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
        height: '100%',
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pb: 2,
          pt: 3,
          px: 2.5,
          ...(isCollapse && {
            alignItems: 'center',
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>
        </Stack>
        {header}
      </Stack>
      <NavSection navConfig={sidebarMenuConfig} isShow={!isCollapse} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              bgcolor: 'background.default',
              width: DRAWER_WIDTH,
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                WebkitBackdropFilter: 'blur(6px)',
                backdropFilter: 'blur(6px)',
                bgcolor: theme => alpha(theme.palette.background.default, 0.88),
                borderRight: 0,
                // Fix on Mobile
                boxShadow: theme => theme.customShadows.z20,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};
