import { gql } from '@apollo/client';

export const HELP_REQUEST = {
  CREATE: gql`
    mutation CreateHelpRequest($input: CreateHelpRequestInput!) {
      helpRequestMutations {
        create(input: $input)
      }
    }
  `,
};
