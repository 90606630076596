export const phoneNumber = (value: string): string =>
  value.replace(/[^\d+()+\-()]+/g, '');

export const onlyNumbers = (value: string): string =>
  value && typeof value === 'string' ? value.replace?.(/[^0-9.]/g, '') : value;

export const removeSpaces = (value: string | null): string | null =>
  typeof value === 'string' ? value.replace(/\s/g, '') : value;

export const toUpperCase = (value: string | null): string | null =>
  typeof value === 'string' ? value.toUpperCase() : value;

export const toLowerCase = (value: string | null): string | null =>
  typeof value === 'string' ? value.toLowerCase() : value;

export const compose =
  (...fns: ((value: string | null) => string | null)[]) =>
  (arg: string | null): string | null =>
    fns.reduce((acc, fn) => (fn ? fn(acc) : acc), arg);
