import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar as MUIAppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import { FC } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { MHidden } from '@src/components';
import { useCollapseDrawer } from '@common/hooks';
import { LanguagePopover } from '../Dashboard/LanguagePopover';
import { AccountPopover } from '../Dashboard/AccountPopover';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const StyledAppBar = styled(MUIAppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export const AppBar: FC = () => {
  const { toggleSidebar } = useCollapseDrawer();
  return (
    <StyledAppBar>
      <StyledToolbar>
        <MHidden width="lgUp">
          <IconButton
            onClick={toggleSidebar}
            sx={{ color: 'text.primary', mr: 1 }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ sm: 1.5, xs: 0.5 }}
        >
          <LanguagePopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledAppBar>
  );
};
