import { InsuranceCaseStatus } from '@shared/constants';
import { TFunction } from 'react-i18next';

export function getInsuranceCaseStatusTranslated(
  value: InsuranceCaseStatus,
  t: TFunction,
): string {
  const statusLabels: Record<InsuranceCaseStatus, string> = {
    [InsuranceCaseStatus.Approved]: t('insuranceCaseStatus.approved'),
    [InsuranceCaseStatus.ChangesRequested]: t(
      'insuranceCaseStatus.changesRequested',
    ),
    [InsuranceCaseStatus.NotInsurable]: t('insuranceCaseStatus.notInsurable'),
    [InsuranceCaseStatus.Pending]: t('insuranceCaseStatus.pending'),
    [InsuranceCaseStatus.Reviewing]: t('insuranceCaseStatus.reviewing'),
    [InsuranceCaseStatus.DocumentsSigning]: t(
      'insuranceCaseStatus.documentsSigning',
    ),
    [InsuranceCaseStatus.Covered]: t('insuranceCaseStatus.covered'),
    [InsuranceCaseStatus.PoaSign]: t('insuranceCaseStatus.poaSigning'),
    [InsuranceCaseStatus.Draft]: t('insuranceCaseStatus.draft'),
    [InsuranceCaseStatus.DocumentsGenerating]: t(
      'insuranceCaseStatus.documentsGenerating',
    ),
    [InsuranceCaseStatus.ApplicationFilling]: t(
      'insuranceCaseStatus.applicationFilling',
    ),
    [InsuranceCaseStatus.Created]: t('insuranceCaseStatus.created'),
    [InsuranceCaseStatus.PaymentSent]: t('insuranceCaseStatus.paymentSent'),
  };

  return statusLabels[value];
}
