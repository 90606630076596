import { gql } from '@apollo/client';

export const InviteCodeFrag = gql`
  fragment InviteCodeFragment on InviteCodeGQL {
    id
    createdAt
    code
    isMulti
    status
    usageCount
    provision
  }
`;

export const INVITE_CODE = {
  CHANGE_STATUS: gql`
    mutation SetInviteCodeStatus($inviteCodeId: String!, $status: String!) {
      inviteCodeMutations {
        changeStatus(inviteCodeId: $inviteCodeId, status: $status) {
          ...InviteCodeFragment
        }
      }
    }
    ${InviteCodeFrag}
  `,
  CREATE: gql`
    mutation CreateInviteCode($inputs: [CreateInviteCodeInput!]!) {
      inviteCodeMutations {
        create(inputs: $inputs) {
          ...InviteCodeFragment
        }
      }
    }
    ${InviteCodeFrag}
  `,
  PAGED: gql`
    query GetInviteCodesPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      inviteCodesPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
          endCursor
          startCursor
        }
        edges {
          node {
            ...InviteCodeFragment
          }
        }
      }
    }
    ${InviteCodeFrag}
  `,
};
