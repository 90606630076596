import { FormStepper } from '@components/forms/FormStepper';
import { FormStepsSidebar } from '@components/forms/FormStepsSidebar';
import { styled } from '@mui/material/styles';
import React, { FC, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { GQL } from '@queries';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  PublishInsuranceCaseDraft,
  PublishInsuranceCaseDraft_insuranceCaseMutations_publishDraft,
  PublishInsuranceCaseDraftVariables,
} from '@queries/types/PublishInsuranceCaseDraft';
import { useMutation, useQuery } from '@apollo/client';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { InsuranceCaseFragment } from '@queries/types/InsuranceCaseFragment';
import { RegenerateFormTestButton } from '@components/RegenerateFormTestButton';
import { calculateFields } from '@components/forms/ApplicationForm/calculateFields';
import { ROUTES } from '@common/routes';
import { useNavigate } from 'react-router';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { PoaStatus } from '@shared/constants';
import { getSteps } from './getSteps';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  overflow: 'auto',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
    paddingTop: APP_BAR_DESKTOP + 24,
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: APP_BAR_MOBILE + 24,
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const StepStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('xxl')]: {
    width: 1200,
  },
  [theme.breakpoints.down('xxl')]: {
    width: 900,
  },
  [theme.breakpoints.down('xl')]: {
    width: 500,
  },
  [theme.breakpoints.down('lg')]: {
    width: 800,
  },
  [theme.breakpoints.down('md')]: {
    width: 500,
  },
  [theme.breakpoints.down('sm')]: {
    width: 260,
  },
}));

interface Props {
  insuranceCase: InsuranceCaseFragment;
  advertiserId: string;
  onComplete?: (
    entity: PublishInsuranceCaseDraft_insuranceCaseMutations_publishDraft,
  ) => void;
}

export const ApplicationDraftForm: FC<Props> = ({
  advertiserId,
  insuranceCase,
  onComplete,
}) => {
  const formStepsSidebarRef = useRef<any>();
  const formStateQuery = useQuery(GQL.FORM_STATE.FORM_STATE, {
    fetchPolicy: 'network-only',
    variables: {
      formContainerId: insuranceCase.formContainerId,
      formType: 'application',
    },
  });
  const [poaStatus, setPoaStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [publishDraft] = useMutation<
    PublishInsuranceCaseDraft,
    PublishInsuranceCaseDraftVariables
  >(GQL.INSURANCE_CASE.PUBLISH_DRAFT);

  const hasPowerOfAttorney = [
    PoaStatus.Generating,
    PoaStatus.Uploading,
  ].includes(
    poaStatus || formStateQuery.data?.formState?.state?.values?.poaStatus,
  );

  const onSubmit = async () => {
    try {
      const { data } = await publishDraft({
        variables: {
          advertiserId,
          insuranceCaseId: insuranceCase.id,
        },
      });
      const result = data?.insuranceCaseMutations.publishDraft;
      if (hasPowerOfAttorney) {
        navigate(
          ROUTES.ADVERTISER.insuranceCaseDetails({
            insuranceCaseId: insuranceCase.id,
          }),
        );
        return;
      }
      if (result) {
        onComplete?.(result);
      }
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  return (
    <QueryLoaderComponent
      renderLoading={() => <CircularProgress />}
      renderData={({ formState }) => (
        <MainStyle>
          <FormStepper
            getSteps={getSteps}
            initialValues={formState?.state.values}
            initialStepName={formState?.currentStepName}
            persistenceData={{
              debounceTime: 500,
              formContainerId: insuranceCase.formContainerId,
              formType: 'application',
              onDataUpdated: values =>
                values.poaStatus ? setPoaStatus(values.poaStatus) : null,
            }}
            meta={{
              documents: insuranceCase.documents,
            }}
            onSubmit={onSubmit}
            isValidationEnabled={hasPowerOfAttorney}
            isInitialValidationEnabled={hasPowerOfAttorney}
            decorators={[calculateFields]}
          >
            {({
              currentStep: { component: CurrentStep, stepNameSidebar, meta },
              stepsWithMeta,
              goBack,
              goNext,
            }) => (
              <>
                <FormStepsSidebar
                  ref={formStepsSidebarRef}
                  header={
                    <Stack spacing={2}>
                      <div>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            backgroundColor: theme =>
                              theme.palette.warning.lighter,
                            borderRadius: 1,
                            color: theme => theme.palette.warning.dark,
                            display: 'inline-block',
                            marginLeft: 5,
                            padding: 2,
                            paddingY: 1,
                          }}
                        >
                          {t('applicationDraft')}
                        </Typography>
                      </div>
                      <RegenerateFormTestButton
                        insuranceCaseId={insuranceCase.id}
                        documentContainerId={insuranceCase.documentContainerId}
                      />
                    </Stack>
                  }
                  stepsWithMeta={
                    hasPowerOfAttorney
                      ? stepsWithMeta
                      : stepsWithMeta.map(step => {
                          //todo: remove this shit!
                          step.isRejected = false;
                          step.isDisabled = false;
                          // step.isPassed = false;
                          return step;
                        })
                  }
                />
                <Helmet>
                  <title>{stepNameSidebar}</title>
                </Helmet>
                <StepStyled sx={{ width: 400 }}>
                  <Box position="absolute" right={0}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate(ROUTES.ADVERTISER.applicants.path)
                      }
                    >
                      {t('close')}
                    </Button>
                  </Box>
                  <CurrentStep
                    goBack={goBack}
                    goNext={goNext}
                    meta={{
                      ...meta,
                      insuranceCase,
                      isDraft: true,
                      openReviewSidebar: () => {
                        formStepsSidebarRef?.current.openSidebar(
                          stepsWithMeta.find(({ group }) => !!group)?.group,
                        );
                      },
                    }}
                  />
                </StepStyled>
              </>
            )}
          </FormStepper>
        </MainStyle>
      )}
      queryState={formStateQuery}
    />
  );
};
