import { Field, FieldRenderProps } from 'react-final-form';
import { showErrorOnChange } from 'mui-rff/src/Util';
import React, { FC } from 'react';
import { TextFieldProps } from 'mui-rff/src/TextField';
import PhoneInput, {
  Props as PhoneNumberProps,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from '@emotion/styled';
import { PhoneInputMUI } from './';

type Props = FieldRenderProps<PhoneNumberProps & TextFieldProps>;

const StyledPhoneInput = styled(PhoneInput)`
    width: 100%;
    position: relative;

    .PhoneInputCountry {
      position: absolute;
      top: 20px;
      left: 15px;
    }
    .MuiInputLabel-root {
      margin-left: 50px;
      &.MuiInputLabel-shrink {
        margin-left: 0;
      }
    }
    .MuiOutlinedInput-root {
      padding-left: 50px;
    }
  }
`;

const PhoneNumberBase: FC<Props> = props => {
  const {
    input: { name, value, type, onChange, onBlur, onFocus, ...restInput },
    meta,
    required,
    fullWidth = true,
    helperText = '',
    showError = showErrorOnChange,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  return (
    <StyledPhoneInput
      international
      inputComponent={PhoneInputMUI}
      numberInputProps={{
        error: rest.error || isError,
        fullWidth,
        helperText: isError ? error || submitError : helperText,
        inputProps: {
          required,
          ...restInput,
        },
        name,
        onBlur: onBlur,
        onFocus: onFocus,
        required,
        type,
        value,
      }}
      onChange={onChange}
      value={value as any}
      {...rest}
    />
  );
};

export const PhoneNumberField: FC<
  Partial<PhoneNumberProps> & TextFieldProps
> = props => {
  const { name, type, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      type={type}
      render={({ input, meta }) => (
        <PhoneNumberBase
          input={input}
          meta={meta}
          name={name}
          type={type}
          {...rest}
        />
      )}
      {...fieldProps}
    />
  );
};
