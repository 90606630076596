import { MenuItem, Stack, Typography } from '@mui/material';
import { DatePicker, Select, TextField } from '@common/forms/fields';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { StepComponent, StepMeta } from '@components/forms/types';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { ChildRelationship, GLOBAL_MIN_DATE } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction, { idx }: StepMeta = {}): AnySchema =>
  Yup.object().shape({
    [`child_${idx}_birthCountry`]: Yup.string().nullable(),
    [`child_${idx}_birthPlace`]: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    [`child_${idx}_nationality`]: Yup.string().nullable(),
    [`child_${idx}_secondNationality`]: Yup.string().nullable(),
    [`child_${idx}_childRelationship`]: Yup.string().nullable(),
    [`child_${idx}_previousNationality`]: Yup.string().nullable(),
    [`child_${idx}_birthDate`]: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate'))
      .min(
        new Date(GLOBAL_MIN_DATE),
        t('validation.shouldNotBeBefore', {
          date: GLOBAL_MIN_DATE,
        }),
      )
      .max(new Date(), t('validation.shouldNotBeAfterToday')),
    [`child_${idx}_isSpouseParent`]: Yup.string().nullable(),
    [`child_${idx}_relationship`]: Yup.string().nullable(),
  });

const getActiveFields = (values, { idx }: StepMeta = {}) => ({});

export const ChildBirthInfoStep: StepComponent = props => {
  const idx = props.meta?.idx;
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.birthDatePlace')}</Typography>
      </StepTitle>
      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <CountrySelect
          label={getLabel(`child_${idx}_birthCountry`, t)}
          name={`child_${idx}_birthCountry`}
        />
        <TextField
          label={getLabel(`child_${idx}_birthPlace`, t)}
          name={`child_${idx}_birthPlace`}
        />
      </Stack>
      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <CountrySelect
          label={getLabel(`child_${idx}_nationality`, t)}
          name={`child_${idx}_nationality`}
        />
        <CountrySelect
          label={getLabel(`child_${idx}_secondNationality`, t)}
          name={`child_${idx}_secondNationality`}
        />
      </Stack>
      <DatePicker
        label={getLabel(`child_${idx}_birthDate`, t)}
        name={`child_${idx}_birthDate`}
      />
      <Select
        label={getLabel(`child_${idx}_childRelationship`, t)}
        name={`child_${idx}_childRelationship`}
      >
        <MenuItem
          key={ChildRelationship.Daughter}
          value={ChildRelationship.Daughter}
        >
          {t('relationship.daughter')}
        </MenuItem>
        <MenuItem key={ChildRelationship.Son} value={ChildRelationship.Son}>
          {t('relationship.son')}
        </MenuItem>
        <MenuItem
          key={ChildRelationship.StepChild}
          value={ChildRelationship.StepChild}
        >
          {t('relationship.stepChild')}
        </MenuItem>
        <MenuItem
          key={ChildRelationship.GrandChild}
          value={ChildRelationship.GrandChild}
        >
          {t('relationship.grandChild')}
        </MenuItem>
        <MenuItem
          key={ChildRelationship.FosterChild}
          value={ChildRelationship.FosterChild}
        >
          {t('relationship.fosterChild')}
        </MenuItem>
        <MenuItem
          key={ChildRelationship.AdoptedChild}
          value={ChildRelationship.AdoptedChild}
        >
          {t('relationship.adoptedChild')}
        </MenuItem>
      </Select>
      <QuestionField
        question={getLabel(`child_${idx}_isSpouseParent`, t)}
        name={`child_${idx}_isSpouseParent`}
      />
    </Stack>
  );
};

ChildBirthInfoStep.getActiveFields = getActiveFields;
ChildBirthInfoStep.getValidationSchema = getValidationSchema;
