import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormGroupProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Grid,
  styled,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Field, FieldProps } from 'react-final-form';
import {
  ErrorMessage,
  ShowErrorFunc,
  showErrorOnChange,
  useFieldForErrors,
} from './util';

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid`,
  borderRadius: theme.spacing(1),
  margin: 0,
  padding: theme.spacing(0.5),
  width: '100%',
}));

export interface CheckboxData {
  label: string | number | ReactElement;
  value: unknown;
  disabled?: boolean;
  indeterminate?: boolean;
}

export interface CheckboxesProps
  extends Partial<Omit<MuiCheckboxProps, 'onChange'>> {
  name: string;
  data: CheckboxData | CheckboxData[];
  label?: string | number | ReactElement;
  required?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  formGroupProps?: Partial<FormGroupProps>;
  formLabelProps?: Partial<FormLabelProps>;
  formControlLabelProps?: Partial<FormControlLabelProps> & {
    label: string | number | React.ReactElement;
  };
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
}

export const Checkboxes: React.FC<CheckboxesProps> = (
  props: CheckboxesProps,
) => {
  const {
    required,
    label,
    data,
    name,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    ...restCheckboxes
  } = props;

  const itemsData = Array.isArray(data) ? data : [data];
  const single = !Array.isArray(data);
  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} {...formControlProps}>
      {label ? <FormLabel {...formLabelProps}>{label}</FormLabel> : <></>}
      <FormGroup {...formGroupProps}>
        <Grid container spacing={2}>
          {itemsData.map((item: CheckboxData, idx: number) => (
            <Grid
              key={idx}
              item
              xs={12}
              sm={single ? 12 : 6}
              md={single ? 12 : 4}
            >
              <FormControlLabelStyled
                sx={{
                  borderColor: theme =>
                    (isError && theme.palette.error.main) ||
                    theme.palette.grey['300'],
                }}
                name={name}
                label={item.label}
                value={single ? undefined : item.value}
                disabled={item.disabled}
                control={
                  <Field
                    type="checkbox"
                    name={name}
                    render={({
                      input: {
                        name: inputName,
                        value,
                        onChange,
                        checked,
                        ...restInput
                      },
                    }) => (
                      <MuiCheckbox
                        name={inputName}
                        value={value}
                        onChange={onChange}
                        checked={checked}
                        disabled={item.disabled}
                        inputProps={{ required, ...restInput }}
                        indeterminate={item.indeterminate}
                        {...restCheckboxes}
                      />
                    )}
                    {...fieldProps}
                  />
                }
                {...formControlLabelProps}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
};
