import {
  GetAdvertiser_advertiser,
  GetAdvertiser_advertiser_documents,
} from '@common/queries/types/GetAdvertiser';
import { useQuery } from '@apollo/client';
import { SignedUrl } from '@queries/types/SignedUrl';
import { GQL } from '@queries';
import { FC } from 'react';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArticleIcon from '@mui/icons-material/Article';

interface Props {
  advertiser: GetAdvertiser_advertiser;
  document: GetAdvertiser_advertiser_documents;
}

export const AdvertiserAgreementSigned: FC<Props> = ({
  advertiser,
  document,
}: Props) => {
  const { t } = useTranslation();

  const documentKey = {
    documentContainerId: advertiser?.documentContainerId,
    id: document?.id,
  };

  const queryState = useQuery<SignedUrl>(GQL.DOCUMENT.SIGNED_URL, {
    fetchPolicy: 'network-only',
    variables: {
      documentKey: documentKey,
      forceDownload: true,
    },
  });

  const signedUrl = queryState.data?.signedUrl;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={'row'}>
          <ArticleIcon />
          <Typography variant={'h6'}>
            {t('advertisingAgreement.title')}
          </Typography>
        </Stack>
        <Chip label={t('advertisingAgreement.status.signed')} color="success" />
      </Stack>
      <Typography variant="body2">
        {t('advertisingAgreement.signed.info')}
      </Typography>

      {signedUrl && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Button variant="text" href={signedUrl}>
            {t('download')}
          </Button>
        </Stack>
      )}
    </>
  );
};
