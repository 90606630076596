import * as Yup from 'yup';
import EuroIcon from '@mui/icons-material/Euro';
import { StepComponent, StepMeta } from '@components/forms/types';
import { InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { DatePicker, Select, TextField } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { StudyType } from '@shared/constants';
import { TFunction } from 'i18next';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { getStudyTypeLabel } from '@common/constants';

const getValidationSchema = (
  t: TFunction,
  { idx }: StepMeta = {},
): Yup.AnySchema =>
  Yup.object().shape({
    [`child_${idx}_isEmployed`]: Yup.boolean().nullable(),
    [`child_${idx}_isEmployedFullTime`]: Yup.boolean().nullable(),
    [`child_${idx}_miniJobStartDate`]: Yup.string().nullable(),
    [`child_${idx}_miniJobEndDate`]: Yup.string().nullable(),
    [`child_${idx}_miniJobIncome`]: Yup.number().nullable(),
    [`child_${idx}_jobStartDate`]: Yup.string().nullable(),
    [`child_${idx}_jobEndDate`]: Yup.string().nullable(),
    [`child_${idx}_jobIncome`]: Yup.number().nullable(),
    [`child_${idx}_isStudying`]: Yup.boolean().nullable(),
    [`child_${idx}_studyStartDate`]: Yup.string().nullable(),
    [`child_${idx}_studyEndDate`]: Yup.string().nullable(),
    [`child_${idx}_studyType`]: Yup.string().nullable(),
    [`child_${idx}_studyGradeOrSemester`]: Yup.string().nullable(),
    [`child_${idx}_isHavingAdditionalIncome`]: Yup.string().nullable(),
    [`child_${idx}_otherIncome`]: Yup.number().nullable(),
    [`child_${idx}_isDoingMilitaryOrVoluntary`]: Yup.string().nullable(),
    [`child_${idx}_militaryOrVoluntaryStartDate`]: Yup.string().nullable(),
    [`child_${idx}_militaryOrVoluntaryEndDate`]: Yup.string().nullable(),
  });

const getActiveFields = (values, { idx }: StepMeta = {}) => {
  const isWorking = values[`child_${idx}_isEmployed`] === true;
  const isWorkingFullTime = values[`child_${idx}_isEmployedFullTime`] === true;
  const isStudying = !!values[`child_${idx}_isStudying`];
  const isHavingAdditionalIncome =
    !!values[`child_${idx}_isHavingAdditionalIncome`];
  const isDoingMilitaryOrVoluntary =
    !!values[`child_${idx}_isDoingMilitaryOrVoluntary`];
  return {
    [`child_${idx}_isEmployedFullTime`]: isWorking,
    [`child_${idx}_jobStartDate`]: isWorking && isWorkingFullTime,
    [`child_${idx}_jobEndDate`]: isWorking && isWorkingFullTime,
    [`child_${idx}_jobIncome`]: isWorking && isWorkingFullTime,
    [`child_${idx}_miniJobStartDate`]: isWorking && !isWorkingFullTime,
    [`child_${idx}_miniJobEndDate`]: isWorking && !isWorkingFullTime,
    [`child_${idx}_miniJobIncome`]: isWorking && !isWorkingFullTime,
    [`child_${idx}_studyStartDate`]: isStudying,
    [`child_${idx}_studyEndDate`]: isStudying,
    [`child_${idx}_studyType`]: isStudying,
    [`child_${idx}_studyGradeOrSemester`]: isStudying,
    [`child_${idx}_otherIncome`]: isHavingAdditionalIncome,
    [`child_${idx}_militaryOrVoluntaryStartDate`]: isDoingMilitaryOrVoluntary,
    [`child_${idx}_militaryOrVoluntaryEndDate`]: isDoingMilitaryOrVoluntary,
  };
};

export const ChildOccupationStep: StepComponent = ({ meta }) => {
  const { t } = useTranslation();
  const idx = meta?.idx;
  const isDraft = meta?.isDraft;

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.childOccupationStep')}
        </Typography>
      </StepTitle>
      <QuestionField
        name={`child_${idx}_isEmployed`}
        question={getLabel(`child_${idx}_isEmployed`, t, { isDraft })}
      />
      <ConditionalFields fieldNames={[`child_${idx}_isEmployedFullTime`]}>
        <QuestionField
          name={`child_${idx}_isEmployedFullTime`}
          question={getLabel(`child_${idx}_isEmployedFullTime`, t, { isDraft })}
        />
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          `child_${idx}_jobStartDate`,
          `child_${idx}_jobEndDate`,
          `child_${idx}_jobIncome`,
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name={`child_${idx}_jobStartDate`}
            label={getLabel(`child_${idx}_jobStartDate`, t)}
          />
          <DatePicker
            name={`child_${idx}_jobEndDate`}
            label={getLabel(`child_${idx}_jobEndDate`, t)}
          />
        </Stack>
        <TextField
          name={`child_${idx}_jobIncome`}
          label={getLabel(`child_${idx}_jobIncome`, t)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EuroIcon />
              </InputAdornment>
            ),
          }}
        />
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          `child_${idx}_miniJobStartDate`,
          `child_${idx}_miniJobEndDate`,
          `child_${idx}_miniJobIncome`,
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name={`child_${idx}_miniJobStartDate`}
            label={getLabel(`child_${idx}_miniJobStartDate`, t)}
          />
          <DatePicker
            name={`child_${idx}_miniJobEndDate`}
            label={getLabel(`child_${idx}_miniJobEndDate`, t)}
          />
        </Stack>
        <TextField
          name={`child_${idx}_miniJobIncome`}
          label={getLabel(`child_${idx}_miniJobIncome`, t)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EuroIcon />
              </InputAdornment>
            ),
          }}
        />
      </ConditionalFields>
      <QuestionField
        name={`child_${idx}_isStudying`}
        question={getLabel(`child_${idx}_isStudying`, t, { isDraft })}
      />
      <ConditionalFields
        fieldNames={[
          `child_${idx}_studyStartDate`,
          `child_${idx}_studyEndDate`,
          `child_${idx}_studyType`,
          `child_${idx}_studyGradeOrSemester`,
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name={`child_${idx}_studyStartDate`}
            label={getLabel(`child_${idx}_studyStartDate`, t)}
          />
          <DatePicker
            name={`child_${idx}_studyEndDate`}
            label={getLabel(`child_${idx}_studyEndDate`, t)}
          />
        </Stack>
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <Select
            name={`child_${idx}_studyType`}
            label={getLabel(`child_${idx}_studyType`, t)}
          >
            {Object.values(StudyType).map(value => (
              <MenuItem key={value} value={value}>
                {getStudyTypeLabel(value, t)}
              </MenuItem>
            ))}
          </Select>
          <TextField
            name={`child_${idx}_studyGradeOrSemester`}
            label={getLabel(`child_${idx}_studyGradeOrSemester`, t)}
          />
        </Stack>
      </ConditionalFields>
      <QuestionField
        name={`child_${idx}_isHavingAdditionalIncome`}
        question={getLabel(`child_${idx}_isHavingAdditionalIncome`, t, {
          isDraft,
        })}
      />
      <ConditionalFields fieldNames={[`child_${idx}_otherIncome`]}>
        <TextField
          name={`child_${idx}_otherIncome`}
          label={getLabel(`child_${idx}_otherIncome`, t)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EuroIcon />
              </InputAdornment>
            ),
          }}
        />
      </ConditionalFields>
      <QuestionField
        name={`child_${idx}_isDoingMilitaryOrVoluntary`}
        question={getLabel(`child_${idx}_isDoingMilitaryOrVoluntary`, t, {
          isDraft,
        })}
      />
      <ConditionalFields
        fieldNames={[
          `child_${idx}_militaryOrVoluntaryStartDate`,
          `child_${idx}_militaryOrVoluntaryEndDate`,
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name={`child_${idx}_militaryOrVoluntaryStartDate`}
            label={getLabel(`child_${idx}_militaryOrVoluntaryStartDate`, t)}
          />
          <DatePicker
            name={`child_${idx}_militaryOrVoluntaryEndDate`}
            label={getLabel(`child_${idx}_militaryOrVoluntaryEndDate`, t)}
          />
        </Stack>
      </ConditionalFields>
    </Stack>
  );
};

ChildOccupationStep.getValidationSchema = getValidationSchema;
ChildOccupationStep.getActiveFields = getActiveFields;
