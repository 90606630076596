import { Typography, Box } from '@mui/material';
import { FC } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { Dot } from '@components/Dot';

export interface Props {
  exactPath?: boolean;
  title: string;
  to: string;
}

export const PageHeaderNavItem: FC<Props> = ({
  exactPath = true,
  title,
  to,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = to && !!matchPath({ end: exactPath, path: to }, pathname);

  return (
    <Box
      sx={{ cursor: 'pointer', position: 'relative' }}
      onClick={() => navigate(to)}
    >
      <Typography color={isActive ? 'initial' : 'GrayText'} variant="subtitle2">
        {title}
      </Typography>
      {isActive && (
        <Dot
          isSelected
          sx={{
            height: 6,
            left: '50%',
            position: 'absolute',
            top: 26,
            transform: 'translateX(-50%)',
            width: 6,
          }}
        />
      )}
    </Box>
  );
};
