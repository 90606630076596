import { createContext, FC, useEffect, useMemo, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { RejectedFields, StepWithMeta } from '@components/forms/types';

export const ActiveFieldsContext = createContext<Record<string, any>>({});

interface Props {
  stepsWithMeta: StepWithMeta[];
  rejectedFields: Map<string[], RejectedFields>;
  formMeta?: Record<string, any>;
}

export const ActiveFieldsProvider: FC<Props> = ({
  children,
  stepsWithMeta,
  rejectedFields,
  formMeta = {},
}) => {
  const { values } = useFormState({ subscription: { values: true } });
  const { change } = useForm();
  const getActiveFieldsMerged = stepsWithMeta.reduce(
    (acc, { component, meta }) => ({
      ...acc,
      ...component.getActiveFields?.(values, { ...meta, ...formMeta }),
    }),
    {},
  );
  const rejectedFieldNames = [...rejectedFields.keys()].flat();
  const activeFields = useMemo(
    () =>
      Object.entries(getActiveFieldsMerged)
        .map(
          ([fieldName, isActive]) =>
            (isActive || rejectedFieldNames.includes(fieldName)) && fieldName,
        )
        .filter(Boolean) as string[],
    [values, getActiveFieldsMerged],
  );
  const previousActiveFields = useRef<string[]>([]);
  const previousStepsRef = useRef<StepWithMeta[]>([]);

  useEffect(() => {
    if (!previousActiveFields.current.length) {
      previousActiveFields.current = activeFields;
      return;
    }
    const changedActiveFields = [...activeFields]
      .filter(x => !previousActiveFields.current.includes(x as any))
      .concat(
        previousActiveFields.current.filter(x => !activeFields.includes(x)),
      );
    previousActiveFields.current = activeFields;
    for (const fieldName of changedActiveFields) {
      change(fieldName, null);
    }
  }, [activeFields, values]);

  // clears all step fields when step is deleted from steps array.
  useEffect(() => {
    if (!previousStepsRef.current.length) {
      previousStepsRef.current = stepsWithMeta;
      return;
    }

    const stepNamesSet = new Set(stepsWithMeta.map(({ stepName }) => stepName));

    previousStepsRef.current
      .filter(({ stepName }) => !stepNamesSet.has(stepName))
      .map(({ stepFieldNames }) => stepFieldNames)
      .flat()
      .forEach(fieldName => {
        change(fieldName, null);
      });

    previousStepsRef.current = stepsWithMeta;
  }, [stepsWithMeta]);

  return (
    <ActiveFieldsContext.Provider value={{ activeFields }}>
      {children}
    </ActiveFieldsContext.Provider>
  );
};
