import { getPdfFieldLabel } from '@common/getPdfFieldLabel';
import { Typography, Grid } from '@mui/material';
import { Maybe } from '@shared/types';
import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

interface Props {
  pdfFieldType: string;
  rawPdfFieldValue: Maybe<string> | string[];
  rejectedRawPdfFieldValue: Maybe<string> | string[];
}

const getCheckboxLabel = (rawPdfFieldValue: string[], t: TFunction): string =>
  rawPdfFieldValue?.map(value => getPdfFieldLabel(value, t, true)).join(', ');

export const FieldReviewChangedView: FC<Props> = ({
  pdfFieldType,
  rawPdfFieldValue,
  rejectedRawPdfFieldValue,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs>
        <Typography color="GrayText" variant="subtitle2">
          {t('reviewChanges.was')}
        </Typography>
        <Typography variant="body2">
          {pdfFieldType === 'checkbox'
            ? getCheckboxLabel(rejectedRawPdfFieldValue as string[], t) ||
              t('notSelected')
            : rejectedRawPdfFieldValue || t('notFilled')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography color="GrayText" variant="subtitle2">
          {t('reviewChanges.changedTo')}
        </Typography>
        <Typography variant="body2">
          {pdfFieldType === 'checkbox'
            ? getCheckboxLabel(rawPdfFieldValue as string[], t) ||
              t('notSelected')
            : rawPdfFieldValue || t('notFilled')}
        </Typography>
      </Grid>
    </Grid>
  );
};
