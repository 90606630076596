import * as Yup from 'yup';
import { FC, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '@hooks/useAuth';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { makeValidate } from 'mui-rff';
import { ConfirmUserDialog } from '@components/ConfirmUserDialog';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import { Checkbox } from '@common/forms/fields/Checkbox';
import { Trans, useTranslation } from 'react-i18next';
import {
  MAP_LANGUAGE_CODE_TO_PRIVACY_POLICY_LINK,
  PRIVACY_POLICY_LINK_DEFAULT,
} from '@shared/constants';
import { TextField } from '@common/forms/fields';
import {
  compose,
  removeSpaces,
  toLowerCase,
} from '@common/forms/normalization';
import { EMAIL_REGEX } from '@shared/regex';
import { Maybe } from '@shared/types';

type InitialValues = {
  email: string;
  password: string;
  isPrivacyPolicyAccepted: boolean;
  locale: string;
};

type Props = {
  refLink?: Maybe<string>;
  initialValues?: Pick<InitialValues, 'email' | 'password'>;
};

export const SignUpForm: FC<Props> = ({
  initialValues: initialValuesProp,
  refLink,
}) => {
  const { signUp } = useAuth();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          email: Yup.string()
            .matches(EMAIL_REGEX, t('validation.validEmail'))
            .required(t('validation.required')),
          isPrivacyPolicyAccepted: Yup.boolean()
            .oneOf([true], t('validation.required'))
            .required(t('validation.required')),
          locale: Yup.string().required(t('validation.required')),
          password: Yup.string()
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/,><’:;|_~`])[A-Za-z\d^$*.[\]{}()?\-"!@#%&/,><’:;|_~`]{8,}$/,
              t('validation.passwordShouldContain'),
            )
            .required(t('validation.required')),
        }) as any,
      ),
    [t],
  );

  const onSubmit = async ({ email, password, locale }: InitialValues) => {
    try {
      await signUp({
        attributes: {
          'custom:refLink': refLink ?? '',
          locale,
        },
        email,
        password,
      });
      setSuccessDialogOpen(true);
    } catch (error: any) {
      return {
        [FORM_ERROR]: error?.message,
      };
    }
  };

  return (
    <>
      <ConfirmUserDialog
        open={isSuccessDialogOpen}
        onClose={() => navigate(ROUTES.AUTH.login.path)}
      />
      <Form
        onSubmit={onSubmit}
        initialValues={
          {
            isPrivacyPolicyAccepted: false,
            locale: language,
            ...initialValuesProp,
          } as Partial<InitialValues>
        }
        validate={validate}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {submitError && <Alert severity="error">{t(submitError)}</Alert>}
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label={t('email')}
                name="email"
                parse={compose(removeSpaces, toLowerCase)}
              />
              <TextField
                fullWidth
                autoComplete="current-password"
                type={isPasswordShown ? 'text' : 'password'}
                label={t('password')}
                name="password"
                parse={removeSpaces}
                format={removeSpaces}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setIsPasswordShown(prev => !prev)}
                      >
                        <Icon icon={isPasswordShown ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Checkbox
                name="isPrivacyPolicyAccepted"
                label={
                  <Trans i18nKey="iAgreeToPrivacyPolicy">
                    I agree to{' '}
                    <Link
                      underline="none"
                      variant="subtitle2"
                      href={
                        MAP_LANGUAGE_CODE_TO_PRIVACY_POLICY_LINK[language] ||
                        PRIVACY_POLICY_LINK_DEFAULT
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </Link>
                  </Trans>
                }
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={submitting}
              >
                {t('register')}
              </LoadingButton>
            </Stack>
          </form>
        )}
      </Form>
    </>
  );
};
