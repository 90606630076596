import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Snackbar(theme: Theme): Record<string, any> {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
