type ObjectKey = string | number | symbol;

export const arrayGroupBy = <
  TItem extends Record<K, any>,
  K extends ObjectKey = ObjectKey,
>(
  items: TItem[],
  key: K,
): Record<ObjectKey, TItem[]> =>
  items.reduce((result, item) => {
    if (!item[key]) {
      return result;
    }
    return {
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    };
  }, {} as Record<ObjectKey, TItem[]>);
