import { useContext } from 'react';
import { AuthContext, AuthContextInterface } from '../providers/AuthProvider';

export const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext);
  // this way components always see context with values instead of context|null
  if (context === undefined) {
    throw Error('Auth context have to be defined');
  }
  return context;
};
