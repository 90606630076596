import { FC } from 'react';
import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/system';

import { AdvertiserStatus } from '@src/../types/globalTypes';
import { getAdvertiserStatusTranslated } from './getAdvertiserStatusTranslated';

const getColorsByStatus = (
  status: string,
  theme: Theme,
): Record<string, { color: string; backgroundColor: string }> =>
  ({
    [AdvertiserStatus.PROFILE]: {
      backgroundColor: theme.palette.info.lighter,
      color: theme.palette.info.dark,
    },
    [AdvertiserStatus.PENDING]: {
      backgroundColor: theme.palette.warning.lighter,
      color: theme.palette.warning.dark,
    },
    [AdvertiserStatus.APPROVED]: {
      backgroundColor: theme.palette.success.lighter,
      color: theme.palette.success.dark,
    },
  }[status] ?? {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  });

interface Props {
  status: AdvertiserStatus;
}

const TypographyStyled = styled(Typography)<Props>(({ theme, status }) => ({
  borderRadius: theme.spacing(0.5),
  display: 'inline-block',
  flexGrow: 0,
  fontSize: 12,
  fontWeight: 600,
  padding: theme.spacing('1px', 1),
  textOverflow: 'ellipsis',
  textTransform: 'none',
  ...getColorsByStatus(status, theme),
}));

export const AdvertiserStatusBadge: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <TypographyStyled variant="body1" status={status}>
      {getAdvertiserStatusTranslated(status, t)}
    </TypographyStyled>
  );
};
