import { FC, HTMLProps, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Button, Fade, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SignaturePadContainer = styled(
  ({ children, ...props }: { height: string } & HTMLProps<HTMLDivElement>) => (
    <div {...props}>{children}</div>
  ),
)(
  ({ theme, height }) => ({
    '& > .canvas': {
      height: '100%',
      width: '100%',
    },
    backgroundColor: theme.palette.grey[200],
    borderRadius: 12,
    height: height ?? '100%',
    position: 'relative',
    width: '100%',
  }),
  ({ height }: any) => ({ height }),
);

const ClearButton = styled(Button)`
  && {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

interface Props {
  clearOnResize?: boolean;
  dotSize?: number;
  encoderOptions?: Record<string, any>;
  height?: string;
  mimeType?: string;
  onChange: (value: string | null) => any;
  penProps?: {
    maxWidth?: number;
    minDistance?: number;
    minWidth?: number;
    penColor?: string;
    throttle?: number;
    velocityFilterWeight?: number;
  };
  clearButtonLabel?: string;
}

const SIGNATURE_MIN_SIZE = 2000;
const FLASH_MESSAGE_TIMEOUT = 1500;

export const SignaturePad: FC<Props> = ({
  clearButtonLabel = 'Clear',
  encoderOptions = {},
  height = '200px',
  mimeType = 'image/png',
  onChange,
  penProps = {},
}) => {
  const padRef = useRef(null);
  const [flashMessage, setFlashMessage] = useState<string | null>(null);

  const reset = () => {
    (padRef?.current as any).clear();
    onChange(null);
  };

  const onClearButtonClick = () => {
    reset();
  };

  const checkIsMinSizeValid = (canvas: string, size = SIGNATURE_MIN_SIZE) => {
    return canvas.length >= size;
  };

  // todo move to hook
  useEffect(() => {
    if (flashMessage) {
      const timeout = setTimeout(() => {
        setFlashMessage(null);
      }, FLASH_MESSAGE_TIMEOUT);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [flashMessage]);

  // fixes clear on scroll for mobile
  // https://github.com/agilgur5/react-signature-canvas/pull/66
  // const userAgent = window?.navigator?.userAgent;
  // const isMobile =
  //   userAgent &&
  //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     userAgent,
  //   );
  // const shouldClearOnResize = !isMobile;
  const shouldClearOnResize = false;

  return (
    <Paper sx={{ position: 'relative' }}>
      {flashMessage && (
        <Box
          sx={{
            bottom: '16px',
            left: '50%',
            marginLeft: '-75px',
            position: 'absolute',
            width: '150px',
            zIndex: 5,
          }}
        >
          <Fade in>
            <Typography variant="subtitle2" color="text.secondary">
              {flashMessage}
            </Typography>
          </Fade>
        </Box>
      )}

      <SignaturePadContainer height={height}>
        <SignatureCanvas
          clearOnResize={shouldClearOnResize}
          penColor="#103ffb"
          {...penProps}
          onBegin={() => {
            setFlashMessage(null);
          }}
          onEnd={() => {
            const canvas = (padRef?.current as any)
              ?.getTrimmedCanvas()
              .toDataURL(mimeType, encoderOptions);

            const isMinSizeValid = checkIsMinSizeValid(canvas);
            if (!isMinSizeValid) {
              setFlashMessage('Signature is too short');
              reset();
            } else {
              onChange(canvas);
            }
          }}
          canvasProps={{ className: 'canvas' }}
          ref={padRef}
        />
        <ClearButton onClick={onClearButtonClick}>
          {clearButtonLabel}
        </ClearButton>
      </SignaturePadContainer>
    </Paper>
  );
};
