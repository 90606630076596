import { FC } from 'react';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@mui/material/styles';

export const getSearchInputStyles = (theme: Record<string, any>): any => ({
  '&& fieldset': {
    border: 0,
  },
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  backgroundColor: theme.palette.grey[200],
  margin: 0,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    duration: theme.transitions.duration.shorter,
    easing: theme.transitions.easing.easeInOut,
  }),
  width: '100%',
});

const StyledSearch = styled(OutlinedInput)(({ theme }) =>
  getSearchInputStyles(theme),
);

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  size?: 'small' | 'medium';
}

export const SearchInput: FC<Props> = ({
  placeholder,
  onChange,

  value,
}) => {
  return (
    <StyledSearch
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <Box
            component={Icon}
            icon={searchFill}
            sx={{ color: 'text.disabled' }}
          />
        </InputAdornment>
      }
    />
  );
};
