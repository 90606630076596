import { InsuranceCaseFragment } from '@common/queries/types/InsuranceCaseFragment';
import { ROUTES } from '@common/routes';
import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceCaseStatusBadge } from './InsuranceCaseStatusBadge';
import { PageNavHeader } from './PageNavHeader';

interface Props {
  insuranceCase: InsuranceCaseFragment;
  isReviewing: boolean;
}

export const InsuranceCasePageNavHeader: FC<Props> = ({
  insuranceCase,
  isReviewing,
}) => {
  const { t } = useTranslation();
  const {
    id,
    internalCRMNumber,
    profile: { firstName, lastName },
    status,
    advertiser: { publicId: advertiserPublicId },
  } = insuranceCase;

  const navItemsConfig = useMemo(
    () => [
      {
        title: t('reviewDocuments'),
        to: ROUTES.BRANCH.insuranceCaseDetails({ insuranceCaseId: id }),
      },
      {
        title: t('finishReview'),
        to: ROUTES.BRANCH.insuranceCaseFinishReview({ insuranceCaseId: id }),
      },
    ],
    [t],
  );

  return (
    <PageNavHeader
      backTo={ROUTES.BRANCH.applicants()}
      title={
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography variant="h5">
              {`${firstName || '-'} ${lastName || '-'}`}
            </Typography>
            <InsuranceCaseStatusBadge status={status} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {advertiserPublicId && (
              <Typography variant="overline" noWrap color="GrayText">
                {t('advertiserPublicId')}: {advertiserPublicId}
              </Typography>
            )}
            {advertiserPublicId && internalCRMNumber && (
              <Typography>|</Typography>
            )}
            {internalCRMNumber && (
              <Typography variant="overline" noWrap color="GrayText">
                {t('internalCRMNumber')}: {internalCRMNumber}
              </Typography>
            )}
          </Stack>
        </Stack>
      }
      navItemsConfig={isReviewing ? navItemsConfig : undefined}
    />
  );
};
