import { FC } from 'react';
import { motion } from 'framer-motion';
import { alpha, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
}));

export const LoadingScreen: FC<{ text?: string } & any> = ({
  text,
  ...rest
}) => (
  <>
    <RootStyle {...rest}>
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatDelay: 1,
        }}
      >
        <Box sx={{ height: 40, width: 40 }}>
          <img
            alt="logo"
            src="/static/icons/icon-500x500.png"
            width="40"
            height="auto"
          />
        </Box>
      </motion.div>
      <Box
        component={motion.div}
        animate={{
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          opacity: [0.25, 1, 1, 1, 0.25],
          rotate: [270, 0, 0, 270, 270],
          scale: [1.2, 1, 1, 1.2, 1.2],
        }}
        transition={{ duration: 3.2, ease: 'linear', repeat: Infinity }}
        sx={{
          border: theme =>
            `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
          borderRadius: '25%',
          height: 100,
          position: 'absolute',
          width: 100,
        }}
      />
      <Box
        component={motion.div}
        animate={{
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          rotate: [0, 270, 270, 0, 0],
          scale: [1, 1.2, 1.2, 1, 1],
        }}
        transition={{
          duration: 3.2,
          ease: 'linear',
          repeat: Infinity,
        }}
        sx={{
          border: theme =>
            `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
          borderRadius: '25%',
          height: 120,
          position: 'absolute',
          width: 120,
        }}
      />
      {text && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            align="center"
            sx={{ pt: 32, px: 2 }}
          >
            {text}
          </Typography>
        </Box>
      )}
    </RootStyle>
  </>
);
