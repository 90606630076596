export const getBlockedReviewPDFFieldNames = (
  values: Record<string, any>,
): string[] => {
  const blockedFieldNames: string[] = [];

  const setBlockedFields = (condition: boolean, fieldNames: string[]) => {
    if (condition) {
      blockedFieldNames.push(...fieldNames);
    }
  };

  setBlockedFields(
    !values['main_0_insurancePurposes_trainee'] &&
      !values['main_0_insurancePurposes_employment'] &&
      !values['main_0_insurancePurposes_partner'],
    [
      'main_0_employerAddress.zipCode,employerAddress.place,employerAddress.streetName,employerAddress.houseNumber',
      'main_0_employerName',
      'main_0_hasFamilyRelationshipToEmployer',
      'main_0_jobStartDate',
      'main_0_salaryRange_high',
      'main_0_salaryRange_low',
    ],
  );
  setBlockedFields(!values['main_0_insurancePurposes_study'], [
    'main_0_studyStartDate',
    'main_0_studyEndDate',
  ]);

  setBlockedFields(!values['main_0_insurancePurposes_other'], [
    'main_0_otherInsurancePurposeStartDate',
    'main_0_otherInsurancePurpose',
  ]);

  setBlockedFields(!values['main_0_insurancePurposes_unemployment'], [
    'main_0_unemploymentStartDate',
    'main_0_unemploymentBenefitStartDate',
  ]);

  setBlockedFields(!values['main_0_insurancePurposes_pension'], [
    'main_0_pensionStartDate',
    'main_0_pensionAppliedStartDate',
  ]);

  setBlockedFields(!values['main_0_hasPreviousInsurance'], [
    'main_0_previousInsuranceStartDate',
    'main_0_previousInsuranceEndDate',
    'main_0_previousInsuranceProviderName',
    'main_0_previousInsuranceType_mainMember',
    'main_0_previousInsuranceType_familyMember',
    'main_0_previousInsuranceFamilyMemberLastName',
    'previousInsuranceFamilyMemberFirstName',
    'main_0_previousInsuranceFamilyMemberBirthDate',
    'main_0_previousInsuranceFamilyMemberPolicyNumber',
  ]);

  setBlockedFields(!values['main_0_previousInsuranceType_familyMember'], [
    'main_0_previousInsuranceFamilyMemberLastName,previousInsuranceFamilyMemberFirstName',
    'main_0_previousInsuranceFamilyMemberBirthDate',
    'main_0_previousInsuranceFamilyMemberPolicyNumber',
    'main_0_previousInsuranceFamilyMemberLastName,previousInsuranceFamilyMemberFirstName',
    'main_0_previousInsuranceFamilyMemberBirthDate',
    'main_0_previousInsuranceFamilyMemberPolicyNumber',
  ]);

  setBlockedFields(!values['spouse_0_previousInsuranсeProviderName'], [
    'spouse_0_previousInsuranceType_mainMember',
    'spouse_0_previousInsuranceType_familyMember',
    'spouse_0_previousInsuranceType_notStatutory',
  ]);

  [...Array(6)].forEach((_, index) => {
    setBlockedFields(!values[`child_${index}_previousInsuranсeProviderName`], [
      `child_${index}_previousInsuranceType_mainMember`,
      `child${index}_previousInsuranceType_familyMember`,
      `child${index}_previousInsuranceType_notStatutory`,
      `child${index}_previousInsuranceType_mainMember`,
      `child${index}_previousInsuranceType_familyMember`,
      `child${index}_previousInsuranceType_notStatutory`,
    ]);

    setBlockedFields(!values[`child_${index}_isEmployed_yes`], [
      `child_${index}_jobStartDate,jobEndDate`,
      `child_${index}_jobIncome`,
      `child_${index}_miniJobStartDate,miniJobEndDate`,
      `child_${index}_miniJobIncome`,
    ]);
  });

  setBlockedFields(!values['spouse_0_isEmployed_yes'], [
    'spouse_0_jobStartDate,jobEndDate',
    'spouse_0_jobIncome',
    'spouse_0_miniJobStartDate,miniJobEndDate',
    'spouse_0_miniJobIncome',
  ]);

  return blockedFieldNames;
};
