import { AdvertiserStatus } from '@src/../types/globalTypes';
import { TFunction } from 'react-i18next';

export const getAdvertiserStatusTranslated = (
  value: AdvertiserStatus,
  t: TFunction,
): string => {
  const MAP_STATUS_TO_TEXT: Record<AdvertiserStatus, string> = {
    [AdvertiserStatus.PROFILE]: t('advertiserStatus.profile'),
    [AdvertiserStatus.CONTRACT_SIGNING]: t('advertiserStatus.contractSigning'),
    [AdvertiserStatus.ADDENDUM_SIGNING]: t('advertiserStatus.addendumSigning'),
    [AdvertiserStatus.PENDING]: t('advertiserStatus.pending'),
    [AdvertiserStatus.APPROVED]: t('advertiserStatus.approved'),
  };

  return MAP_STATUS_TO_TEXT[value] || value;
};
