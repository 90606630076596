import React, { FC } from 'react';
import { Page } from '@components/Page';
import { useTranslation } from 'react-i18next';
import { Container, Paper } from '@mui/material';
import { HeaderBreadcrumbs } from '@components/HeaderBreadcrumbs';
import { AdvertiserUndersTable } from '@pages/AdvertiserUndersPage/AdvertiserUndersTable';
import { Maybe } from '@shared/types';

interface Props {
  advertiserProvision: Maybe<string>;
  advertiserId: string;
}

// const UnderAdvertiserInviteCodeBadge: FC = memo(() => {
//   const { t } = useTranslation();
//   const { advertiserId } = useCurrentUser();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const openPopover = event => setAnchorEl(event.target);
//   const closePopover = () => setAnchorEl(null);
//
//   return (
//     <QueryLoaderComponent
//       queryState={inviteCodeQueryState}
//       renderData={data => (
//         <Typography
//           component="div"
//           sx={{
//             alignItems: 'center',
//             backgroundColor: theme => theme.palette.grey[300],
//             borderRadius: 1,
//             display: 'flex',
//             justifyContent: 'space-between',
//             pl: 2,
//             pr: 1,
//             py: 0.5,
//           }}
//           color="GrayText"
//           variant="subtitle2"
//         >
//           {t('advertiserInviteCode')}: {data.advertiserInviteCode.code}
//           <IconButton size="small" onClick={openPopover}>
//             <InfoOutlinedIcon />
//           </IconButton>
//           <Popover
//             anchorEl={anchorEl}
//             open={!!anchorEl}
//             onClose={closePopover}
//             PaperProps={{ sx: { p: 2 } }}
//           >
//             <Typography sx={{ pb: 1 }} variant="h6">
//               {t('advertiserInviteCode')}
//             </Typography>
//             <Typography>{t('advertiserInviteCode.badgeInfoDescr')}</Typography>
//           </Popover>
//         </Typography>
//       )}
//     />
//   );
// });

export const AdvertiserUndersPage: FC<Props> = ({
  advertiserProvision,
  advertiserId,
}) => {
  const { t } = useTranslation();

  return (
    <Page title={t('pageTitle.subAdvertisers')}>
      <Container>
        <HeaderBreadcrumbs heading={t('subAdvertisersList')} links={[]} />
        <Paper elevation={3}>
          <AdvertiserUndersTable
            advertiserId={advertiserId}
            mainAdvertiserProvision={advertiserProvision}
          />
        </Paper>
      </Container>
    </Page>
  );
};
