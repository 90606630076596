import { GQL } from '@common/queries';
import { InsuranceCaseDocumentReviewStatus } from '@shared/constants';
import { useMutation } from '@apollo/client';
import { GetInsuranceCaseDocumentReviews } from '@queries/types/GetInsuranceCaseDocumentReviews';
import { Maybe } from '@shared/types';

type UpsertDocumentReview = (args: {
  documentName: string;
  rejectionReason: string;
  insuranceCaseId: string;
}) => any;

type DeleteDocumentReview = (args: {
  insuranceCaseId: string;
  documentReviewId: string;
}) => any;

export const useDocumentReviewActions = (): {
  isUpsertDocumentReviewLoading: boolean;
  isDeleteDocumentReviewLoading: boolean;
  upsertDocumentReview: UpsertDocumentReview;
  deleteDocumentReview: DeleteDocumentReview;
} => {
  const [upsertReview, { loading: isUpsertDocumentReviewLoading }] =
    useMutation(GQL.INSURANCE_CASE_REVIEW.UPSERT_DOCUMENT_REVIEW);
  const [deleteReview, { loading: isDeleteDocumentReviewLoading }] =
    useMutation(GQL.INSURANCE_CASE_REVIEW.DELETE_DOCUMENT_REVIEW);

  const upsertDocumentReview: UpsertDocumentReview = ({
    documentName,
    rejectionReason,
    insuranceCaseId,
  }) => {
    const variables = {
      documentName,
      insuranceCaseId,
      rejectionReason,
      status: InsuranceCaseDocumentReviewStatus.Rejected,
    };

    return upsertReview({
      optimisticResponse: {
        __typename: 'InsuranceCaseReviewMutations',
        insuranceCaseReviewMutations: {
          upsertDocumentReview: {
            __typename: 'InsuranceCaseDocumentReview',
            createdBy: null,
            id: Date.now(),
            rejectedDocuments: [],
            ...variables,
          },
        },
      },
      update: (
        cache,
        {
          data: {
            insuranceCaseReviewMutations: { upsertDocumentReview: result },
          },
        },
      ) => {
        const data = cache.readQuery<Maybe<GetInsuranceCaseDocumentReviews>>({
          query: GQL.INSURANCE_CASE_REVIEW.DOCUMENT_REVIEWS,
          variables: {
            insuranceCaseId,
          },
        });

        cache.writeQuery({
          data: {
            insuranceCaseDocumentReviews: [
              ...(data?.insuranceCaseDocumentReviews || []),
              result,
            ],
          },
          query: GQL.INSURANCE_CASE_REVIEW.DOCUMENT_REVIEWS,
          variables: {
            insuranceCaseId,
          },
        });
      },
      variables,
    });
  };

  const deleteDocumentReview: DeleteDocumentReview = ({
    insuranceCaseId,
    documentReviewId,
  }) =>
    deleteReview({
      optimisticResponse: {
        __typename: 'InsuranceCaseReviewMutations',
        insuranceCaseReviewMutations: {
          deleteDocumentReview: true,
        },
      },

      update: cache => {
        const data = cache.readQuery<Maybe<GetInsuranceCaseDocumentReviews>>({
          query: GQL.INSURANCE_CASE_REVIEW.DOCUMENT_REVIEWS,
          variables: {
            insuranceCaseId,
          },
        });

        if (data?.insuranceCaseDocumentReviews) {
          cache.writeQuery({
            data: {
              insuranceCaseDocumentReviews:
                data.insuranceCaseDocumentReviews.filter(
                  ({ id }) => id !== documentReviewId,
                ),
            },
            query: GQL.INSURANCE_CASE_REVIEW.DOCUMENT_REVIEWS,
            variables: {
              insuranceCaseId,
            },
          });
        }
      },
      variables: {
        documentReviewId: documentReviewId,
      },
    });

  return {
    deleteDocumentReview,
    isDeleteDocumentReviewLoading,
    isUpsertDocumentReviewLoading,
    upsertDocumentReview,
  };
};
