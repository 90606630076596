import { StepComponent } from '@components/forms/types';
import { Stack, Typography } from '@mui/material';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';

export const DependantsVoidStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('dependantsVoidStepTitle')}</Typography>
      </StepTitle>
      <Typography>{t('dependantsVoidStepPrompt')}</Typography>
    </Stack>
  );
};
