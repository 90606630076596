import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { DatePicker, Select, TextField } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { StudyType } from '@shared/constants';
import { TFunction } from 'i18next';
import EuroIcon from '@mui/icons-material/Euro';
import { getStudyTypeLabel } from '@common/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { onlyNumbers } from '@common/forms/normalization';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): Yup.AnySchema =>
  Yup.object().shape({
    spouse_0_gradeOrSemester: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    spouse_0_isDoingMilitaryOrVoluntary: Yup.string().nullable(),
    spouse_0_isEmployment: Yup.boolean().nullable(),
    spouse_0_isEmploymentFullTime: Yup.boolean().nullable(),
    spouse_0_isHavingAdditionalIncome: Yup.string().nullable(),
    spouse_0_isStudying: Yup.boolean().nullable(),
    spouse_0_jobEndDate: Yup.string().nullable(),
    spouse_0_jobIncome: Yup.string().nullable(),
    spouse_0_jobStartDate: Yup.string().nullable(),
    spouse_0_militaryOrVoluntaryEndDate: Yup.string().nullable(),
    spouse_0_militaryOrVoluntaryStartDate: Yup.string().nullable(),
    spouse_0_miniJobEndDate: Yup.string().nullable(),
    spouse_0_miniJobIncome: Yup.string().nullable(),
    spouse_0_miniJobStartDate: Yup.string().nullable(),
    spouse_0_otherIncome: Yup.string().nullable(),
    spouse_0_studyEndDate: Yup.string().nullable(),
    spouse_0_studyStartDate: Yup.string().nullable(),
    spouse_0_studyType: Yup.string().nullable(),
  });

const getActiveFields = values => ({
  spouse_0_gradeOrSemester: !!values.spouse_0_isStudying,
  spouse_0_isEmploymentFullTime: values.spouse_0_isEmployment === true,
  spouse_0_jobEndDate: values.spouse_0_isEmploymentFullTime === true,
  spouse_0_jobIncome: values.spouse_0_isEmploymentFullTime === true,
  spouse_0_jobStartDate: values.spouse_0_isEmploymentFullTime === true,
  spouse_0_militaryOrVoluntaryEndDate:
    !!values.spouse_0_isDoingMilitaryOrVoluntary,
  spouse_0_militaryOrVoluntaryStartDate:
    !!values.spouse_0_isDoingMilitaryOrVoluntary,
  spouse_0_miniJobEndDate: values.spouse_0_isEmploymentFullTime === false,
  spouse_0_miniJobIncome: values.spouse_0_isEmploymentFullTime === false,
  spouse_0_miniJobStartDate: values.spouse_0_isEmploymentFullTime === false,
  spouse_0_otherIncome: !!values.spouse_0_isHavingAdditionalIncome,
  spouse_0_studyEndDate: !!values.spouse_0_isStudying,
  spouse_0_studyStartDate: !!values.spouse_0_isStudying,
  spouse_0_studyType: !!values.spouse_0_isStudying,
});

export const SpouseOccupationStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.ocсupation')}</Typography>
      </StepTitle>
      <QuestionField
        name="spouse_0_isEmployment"
        question={getLabel('spouse_0_isEmployment', t, { isDraft })}
      />
      <ConditionalFields fieldNames={['spouse_0_isEmploymentFullTime']}>
        <QuestionField
          name="spouse_0_isEmploymentFullTime"
          question={getLabel('spouse_0_isEmploymentFullTime', t, { isDraft })}
        />
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          'spouse_0_jobStartDate',
          'spouse_0_jobEndDate',
          'spouse_0_jobIncome',
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name="spouse_0_jobStartDate"
            label={getLabel('spouse_0_jobStartDate', t)}
          />
          <DatePicker
            name="spouse_0_jobEndDate"
            label={getLabel('spouse_0_jobEndDate', t)}
          />
        </Stack>
        <TextField
          name="spouse_0_jobIncome"
          label={getLabel('spouse_0_jobIncome', t)}
          parse={onlyNumbers}
          format={onlyNumbers}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EuroIcon />
              </InputAdornment>
            ),
          }}
        />
      </ConditionalFields>
      <ConditionalFields
        fieldNames={[
          'spouse_0_miniJobStartDate',
          'spouse_0_miniJobEndDate',
          'spouse_0_miniJobIncome',
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name="spouse_0_miniJobStartDate"
            label={getLabel('spouse_0_miniJobStartDate', t)}
          />
          <DatePicker
            name="spouse_0_miniJobEndDate"
            label={getLabel('spouse_0_miniJobEndDate', t)}
          />
        </Stack>
        <TextField
          name="spouse_0_miniJobIncome"
          label={getLabel('spouse_0_miniJobIncome', t)}
          parse={onlyNumbers}
          format={onlyNumbers}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EuroIcon />
              </InputAdornment>
            ),
          }}
        />
      </ConditionalFields>
      <QuestionField
        name="spouse_0_isStudying"
        question={getLabel('spouse_0_isStudying', t, { isDraft })}
      />
      <ConditionalFields
        fieldNames={[
          'spouse_0_studyStartDate',
          'spouse_0_studyEndDate',
          'spouse_0_studyType',
          'spouse_0_gradeOrSemester',
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name="spouse_0_studyStartDate"
            label={getLabel('spouse_0_studyStartDate', t)}
          />
          <DatePicker
            name="spouse_0_studyEndDate"
            label={getLabel('spouse_0_studyEndDate', t)}
          />
        </Stack>
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <Select
            name="spouse_0_studyType"
            label={getLabel('spouse_0_studyType', t)}
          >
            {Object.values(StudyType).map(value => (
              <MenuItem key={value} value={value}>
                {getStudyTypeLabel(value, t)}
              </MenuItem>
            ))}
          </Select>
          <TextField
            name="spouse_0_gradeOrSemester"
            label={getLabel('spouse_0_gradeOrSemester', t)}
          />
        </Stack>
      </ConditionalFields>

      <QuestionField
        name="spouse_0_isHavingAdditionalIncome"
        question={getLabel('spouse_0_isHavingAdditionalIncome', t, { isDraft })}
      />
      <ConditionalFields fieldNames={['spouse_0_otherIncome']}>
        <TextField
          name="spouse_0_otherIncome"
          label={getLabel('spouse_0_otherIncome', t)}
          parse={onlyNumbers}
          format={onlyNumbers}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EuroIcon />
              </InputAdornment>
            ),
          }}
        />
      </ConditionalFields>

      <QuestionField
        name="spouse_0_isDoingMilitaryOrVoluntary"
        question={getLabel('spouse_0_isDoingMilitaryOrVoluntary', t, {
          isDraft,
        })}
      />
      <ConditionalFields
        fieldNames={[
          'spouse_0_militaryOrVoluntaryStartDate',
          'spouse_0_militaryOrVoluntaryEndDate',
        ]}
      >
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <DatePicker
            name="spouse_0_militaryOrVoluntaryStartDate"
            label={getLabel('spouse_0_militaryOrVoluntaryStartDate', t)}
          />
          <DatePicker
            name="spouse_0_militaryOrVoluntaryEndDate"
            label={getLabel('spouse_0_militaryOrVoluntaryEndDate', t)}
          />
        </Stack>
      </ConditionalFields>
    </Stack>
  );
};

SpouseOccupationStep.getValidationSchema = getValidationSchema;
SpouseOccupationStep.getActiveFields = getActiveFields;
