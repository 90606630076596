import { StepComponent } from '@components/forms/types';
import { Stack, Typography } from '@mui/material';
import { Checkboxes } from '@common/forms/fields';
import { ConditionalFields, StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { QuestionField } from '@common/forms/fields/QuestionField';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { getLabel } from '@components/forms/getLabelByFieldName';

const getValidationSchema = (t: TFunction): Yup.AnySchema =>
  Yup.object().shape({
    main_0_hasEmployerPensionOrLumpSum: Yup.boolean().nullable(),
    main_0_hasWillingCommunicateEnglish: Yup.boolean().nullable(),
    main_0_hasWillingToBeReferral: Yup.boolean().nullable(),
    main_0_isAgriculturalEntrepreneur: Yup.boolean().nullable(),
    main_0_isHavingAdditionalIncome: Yup.boolean().nullable(),
  });

const getActiveFields = values => ({
  main_0_hasEmployerPensionOrLumpSum: !!values.main_0_isHavingAdditionalIncome,
  main_0_isAgriculturalEntrepreneur: !!values.main_0_isHavingAdditionalIncome,
});

export const AdditionalInformationStep: StepComponent = ({ meta }) => {
  const isDraft = meta?.isDraft;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.additionalInfo')}</Typography>
      </StepTitle>

      <QuestionField
        name="main_0_isHavingAdditionalIncome"
        question={getLabel('main_0_isHavingAdditionalIncome', t, { isDraft })}
      />

      <ConditionalFields
        fieldNames={[
          'main_0_hasEmployerPensionOrLumpSum',
          'main_0_isAgriculturalEntrepreneur',
        ]}
      >
        <QuestionField
          name="main_0_hasEmployerPensionOrLumpSum"
          question={getLabel('main_0_hasEmployerPensionOrLumpSum', t, {
            isDraft,
          })}
        />
        <QuestionField
          name="main_0_isAgriculturalEntrepreneur"
          question={getLabel('main_0_isAgriculturalEntrepreneur', t, {
            isDraft,
          })}
        />
      </ConditionalFields>

      <Checkboxes
        name="main_0_hasWillingToBeReferral"
        data={{
          label: getLabel('main_0_hasWillingToBeReferral', t, { isDraft }),
          value: true,
        }}
      />
      <Checkboxes
        name="main_0_hasWillingCommunicateEnglish"
        data={{
          label: getLabel('main_0_hasWillingCommunicateEnglish', t, {
            isDraft,
          }),
          value: true,
        }}
      />
    </Stack>
  );
};

AdditionalInformationStep.getValidationSchema = getValidationSchema;
AdditionalInformationStep.getActiveFields = getActiveFields;
