import { FC, useEffect, useState } from 'react';
import qs from 'qs';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { DocumentFragment } from '@queries/types/DocumentFragment';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMutation, useQuery } from '@apollo/client';
import { GQL } from '@common/queries';
import { QueryLoaderComponent } from '@components/QueryLoaderComponent';
import { clickOnDownloadUrl } from '@common/utils/clickOnDownloadUrl';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { DotStepper } from '@components/DotStepper';
import { SignedUrl } from '@queries/types/SignedUrl';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { DocumentNewBadge } from './DocumentNewBadge';

const NavigateIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255,0.3)',
  border: `1px solid ${theme.palette.grey[300]}`,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
}));

const EmbedPdf = styled('embed')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  height: '100%',
  width: '100%',
}));

const Image = styled('img')(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[5],
  maxHeight: '100%',
  maxWidth: '100%',
  padding: theme.spacing(2),
}));

interface Props {
  documents: (DocumentFragment & { isUploadedAfterReview: boolean })[];
  documentContainerId: string;
  onCloseClick: VoidFunction;
}

export const DocumentsPreview: FC<Props> = ({
  documents,
  documentContainerId,
  onCloseClick,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const { documentName, id, isUploadedAfterReview } =
    documents[selectedDocumentIndex];

  useEffect(() => {
    setIsImageLoaded(false);
  }, [selectedDocumentIndex]);

  const queryState = useQuery<SignedUrl>(GQL.DOCUMENT.SIGNED_URL, {
    variables: {
      documentKey: {
        documentContainerId,
        id,
      },
    },
  });

  const [getSignedUrl, { loading: isSignedUrlMutationLoading }] = useMutation(
    GQL.DOCUMENT.GET_SIGNED_URL,
  );

  const onDownloadButtonClick = async () => {
    try {
      const result = await getSignedUrl({
        variables: {
          documentKey: {
            documentContainerId,
            id,
          },
        },
      });
      clickOnDownloadUrl(result.data.documentMutations.getSignedUrl, true);
    } catch (error) {
      const label = getErrorLabelTranslated(error, t) || t('error.generic');
      enqueueSnackbar(label, { variant: 'error' });
    }
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        backgroundColor: theme => theme.palette.grey[100],
        height: '100%',
        p: 4,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography color="GrayText" variant="subtitle1">
            {getLabel(documentName, t)}
          </Typography>
          {isUploadedAfterReview && (
            <Box sx={{ display: 'flex' }}>
              <DocumentNewBadge />
            </Box>
          )}
        </Stack>
        <IconButton onClick={onCloseClick} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <QueryLoaderComponent
        queryState={queryState}
        renderLoading={() => (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        renderData={({ signedUrl: url }) => {
          const query = qs.parse(url, { ignoreQueryPrefix: true });
          const contentType = query['response-content-type'];
          const isImage =
            contentType && (contentType as string[]).includes('image');
          const isPdf =
            contentType &&
            (contentType as string[]).includes('application/pdf');

          if (isImage) {
            return (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  overflow: 'hidden',
                  padding: 2,
                }}
              >
                {!isImageLoaded && <CircularProgress />}
                <Image
                  src={url}
                  onLoad={() => setIsImageLoaded(true)}
                  sx={{
                    ...(isImageLoaded ? {} : { display: 'none' }),
                  }}
                />
              </Box>
            );
          }

          if (isPdf) {
            return (
              <Paper
                elevation={5}
                sx={{
                  borderRadius: 2,
                  height: '100%',
                  width: '100%',
                }}
              >
                <EmbedPdf src={url} />
              </Paper>
            );
          }

          return (
            <Stack
              sx={{
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
                width: '100%',
              }}
              spacing={2}
            >
              <Typography color="GrayText" variant="body1">
                {t('previewIsNotSupportedForDocumentType')}{' '}
              </Typography>
              <LoadingButton
                loading={isSignedUrlMutationLoading}
                variant="contained"
                onClick={onDownloadButtonClick}
              >
                {t('download')}
              </LoadingButton>
            </Stack>
          );
        }}
      />
      {documents.length > 1 && (
        <>
          <Box display="flex" justifyContent="center">
            <DotStepper
              count={documents.length}
              onDotClick={setSelectedDocumentIndex}
              selectedIndex={selectedDocumentIndex}
            />
          </Box>
          <NavigateIconButton
            disabled={selectedDocumentIndex === 0}
            onClick={() => setSelectedDocumentIndex(prev => prev - 1)}
            sx={{ left: 8 }}
          >
            <ArrowBackIcon />
          </NavigateIconButton>
          <NavigateIconButton
            disabled={selectedDocumentIndex === documents.length - 1}
            onClick={() => setSelectedDocumentIndex(prev => prev + 1)}
            sx={{ right: 8 }}
          >
            <ArrowForwardIcon />
          </NavigateIconButton>
        </>
      )}
    </Stack>
  );
};
