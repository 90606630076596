import * as Yup from 'yup';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '@hooks/useAuth';
import qs from 'qs';
import { makeValidate } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { ROUTES } from '@common/routes';
import { ConfirmUserDialog } from '@components/ConfirmUserDialog';
import { TextField } from '@common/forms/fields';
import {
  compose,
  removeSpaces,
  toLowerCase,
} from '@common/forms/normalization';
import { useLocales } from '@common/hooks';
import { EMAIL_REGEX } from '@shared/regex';
import { MIconButton } from '../@material-extend';
import { LoadingScreen } from '../LoadingScreen';

type InitialValues = {
  email: string;
  password: string;
};

export const LoginForm: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { login, confirmEmail, resendConfirmationEmail } = useAuth();
  const {
    username,
    code,
    token,
    email: emailParam,
    locale,
  } = qs.parse(location.search, {
    decoder: d => decodeURIComponent(d),
    ignoreQueryPrefix: true,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmRequiredDialogOpen, setConfirmRequiredDialogOpen] =
    useState(false);
  const { changeLanguage } = useLocales();

  useEffect(() => {
    if (!locale) {
      return;
    }
    changeLanguage(locale as string);
  }, [locale]);

  useEffect(() => {
    if (!username || !token) {
      return;
    }
    loginUserSilent(username, token);
  }, [username, token]);

  useEffect(() => {
    if (!username || !code) {
      return;
    }

    confirmUserEmail(username as string, code as string);
  }, [username, code]);

  const loginUserSilent = async (email, password) => {
    try {
      setIsLoading(true);
      await login({ email, password });
    } catch (error) {
      enqueueSnackbar(t('error.generic'), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmUserEmail = async (u: string, c: string) => {
    try {
      setIsLoading(true);
      await confirmEmail({ code: c, username: u });
      enqueueSnackbar('Your email has been successfully confirmed!', {
        variant: 'success',
      });
    } catch (error: any) {
      if (
        error.code === 'ExpiredCodeException' &&
        typeof emailParam === 'string'
      ) {
        await resendConfirmationEmail(emailParam);
        setConfirmRequiredDialogOpen(true);
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleIsPasswordShow = () => {
    setIsPasswordShown(show => !show);
  };

  const onSubmit = async ({ email, password }) => {
    try {
      await login({ email, password });
      enqueueSnackbar(t('loginSuccess'), {
        action: key => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
        variant: 'success',
      });
    } catch (error: any) {
      if (error?.code === 'UserNotConfirmedException') {
        setConfirmRequiredDialogOpen(true);
        return;
      }
      return {
        [FORM_ERROR]: error.message,
      };
    }
  };

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          email: Yup.string()
            .matches(EMAIL_REGEX, t('validation.validEmail'))
            .required(t('validation.required')),
          password: Yup.string().required(t('validation.required')),
        }) as any,
      ),
    [t],
  );

  const initialValues: InitialValues = {
    email: (emailParam as string) || '',
    password: '',
  };

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          left: 0,
          position: 'fixed',
          top: 0,
          width: 1,
          zIndex: 9999,
        }}
      />
    );
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {submitError && <Alert severity="error">{t(submitError)}</Alert>}
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                name="email"
                label={t('email')}
                parse={compose(removeSpaces, toLowerCase)}
              />
              <TextField
                fullWidth
                name="password"
                autoComplete="current-password"
                type={isPasswordShown ? 'text' : 'password'}
                label={t('password')}
                parse={removeSpaces}
                format={removeSpaces}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleIsPasswordShow} edge="end">
                        <Icon icon={isPasswordShown ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ my: 2 }}
            >
              <Link
                component={RouterLink}
                variant="subtitle2"
                to={ROUTES.AUTH.forgotPassword()}
              >
                {t('forgotPasswordQuestion')}
              </Link>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={submitting}
            >
              {t('login')}
            </LoadingButton>
          </form>
        )}
      </Form>
      <ConfirmUserDialog
        open={isConfirmRequiredDialogOpen}
        onClose={() => setConfirmRequiredDialogOpen(false)}
      />
    </>
  );
};
