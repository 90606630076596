import { gql } from '@apollo/client';

export const AddressFrag = gql`
  fragment AddressFragment on Address {
    country
    houseNumber
    place
    streetName
    zipCode
  }
`;
