import { gql } from '@apollo/client';
import {
  InsuranceCaseDocumentReviewFrag,
  InsuranceCaseFieldReviewFrag,
} from '@common/queries/insuranceCaseReview.queries';

export const FormStateFrag = gql`
  fragment FormStateFragment on FormState {
    id
    state
    currentStepName
  }
`;

export const FORM_STATE = {
  FORM_STATE: gql`
    query GetFormState($formType: String!, $formContainerId: String!) {
      formState(formType: $formType, formContainerId: $formContainerId) {
        id
        state
        currentStepName
      }
    }
  `,
  FORM_STATE_WITH_REVIEWS: gql`
    query GetFormStateAndReviews(
      $insuranceCaseId: String!
      $formType: String!
      $formContainerId: String!
    ) {
      formState(formType: $formType, formContainerId: $formContainerId) {
        ...FormStateFragment
      }
      insuranceCaseFieldReviews(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseFieldReviewFragment
      }
      insuranceCaseDocumentReviews(insuranceCaseId: $insuranceCaseId) {
        ...InsuranceCaseDocumentReviewFragment
        # todo: fragment
        rejectedDocuments {
          id
          createdAt
          documentName
        }
      }
    }
    ${InsuranceCaseDocumentReviewFrag}
    ${InsuranceCaseFieldReviewFrag}
    ${FormStateFrag}
  `,
  UPSERT: gql`
    mutation UpsertFormState(
      $type: String!
      $formContainerId: String!
      $currentStepName: String
      $state: JSONObject!
    ) {
      formStateMutations {
        upsert(
          type: $type
          formContainerId: $formContainerId
          state: $state
          currentStepName: $currentStepName
        ) {
          id
          formContainerId
        }
      }
    }
  `,
};
