import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', ...rest }, ref) => (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        minHeight: '100%',
      }}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  ),
);
