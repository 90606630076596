import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as React from 'react';
import { SyntheticEvent, useState } from 'react';
import { GetInsuranceCasesPaged_insuranceCasesPaged_edges_node } from '@common/queries/types/GetInsuranceCasesPaged';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { useMutation } from '@apollo/client';
import { GQL } from '@common/queries';
import { useSnackbar } from 'notistack';
import { InsuranceCaseStatus, UserRole } from '@shared/constants';

interface Props {
  insuranceCase: GetInsuranceCasesPaged_insuranceCasesPaged_edges_node;
  userRole: UserRole;
}

export const InsuranceCaseActionsCell: React.FC<Props> = ({
  insuranceCase,
  userRole,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const [deleteInsuranceCase, { loading }] = useMutation(
    GQL.INSURANCE_CASE.DELETE,
  );

  const disableRemove =
    userRole === UserRole.BranchOffice &&
    ![InsuranceCaseStatus.Created, InsuranceCaseStatus.NotInsurable].includes(
      insuranceCase.status,
    );

  const onDeleteClick = async () => {
    if (disableRemove) {
      return;
    }
    confirm({
      description: t('deleteInsuranceCaseConfirmation.description'),
      title: t('deleteInsuranceCaseConfirmation.title'),
    }).then(async () => {
      try {
        await deleteInsuranceCase({
          update: cache => {
            cache.modify({
              fields: {
                insuranceCasesPaged(connection, { readField }) {
                  return {
                    ...connection,
                    edges: connection.edges.filter(({ node }) => {
                      return readField('id', node) !== insuranceCase.id;
                    }),
                    pageInfo: {
                      ...connection.pageInfo,
                      count: connection.pageInfo.count - 1,
                    },
                  };
                },
              },
            });
          },
          variables: { insuranceCaseId: insuranceCase.id },
        });

        enqueueSnackbar(t('deleteInsuranceCaseSnack.success'), {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(t('error.generic'), { variant: 'error' });
      }
    });
  };

  return (
    <>
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <IconButton
          onClick={event => {
            event.stopPropagation();
            openMenu(event);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        onClose={(event: SyntheticEvent) => {
          event.stopPropagation();
          closeMenu();
        }}
        open={!!anchorEl}
      >
        <Tooltip
          // open={!!anchorEl}
          color="primary"
          arrow
          title={
            disableRemove
              ? (t('text.cannotDeleteAtCurrentStatus') as string)
              : ''
          }
        >
          <MenuItem
            onClick={event => {
              // made to maintain tooltip
              event.stopPropagation();
              onDeleteClick();
              closeMenu();
            }}
            sx={{ color: disableRemove ? 'text.disabled' : 'text.primary' }}
            // disabled={disableRemove}
          >
            {t('delete')}
          </MenuItem>
        </Tooltip>
      </Menu>
    </>
  );
};
