import isPropValid from '@emotion/is-prop-valid';
import {
  Button,
  Popover,
  Stack,
  styled,
  Typography,
  Tooltip,
} from '@mui/material';
import { GQL } from '@queries';
import { InsuranceCaseFieldReviewStatus } from '@shared/constants';
import { Maybe } from '@shared/types';
import { useSnackbar } from 'notistack';
import { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TextField, makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import { getErrorLabelTranslated } from '@utils/getErrorLabelTranslated';
import { useMutation } from '@apollo/client';
import { GetInsuranceCaseFieldReviews } from '@queries/types/GetInsuranceCaseFieldReviews';
import { FieldReviewChangedView } from './FieldReviewChangedView';

interface FieldBoxStyledProps {
  height: number;
  isSelected: boolean;
  left: number;
  status?: string;
  top: number;
  width: number;
}

const FieldBox = styled('div', {
  shouldForwardProp: prop => isPropValid(prop as any),
})<FieldBoxStyledProps>(
  ({ theme, top, left, isSelected, width, height, status }) => {
    let backgroundColor = 'rgba(0,0,0,0.1)';
    if (status === InsuranceCaseFieldReviewStatus.Approved) {
      backgroundColor = 'rgba(0,255,0,0.1)';
    }
    if (status === InsuranceCaseFieldReviewStatus.Rejected) {
      backgroundColor = 'rgba(255,0,0,0.1)';
    }

    if (status === InsuranceCaseFieldReviewStatus.Changed) {
      backgroundColor = '#ffe55c4a';
    }

    return {
      backgroundColor,
      border: isSelected ? `2px solid ${theme.palette.primary.main}` : 'none',
      cursor: 'pointer',
      height,
      left,
      position: 'absolute',
      top,
      width,
    };
  },
);

interface Props {
  fieldReview?: any; // todo add type
  isDisabled?: boolean;
  height: number;
  id: string;
  insuranceCaseId: string;
  isTooltipShown?: boolean;
  left: number;
  pdfFieldName: string;
  rawPdfFieldValue: Maybe<string> | string[];
  top: number;
  value: Maybe<string | boolean>;
  width: number;
  pdfFieldType: string;
}

export const FieldReview: FC<Props> = memo(
  ({
    fieldReview,
    isDisabled,
    height,
    insuranceCaseId,
    isTooltipShown,
    left,
    pdfFieldName,
    pdfFieldType,
    rawPdfFieldValue,
    top,
    value,
    width,
  }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState<Maybe<HTMLElement>>(null);

    const [upsertReview] = useMutation(
      GQL.INSURANCE_CASE_REVIEW.UPSERT_FIELD_REVIEW,
    );
    const [deleteReview] = useMutation(
      GQL.INSURANCE_CASE_REVIEW.DELETE_FIELD_REVIEW,
    );
    const currentStatus = fieldReview?.status;

    const onRejectionSubmit = async ({ rejectionReason }) => {
      const variables = {
        insuranceCaseId,
        pdfFieldName,
        rejectedRawPdfFieldValue: rawPdfFieldValue,
        rejectionReason,
        status: InsuranceCaseFieldReviewStatus.Rejected,
      };
      try {
        setAnchorEl(null);
        await upsertReview({
          optimisticResponse: {
            __typename: 'InsuranceCaseReviewMutations',
            insuranceCaseReviewMutations: {
              upsertFieldReview: {
                __typename: 'InsuranceCaseFieldReview',
                fieldValues: [],
                formFieldNames: [],
                id: Date.now(),
                ...variables,
              },
            },
          },
          update: (
            cache,
            {
              data: {
                insuranceCaseReviewMutations: { upsertFieldReview },
              },
            },
          ) => {
            const data = cache.readQuery<Maybe<GetInsuranceCaseFieldReviews>>({
              query: GQL.INSURANCE_CASE_REVIEW.FIELD_REVIEWS,
              variables: {
                insuranceCaseId,
              },
            });

            cache.writeQuery({
              data: {
                insuranceCaseFieldReviews: [
                  ...(data?.insuranceCaseFieldReviews ?? []).filter(
                    ({ id }) => id !== upsertFieldReview.id,
                  ),
                  upsertFieldReview,
                ],
              },
              query: GQL.INSURANCE_CASE_REVIEW.FIELD_REVIEWS,
              variables: {
                insuranceCaseId,
              },
            });
          },
          variables,
        });
      } catch (error) {
        const label = getErrorLabelTranslated(error, t) || t('error.generic');
        enqueueSnackbar(label, { variant: 'error' });
      }
    };

    const onClearClick = async () => {
      try {
        setAnchorEl(null);
        await deleteReview({
          optimisticResponse: {
            __typename: 'InsuranceCaseReviewMutations',
            insuranceCaseReviewMutations: {
              deleteFieldReview: true,
            },
          },
          update: cache => {
            const data = cache.readQuery<Maybe<GetInsuranceCaseFieldReviews>>({
              query: GQL.INSURANCE_CASE_REVIEW.FIELD_REVIEWS,
              variables: {
                insuranceCaseId,
              },
            });
            if (data?.insuranceCaseFieldReviews) {
              cache.writeQuery({
                data: {
                  insuranceCaseFieldReviews:
                    data.insuranceCaseFieldReviews.filter(
                      ({ id: fieldId }) => fieldId !== fieldReview?.id,
                    ),
                },
                query: GQL.INSURANCE_CASE_REVIEW.FIELD_REVIEWS,
                variables: {
                  insuranceCaseId,
                },
              });
            }
          },
          variables: {
            fieldReviewId: fieldReview?.id,
            insuranceCaseId,
          },
        });
      } catch (error) {
        const label = getErrorLabelTranslated(error, t) || t('error.generic');
        enqueueSnackbar(label, { variant: 'error' });
      }
    };

    const validate = useMemo(
      () =>
        makeValidate(
          Yup.object().shape({
            rejectionReason: Yup.string().required(t('validation.required')),
          }) as any,
        ),
      [t],
    );

    return (
      <>
        {(!!fieldReview || !isDisabled) && (
          <Tooltip title={isTooltipShown ? value ?? '' : ''}>
            <FieldBox
              onClick={event => setAnchorEl(event.currentTarget)}
              top={top}
              left={left}
              width={width}
              height={height}
              status={currentStatus}
              isSelected={!!anchorEl}
            />
          </Tooltip>
        )}
        <Popover
          PaperProps={{
            sx: {
              maxWidth: 450,
              minWidth: 300,
            },
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
        >
          <Form
            initialValues={{
              rejectionReason: fieldReview?.rejectionReason ?? '',
            }}
            validate={validate}
            onSubmit={onRejectionSubmit}
          >
            {({ handleSubmit, dirty }) => (
              <form onSubmit={handleSubmit}>
                <Stack direction="column" spacing={2} sx={{ p: 2 }}>
                  <Typography variant="h6">
                    {isDisabled
                      ? t('rejectionReason')
                      : t('rejectApplicationField')}
                  </Typography>
                  {fieldReview?.status ===
                    InsuranceCaseFieldReviewStatus.Changed && (
                    <FieldReviewChangedView
                      rawPdfFieldValue={rawPdfFieldValue}
                      rejectedRawPdfFieldValue={
                        fieldReview?.rejectedRawPdfFieldValue
                      }
                      pdfFieldType={pdfFieldType}
                    />
                  )}
                  <TextField
                    autoFocus
                    multiline
                    disabled={isDisabled}
                    label={t('rejectionReason')}
                    minRows={3}
                    maxRows={6}
                    name="rejectionReason"
                  />
                  {!isDisabled && (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      {fieldReview?.status ===
                        InsuranceCaseFieldReviewStatus.Rejected && (
                        <Button onClick={onClearClick}>{t('clear')}</Button>
                      )}
                      <Button
                        disabled={fieldReview?.status === 'Rejected' && !dirty}
                        type="submit"
                        color="error"
                        variant="contained"
                      >
                        {fieldReview?.status ===
                        InsuranceCaseFieldReviewStatus.Rejected
                          ? t('update')
                          : t('reject')}
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </form>
            )}
          </Form>
        </Popover>
      </>
    );
  },
);
