import * as Yup from 'yup';
import {
  Alert,
  AlertTitle,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import { StepComponent } from '@components/forms/types';
import { StepTitle } from '@components/forms/StepTitle';
import { PoaStatus } from '@shared/constants';
import { Select } from '@common/forms/fields';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    poaStatus: Yup.string().oneOf(
      [PoaStatus.Generating, PoaStatus.Uploading, PoaStatus.NotAvailable],
      t('validation.required'),
    ),
  });
export const PowerOfAttorneyStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.powerOfAttorney')}</Typography>
      </StepTitle>
      <Alert sx={{ mb: 2 }} severity="info">
        <AlertTitle>{t('pleaseNotice')}</AlertTitle>
        <div>{t('youCanAlwaysReturnBackToThisStep')}</div>
      </Alert>
      <Grid container alignItems="stretch">
        <Select name="poaStatus" label={t('isPowerOfAttorneyAvailable')}>
          <MenuItem value={PoaStatus.Uploading}>
            {t('yesIwantToUpload')}
          </MenuItem>
          <MenuItem value={PoaStatus.Generating}>
            {t('generateAndSendToApplicant')}
          </MenuItem>
          <MenuItem value={PoaStatus.NotAvailable}>
            {t('noIWantDelegate')}
          </MenuItem>
        </Select>
      </Grid>
    </Stack>
  );
};

PowerOfAttorneyStep.getValidationSchema = getValidationSchema;
