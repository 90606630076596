import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { Stack, Typography } from '@mui/material';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { TextField } from '@common/forms/fields';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_address: Yup.object({
      country: Yup.string().nullable().required(t('validation.required')),
      houseNumber: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .nullable(),
      place: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .nullable(),
      streetName: Yup.string()
        .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
        .nullable(),
      zipCode: Yup.string().nullable(),
    }),
    main_0_pensionInsuranceNumber: Yup.string()
      .test('len', t('validation.shouldBeExactly', { characters: 12 }), val =>
        val ? val.length === 12 : true,
      )
      .nullable(),
  });

export const ResidenceStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">
          {t('stepTitle.residenceInformation')}
        </Typography>
      </StepTitle>

      <CountrySelect
        name="main_0_address.country"
        label={getLabel('main_0_address.country', t)}
      />
      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <TextField
          label={getLabel('main_0_address.zipCode', t)}
          name="main_0_address.zipCode"
        />
        <TextField
          label={getLabel('main_0_address.place', t)}
          name="main_0_address.place"
        />
      </Stack>

      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <TextField
          label={getLabel('main_0_address.streetName', t)}
          name="main_0_address.streetName"
        />
        <TextField
          label={getLabel('main_0_address.houseNumber', t)}
          name="main_0_address.houseNumber"
        />
      </Stack>

      <TextField
        name="main_0_pensionInsuranceNumber"
        label={getLabel('main_0_pensionInsuranceNumber', t)}
      />
    </Stack>
  );
};

ResidenceStep.getValidationSchema = getValidationSchema;
