import { gql } from '@apollo/client';
import { AddressFrag } from './address.queries';

export const BranchOfficeFrag = gql`
  fragment BranchOfficeFragment on BranchOffice {
    id
    address {
      ...AddressFragment
    }
    name
    email
    phone
    createdAt
    updatedAt
    createdById
  }
  ${AddressFrag}
`;

export const BRANCH_OFFICE = {
  BRANCH_OFFICE: gql`
    query GetBranchOffice($branchOfficeId: String!) {
      branchOffice(branchOfficeId: $branchOfficeId) {
        ...BranchOfficeFragment
      }
    }
    ${BranchOfficeFrag}
  `,
  BRANCH_OFFICES: gql`
    query GetBranchOffices($branchOfficeIds: [String!]) {
      branchOffices(branchOfficeIds: $branchOfficeIds) {
        id
        name
      }
    }
  `,
  BRANCH_OFFICES_PAGED: gql`
    query GetBranchOfficesPaged(
      $after: String
      $first: Int
      $searchValue: String
      $orderBy: String
      $orderDirection: OrderDirection
      $filterBy: String
      $filterValues: [String!]
    ) {
      branchOfficesPaged(
        after: $after
        first: $first
        searchValue: $searchValue
        orderBy: $orderBy
        orderDirection: $orderDirection
        filterBy: $filterBy
        filterValues: $filterValues
      ) {
        pageInfo {
          count
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...BranchOfficeFragment
          }
        }
      }
    }
    ${BranchOfficeFrag}
  `,
  CREATE: gql`
    mutation CreateBranchOffice($data: CreateBranchOfficeInput!) {
      branchOfficeMutations {
        create(data: $data) {
          ...BranchOfficeFragment
        }
      }
    }
    ${BranchOfficeFrag}
  `,
  UPDATE: gql`
    mutation UpdateBranchOffice(
      $branchOfficeId: String!
      $data: UpdateBranchOfficeInput!
    ) {
      branchOfficeMutations {
        update(branchOfficeId: $branchOfficeId, data: $data) {
          ...BranchOfficeFragment
        }
      }
    }
    ${BranchOfficeFrag}
  `,
};
