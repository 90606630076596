import { OfficeMemberType, StudyType } from '@shared/constants';
import { TFunction } from 'i18next';
import { ErrorCode } from '../../../../shared/errors';

export const getStudyTypeLabel = (
  studyType: StudyType,
  t: TFunction,
): string => {
  const MAP_STUDY_TYPE_TO_KEY: Record<StudyType, string> = {
    [StudyType.ElementarySchool]: t('studyType.elementarySchool'),
    [StudyType.SecondarySchool]: t('studyType.secondarySchool'),
    [StudyType.SecondaryModernSchool]: t('studyType.secondaryModernSchool'),
    [StudyType.GrammarSchool]: t('studyType.grammarSchool'),
    [StudyType.ComprehensiveSchool]: t('studyType.comprehensiveSchool'),
    [StudyType.University]: t('studyType.university'),
    [StudyType.TechnicalCollege]: t('studyType.technicalCollege'),
    [StudyType.UniversityOfAppliedSciences]: t(
      'studyType.universityOfAppliedSciences',
    ),
  };

  return MAP_STUDY_TYPE_TO_KEY[studyType];
};

export const getOfficeMemberTypeLabel = (
  officeMemberType: OfficeMemberType,
  t: TFunction,
): string => {
  const labels: Record<OfficeMemberType, string> = {
    [OfficeMemberType.BranchOffice]: t('officeMemberType.branchOffice'),
    [OfficeMemberType.ServicePoint]: t('officeMemberType.servicePoint'),
    [OfficeMemberType.HeadOffice]: t('officeMemberType.headOffice'),
  };

  return labels[officeMemberType];
};

export const ADVERTISER_AGREEMENT_DOCUMENT_NAME = 'barmerAdvertiserAgreement';
export const ADVERTISER_AGREEMENT_ADDENDUM_DOCUMENT_NAME =
  'barmerAdvertiserAddendumAgreement';

export const getErrorCodeLabel = (code: ErrorCode, t: TFunction): string => {
  const MAP_ERROR_CODE_TO_LABEL: Partial<Record<ErrorCode, string>> = {
    [ErrorCode.RefLinkIsNotUnique]: t('error.refLinkIsNotUnique'),
    [ErrorCode.AdvertiserNotApproved]: t('error.advertiserNotApproved'),
    [ErrorCode.BranchOfficeNotFound]: t('error.branchOfficeNotFound'),
    [ErrorCode.EmailTaken]: t('error.emailTaken'),
    [ErrorCode.BadData]: t('error.badData'),
    [ErrorCode.CouldntChangeStatusAlreadyComplete]: t(
      'error.couldntChangeStatusAlreadyComplete',
    ),
    [ErrorCode.CouldntChangeStatusWrongOne]: t(
      'error.couldntChangeStatusWrongOne',
    ),
    [ErrorCode.DocumentMapperNotFound]: t('error.documentMapperNotFound'),
    [ErrorCode.ForbiddenWords]: t('error.forbiddenWords'),
    [ErrorCode.DocumentNotFound]: t('error.documentNotFound'),
    [ErrorCode.InviteCodeRevoked]: t('error.inviteCodeRevoked'),
    [ErrorCode.WrongProvision]: t('error.wrongProvision'),
    [ErrorCode.SignatureSlotIsSigned]: t('error.signatureSlotIsSigned'),
    [ErrorCode.MaxProvisionAmountExceeded]: t(
      'error.maxProvisionAmountExceeded',
    ),
  };

  return MAP_ERROR_CODE_TO_LABEL[code] || t('error.generic');
};

export const ACCEPTABLE_DOCUMENT_MIME_TYPES_AND_EXTENSIONS = {
  bmp: 'image/bmp',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  odt: 'application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.text-template',
  pdf: 'application/pdf',
  png: 'image/png',
  rtf: 'application/rtf',
  tif: 'image/tiff',
};

export const REF_LINK_COOKIE = 'refLink';
