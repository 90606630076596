import { TFunction } from 'i18next';
import { AnySchema } from 'yup/lib';
import * as Yup from 'yup';
import { StepComponent } from '@components/forms/types';
import { Stack, Typography } from '@mui/material';
import { CountrySelect } from '@common/forms/fields/CountrySelect/CountrySelect';
import { DatePicker, TextField } from '@common/forms/fields';
import { DateTime } from 'luxon';
import { StepTitle } from '@components/forms';
import { useTranslation } from 'react-i18next';
import { GLOBAL_MIN_DATE } from '@shared/constants';
import { getLabel } from '@components/forms/getLabelByFieldName';
import { LATIN_REG_EXP } from '@shared/regex';

const getValidationSchema = (t: TFunction): AnySchema =>
  Yup.object().shape({
    main_0_birthCountry: Yup.string().nullable(),
    main_0_birthDate: Yup.date()
      .nullable()
      .typeError(t('validation.invalidDate'))
      .min(
        new Date(GLOBAL_MIN_DATE),
        t('validation.shouldNotBeBeforeDate', {
          date: DateTime.fromISO(GLOBAL_MIN_DATE, { zone: 'UTC' }).toFormat(
            'dd/MM/yyyy',
          ),
        }),
      )
      .max(new Date(), t('validation.shouldNotBeAfterToday'))
      .required(t('validation.required')),
    main_0_birthPlace: Yup.string()
      .matches(LATIN_REG_EXP, t('validation.onlyLatinCharacters'))
      .nullable(),
    main_0_nationality: Yup.string()
      .nullable()
      .required(t('validation.required')),
    main_0_secondNationality: Yup.string().nullable(),
  });

export const BirthInformationStep: StepComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <StepTitle>
        <Typography variant="h4">{t('stepTitle.birthInformation')}</Typography>
      </StepTitle>

      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <CountrySelect
          name="main_0_birthCountry"
          label={getLabel('main_0_birthCountry', t)}
        />
        <TextField
          label={getLabel('main_0_birthPlace', t)}
          name="main_0_birthPlace"
        />
      </Stack>

      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
        <CountrySelect
          name="main_0_nationality"
          label={getLabel('main_0_nationality', t)}
        />
        <CountrySelect
          name="main_0_secondNationality"
          label={getLabel('main_0_secondNationality', t)}
        />
      </Stack>

      <DatePicker
        name="main_0_birthDate"
        label={getLabel('main_0_birthDate', t)}
      />
    </Stack>
  );
};

BirthInformationStep.getValidationSchema = getValidationSchema;
