import { getLabel } from '@components/forms/getLabelByFieldName';
import { Maybe } from '@shared/types';
import { TFunction } from 'react-i18next';

export const getFullName = ({
  firstName,
  lastName,
}: {
  firstName: Maybe<string>;
  lastName: Maybe<string>;
}): string => [firstName, lastName].filter(Boolean).join(' ').trim() ?? '';

const parseIndex = (index: string | number): number =>
  typeof index === 'string' ? parseInt(index, 10) : index;

export const getInsuranceCaseFullName = ({
  profileType,
  index = 0,
  insuranceCase,
}: {
  index?: number | string;
  insuranceCase: any;
  profileType: string;
}): string => {
  const { profile, spouseProfile, childProfiles } = insuranceCase;
  if (profileType === 'main') {
    return getFullName(profile);
  }
  if (profileType === 'spouse') {
    return getFullName(spouseProfile);
  }

  const childProfile = childProfiles.find(
    ({ index: idx }) => idx === parseIndex(index),
  );
  return childProfile ? getFullName(childProfile) : '';
};

export const getProfileTypeWithFullName = ({
  profileType,
  index = 0,
  insuranceCase,
  t,
}: {
  index?: number | string;
  insuranceCase: any;
  profileType: string;
  t: TFunction;
}): string => {
  return `
  ${getProfileTypeLabel({ index, profileType, t })} (${getInsuranceCaseFullName(
    {
      index,
      insuranceCase,
      profileType,
    },
  )})`;
};

export const getProfileTypeLabel = ({
  index = 0,
  profileType,
  t,
}: {
  index: string | number;
  profileType: string;
  t: TFunction;
}): Maybe<string> => {
  const childIndex = parseIndex(index);

  return {
    child: {
      1: t('profileType.child1'),
      2: t('profileType.child2'),
      3: t('profileType.child3'),
      4: t('profileType.child4'),
      5: t('profileType.child5'),
      6: t('profileType.child6'),
    }[childIndex + 1],
    main: t('profileType.main'),
    spouse: t('profileType.spouse'),
  }[profileType];
};

export const getInsProfileDocumentFullLabel = ({
  documentName,
  insuranceCase,
  t,
}: {
  documentName: string;
  insuranceCase: any;
  t: TFunction;
}): string => {
  const [profileType, index] = documentName.split('_');

  return `${t('posessiveObj', {
    object: getInsuranceCaseFullName({
      index,
      insuranceCase,
      profileType,
    }),
  })} ${getLabel(documentName, t)}`;
};

const DEFAULT_SORT = ['main', 'spouse', 'child'];

export const groupByInsProfile = <TEntity extends Record<string, any>>({
  childProfiles = [],
  getFieldName,
  items,
  profile,
  spouseProfile = {},
}: {
  childProfiles: any[];
  getFieldName: (entity: TEntity) => string;
  items: TEntity[];
  profile: any;
  spouseProfile?: any;
}): any[] => {
  const byGroup = items.reduce((acc, item) => {
    const [profileType, index] = getFieldName(item).split('_');
    const groupName = `${profileType}_${index}`;

    return {
      ...acc,
      [groupName]: acc[groupName]
        ? {
            ...acc[groupName],
            items: [...acc[groupName].items, item],
          }
        : {
            fullName: getFullName(
              {
                main_0: profile,
                spouse_0: spouseProfile,
                ...childProfiles.reduce(
                  (acum, childProfile) => ({
                    ...acum,
                    [`child_${childProfile.index}`]: childProfile,
                  }),
                  {},
                ),
              }[groupName] || {},
            ),
            groupName,
            index: parseInt(index, 10),
            items: [item],
            profileType,
          },
    };
  }, {});

  return Object.values(byGroup).sort((a: any, b: any) => {
    const aIndex = DEFAULT_SORT.indexOf(a.profileType);
    const bIndex = DEFAULT_SORT.indexOf(b.profileType);

    if (a.profileType === 'child' && b.profileType === 'child') {
      return a.index - b.index;
    }

    return aIndex - bIndex;
  });
};
