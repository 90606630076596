import { FormApi } from 'final-form';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { makeValidate } from 'mui-rff';
import { Step } from '@components/forms/types';
import { withRejectedFieldsValidation } from '@components/forms/withRejectedFieldsValidation';

interface Props {
  formApi: FormApi<any, any>;
  setLastEnabledStepIndex: Dispatch<SetStateAction<number>>;
  currentStep: Step;
  steps: Step[];
  isInitialValidationEnabled: boolean;
  rejectedFieldGroups: string[][];
  rejectedValues: Record<string, any>;
  formMeta: Record<string, any>;
}

export const InitialValidation: FC<Props> = ({
  steps,
  currentStep,
  formApi,
  isInitialValidationEnabled,
  setLastEnabledStepIndex,
  rejectedFieldGroups = [],
  rejectedValues = {},
  formMeta = {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentStepIndex = steps.findIndex(
    ({ stepName }) => currentStep.stepName === stepName,
  );
  useEffect(() => {
    const schemasBeforeStep = steps
      // CRUTCH-COUPLE: include current step if has any rejected fields
      // todo: check
      .slice(
        0,
        rejectedFieldGroups.length ? currentStepIndex + 1 : currentStepIndex,
      )
      .map(({ component, meta: stepMeta }) =>
        component?.getValidationSchema?.(t, { ...formMeta, ...stepMeta }),
      );
    const currentFormValues = formApi.getState().values;
    const validateStepsBefore = async () => {
      for (const [index, schema] of schemasBeforeStep.entries()) {
        if (!schema) continue;
        const validationErrors = await makeValidate(
          withRejectedFieldsValidation(schema)({
            rejectedFieldGroups,
            rejectedValues,
            t,
          }) as any,
        )(currentFormValues);
        const validationErrorFields = Object.keys(validationErrors);

        if (validationErrorFields.length) {
          // do not force block sidebar and no force redirected if disabled
          if (isInitialValidationEnabled) {
            setLastEnabledStepIndex(index);
            navigate(steps[index].stepName);
          }
          // triggers validation for current step if it has any error
          if (index === currentStepIndex) {
            setTimeout(() => formApi.submit(), 1);
            return;
          }
        }
      }
    };
    validateStepsBefore();
  }, [currentStep]);

  return null;
};
