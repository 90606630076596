import { FC } from 'react';
import { ROUTES } from '@common/routes/routes';
import { Navigate, Route, Routes } from 'react-router';
import AdvertiserApplicantsPage from '@pages/AdvertiserApplicantsPage';
import AdvertiserRefLinksPage from '@pages/AdvertiserRefLinksPage';
import AdvertiserSettingsPage from '@pages/AdvertiserSettingsPage';
import AdvertiserVerificationPage from '@pages/AdvertiserVerificationPage';
import AdvertiserVerificationPendingPage from '@pages/AdvertiserVerificationPendingPage';
import AdvertiserInsuranceCaseDraftPage from '@pages/AdvertiserInsuranceCaseDraftPage';
import { DashboardLayout } from '@src/layouts/Dashboard';
import { AppBarLayout } from '@src/layouts/AppBarLayout';
import AdvertiserDocumentsPage from '@pages/AdvertiserDocumentsPage';
import HelpCenterPage from '@pages/HelpCenterPage';
import { SignAddendumNotification } from '@components/SignAddendumNotification';
import { AdvertiserType } from '@shared/constants';
import AdvertiserMembersPage from '@pages/AdvertiserMembersPage';
import InviteCodePage from '@pages/InviteCodePage';
import AdvertiserInsuranceCasePage from '@pages/AdvertiserInsuranceCasePage';
import { AdvertiserRefLinkDetailsPage } from '@pages/AdvertiserRefLinkDetailsPage/AdvertiserRefLinkDetailsPage';
import { AdvertiserStatus } from '../../../types/globalTypes';
import { AdvertiserUndersPage } from '../../pages/AdvertiserUndersPage/AdvertiserUndersPage';
import { LoadCurrentAdvertiser } from './LoadCurrentAdvertiser';
import { AdvertiserPublicIdBadge } from './AdvertiserPublicIdBadge';

export const AdvertiserRoutes: FC = () => (
  <LoadCurrentAdvertiser>
    {({ id: advertiserId, publicId, status, type, provision }) => {
      return (
        <>
          <Routes>
            {status === AdvertiserStatus.PROFILE && (
              <>
                <Route element={<DashboardLayout />}>
                  <Route
                    path={`${ROUTES.ADVERTISER.verification.path}/*`}
                    element={<AdvertiserVerificationPage />}
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    <Navigate to={ROUTES.ADVERTISER.verification()} replace />
                  }
                />
              </>
            )}
            {status === AdvertiserStatus.PENDING && (
              <>
                <Route element={<AppBarLayout />}>
                  <Route
                    path={ROUTES.ADVERTISER.verificationPending.path}
                    element={<AdvertiserVerificationPendingPage />}
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={ROUTES.ADVERTISER.verificationPending()}
                      replace
                    />
                  }
                />
              </>
            )}
            {status === AdvertiserStatus.CONTRACT_SIGNING && (
              <>
                <Route
                  element={
                    <DashboardLayout
                      sidebarHeader={
                        <AdvertiserPublicIdBadge publicId={publicId} />
                      }
                    />
                  }
                >
                  <Route
                    path={ROUTES.ADVERTISER.documents.path}
                    element={<AdvertiserDocumentsPage />}
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    <Navigate to={ROUTES.ADVERTISER.documents()} replace />
                  }
                />
              </>
            )}
            {[
              AdvertiserStatus.APPROVED,
              AdvertiserStatus.ADDENDUM_SIGNING,
            ].includes(status) && (
              <>
                <Route element={<AppBarLayout />}>
                  <Route
                    path={`${ROUTES.ADVERTISER.insuranceCaseDetails.path}/*`}
                    element={<AdvertiserInsuranceCasePage />}
                  />
                </Route>
                <Route element={<AppBarLayout />}>
                  <Route
                    path={`${ROUTES.ADVERTISER.insuranceCaseDraft.path}/*`}
                    element={<AdvertiserInsuranceCaseDraftPage />}
                  />
                </Route>
                <Route
                  element={
                    <DashboardLayout
                      sidebarHeader={
                        <>
                          <AdvertiserPublicIdBadge publicId={publicId} />
                          <SignAddendumNotification status={status} />
                        </>
                      }
                    />
                  }
                >
                  <Route
                    path={`${ROUTES.ADVERTISER.applicants.path}/*`}
                    element={<AdvertiserApplicantsPage status={status} />}
                  />
                  <Route
                    path={`${ROUTES.ADVERTISER.refLinks.path}/*`}
                    element={<AdvertiserRefLinksPage />}
                  />
                  <Route
                    path={`${ROUTES.ADVERTISER.refLinkDetails.path}/*`}
                    element={<AdvertiserRefLinkDetailsPage />}
                  />
                  <Route
                    path={ROUTES.COMMON.settings.path}
                    element={<AdvertiserSettingsPage />}
                  />
                  {type === AdvertiserType.Main && (
                    <>
                      <Route
                        path={ROUTES.ADVERTISER.unders.path}
                        element={
                          <AdvertiserUndersPage
                            advertiserId={advertiserId}
                            advertiserProvision={provision}
                          />
                        }
                      />
                      <Route
                        path={ROUTES.ADVERTISER.inviteCodes.path}
                        element={<InviteCodePage />}
                      />
                    </>
                  )}
                  <Route
                    path={ROUTES.ADVERTISER.members.path}
                    element={
                      <AdvertiserMembersPage
                        advertiserId={advertiserId}
                        publicId={publicId}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.ADVERTISER.documents.path}
                    element={<AdvertiserDocumentsPage />}
                  />
                  <Route
                    path={ROUTES.COMMON.help.path}
                    element={<HelpCenterPage />}
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    <Navigate to={ROUTES.ADVERTISER.applicants()} replace />
                  }
                />
              </>
            )}
          </Routes>
        </>
      );
    }}
  </LoadCurrentAdvertiser>
);

export default AdvertiserRoutes;
