import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as React from 'react';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { useMutation } from '@apollo/client';
import { GQL } from '@common/queries';
import { useSnackbar } from 'notistack';
import { ROUTES } from '@common/routes';

interface Props {
  advertiserRefLink: any;
}

export const AdvertiserRefLinkActionsCell: React.FC<Props> = ({
  advertiserRefLink,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const [deleteRefLink] = useMutation(GQL.ADVERTISER_REF_LINK.DELETE);

  const onDeleteClick = async () => {
    confirm({
      cancellationText: t('cancel'),
      confirmationText: t('yes'),
      description: t('deleteAdvertiserRefLinkConfirmation.description'),
      title: t('deleteAdvertiserRefLinkConfirmation.title'),
    }).then(async () => {
      try {
        await deleteRefLink({
          update: cache => {
            cache.modify({
              fields: {
                advertiserRefLinksPaged(connection, { readField }) {
                  return {
                    ...connection,
                    edges: connection.edges.filter(({ node }) => {
                      return readField('id', node) !== advertiserRefLink.id;
                    }),
                    pageInfo: {
                      ...connection.pageInfo,
                      count: connection.pageInfo.count - 1,
                    },
                  };
                },
              },
            });
          },
          variables: {
            advertiserRefLinkId: advertiserRefLink.id,
          },
        });

        enqueueSnackbar(t('deleteAdvertiserRefLinkSnack.success'), {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(t('error.generic'), { variant: 'error' });
      }
    });
  };

  return (
    <>
      <IconButton
        onClick={event => {
          event.stopPropagation();
          openMenu(event);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={(event: SyntheticEvent) => {
          event.stopPropagation();
          closeMenu();
        }}
        open={!!anchorEl}
      >
        <MenuItem
          onClick={event => {
            event.stopPropagation();
            onDeleteClick();
            closeMenu();
          }}
          sx={{ color: 'text.primary' }}
        >
          {t('delete')}
        </MenuItem>
        <MenuItem
          disabled={!advertiserRefLink.casesCount}
          onClick={() => {
            window.open(
              ROUTES.ADVERTISER.refLinkDetails({
                refLinkId: advertiserRefLink.id,
              }),
              '_blank',
            );
          }}
          sx={{ color: 'text.primary' }}
        >
          {t('viewApplications')}
        </MenuItem>
      </Menu>
    </>
  );
};
